.container {
  background-color: rgb(240, 239, 239);
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.headerSection {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}
.newdiv {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 20%;
  left: 20%;
  z-index: 100;
}
.loader {
  /* background-color: #fafbfc; */
  /* border-radius: 30px 30px 30px 30px; */
  z-index: 150;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.changeDiv3 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 85%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 8%;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
}
.sectionStyle {
  display: grid;
  grid-template-columns: 17rem auto auto;
  height: 100vh;
}

.topHeading {
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  justify-content: space-between;
  align-items: center;
  height: 9vh;
  width: 69vw;
}

.leftHead {
  color: #333333;
  padding: rem;
}
.rightHead {
  color: #333333;
  padding-top: 0.5rem;
  font-weight: bold;
}
.footer {
  width: 70.3%;
  height: 14%;
  background-color: transparent;
  position: absolute;
  top: 95%;
  left: 19.1%;
}
.filter {
  height: 50px;
  margin: -4% 0 0 0;
  cursor: pointer;
  visibility: hidden;
}

.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.labelImg {
  height: 35%;
  width: 5%;
  cursor: pointer;
}

.nodata {
  display: block;
  width: 50%;
  margin: 5% auto;
}

.tagline {
  color: #24242e;
  font-weight: 600;
  font-size: 1rem;
}

.centered_button {
  opacity: 1;
}

.centered {
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  left: 20%;
  z-index: 150;
  margin: 10% 0% 0 5%;
  position: absolute;
  box-shadow: 20pt 23pt 100pt;
}

.addBlurr {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

.createOpp {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #1e118d;
  font-weight: 500;
  height: 1.3em;
  width: 100%;
  border-radius: 14px;
  border: 1px solid #110766;
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  margin-top: -2.5%;
}

.options img {
  margin-left: 25em;
}

/* tabs */
@media only screen and (max-width: 768px) {
  .sectionStyle {
    grid-template-columns: 0px auto auto;
  }
  .newdiv {
    left: -11%;
    top: 38%;
  }
  .headerStyle {
    width: 100vw;
  }
  .topHeading {
    margin: 0;
    width: 95vw;
  }
  .centered {
    font-family: "Poppins-medium";
    width: 100%;
    height: 75%;
    position: absolute;
    top: 20%;
    left: 0%;
    margin-left: 0%;
  }
  .leftHead {
    padding-top: 2rem;
  }
  .options img {
    margin-left: 0;
  }
  .tagline {
    font-size: 1rem;
  }
  .rightHead {
    margin-top: 0.3rem;
  }
  .createOpp {
    padding: 0.5rem;
    height: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .leftHead {
    padding: 0;
  }
  .tagline {
    font-size: 0.8rem;
  }
  .createOpp {
    font-size: 0.8rem;
    height: 100%;
  }
  .changeDiv3 {
    width: 85%;
    left: 5%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1080px) {
  .headerSection {
    padding: 0;
  }
  .centered {
    width: 85%;
    height: 80%;
    background-color: #ffffff;
    position: absolute;
    top: 14%;
    left: 13%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .options {
    margin-left: -8rem;
  }
  .sectionStyle {
    display: grid;
    grid-template-columns: 14% 57% 26%;
    height: 100vh;
  }
  .createOpp {
    height: 3.3em;
  }
  .changeDiv3 {
    width: 65%;
    left: 19%;
    height: auto;
    padding-bottom: 10px;
  }
}
