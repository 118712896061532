    .container {
        /* width: 35%; */
        border-radius: 30px;
        background-color: #FFFFFF;
        ;
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .closeModal {
        float: right;
        width: 25px;
        height: 25px;
        margin: 2% 4% 0 0;
        cursor: pointer;
    }
    
    .para {
        font-size: 18px;
        padding: 5% 0% 0% 7%;
    }
    
    .form1 {
        display: block;
    }
    
    .label {
        display: inline-flex;
        color: #919191;
        font-size: 11pt;
        justify-content: right;
        margin: 10px 0 5px 40px;
        font-weight: 500;
        ;
    }
    
    .input {
        display: flex;
        width: 64%;
        height: 23px;
        align-items: center;
        justify-content: flex-end;
        padding: 1% 13% 1% 4%;
        margin-left: 40px;
        border-radius: 6pt;
        border: 1pt solid #B9B9B9;
        opacity: 0.5;
        font-size: 11pt;
    }
    
    .keyword img {
        width: 5%;
    }
    
    .skilldiv {
        display: flex;
        width: 81%;
        height: 38px;
        align-items: center;
        justify-content: flex-end;
        /* padding: 1% 0% 1% 4%; */
        margin-left: 40px;
        border-radius: 6pt;
        border: 1pt solid #B9B9B9;
        opacity: 0.5;
        font-size: 11pt;
    }
    
    .inputcategory {
        display: flex;
        width: 93%;
        height: 100%;
        align-items: center;
        justify-content: flex-end;
        /* padding: 1% 13% 1% 4%; */
        /* margin-left: 51px; */
        border-radius: 6pt;
        /* border: 1pt solid #B9B9B9; */
        border: none;
        opacity: 0.5;
        font-size: 11pt;
    }
    
    .input:focus,
    .input1:focus,
    .input2:focus,
    .inputcategory {
        outline: none;
    }
    
    .subheading {
        display: inline-flex;
        justify-content: right;
        margin: 10px 0 5px 40px;
        font-weight: 600;
        ;
        color: #333333;
        font-size: 12pt;
    }
    
    .inputcalendar {
        width: 116%;
        height: 50%;
        margin: 2% 0% 0% 20%;
        border-radius: 6pt;
        border: 1pt solid #B9B9B9;
        display: flex;
    }
    
    .input1 {
        border: 1px solid #B9B9B9;
        /* border: none; */
        width: 100%;
        font-size: 15px;
        opacity: 0.5;
        color: #333333;
        margin: 2% 0% 0% 16%;
        height: 55%;
        border-radius: 9px;
    }
    
    .input2 {
        border: 1px solid #B9B9B9;
        width: 110%;
        font-size: 15px;
        opacity: 0.5;
        color: #333333;
        margin: 2% 0% 0% 15%;
        height: 55%;
        border-radius: 9px;
    }
    /* .input1 {
    display: flex;
    width: 90%;
    height: 23pt;
    align-items: center;
    justify-content: flex-end;
    padding: 1% 13% 1% 4%;
    margin-left: 40px;
    margin-top: -20px;
    border-radius: 6pt;
    border: 1pt solid #B9B9B9;
    opacity: 0.5;
    font-size: 11pt;
}*/
    
    .calendarimg {
        width: 12%;
        height: 56%;
        margin: 4% 2% 0% 0%
    }
    
    .modalStyle {
        position: absolute;
        margin: 7% 0% 0% 0%;
        width: 41%;
        border-radius: 5%;
    }
    
    .inputcalendar2 {
        width: 116%;
        height: 50%;
        margin: 2% 0% 0% 20%;
        border-radius: 6pt;
        border: 1pt solid #B9B9B9;
        display: flex;
    }
    
    .calendarimg2 {
        width: 12%;
        height: 56%;
        margin: 4% 2% 0% 0%
    }
    
    .modalStyle2 {
        position: absolute;
        margin: 7% 0% 0% 0%;
        width: 41%;
        border-radius: 5%;
    }
    
    .fourth {
        display: flex;
        flex-wrap: wrap;
    }
    
    .fourth1 {
        display: flex;
        flex-wrap: wrap;
        width: 45%;
    }
    
    .fourth2 {
        margin-left: 55px
    }
    .errorMsgbelow {
        font-size: 0.7rem;
        color: red;
        padding: 2.5% 0 0 6%;
    }
    .btn_css {
        position: relative;
        width: 100%;
        padding: 28px 14px 30px 219px;
    }
    .whiteButton {
        box-shadow: 0 0px 2px 2px #0000001F;
        background-color: #FFFFFF;
        width: 134px;
        height: 35px;
        margin: 6px 0px 0px 14px;
        font-weight: 500;
        border-radius: 14px;
        border: 1px solid #110766;
        opacity: 1;
        color: #110766;
        text-align: center;
        font-size: 16px;
        cursor: pointer;
    }

    @media screen and (max-width: 700px){
    .whiteButton{
        width: 20.8vw;
        font-size: 3vw;
    }
    .btn_css{
        padding: 28px 14px 30px 54px;
    }
    }