.maincontainer {
  background-color: white;
  height: 14rem;
}

.maincard {
  display: grid;
  grid-template-rows: 0px 150px;
  margin-left: 10px;
}

.card {
  border: 1px solid #cfcfd2;
  margin: 15px 0px 0px 0px;
  padding: 16px;
  height: 158px;
  width: 94%;
}

/*.card:hover {
    box-shadow: 20px 20px 18px -8px #00000015;
    transform: scale(1, 1);
}*/

.square {
  margin-left: 1px;
  margin-top: 16px;
  width: 25px;
  height: 25px;
  background-image: -webkit-linear-gradient(-45deg, #36b453 50%, #ffffff 50%);
}

.box1 {
  width: 80%;
}

.heading {
  display: flex;
  flex-direction: row;
  /* background-color: #1E118D; */
  height: 50px;
}
.constantImg {
  display: flex;
  flex-direction: row;
  margin-top: -10px;
}
.constantImg1 {
  display: flex;
  flex-direction: row;
}

.lefthead {
  font-size: 0.9rem;
  width: 94%;
  font-weight: 700;
}

.lefthead p {
  margin: 0px 0px 0px 4px;
}

.lefthead p {
  opacity: 0.6;
  color: #333333;
  font-size: 8px;
  margin: 5px;
}

.detail {
  /* background-color: #b63f49; */
  width: 38px;
}

.detail img {
  width: 24px;
  height: 25px;
  left: 665px;
  top: 44px;
  opacity: 1;
}

.more {
  /* background-color: #3a48c5; */
  width: 38px;
  cursor: pointer;
}

.more img {
  cursor: pointer;
  z-index: 1;
}
.lefthead1 {
  width: 94%;
  font-weight: 700;
}

/* .box1 img {
    width: 24px;
    height: 21px;
    left: 665px;
    top: 44px;
    
} */

.table {
  font-size: 15px;
  min-width: 548px;
  /* margin: 25px 0px; */
}

.table tbody {
  margin: 25px;
}

.shelf {
  flex: 1 1 auto;
  width: 93%;
  margin-bottom: 0.8%;
  margin-left: 1%;
  display: flex;
  flex-direction: row;
}
.shelf:last-child {
  margin-bottom: 0;
}

.labelbox {
  flex: 0 0 35%;
  opacity: 0.6;
  color: #333333;
  font-size: 12px;
}

.valuebox {
  flex: 0 0 65%;
  opacity: 1;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}

.box2 {
  background-color: white;
}

.card_image img {
  height: 10rem;
  width: 10rem;
  margin-top: -11.7rem;
  margin-right: 10px;
  float: right;
}

.card_info {
  width: 30px;
}

.appliedImg {
  height: 10%;
}

.left img {
  width: 45px;
  height: 40px;
  margin-top: -3px;
}

.left p {
  color: #1e118d;
  margin-left: 62px;
  margin-top: -40px;
  font-size: 15px;
  font-weight: bold;
}

.eye {
  margin-right: 4%;
    margin-top: -14%;
}

.right img {
  width: 32px;
  height: 34px;
  margin-left: 257px;
  margin-top: -3px;
}

.right p {
  color: #747474;
  opacity: 1;
  font-size: 13px;
  margin-left: 300px;
  margin-top: -33px;
}

@media screen and (max-width: 1500px) {
  .lefthead {
    width: 84%;
  }
  .lefthead1 {
    width: 83%;
  }
}
@media screen and (max-width: 1300px) {
  .lefthead {
    width: 80%;
  }
  .lefthead1 {
    width: 78%;
  }
}
@media screen and (max-width: 1150px) {
  .lefthead {
    width: 76%;
  }
  .lefthead1 {
    width: 74%;
  }
}
@media screen and (max-width:575px){
  .card_image img{
     height: 5rem;
      width: 5rem;
      margin-top: -49.7%;
      margin-right: 1px;
  }
  .box1{
    width: 100%;
  }
}