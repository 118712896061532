.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100%;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.centered {
  width: 70%;
  height: 80%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.para {
  margin-left: 11%;
  font-size: 1.1rem;
  margin-top: 2.2%;
}

.heading {
  font-size: 14px;
  margin-bottom: 1%;
  color: #24242e;
  padding: 1% 0px 0% 47px;
}

.img {
  /* width: 20%;  */
  height: 100px;
  width: 150px;
  margin-top: 0%;
  margin-left: 5%;
  border-radius: 6px 6px 6px 6px;
  background-color: #f7f7f7;
  object-fit: contain;
}

.img1 {
  width: 2.5rem;
  margin-top: 9%;
  margin-left: -10%;
  position: absolute;
}

.divFirst {
  display: grid;
  grid-template-columns: repeat(2, 1rf);
  margin: auto;
}

.label1 {
  color: #919191;
  font-size: 13px;
  margin-left: 4%;
  margin-top: 1.8%;
  margin-bottom: 0.8%;
}

.input1 {
  color: #0b2342;
  font-size: 13px;
  margin-left: 4%;
  border-radius: 6px;
  width: 92%;
  height: 110%;
  opacity: 1;
  padding: 2px;
  border: 1pt solid #b9b9b9;
}

.input1:focus {
  outline: none;
}

.line {
  margin-top: 2.5%;
  color: #707070;
  opacity: 0.5;
}
.baseone {
  display: block;
  padding: 0 4%;
}
.basetwo {
  display: block;
  width: 30%;
  text-align: center;
}
.div2 {
  display: grid;
  grid-template-columns: 50% 50%;
}

.left {
  flex-direction: column;
  width: 120%;
}

.right {
  flex-direction: column;
  width: 70%;
  height: 1%;
}

.form1 {
  display: grid;
  grid-template-rows: repeat(6);
}

.form2 {
  display: grid;
  grid-template-rows: repeat(2);
}

.label2 {
  color: #919191;
  font-size: 13px;
  margin-left: 6%;
  margin-top: 2rem;
  margin-bottom: 0.8%;
}

.input2 {
  font-size: 13px;
  margin-left: 6%;
  padding: 2px;
  border-radius: 6px 6px 6px 6px;
  width: 75%;
  opacity: 1;
  height: 110%;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
  color: #0b2342;
}

.input2:focus {
  outline: none;
}

.input2 option {
  background: none;
}
.errorMsg {
  font-size: 0.7rem;
  color: red;
  padding: 2% 0 0 4%;
}

.errorMsgbelow {
  font-size: 0.7rem;
  color: red;
  padding: 2.5% 0 0 6%;
  pointer-events: none;
}

.errorMsgta {
  font-size: 0.7rem;
  color: red;
  padding: 10% 0 0 6%;
  pointer-events: none;
}

.location {
  font-size: 13px;
  margin-left: 6%;
  padding: 3px;
  border-radius: 6px 6px 6px 6px;
  width: 75%;
  opacity: 1;
  height: 110%;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
  color: #0b2342;
}

.location:focus {
  outline: none;
}

.label3 {
  color: #919191;
  font-size: 13px;
  margin-left: 6%;
  margin-top: 2rem;
  margin-bottom: 0.8%;
}

.input3 {
  font-size: 13px;
  margin-left: 6%;
  border-radius: 6px 6px 6px 6px;
  width: 125%;
  opacity: 1;
  height: 110%;
  padding: 2px;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
}

.input3:focus {
  outline: none;
}

.textarea1 {
  font-size: 13px;
  padding: 10px;
  margin-left: 6%;
  border-radius: 6px 6px 6px 6px;
  width: 125%;
  opacity: 1;
  height: 140%;
  padding: 2px;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
  resize: none;
}

.textarea1:focus {
  outline: none;
}

.button1 {
  height: 8%;
  width: 10%;
  background-color: #1e118d;
  color: white;
  border-radius: 14px 14px 14px 14px;
}

.createButton {
  height: 6%;
  width: 10%;
  border-radius: 14px 14px 14px 14px;
  border-color: #1e118d;
  color: #1e118d;
  margin: 8% 2% 3% 60%;
}

.saveButton {
  height: 6%;
  width: 10%;
  border-radius: 14px 14px 14px 14px;
  color: white;
  background-color: #1e118d;
}

.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 10% 33%;
  z-index: 150;
  position: absolute;
}

.topHeading {
  display: block;
  width: 100%;
  height: 5%;
}
.whiteBtn {
  margin: 5% 0% 0% 63%;
}
.blueBtn {
  margin: -5% 0% 0% 80%;
}
.buttonDiv {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  right: 28px;
  margin-top: 2rem;
}

@media only screen and (max-width: 700px) {
  .centered {
    width: 100%;
    left: 0;
  }
  .whiteBtn {
    margin-left: 40%;
    margin-top: 2rem;
    padding: 1px 6px;
  }
  .blueBtn {
    margin-left: 65%;
    margin-top: -3rem;
  }
  .img1 {
    margin-top: 21%;
    margin-left: -33%;
  }
  .div2 {
    grid-template-columns: 1fr;
    margin-left: -1rem;
  }
  .form2 {
    margin-left: 0.6rem;
  }
  .textarea1 {
    width: 80vw !important;
  }
  .buttonDiv {
    margin-top: 1rem;
  }
}
