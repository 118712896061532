.container {
  display: grid;
  /* / grid-template-rows: 4rem 36rem 5rem; / */
  grid-template-rows: 4rem 57rem;
  height: 100%;
  width: 100vw;
}

.background_img {
  width: 92%;
  height: 100%;
  margin: 0% 8%;
  background-repeat: no-repeat;
  background-size: cover;
}

.centered_text {
  font-size: 2.92vw;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.centered_button {
  opacity: 1;
}

.spanStyle {
  font-size: 24px;
}

.sectionStyle {
  z-index: 90;
  overflow: hidden;
}

.loader_style {
  margin: 20% auto;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.footerStyle {
  z-index: 100;
  padding-left: 5%;
  box-shadow: 0 0px 3px 8px #00000029;
}

.centered {
  font-family: "Poppins";
  font-weight: 500;
  z-index: 1;
  position: absolute;
  top: 32%;
  left: 18%;
}

.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 11% 29%;
  z-index: 150;
  position: absolute;
}
.changeDiv1 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 12% 34%;
  z-index: 150;
  position: absolute;
}
.changeDiv2 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 17% 39%;
  z-index: 150;
  position: absolute;
}
.addBlurr {
  filter: brightness(40px);
  -webkit-filter: blur(40px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

@media screen and (max-width: 1600px) {
  .changeDiv {
    margin: 4% 28%;
  }
  .changeDiv1 {
    margin: 12% 34%;
  }
  .changeDiv2 {
    margin: 15% 35%;
  }
}

@media screen and (max-width: 1400px) {
  .changeDiv {
    margin: 2% 27%;
  }
  .changeDiv1 {
    margin: 12% 34%;
  }
  .changeDiv2 {
    margin: 12% 35%;
  }
}
@media screen and (max-width: 1366px) {
  .changeDiv {
    margin: 2% 27%;
  }
  .changeDiv1 {
    margin: 8% 34%;
  }
  .changeDiv2 {
    margin: 10% 35%;
  }
}

@media (max-width: 575px) {
  .background_img {
    height: 350px;
  }
}

@media screen and (max-width: 766px) {
  .centered_text {
    font-size: 2.19vw;
  }
  .centered {
    position: absolute;
    top: 32%;
    left: 8%;
  }
  .changeDiv {
    margin: 5% 5%;
    width: 85vw;
  }
  .spanStyle {
    font-size: 1.31vw;
  }
  .background_img {
    height: 60.97vh;
  }
  .changeDiv1 {
    margin: 21% 5%;
  }
  .changeDiv2 {
    margin: 10% 2%;
  }
}

.hero {
  /* max-height: 400px; */
  border-radius: 30px 30px 0 0;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  background: #fff;
}
.left {
  padding-top: 40px;
  width: 40%;
}
.left p:nth-child(1) {
  font-size: 16px;
}
.left p:nth-child(2) {
  font-size: 16px;
}
.left button {
  background: #9675e4 0% 0% no-repeat padding-box;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 10px;
  color: #fff;
  box-shadow: 0px 3px 6px #00000029;
}
.right {
  display: flex;
  text-align: center;
  position: relative;
  width: 50%;
}
.right img:nth-child(1) {
  height: 400px;
  width: 100%;
  margin-top: 100px;
}
.right img:nth-child(2) {
  position: absolute;
  top: 60%;
  left: 50%;
  height: 50px;
  width: 50px;
}
.provide {
  background: #eff4f8;
  padding: 20px;
}
.provideTitle {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 80px auto 40px auto;
  width: 90%;
}
.provideTitle div {
  border: 1px solid #8674ec;
  width: 100px;
}

.provideBox {
  display: grid;
  width: 90%;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
  grid-gap: 20px;
}

.provideBoxItem {
  background: #fff;
  box-shadow: 5px 30px 40px #00000040;
  border-radius: 30px;
  padding: 20px;
  /* width: 40%; */
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.provideBoxItem:hover {
  transform: scale(1.1);
  background: linear-gradient(264deg, #6a68d2 0%, #5f5dbd 100%);
}
.provideBoxItem img {
  margin-left: 40px;
}
.provideBoxItem .withHover {
  display: none;
}
.provideBoxItem:hover .withHover {
  display: block;
}
.provideBoxItem:hover .withoutHover {
  display: none;
}
.provideBoxItem p {
  margin: 0;
  /* width: 35%; */
  padding: 2px 0;
  margin-left: 40px;
  color: #000;
}
.provideBoxItem:hover p {
  color: #fff;
}
.provideBoxItem p:nth-child(2) {
  font-weight: bold;
}
.provideBoxItem button {
  border: 1px solid #9977e8;
  border-radius: 9px;
  outline: none;
  cursor: pointer;
  color: #9977e8;
  background: transparent;
  padding: 10px;
  margin-left: 40px;
  margin-top: 20px;
}
.provideBoxItem:hover button {
  border: 1px solid #fff;
  color: #fff;
}

.choose {
  background: #fff;
  padding: 20px;
}
.chooseTitle {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 30px auto 40px auto;
  width: 90%;
}
.chooseTitle div {
  border: 1px solid #8674ec;
  width: 100px;
}

.item {
  width: 90%;
  margin: auto;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
}
.item div {
  border-radius: 30px;
  box-shadow: 2px 10px 20px #0000001a;
  background: #f5f8fb;
  text-align: center;
  padding: 40px;
}
.item div p:nth-child(2) {
  font-weight: bold;
}
.trusted {
  padding: 20px;
  background: #eff4f8;
}
.trustTitle {
  width: 90%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trustTitle div:nth-child(1),
.trustTitle div:nth-child(3) {
  background: #8674ec;
  width: 10%;
  height: 2px;
}
.trustTitle div:nth-child(2) {
  font-size: 1.8em;
  font-weight: bold;
}

.companies {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
}
.companies div {
  justify-self: center;
  align-self: center;
}
.companies div img {
  /* width:150px;
    height: 100px; */
}

@media screen and (max-width: 900px) {
  .left {
    width: 100%;
    text-align: center;
    height: auto;
  }
  .left p:nth-child(1) {
    font-size: 20px;
  }
  .left p:nth-child(2) {
    font-size: 16px;
  }
  .right {
    display: none;
  }
  .chooseTitle {
    font-size: 20px;
  }
  .item {
    grid-template-columns: 1fr;
  }
  .provideTitle {
    font-size: 20px;
  }
  .provideTitle div {
    width: 89px;
  }
  .provideBox {
    grid-template-columns: 1fr;
  }
  .trustTitle div:nth-child(2) {
    font-size: 12px;
  }
  .landingPage {
    /* margin-top: 0; */
  }
  .companies {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
  .companies div img {
    /* height: 50px; */
    width: 120px;
  }
}
@media screen and (max-width: 600px) {
  .provideTitle {
    font-size: 18px;
    margin-top: 10px;
  }
  .provideTitle div {
    width: 70px;
  }

  .chooseTitle {
    font-size: 18px;
  }
  .chooseTitle div {
    width: 70px;
  }
  .companies {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
  .companies div img {
    /* height: 50px; */
    width: 100px;
  }
  .trustTitle {
  }
  .trustTitle div:nth-child(1),
  .trustTitle div:nth-child(3) {
    background: #8674ec;
    width: 5%;
    height: 2px;
  }
  .trustTitle div:nth-child(2) {
    font-size: 0.8em;
  }
  /* .trustTitle div:nth-child(2){
        font-size: 12px;
    } */
}
@media screen and (max-width: 400px) {
  .trustTitle div:nth-child(2) {
    font-size: 0.6em;
  }
}

/* media query for ipad */
@media screen and (max-width: 1080px) {
  .changeDiv {
    margin: 10% 25%;
  }
  .headerStyle {
    padding: 0;
  }
  .changeDiv2,
  .changeDiv,
  .changeDiv1 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
  }
  .changeDiv1 {
    padding: 10px;
  }
}
