.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}

.background_img {
  width: 80%;
  height: 110%;
}

.centered_text {
  font-size: 3.125rem;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.centered_button {
  opacity: 1;
}

/* span {
          font-size: 24px;
        } */

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}
.loader_style {
  margin: 20% auto;
}
.rows {
  display: flex;
  flex-direction: row;
  margin-left: 20%;
  margin-top: 3%;
}

.flexGap {
  display: inline-flex;
  flex-wrap: wrap;
}
.flexGap > div {
  margin-left: 14px;
}

.div2 img {
  width: 60%;

  height: 20rem;
  margin: 5% 0% 0% 20%;
  border: 1 px solid lightgray;
  object-fit: contain;
  background: white;
}

.div2 h1 {
  font-size: 1.125rem;
  font-weight: bold;
  color: #161233;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 3px;
}

.div2 p {
  opacity: 1;
  color: #707070;
  display: flex;
  margin-top: -10px;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
}

.whiteBackground {
  width: 60%;
  height: 4rem;
  margin: -1.5% 0% 0% 20%;
  background-color: #ffffff;
}

.div2 a {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #122190;
  opacity: 1;
}

.div3 {
  background-color: #ffffff;
  text-align: center;
  border-radius: 15px;
  padding: 1% 7% 1% 7%;
}

.div3 h1 {
  font-weight: bold;
  font-size: 3.125rem;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.div3 p {
  font-weight: bold;
  margin-top: -44px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.div4 {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 1% 5% 2% 5%;
  margin-left: 5%;
}

.div4 h1 {
  font-weight: bold;
  font-size: 3.125rem;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.div4 p {
  font-weight: bold;
  margin-top: -44px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.div5 {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 1% 4% 1% 4%;
  margin-left: 5%;
}

.div5 h1 {
  font-weight: bold;
  font-size: 3.125rem;
  color: #1e118d;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.div5 p {
  font-weight: bold;

  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: -42px;
}

.imgIcon {
  position: absolute;
  margin-left: 11%;
  margin-top: -5%;

  cursor: pointer;
}

@media screen and (max-width: 1550px) and (min-width: 745px) {
  .div2 img {
    width: 60%;
    height: 15rem;
    margin: 5% 0% 0% 20%;
    object-fit: contain;
  }

  .div3 {
    background-color: #ffffff;
    text-align: center;
    border-radius: 15px;
    padding: 1% 7% 1% 7%;
  }
  .whiteBackground {
    margin: -1.8% 0% 0% 20%;
  }

  .div3 h1 {
    font-weight: bold;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
  }

  .div3 p {
    font-weight: bold;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .mainDiv {
    display: flex;
  }

  .div4 {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 1% 5% 2% 5%;
    margin-left: 3%;
  }

  .div4 h1 {
    font-weight: bold;

    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
  }

  .div4 p {
    font-weight: bold;

    display: flex;
    justify-content: center;
    text-align: center;
  }

  .div5 {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 1% 2% 1% 2%;
    margin-left: 3%;
  }

  .div5 h1 {
    font-weight: bold;

    color: #1e118d;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
  }

  .div5 p {
    font-weight: bold;

    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: -42px;
  }

  .imgIcon {
    position: absolute;
    margin-left: 12%;
    margin-top: -7%;
    cursor: pointer;
  }
}

@media screen and (max-width: 1368px) and (min-width: 745px) {
  .imgIcon {
    position: absolute;
    margin-left: 13%;
    margin-top: -7%;
    cursor: pointer;
  }

  .div2 img {
    width: 60%;
    height: 11rem;
    margin: 5% 0% 0% 20%;
    object-fit: contain;
    background-color: white;
  }
  .whiteBackground {
    margin: -2% 0% 0% 20%;
  }
}
@media screen and (max-width: 775px) {
  .div2 img {
    width: 100%;
    height: 21vh;
    margin: 20px 20px 20px -22px;
  }
  .whiteBackground {
    width: 100vw;
    height: 0%;
    margin: -10.5% 0% 0% -12%;
    background-color: #ffffff;
  }
  .rows {
    margin-left: -2%;
    margin-top: 6%;
  }
  .div3 {
    height: 120px;
  }
  .div4 {
    height: 120px;
  }
  .div5 {
    margin-left: -1%;
    margin-top: 40%;
    height: 130px;
    position: absolute;
  }
  .imgIcon {
    margin-left: 96%;
    margin-top: -50%;
    cursor: pointer;
    position: absolute;
  }
}

@media screen and (max-width: 1080px) {
  .rows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    margin-left: 0;
  }
  .imgIcon {
    position: absolute;
    margin-left: 17%;
    margin-top: -13%;
    cursor: pointer;
  }
  .headerStyle {
    padding: 0;
  }
}
