.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}
.changeDiv1 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 40%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 12%;
  position: absolute;
}

/* .heading {
    height: 60px;
    display: grid;
    grid-template-columns: 200px 740px 300px;
}
.arrow img {
    width: 10%;
    margin: 35px 0px 0px 160px;
}
.text p {
    font-weight: bold;
    margin: 30px;
    margin: 30px 0px 0px 0px;
}*/

.filter {
  height: 50px;
  margin: -10px 0 0px 175px;
  cursor: pointer;
}

.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.topHeading {
  width: 100%;
  height: 5%;
  margin: 2% 0 0 0;
  margin: 2% 0 0 0;
  padding: 0% 11%;
}

.labelImg {
  height: 14px;
  cursor: pointer;
}

.label {
  /* float: left; */
  color: #24242e;
  font-weight: 600;
  font-size: 12pt;
  margin: 0px 0 35px 13px;
}

.centered_button {
  opacity: 1;
}

.options img {
  margin-left: 55rem !important;
  margin-top: -2.5rem !important;
}

.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  /* / height: 52%; / */
  left: 20%;
  z-index: 150;
  margin: 10% 0% 0 5%;
  /* / top: 30%; / */
  position: absolute;
  box-shadow: 20pt 23pt 100pt;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.centered {
  width: 70%;
  height: 75%;
  background-color: #f7f7f7;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-y: auto;
}

.header {
  width: 70.3%;
  height: 14%;
  background-color: #f7f7f7;
  position: absolute;
  top: 10%;
  left: 19.1%;
}

.footer {
  width: 70.3%;
  height: 14%;
  background-color: #f7f7f7;
  position: absolute;
  top: 94%;
  left: 19.1%;
}

.keyword {
  width: 94%;
  height: 42%;
  border: 1px solid #dbdbdb;
  background-color: white;
  margin: 10px;
  display: flex;
}

.keyword img {
  width: 18px;
  padding: 10px;
}

.input {
  border: none;
  width: 100%;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
}

.input:focus {
  outline: none;
}

.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  /* height: 52%; */
  left: 20%;
  z-index: 150;
  margin: 10% 0% 0 5%;
  /* top: 30%; */
  position: absolute;
  box-shadow: 20pt 23pt 100pt;
}

.addBlurr {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}
.container {
  overflow: hidden;
}
@media screen and (max-width: 480px) {
  .sectionStyle {
    grid-template-columns: 11% 81%;
  }

  .button {
    width: 110px;
    margin-left: 45%;
  }
}

@media screen and (max-width: 575px) {
  .headerStyle {
    width: 100vw;
  }
  .changeDiv1 {
    width: 85%;
    left: 5%;
  }
  .header {
    width: 92.3%;
    left: 1.1%;
  }
  .centered {
    width: 90%;
    left: 5%;
    top: 19%;
    overflow-x: hidden;
    height: 100vh;
  }
  .labelImg {
    margin-left: -16%;
  }
  .footer {
    width: 25.3%;
    top: 95%;
    left: 35.1%;
    height: 5%;
  }

  .options img {
    margin-left: 14.5rem !important;
  }
  .label {
    font-size: 10.5pt;
  }
  .centered {
    height: 75vh;
  }
}

@media screen and (max-width: 1080px) {
  .header {
    top: 9%;
  }
  .keyword {
    height: 30%;
  }
  .options {
    top: 41%;
  }
  .centered {
    width: 90%;
    left: 10%;
    top: 11%;
    overflow-x: hidden;
    height: 80vh;
  }
  .container {
    height: 100vh;
  }
  .headerStyle {
    padding: 0;
  }
}
