.container {
  display: grid;
  grid-template-columns: 23rem 23rem;
}

.leftContainer {
  background-color: #fafbfc;
  width: 100%;
  height: 107%;
  border-radius: 30px 0 0 30px;
}

.leftContainer .leftLogo {
  padding: 6% 0 0 37%;
}

.leftContainer .leftPara {
  font-size: 0.9rem;
  line-height: 1.3;
  font-weight: 500;
  padding: 2% 0 0 6%;
  opacity: 0.7;
}

.leftContainer .leftImg img {
  width: 80%;
  padding: 7% 0 0 10%;
  height: 200px;
}

.rightContainer {
  background-color: #ffffff;
  border-radius: 0 30px 30px 0;
  width: 100%;
  height: 107%;
}

.rightContainer .title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333333;
  padding: 16% 0% 0% 10%;
  line-height: 0.2;
}

.rightContainer .subTitle p {
  font-size: 0.9rem;

  opacity: 0.5;
}

.rightContainer .formStyle {
  padding-top: 1%;
}

.anchorStyle {
  color: #1e118d;
  font-weight: 500;
  opacity: 1;
  font-size: 0.9rem;
}

.rightContainer .subTitle {
  padding: 0% 0% 0% 10%;
  opacity: 0.5;
  display: inline;
  font-size: 0.9rem;
}

.closeModal {
  float: right;
  width: 25px;
  height: 25px;
  margin: 5%;
  cursor: pointer;
}

.orgRadio {
  grid-area: orgRadio;
  margin-bottom: 2%;
}

.recRadio {
  grid-area: recRadio;
  margin-bottom: 2%;
}

.orgName {
  grid-area: orgname;
}

.emailName {
  grid-area: emailname;
}

.pass {
  grid-area: pass;
}

.allBtn {
  grid-area: allbtn;
}

.formStyle input[type="text"],
.formStyle input[type="password"],
.formStyle input[type="email"] {
  display: flex;
  width: 60%;
  height: 25px;
  align-items: center;
  justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 8pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 0.9rem;
}
.formStyle input:focus {
  outline: none;
}

.formStyle input[type="radio"] {
  height: 20px;
  align-items: center;
  justify-content: flex-end;
  padding: 0.25em;
  border-color: rgb(160, 156, 156);
  opacity: 0.5;
  margin: 0 41% 0 0;
  float: right;
}

.tabLinks {
  background: none !important;
  border: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #333333;
  opacity: 0.8;
  cursor: pointer;
}

.activeTab {
  color: #1e118d;
  font-weight: 600;
  font-size: 0.9rem;

  border-bottom: 5px solid #1e118d;
}

.errorMsg {
  font-size: 0.7rem;
  color: red;
  float: right;
  margin-right: 11%;
}

.lineStyle {
  width: 88%;
  margin-left: 8%;
  margin-top: 1%;
}

.mailtext {
  width: 340px;
  height: 25px;
  align-items: center;
  justify-content: flex-end;
  padding: 0.25em;
  margin-left: 40px;
  border-radius: 8px;
  border-color: rgb(160, 156, 156);
  opacity: 0.5;
}

.mail {
  display: inline-flex;
  font-size: 0.9rem;
  justify-content: right;
  margin: 25px 0 5px 40px;
  font-weight: 500;
  opacity: 0.8;
}

.radioStyle {
  display: grid;
  margin: 0 0 -9px 40px;
  font-weight: 500;
  opacity: 1;
  color: #5c5c5c;
  font-size: 0.9rem;
  grid-template-areas:
    "orgRadio"
    "recRadio";
}

.password {
  display: inline-flex;
  justify-content: right;
  margin-left: 38px;
  margin-top: 10px;
  margin-bottom: 5px;
  opacity: 0.9;
}
.button1 {
  display: block;
  background-color: #ffffff;
  opacity: 1;
  width: 78%;
  height: 22%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 25px 0 0 40px;
  color: #110766;
  border: 1px solid #110766;
  font-size: 0.9rem;
  cursor: pointer;
}
.signup {
  display: block;
  background-color: #110766;
  opacity: 1;
  width: 78%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 25px 0 0 40px;
  color: white;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
}

.icon1 {
  margin: -3.5% 0 0 40.5%;
  position: absolute;
  height: 3.7%;
}

.lastPara {
  margin: 30px 6px 0 40px;
  font-size: 0.8rem;
  opacity: 0.5;
  color: #333333;
  font-weight: 500;
  text-align: start;
  padding-right: 10%;
}

@media screen and (max-width: 1400px) {
  .container {
    display: grid;
    grid-template-columns: 21rem 21rem;
  }
  .leftContainer {
    height: 100%;
  }
  .rightContainer {
    height: 100%;
  }
}

@media screen and (max-width: 700px) {
  .icon1 {
    margin: -6.5% 0 0 79.5%;
  }
}

@media screen and (max-width: 1080px) {
  .container {
    grid-template-columns: 13rem 21rem;
  }
  .icon1 {
    margin: -4.5% 0 0 49.5%;
    position: absolute;
    height: 3.7%;
  }
  .headerStyle {
    padding: 0;
  }
}
