.centered {
  /* / background-color: rgb(171, 171, 245); / */
  background-color: whitesmoke;
  margin: 6% 15%;
  position: fixed;
  z-index: 100;
  height: 300px;
  width: 33%;
  border-radius: 15px;
  padding-left: 25px;
}

.header {
  display: grid;
  grid-template-rows: repeat(2, 30px) 0px;
  padding: 15px;
}

.alignImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}

.upper img {
  background-color: #fafbfc;
  float: right;
  margin-top: 2px;
}

.delete img {
  width: 45%;
  margin: -20px 0px 0px 83px;
}

.delete p {
  font-size: 15px;
  letter-spacing: -0.24px;
  color: #333333;
  opacity: 1;
  margin: 3px 0px 0px 65px;
}

.reset {
  background-color: #ffffff;
  border: 1px solid #1e118d;
  opacity: 1;
  border-radius: 10px;
  color: #1e118d;
  padding: 5px;
  float: right;
  margin-left: 370px;
  width: 57px;
}

.btn {
  display: flex;
  flex-direction: row;
  margin-top: 10rem;
  margin-left: 2rem;
}

.apply {
  background-color: #1e118d;
  border: 1px solid #1e118d;
  opacity: 1;
  border-radius: 10px;
  color: #ffffff;
  padding: 5px;
  float: right;
  margin-left: 277px;
  width: 57px;
}

.inputage {
  display: grid;
  grid-template-columns: 48% 48%;
  height: 100%;
  /* / background-color: slateblue; / */
}

/* .inputleft input {
  width: 75%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid #B9B9B9;
  ;
  font-size: 11pt;
  margin: 0px;
} */

.input1 {
  width: 95%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  font-size: 11pt;
  margin: 0px;
  padding: 3px;
}

.input1 {
  width: 95%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  font-size: 11pt;
  margin: 0px;
  padding: 3px;
}

.input1 option {
  color: #0b2342;
  opacity: 1;
}

.location {
  margin-top: 30px;
}

.form input {
  margin: 0px;
  width: 78%;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  font-size: 11pt;
  margin: 0px;
}

@media screen and (max-width: 700px) {
  .centered {
    width: 85%;
  }
}

@media screen and (max-width: 1080px) {
  .centered {
    width: 50%;
    height: 325px;
  }
  .btn {
    margin-left: 4rem;
  }
  .headerStyle {
    padding: 0;
  }
}
