.container {
  border: 1px solid #cfcfd2;
  margin: 13px;
}

.container:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  transform: scale(1, 1);
}

.card {
  padding: 10px 10px 10px 10px;

  display: grid;
  grid-template-columns: 10% 40% 40%;
  overflow: hidden;
}

.left {
  width: 100px;
}

.left img {
  height: 100%;
  width: 95%;
}

.middle p {
  font-size: 15px;
  margin: -6px 0px 0px 17px;
}

.table1 {
  font-size: 15px;
  min-width: 230px;
  margin: 0px 0px 0px 13px;
}

.right_table1 {
  min-width: 230px;
  margin: 15px;
}

.category {
  opacity: 0.6;
  color: #333333;

  font-size: 0.8rem;
  font-weight: bold;
  width: 6vw;
  border: 1px solid transparent;

  border-radius: 10px;
}

.value {
  border-radius: 10px;

  border: 1px solid transparent;
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  width: 70%;
  font-weight: 600;
}

.request {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #cfcfd2;
}

.request p {
  margin: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
}

.request:hover {
  background-color: #0f065f;
  color: white;
}

.request:hover p {
  background-color: #0f065f;
  color: white;
}
@media only screen and (max-width: 768px) {
  .card {
    display: block;
  }
  .left {
    width: 75vw;
    height: 51vw;
  }
  .middle p {
    margin: 7px 0px 0px 2px;
  }
  .table1 {
    margin: 0px 0px 0px -5px;
  }
  .right_table1 {
    margin: -5px;
  }
  .category {
    width: 20vw;
  }
  .value {
    width: 53vw;
  }
}

/* 1080 */

@media only screen and (max-width: 1080px) {
  .card {
    padding: 10px 10px 10px 10px;
    display: grid;
    grid-template-columns: 15% 44% 25%;
    overflow: hidden;
  }
}
