/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.container {
  border: 1px solid #cfcfd2;
  margin: 13px;
  /* padding: 10px 10px 10px 10px; */
  /* height: 140px; */
  cursor: pointer;
}

.container:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  transform: scale(1, 1);
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.card {
  /* border: 1px solid #CFCFD2; */
  /* margin: 13px; */
  padding: 10px 10px 10px 10px;
  /* height: 100px; */
  display: grid;
  grid-template-columns: 95px 300px 368px;
  overflow: hidden;
  /* font-family: 'Poppins', sans-serif; */
}

.nodata {
  overflow: hidden;
  font-weight: 500;
  width: 100%;
  height: auto;
  max-height: 75%;
  background-color: #ffffff;
  top: 19%;
  left: 20%;
}

/* .card:hover {
    box-shadow: 10px 10px 12px -4px #00000015;
    transform: scale(1, 1);
} */

.left {
  width: 100px;
  height: 85px;
}

.left img {
  height: 90%;
  width: 95%;
}
.mainDiv{
  display: flex;
   margin: 5px;
}
.middle p {
  font-size: 15px;
  margin: -6px 0px 0px 17px;
}

.table1 {
  font-size: 15px;
  min-width: 100%;
  margin: 0px 0px 0px 13px;
}

.right_table1 {
  min-width: 100%;
  margin: 15px;
}

.category {
  opacity: 0.6;
  color: #333333;
  width: 6vw;
  border: 1px solid transparent;
  font-size: 0.8rem;
  border-radius: 10px;
}

.value {
  opacity: 1;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
  border-radius: 10px;
  width: 70%;
  font-weight: 600;
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.request {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cfcfd2;
   width: 100%;
   height: 35px;
 /* margin-left: 1.4%; */
 margin-top: -1.5%;
}

.request p {
  /* border: 1px solid #cfcfd2; */
 
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
}

.request:hover {
  background-color: #0f065f;
  color: white;
}

.request:hover p {
  background-color: #0f065f;
  color: white;
}

@media screen and (max-width:767px){
  .mainDiv{
    display: block;
     /* margin: 5px; */
  }
  .left {
    grid-column: 1 / 4;
  }
  .table1{
    margin: 0px 0px 0px -7px;
  }
  .left img {
    height: 90%;
    width: 100%;
    /* margin-top: 36%; */
   
  }

  .card {
    
    /* padding: 10px 10px 10px 10px; */
    display: grid;
  }
  .middle p{
    margin: -6px 0px 0px 1px;
  }
  .middle {
    margin: 0;
    width: 100vw;
  }
  .right_table1 {
    min-width: 100vw;
    margin-top: 37vh;
    margin-left: -27vw
  }
  .value {
    opacity: 1;
    color: #333333;
    font-size: 12px;
    font-weight: bold;
    border-radius: 10px;
    width: 80vw;
    font-weight: 600;
  }
}
