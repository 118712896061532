.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
  overflow: hidden;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.loader_styles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 5% 39%;
  z-index: 150;
  position: absolute;
}

.button {
  display: flex;
  flex-direction: row;
  margin-left: 20%;
  padding-top: 1rem;
}

.centered {
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 22%;
  left: 20%;
  overflow-y: auto;
}
.icon {
  display: inline-table;
  position: relative;
}
.icon i {
  top: 6%;
}
.topHeading {
  text-align: center;
  margin: -0.2% 0 0 0;
  display: grid;
  grid-template-columns: 50% 62%;
}

.cancleButton {
  width: 8%;
  height: 4%;
  margin-top: 3.5%;
  color: #1e118d;
  background-color: white;
  border-color: #1e118d;
  border-radius: 14px;
  margin-right: 1%;
}

.saveButton {
  width: 8%;
  height: 4%;
  color: white;
  background-color: #1e118d;
  border-radius: 14px;
}

.para {
  color: #24242e;
  font-weight: 600;
  font-size: 1.5rem;
  padding-right: 16.5rem;
}

.left {
  display: flex;
  padding: 15px;
}

.leftinner1 {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.leftinner2 {
  display: flex;
  flex-direction: column;
  margin: 8% 0% 0% 0%;
}

.label1 {
  font-size: 10pt;
  color: #919191;
  margin: 3% 0% 0% 3%;
}

.span1 {
  font-size: 12pt;
  color: #0b2342;
  opacity: 0.6;
  margin: 1% 0% 0% 3%;
}

.label2 {
  font-size: 0.9rem;
  color: #919191;
  margin-left: 50%;
  margin-top: -4%;
}

.span2 {
  font-size: 12pt;
  color: #0b2342;
  opacity: 0.6;
  margin-left: 50%;
  margin-top: -2.2%;
}

.div {
  display: flex;
  flex-direction: row;
  height: 65%;
}

.leftd {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.img1 {
  width: 15rem;
  height: 14rem;
  margin: 5% 0% 0% 27%;
}

.img2 {
  height: 46px;
  margin-left: 19%;
  margin-top: -12%;
}

.heading {
  font-size: 14pt;
  margin: 7% 0% 0% 6%;
  font-weight: 600;
}

.yourinput {
  width: 80%;
  height: 8em;
  border-radius: 6px 6px 6px 6px;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
  opacity: 1;
  margin: 3% 0% 0% 5%;
  align-items: flex-start;
  color: #0b2342;
  justify-content: flex-end;
  padding: 2% 5% 2% 2%;
  font-size: 12pt;
  letter-spacing: 0px;
  resize: none;
}

.upimg {
  width: 40px;
  margin-left: -9rem;
  margin-top: 14rem;
  position: absolute;
}

.errorMsgLeft {
  font-size: 0.7rem;
  color: red;
  padding: 9.2% 0 0 25%;
}

.yourinput:focus {
  outline: none;
}

.errorMsg {
  font-size: 0.7rem;
  color: red;
  float: right;
  margin-right: 11%;
}

.right {
  margin-top: 0.5%;
  display: flex;
  flex-direction: column;
  width: 40%;
}

.input1 {
  height: 36px;
  border-radius: 6px 6px 6px 6px;
  border: 1pt solid #b9b9b9;
  opacity: 1;
  border-color: #b9b9b9;
  color: #0b2342;
  margin: 3% 0% 0% 0%;
  padding: 2%;
}

.input1:focus {
  outline: none;
}

.label {
  font-size: 12pt;
  color: #919191;
  margin: 7% 0% 0% 0%;
}

@media only screen and (max-width: 750px) {
  .img2 {
    margin-left: 45%;
  }
  .centered {
    width: 100vw;
    left: 0;
    margin-top: 1.5rem;
  }
  .div {
    flex-direction: column;
  }
  .sectionStyle {
    grid-template-columns: 0px auto;
  }
  .upimg {
    margin-left: 6rem !important;
    margin-top: -1.5rem !important;
    margin-right: 3rem !important;
  }
  .topHeading {
    margin: 0;
    grid-template-columns: 1fr 3fr;
  }
  .icon i {
    top: 6%;
    left: 98%;
  }

  .button {
    padding-top: 21px;
    margin-left: 19%;
  }
  .para {
    margin: 0;
    font-size: 1.6rem;
    padding-top: 2rem;
    padding-right: 0;
  }

  .right {
    margin-top: 0.5%;
    width: 35%;
    margin-left: 10px;
  }
  .img1 {
    margin: 5% 0% 0% 2%;
    width: 15rem !important;
    height: 10rem !important;
  }

  .yourinput {
    width: 80vw;
  }
  .input1 {
    width: 80vw;
  }
  .left {
    flex-direction: column;
  }
  .changeDiv {
    margin: 5% 2%;
  }
}

@media screen and (max-width: 1368px) {
  .img1 {
    width: 13rem;
    height: 12rem;
    margin: 5% 0% 0% 2%;
  }
  .upimg {
    width: 40px;
    margin-left: -8rem;
    margin-top: 12.2rem;
    position: absolute;
  }

  .errorMsgLeft {
    font-size: 0.7rem;
    color: red;
    padding: 6% 0 0 25%;
  }
}

@media screen and (max-width: 1080px) {
  .centered {
    width: 85%;
    height: 75%;
    background-color: #ffffff;
    position: absolute;
    top: 14%;
    left: 13%;
    overflow-y: auto;
  }
  .headerStyle {
    padding: 0;
  }
}
