.centered {
  /* background-color: rgb(171, 171, 245); */
  /* background-color: whitesmoke; */
  /* margin-left: 450px; */
  /* margin-right: 450px; */
  /* height: 300px; */
  /* width: 500px; */
  /* margin-top: 164px; */
  /* border-radius: 15px; */
  padding-left: 25px;
}
.footer {
  margin-bottom: 3%;
}

.header {
  display: grid;
  grid-template-rows: repeat(2, 30px) 0px;
  padding: 15px;
}
.form {
  overflow: scroll;
  max-height: 100px;
}
.btn {
  display: flex;
  margin: 4% 4% 4% 20%;
}

.upper {
  background-color: #fafbfc;
}

.upper img {
  background-color: #fafbfc;
  float: right;
  margin-top: 4px;
}

.lower {
  background-color: #fafbfc;
}

.lower p {
  background-color: #fafbfc;
  font-weight: 700;
  margin-top: 0px;
}

/* .form {
    padding: 15px;
    margin-top: -30px;
}

.form label {
    font-size: 15px;
    
    color: #919191;
} */

.reset {
  background-color: #ffffff;
  border: 1px solid #1e118d;

  border-radius: 10px;
  color: #1e118d;
  padding: 5px;
  float: right;
  margin-left: 370px;
  width: 57px;
}

.reset btn {
  float: right;
}

.apply {
  background-color: #1e118d;
  border: 1px solid #1e118d;

  border-radius: 10px;
  color: #ffffff;
  padding: 5px;
  float: right;
  margin-left: 277px;
  width: 57px;
}

/* .inputleft input {
    width: 75%;
    height: 100%;
    border-radius: 7px;
    border: 1px solid #B9B9B9;
    
    font-size: 11pt;
    margin: 0px;
} */

.requestinput {
  width: 88%;
  border-radius: 7px;
  border: 1px solid #b9b9b9;

  font-size: 11pt;
  margin: 5px;
  padding: 10px;
}

.radioinput {
  margin: 100%;
  display: flex;
  cursor: pointer;
}

.radiodiv1 {
  /* width: 88%;
    height: 17px; */
  border-radius: 7px;
  border: 1px solid #b9b9b9;

  font-size: 11pt;
  margin: 5px;
  padding: 10px;
  display: flex;
}

.radiodiv1 p {
  width: 70%;
  margin: 0% 0% 0% 2%;
  font-size: 12px;
}

.radiodiv1 input {
  float: right;
  margin: 0% 0% 0% 24%;
  color: #1e118d;
}

.radiodiv2 {
  border-radius: 7px;
  border: 1px solid #b9b9b9;

  font-size: 11pt;
  margin: 12px 0px 0px 7px;
  padding: 10px;
  display: flex;
}

.radiodiv2 p {
  width: 70%;
  margin: 0% 0% 0% 2%;
  font-size: 12px;
}

.radiodiv2 input {
  float: right;
  margin: 0% 0% 0% 24%;
  color: #1e118d;
}
.blueButton {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 9.8vw;
  height: 5.32vh;
  margin: 6px 0px 0px 14px;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 1vw;
  cursor: pointer;
}
.blueButton1 {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #11076652;
  font-weight: 500;
  width: 9.8vw;
  height: 5.32vh;
  margin: 6px 0px 0px 14px;
  border-radius: 14px;
  border: 1px solid #11076652;
  opacity: 0.5;
  color: #ffffff;
  text-align: center;
  font-size: 1vw;
  cursor: default;
}
@media only screen and (max-width: 700px) {
  .btn {
    display: flex;
    justify-content: center;
    margin-top: 8%;
    margin-left: -10%;
  }
  .blueButton {
    width: 20.8vw;
    font-size: 3vw;
  }
  .blueButton1 {
    width: 20.8vw;
    font-size: 3vw;
  }
}

@media only screen and (max-width: 1080px) {
  .blueButton1 {
    height: 4.32vh;
  }
  .btn {
    margin-top: 9%;
  }
}
