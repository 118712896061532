.container {
  /* width: 35%; */
  border-radius: 30px;
  background-color: #ffffff;
}

.closeModal {
  float: right;
  width: 25px;
  height: 25px;
  margin: 2% 4% 0 0;
  cursor: pointer;
}
.whiteButton {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #ffffff;
  width: 134px;
  height: 35px;
  margin: 6px 0px 0px 14px;
  font-weight: 500;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #110766;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.para {
  font-size: 18px;
  padding: 2% 0% 0% 7%;
}

.form1 {
  display: block;
}

.label {
  display: inline-flex;
  color: #919191;
  font-size: 11pt;
  justify-content: right;
  margin: 18px 0 5px 40px;
  font-weight: 500;
}
.label2 {
  display: inline-flex;
  color: #919191;
  font-size: 11pt;
  justify-content: right;
  margin: 8px 0 5px 40px;
  font-weight: 500;
}

.input {
  display: flex;
  width: 64%;
  height: 23px;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 6pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 11pt;
  margin-bottom: -1rem;
}

.input:focus,
.input1.focus {
  border: 1pt solid #b9b9b9;
}

.subheading {
  display: inline-flex;
  justify-content: right;
  margin: 10px 0 5px 40px;
  font-weight: 600;
  margin-top: 4%;
  color: #333333;
  font-size: 12pt;
}

.input1 {
  display: flex;
  width: 90%;
  height: 23pt;
  align-items: center;
  justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 6pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 11pt;
  margin-bottom: -1rem;
}

.input1:focus {
  border: 1pt solid #7e7e7e;
}

.fourth {
  display: flex;
  flex-wrap: wrap;
}

.fourth2 {
  margin-left: 55px;
}

.btn_css {
  position: relative;
  width: 100%;
  padding: 28px 14px 30px 219px;
}
.date_style {
  margin-left: 109%;
  margin-top: -3%;
  cursor: pointer;
}
.date_style1 {
  margin-left: 109%;
  cursor: pointer;
}
.Calendar {
  margin-top: -7%;
  margin-left: 7%;
  width: 100px;
}
.errorMsgbelow {
  font-size: 0.7rem;
  color: red;

  margin-top: 5%;
  margin-left: 6%;
}
@media screen and (max-width: 775px) {
  .whiteButton {
    width: 20.8vw;
    font-size: 3vw;
  }
  .btn_css {
    padding: 28px 14px 30px 53px;
  }
  .date_style1 {
    margin-left: 85%;
    position: absolute;
    top: 185%;
  }
  .date_style {
    margin-left: 86%;
    position: absolute;
    top: 156%;
  }
  .fourth {
    display: block;
  }
  .input1 {
    width: 63%;
  }
  .fourth2 {
    margin-left: 3px;
    margin-top: 5%;
  }
  .subheading {
    margin: 20px 0 5px 40px;
  }
  .Calendar {
    position: absolute !important;
    left: 5% !important;
  }
}

@media screen and (max-width: 1080px) {
  .btn_css {
    padding: 0;
    margin-top: 45px;
  }
  .headerStyle {
    padding: 0;
  }
}
