.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.background_img {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.centered_button {
  opacity: 1;
}

.topsomepagewrapper {
  margin: 1% 11%;
}

.toprow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

}

.topcolumn {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
  /* margin-top: 5%; */
  
}
.para{
  margin-left: 50%;
 margin-top: 10%;
}
.backarrow {
  /* background-color: red; */
 
  margin-left: -6%;
margin-top: 1%;
}
.topcolumn1{
  display: flex;
  flex-direction: column;
  /* flex-basis: 100%; */
  /* flex: 1 1; */
 
}

.button{
  box-shadow: 0 0px 2px 2px #0000001F;
    background-color: #110766;
    font-weight: 500;
    width: 134px;
    height: 35px;
   /* margin-top: 5%; */
  
    border-radius: 14px;
    border: 1px solid #110766;
    opacity: 1;
    color: #FFFFFF;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
   
}
.accepted{
  box-shadow: 0 0px 2px 2px #0000001F;
    background-color: grey;
    font-weight: 500;
    width: 134px;
    height: 35px;
    /* margin: 6px 0px 0px 10px; */
    margin-top: -5%;
    border-radius: 14px;
    border: 1px solid grey;
    opacity: 1;
    color: #FFFFFF;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    /* margin: ".5% 0px 2% -25px" */
}
.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 48%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 30%;
  position: absolute;
}
.changeDiv1 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
 left: 38%;
  z-index: 150;
  top: 23%;
position: absolute;
}


.addBlurr {
  filter: brightness(10px);
  -webkit-filter: blur(10px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}


.editbtn {
  text-align: right;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.heading img {
  width: 2%;
  margin-left: 135px;
  margin-top: 25px;
}

.heading p {
  font-weight: bold;
  margin-left: 11%;
  margin-top: 3%;
}

.centered {
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  
}

.borderbox {
  border: 1px solid #cfcfd2;
  margin: 26px;
  margin-top: 18px;
 
}

.row1 {
  background-color: white;
  border-bottom: 1px solid #dedede;
  padding: 15px;
 
  overflow: hidden;
}

.row1box {
  display: grid;
  grid-template-columns: 20% 40% 40%;
}

.left {
  background-color: white;
}

.left img {
  width: 100%;
  margin-top: 5%;
}

.middle {
  background-color: white;
  margin-left: 10%;
  margin-top: 0%;
}

.table1 {
  font-size: 15px;
  min-width: 280px;
  margin-left: -10px;
}

.table1 td {
  padding: 2% 0 0 0;
}

.right {
  background-color: white;
  margin-top: 9%;
  
}



.category {
  opacity: 0.6;
  color: #333333;
  font-size: 0.8rem;
  margin: 2px;
  padding: 3px;
  width: 7vw;
}

.value {
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  font-weight: 600;
  margin: 3px;
  padding: 3px;
  width: 15vw;
}

.row2 {
  background-color: white;
  border-bottom: 1px solid #dedede;
  height: 12%;
  padding: 15px;
  display: flex;
}

.row2left {
  width: 24%;
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.row2right {
  width: 76%;
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
  text-align: justify;
}

.lastview {
  text-align: end;
  padding: 1% 2% 1% 0;
}
.lastview label {
  cursor: pointer;
}

.lastview label:hover{
  color: #110766;
  font-weight: 700;
}

.row3 {
  background-color: white;
  padding: 15px;
}

.upper {
  display: flex;
  margin-top: 10px;
}

.upperleft {
  background-color: white;
  width: 24%;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}
.display{
  display: flex;
}
.upperright {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
  width: 76%;
  text-align: justify;
}

.lower {
  display: flex;
  margin-top: 10px;
}

.lowerleft {
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
  width: 24%;
}

.lowerright {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  width: 76%;
  color: #333333;
  text-align: justify;
}

.row4 {
  padding: 15px;
  border-bottom: 1px solid #dedede;
  color: black;
  margin-top: 4%;
}

.row5 {
  background-color: white;
 height: 88px;
  padding: 15px;
  display: grid;
 grid-template-columns: 12% 60%;
}

.row5left {
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.row5right {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
}


@media screen and (max-width: 1368px) {
  .row4 {
    margin-top: 12%;
  }
  .heading p {
    margin-top: 2%;
  }
}
@media screen and (max-width: 600px){
  .value{
    width: 100%;
  }
  .middle{
    margin-left: 0%;
  }
  .changeDiv1{
    left: 3%;
  }
  .right{
    margin-top: -2%;
    margin-left: -4%;
  }
  .row1box{
    display: block;
  }
  .row2{
display: block;
  }
  .sectionStyle{
    grid-template-columns: 11% 81%;
  }
  .changeDiv{
    width: 97%;
    left: 1%;
  }
  .button{
    width: 110px;
    margin-left: 45%;
  }
  .upperright{
    margin-left: 0%;
    width: 100%;
  }
  .upper{
    display: block;
  }
.profile{
  display: block;
}
.centered {
 left: 0;
 width: 100vw;
}
.backarrow{
  margin-left: -104%;
    margin-top: 1%;
}
.para{
  margin-left: -54%;
  margin-top: 4%;
}
.button{
  width: 110px;
  margin-left: 20%;
}
.left img{
  width: 234px;
  height: 150px;
}
.display{
  display: block;
}
.lower{
  display: block;
}
.lowerright{
  display: block;
}
}