.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}

.para {
  color: #24242e;
  font-weight: 600;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.topsomepagewrapper {
  margin: 1% 11%;
}

.toprow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.topcolumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.editbtn {
  text-align: right;
}

.centered {
  display: flex;
  filter: drop-shadow(0px 3px 8px #00000015);
  flex-direction: row;
  flex-wrap: wrap;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
}

.line {
  margin: 0% 0% 2% -5%;
  opacity: 0.25;
}

.top {
  padding: 25px 0px 0px 35px;
  position: relative;
}

.top p {
  font-size: 14px;
  color: #24242e;
  margin: 5px 0px 5px 0px;
}

.img2 {
  width: 40px;
  position: absolute;
  height: 40px;
  bottom: -7%;
  left: 48%;
}

.orgimg {
  min-height: 9em;
  height: 9em;
  width: 16em;
  object-fit: contain;
}

.somepagewrapper {
  margin: 2% 4%;
  width: 100%;
}

.somepagewrapper2 {
  margin: 0% 4%;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.orangecolumn {
  background-color: orange;
  /* height: 100px; */
}

.bluecolumn {
  background-color: blue;
  /* height: 100px; */
}

.greencolumn {
  background-color: green;
  /* height: 100px; */
}

.doublecolumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}

.upimg {
  margin-left: -16.5em;
  margin-top: 7.5rem;
  width: 2.5rem;
  position: absolute;
}

.label1 {
  font-size: 0.9rem;
  opacity: 0.7;
  width: 85%;
  color: #000000;
  /* padding: 17px 0 5px 15px; */
}

select {
  cursor: pointer;
}

.input,
.inputName {
  width: 85%;
  height: 35px;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1pt solid #eaeaea;
  color: #0b2342;
  font-size: 0.9rem;
  padding: 0% 0% 0% 4%;
  margin: 2% 0% 4% 0%;
  opacity: 1;
  color: #0b2342;
}

.inputName:disabled {
  background-color: #999 !important;
  opacity: 0.4;
}

.input:focus {
  outline: none;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type="number"] {
  -moz-appearance: textfield;
}

.input2 {
  width: 85%;
  height: 100px;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1pt solid #eaeaea;
  color: #0b2342;
  font-size: 0.9rem;
  padding: 0% 0% 0% 4%;
  margin: 2% 0% 4% 0%;
  opacity: 1;
  color: #0b2342;
  resize: none;
}

.input2:focus {
  outline: none;
}

.errorMsg {
  font-size: 0.7rem;
  color: red;
  padding: 0 0 2% 0;
}

.changeDiv {
  display: block;
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 5% 35%;
  z-index: 150;
  position: absolute;
}

.phonediv {
  display: flex;
  /* margin-top: 3%; */
}

.phonedivleft {
  flex: 0 0 12%;
  max-width: 15%;
  margin: 1.5% 0 0 0;
}

.phonedivright {
  flex: 0 0 80%;
  max-width: 85%;
  margin: 0 0 0 5%;
}
@media screen and (max-width: 775px) {
  .centered {
    width: 90%;
    left: 5%;
    top: 25%;
  }
  .top {
    padding: 25px 0px 0px 12px;
  }
  .editbtn {
    display: flex;
    margin-top: 1rem;
  }
  .orgimg {
    width: 74vw;
  }
  .upimg {
    margin-left: -9.5em;
  }
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }
  .para {
    margin-left: -30%;
  }
  .topsomepagewrapper {
    margin: 0% 8%;
  }
  .changeDiv {
    margin: 5% 2%;
  }
  .img2 {
    width: 40px;
    position: absolute;
    height: 40px;
    bottom: 67%;
    left: 40%;
  }
}
