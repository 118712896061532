.subContainer1 {
    height: 25px;
    width: 82px;
    box-shadow: 0 0px 2px 2px #00000029;
    background-color: white;
    border-radius: 9px 0px 9px 9px;
    margin: 5px 0px 3px 5px;
    padding-bottom: 5px;
}

.label {
    float: left;
    font-weight: 400;
    font-size: 7pt;
    margin: 8px 0 0 15px;
}

.changeDiv {
    background-color: #FAFBFC;
    border-radius: 30px 30px 30px 30px;
    width: 45%;
    height: 45%;
    left: 20%;
    z-index: 150;
    margin: 15% 0% 0 5%;
    /* top: 30%; */
    position: absolute;
}

.addBlurr {
    filter: brightness(40px);
    -webkit-filter: blur(15px);
    background-color: #F2F2F2;
    overflow-y: hidden;
}