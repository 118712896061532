.Backdrop {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(100, 98, 98, 0.8);
}


.BackdropOpen {
    display: block;
}

.BackdropClosed {
    display: none;
}