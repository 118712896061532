/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.container {
  border: 1px solid #cfcfd2;
  margin: 13px;
  /* padding: 10px 10px 10px 10px; */
  /* height: 100px; */
  cursor: pointer;
}

.container:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  transform: scale(1, 1);
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.card {
  /* border: 1px solid #CFCFD2; */
  /* margin: 13px; */
  padding: 10px 10px 10px 10px;
  /* height: 100px; */
  display: grid;
  grid-template-columns: 95px 400px 380px;
  overflow: hidden;
  /* font-family: 'Poppins', sans-serif; */
}

/* .card:hover {
    box-shadow: 10px 10px 12px -4px #00000015;
    transform: scale(1, 1);
} */

.right {
  width: 100px;
  height: 85px;
  margin-left: 50vw;
}

.left img {
  height: 50px;
  width: 50px;
  margin-right: 2%;
}

.left {
  color: black;
  width: 55vw;
  display: flex;
}
.left p {
  margin: 2px;
}
.time {
  text-align: left;
  font: normal normal normal 12px/30px Poppins;
  letter-spacing: -0.12px;
  color: #333333;
  opacity: 0.6;
}

.orgname {
  text-align: left;
  font: normal normal normal 15px/30px Poppins;
  letter-spacing: -0.15px;
  color: #333333;
  opacity: 1;
}

.oppdes {
  text-align: left;
  font: normal normal 600 20px/32px Poppins;
  letter-spacing: -0.2px;
  color: #333333;
  opacity: 1;
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

/* .request p {
  margin: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
} */

.request:hover {
  background-color: #0f065f;
  color: white;
}

.request:hover p {
  background-color: #0f065f;
  color: white;
}
@media screen and (max-width: 575px) {
  .right {
    width: 75px;
    height: 75px;
    /* margin-left: 29vw; */
    margin-left: 59vw;
    margin-top: -3vh;
  }
  .left {
    width: 80vw;
  }
  .card {
    display: block;
  }
}
