.container {
  display: grid;
  grid-template-columns: 55% 55%;
}

.leftContainer {
  background-color: #fafbfc;
  width: 100%;
  height: 100%;
  border-radius: 30px 0 0 30px;
}

.leftContainer .leftLogo {
  padding: 6% 0 0 37%;
}

.leftContainer .leftPara {
  font-size: 0.9rem;
  line-height: 1.3;
  font-weight: 500;
  padding: 2% 0 0 6%;
  opacity: 0.7;
}

.leftContainer .leftImg img {
  width: 80%;
  padding: 7% 0 0 10%;
  /* / height: 100%; / */
}

.rightContainer {
  background-color: #ffffff;
  border-radius: 0 30px 30px 0;
  width: 100%;
  height: 100%;
}

.rightContainer .title {
  font-size: 20pt;
  font-weight: 600;
  color: #333333;
  padding: 4% 0% 0% 10%;
  line-height: 0.2;
}

.rightContainer .subTitle p {
  font-size: 11.5pt;
  opacity: 0.5;
}

.rightContainer .formStyle {
  padding-top: 0%;
}

.errorMsg {
  font-size: 0.7rem;
  color: red;
  margin-left: 11%;
}

.perrorMsg {
  font-size: 0.7rem;
  color: red;
  margin-right: 11%;
  margin-bottom: 3%;
  float: left;
  padding-left: 13%;
}
.anchorStyle {
  color: #1e118d;
  font-weight: 500;
  opacity: 1;
  font-size: 11pt;
  cursor: pointer;
}

.rightContainer .subTitle {
  padding: 0% 0% 0% 10%;
  opacity: 0.5;
  display: inline;
  font-size: 1rem;
}

.closeModal {
  float: right;
  width: 25px;
  height: 25px;
  margin: 5%;
  cursor: pointer;
}

.orgRadio {
  grid-area: orgRadio;
  margin-bottom: 2%;
}

.recRadio {
  grid-area: recRadio;
  margin-bottom: 2%;
}

.emailName2 {
  padding-top: 0%;
}

.orgname2 {
  padding-top: 4%;
}

.forgot {
  color: #1e118d;
  opacity: 1;
  background: none;
  font-weight: 500;
  float: right;
  border: none;
  padding: 0 54px 0 0;
}

.allBtn {
  padding: 14px 0 0 0;
}

.allBtn2 {
  padding: 10px 0 0 0;
}

.formStyle input[type="text"],
.formStyle input[type="password"],
.formStyle input[type="email"] {
  display: flex;
  width: 60%;
  height: 21px;
  align-items: center;
  justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 8pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 11pt;
}
.formStyle input:focus {
  outline: none;
}

.formStyle input[type="radio"] {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
  padding: 0.25em;
  align-items: center;
  justify-content: flex-end;
  border-radius: 50%;
  opacity: 1;
  margin: 0 41% 0 0;
  float: right;
  outline: none;
  border: 2px solid #737373;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  margin: 6% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: #110766;
}

input[type="radio"]:checked {
  border-color: #110766;
}

.tabLinks {
  background: none !important;
  border: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #333333;
  opacity: 0.8;
  cursor: pointer;
}

.activeTab {
  color: #1e118d;
  font-weight: 600;
  font-size: 0.9rem;
  opacity: 1;
  border-bottom: 6px solid #1e118d;
  border-radius: 2pt 3pt 0 0;
}

.lineStyle {
  width: 80%;
  margin-left: 8%;
  margin-top: 1%;
}

.mailtext {
  width: 340px;
  height: 25px;
  align-items: center;
  justify-content: flex-end;
  padding: 0.25em;
  margin-left: 40px;
  border-radius: 8px;
  border-color: rgb(160, 156, 156);
  opacity: 0.5;
}

.mail {
  display: inline-flex;
  font-size: 0.9rem;
  justify-content: right;
  margin: 0px 0 5px 40px;
  font-weight: 500;
  opacity: 0.8;
}

.mail2 {
  display: inline-flex;
  font-size: 0.9rem;
  justify-content: right;
  margin: 10px 0 5px 40px;
  font-weight: 500;
  opacity: 0.8;
}

.radioStyle {
  display: grid;
  margin: 0 0 -9px 40px;
  font-weight: 500;
  opacity: 1;
  color: #5c5c5c;
  font-size: 0.9rem;
  grid-template-areas:
    "orgRadio"
    "recRadio";
}

.password {
  display: inline-flex;
  justify-content: right;
  margin: 10px 0 5px 38px;
  opacity: 0.9;
}

.signin1 {
  display: block;
  background-color: #110766;
  opacity: 1;
  width: 77%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 24px 0 31px 40px;
  color: white;
  border: none;
  font-size: 11pt;
  cursor: pointer;
  height: 40px;
}

.signin2 {
  display: block;
  background-color: #110766;
  opacity: 1;
  width: 77%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 24px 0 60px 40px;
  color: white;
  border: none;
  font-size: 11pt;
  cursor: pointer;
  height: 40px;
}

.iconOrg {
  margin: -3.3% 0 0 43%;
  position: absolute;
  height: 3.7%;
}
.icon2 {
  margin: -3.6% 0 0 43%;
  position: absolute;
  height: 20px;
}

.icon3 {
  margin: -3.9% 0 0 43%;
  position: absolute;
  height: 25px;
}

.icon33 {
  margin: -3.3% 0 0 43%;
  position: absolute;
  height: 19px;
}
.tab {
  padding: 0% 0% 0% 10%;
  display: inline;
  overflow: hidden;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 0 0 5%;
  transition: 0.3s;
  font-size: 17px;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: #ccc;
}

.firstTabContent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.secondTabContent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
@media screen and (max-width: 700px) {
  .leftContainer .leftPara {
    font-size: 3.9vw;
  }
  .rightContainer .subTitle {
    padding: 0% 0% 0% 1%;
  }
  .activeTab {
    font-size: 2.9vw;
  }
  .tabLinks {
    font-size: 2.9vw;
  }
  .mail2 {
    margin: 10px 0 5px 13px;
  }
  .formStyle input[type="text"],
  .formStyle input[type="password"],
  .formStyle input[type="email"] {
    margin-left: 13px;
  }
  .icon2 {
    margin: -6.6% 0 0 40%;
    height: 12px;
  }
  .icon3 {
    margin: -6.9% 0 0 40%;
    height: 12px;
  }
  .icon33 {
    margin: -6.9% 0 0 40%;
    height: 12px;
  }
  .tab {
    padding: 0% 0% 0% 1%;
  }
  .signin1 {
    margin: 24px 0 31px 20px;
  }
}

/* media query for ipad */
@media screen and (max-width: 1080px) {
  .container {
    grid-template-columns: 55% 94%;
  }
  .icon2,
  .icon3,
  .iconOrg {
    margin: -6.6% 0 0 74%;
  }
  .headerStyle {
    padding: 0;
  }
}
