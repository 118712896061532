.container {
  display: block;
  background-color: #fafbfc;
  width: 28rem;
  height: 21rem;
  /* top: 50%;
    position: absolute;
   
    transform: translate(35%, -56%); */
  border-radius: 30px;
}

.result {
  text-align: center;
  padding: 25% 30px;
}

.allBtn2 {
  float: right;
  padding: 3% 12% 7% 0;
}

.ok_btn {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 20%;
  height: 35px;
  margin: 0% 2% 0% 17%;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  margin-left: 0px;
}

.message {
  /* margin: 2% 2% 2% 11%; */
  /* color: red; */
  font-size: 10pt;
  font-weight: normal;
}
@media screen and (max-width: 700px) {
  .container {
    width: 19rem;
    height: 19rem;
  }
}

@media screen and (max-width: 1080px) {
  .result {
    text-align: center;
    padding: 33% 30px;
  }
  .headerStyle {
    padding: 0;
  }
}
