.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100;
  background-color: rgd(238, 238, 245);
}
.button {
}
.display {
  display: flex;
  margin: 5px;
}
.apply {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 134px;
  height: 35px;
  margin: -3vh 0vw 0vh 57vw;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.applied {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: grey;
  font-weight: 500;
  width: 134px;
  height: 35px;
  margin: -3vh 0vw 0vh 57vw;
  border-radius: 14px;
  border: 1px solid grey;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
}
.background_img {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.centered_button {
  opacity: 1;
}

.nodata {
  display: block;
  margin: 3% auto;
  width: 50%;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}
.button {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #1e118d;
  font-weight: 500;
  width: 15%;
  margin-top: -3vh;
  margin-left: 65vw;

  height: 32px;
  border-radius: 14px;
  border: 1px solid #110766;
  color: #ffffff;
  text-align: center;
  font-size: 1.2rem;
  cursor: pointer;
  float: right;
}

.topHeading {
  display: flex;
  flex-direction: row;
  margin-left: 11%;
  margin-top: 2%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.aboutcss {
  font-weight: 400;
  margin: -19px 0 0 180px;
  font-size: 0.8rem;
}

.about_rec {
  font-weight: 400;
  margin: -19px 0 0 345px;
  font-size: 0.8rem;
}

.centered {
  font-weight: 400;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 22%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.para {
  color: #24242e;
  font-weight: 600;
  font-size: 1.1rem;
  margin-left: 1rem;
}

.arrow {
  width: 100%;
  height: 2%;
  margin: 0.5% -5% 0px 0px;
  cursor: pointer;
}

.arrow img {
  width: 35%;
  /* / margin-top: 5%; / */
  cursor: pointer;
}

.cricket {
  width: 7rem;
  height: 7rem;
  margin-left: 30px;
  margin-top: 15px;
  border-radius: 5px;
}

.para1 {
  margin-left: 15px;
  font-size: 1.2rem;
  margin-top: 15px;
  opacity: 0.7;
  font-weight: 900;
}

.para2 {
  font-weight: 400;
  margin-left: 1rem;
  font-size: 0.8rem;
  margin-top: -7px;
}

.para3 {
  font-weight: 400;
  margin-left: 1rem;
  font-size: 0.55rem;
  opacity: 0.5;
}

.para4 {
  font-weight: 400;
  margin-left: 6rem;
  font-size: 0.55rem;
  margin-top: -20px;
  opacity: 0.5;
}

.line {
  margin-top: 0.1%;
  opacity: 0.5;
  width: 100%;
  margin-left: 0px;
}

.activeTab {
  color: #1e118d;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  opacity: 0.9;
  border-bottom: 4px solid #1e118d;
  margin-left: 30px;
}
.inactiveTab {
  font-weight: 400;
  margin-left: 30px;
  font-size: 0.8rem;
  cursor: pointer;
}
.inaactiveTab {
  font-weight: 400;
  font-size: 0.8rem;
  cursor: pointer;
}
.aactiveTab {
  color: #1e118d;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  opacity: 0.9;
  border-bottom: 4px solid #1e118d;
}

.high {
  margin-left: 30px;
  font-size: 1rem;
  font-weight: 600;
  opacity: 1;
  color: #333333;
  margin-top: 15px;
  margin-bottom: 1.3rem;
}

.table {
  font-size: 0.9rem;
  min-width: 548px;
  margin: 25px 0px;
  margin-left: 28px;
  margin-top: -15px;
}

.category {
  opacity: 0.6;
  color: #333333;
  font-size: 0.9rem;
  margin-left: 0px;
  font-weight: 500;
  width: 10vw;
}

.value {
  color: #333333;
  font-size: 0.9rem;
  font-weight: 700;
  font-weight: 500;
  width: 60vw;
  color: #333333;
}

.orgimg {
  margin-left: 31px;
  margin-top: 25px;
  height: 13.5rem;
  width: 26rem;
}

.about {
  margin-top: -224px;
  margin-left: 492px;
  font-weight: 600;
  font-size: 0.9rem;
  color: #333333;
}

.paraabout {
  margin-left: 492px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-right: 20px;
  height: 12.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
  padding-right: 8px;
  margin-top: -8px;
}
.orgimgRecr {
  margin-left: 31px;
  margin-top: 25px;
  height: 16rem;
  width: 13.5rem;
}
.aboutRecr {
  margin-left: 30px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 0.9rem;
  color: #333333;
}

.paraaboutRecr {
  margin-left: 30px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-right: 20px;
  margin-top: -7px;
  text-align: justify;
  height: auto;
  max-height: 15.5rem;
  overflow-x: hidden;
  padding-right: 8px;
  overflow-y: auto;
}
.name {
  margin-left: 31px;
  font-weight: 500;
  color: #333333;
  font-size: 1.1rem;
  opacity: 0.7;
  margin-top: -10px;
}

.name1 {
  margin-left: 31px;
  font-weight: 500;
  color: #333333;
  font-size: 0.9rem;
  font-weight: 900;
  opacity: 0.9;
  margin-bottom: 25px;
}

.head3 {
  margin-left: 30px;
  font-weight: 600;
  font-size: 1rem;
  margin-top: -15px;
}

.paraOpp {
  margin-left: 30px;
  margin-right: 40px;
  font-weight: 400;
  font-size: 0.9rem;
  margin-top: -7px;
  text-align: justify;
  color: #333333;
}

.head4 {
  margin-left: 30px;
  font-weight: 600;
  font-size: 1rem;
}

.recruiter {
  margin-left: 30px;
  width: 50px;
  margin-top: -10px;
}

.table2 {
  margin-left: 31px;
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: -10px;
  opacity: 0.6;
}
.playerName {
  font-weight: 500;
  color: black;
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: 31px;
}
.gameName {
  font-weight: 500;
  color: #333333;
  font-size: 0.9rem;
  opacity: 0.7;
  margin-top: -14px;
  margin-left: 31px;
}
@media screen and (max-width: 575px) {
  .centered {
    width: 90%;
    left: 5%;
  }
  .cricket {
    width: 5rem;
    height: 5rem;
    margin-left: 5px;
  }
  .para2 {
    margin-top: -19px;
  }
  .inactiveTab {
    margin-left: 5px;
    font-size: 0.6rem;
  }
  .activeTab {
    margin-left: 5px;
    font-size: 0.6rem;
  }
  .inaactiveTab {
    font-size: 0.6rem;
  }
  .aactiveTab {
    font-size: 0.6rem;
  }
  .aboutcss {
    margin: -19px 0 0 105px;
  }
  .about_rec {
    margin: -19px 0 0 213px;
  }
  .high {
    margin-left: 5px;
  }
  .table {
    margin-left: 5px;
  }
  .head3 {
    margin-left: 5px;
  }
  .paraOpp {
    margin-left: 5px;
  }
  .head4 {
    margin-left: 5px;
  }
  .orgimg {
    margin-left: 5px;
    margin-top: 10px;
    height: 10.5rem;
    width: 19rem;
  }
  .name {
    margin-left: 5px;
  }
  .name1 {
    margin-left: 5px;
  }
  .value {
    width: 100%;
  }
  .category {
    width: 20%;
  }
  .display {
    display: block;
  }
  .orgimgRecr {
    margin-left: 5px;
    margin-top: 10px;
    height: 10rem;
    width: 19.5rem;
  }
  .playerName {
    margin-left: 5px;
  }
  .gameName {
    margin-left: 5px;
  }
  .aboutRecr {
    margin-left: -310px;
    margin-top: 264px;
  }
  .paraaboutRecr {
    margin-left: -310px;
  }
  .arrow {
    width: 4vw;
    height: 2vh;
    margin: 0.5% -5% 0px -60px;
  }
  .para {
    margin-left: -11vw;
  }
  .button {
    width: 30%;
    margin-left: 60vw;
    margin-top: 1vh;
  }
  .applied {
    margin: 1vh 0vw 0vh 46vw;
    width: 88px;
  }
  .apply {
    margin: 1vh 0vw 0vh 46vw;
    width: 88px;
  }
}

@media screen and (max-width: 1080px) {
  .topHeading {
    margin-top: 7%;
  }
  .centered {
    top: 16%;
    font-weight: 400;
    width: 84%;
    height: 75%;
    background-color: #ffffff;
    position: absolute;

    left: 13%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .headerStyle {
    padding: 0;
  }
  .orgimg {
    width: 15rem;
  }
  .about {
    margin-left: 367px;
  }
  .paraabout {
    margin-left: 367px;
  }
  .orgimgRecr {
    height: 12rem;
  }
}
