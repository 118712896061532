.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}
.topHeading {
  text-align: center;
  /* / position: relative; / */
  margin: -0.2% 0 0 0;
  display: grid;
  grid-template-columns: 50% 62%;
}
.button {
  display: flex;

  flex-direction: row;
  padding: 3%;
  margin-left: 116%;
}
.para {
  color: #24242e;
  font-weight: 600;
  font-size: 0.9rem;
  margin: 2% 0 0% -38%;
}
.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;

  margin: 8% 40%;
  z-index: 150;
  /* / top: 10%; / */
  position: absolute;
}
.firstHeading {
  margin: 2% 20%;
  position: absolute;
}
.Calendar {
  position: absolute !important;
}
.mainbox {
  overflow-y: auto;
  overflow-x: hidden;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  border: 1px solid #dedede;
}
.datePicker > div:first-child,
.datePicker > div > div:first-child {
  display: flex !important;
  justify-content: center;
  width: 100%;
}

.date_style {
  margin: 0% 0% -1% -7%;
  cursor: pointer;
}
/*  */

.errorMsg {
  font-size: 0.7rem;
  color: red;
  float: right;
  margin-right: 11%;
}

.text {
  margin-bottom: 10px;
  font-size: 0.9rem;
  /* / color: #0011ff; / */
}

.div {
  display: grid;
  grid-template-columns: 50% 50%;
  /* / background-color: aqua; / */
  height: 70%;
}

.left {
  /* / background-color: #0B2342; / */
  margin-left: 8%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.img1 {
  width: 12rem;
  height: 9rem;
  margin-left: 0%;
  border-radius: 15px;
}

.uploadImg {
  position: relative;
}

.img2 {
  width: 9%;
  bottom: -8%;
  left: 18%;
  position: absolute;
}

.heading {
  font-size: 0.9rem;
  margin-top: 8%;
  margin-left: 0%;
  color: #24242e;
  opacity: 1;
}

.yourinput {
  width: 83%;
  height: 35%;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  font-size: 0.9rem;
  color: #0b2342;
  padding-left: 4%;
  opacity: 1;
  resize: none;
}

.yourinput:focus {
  outline: none;
}

.right {
  display: grid;
  grid-template-rows: repeat(10, 1rf);
  margin: 3%;
}

.phonediv {
  display: flex;
  /* / margin-top: 3%; / */
}

.phonedivleft {
  flex: 0 0 12%;
  max-width: 15%;
}

.phonedivright {
  flex: 0 0 80%;
  max-width: 85%;
  margin: 0 0 0 5%;
}

.input1 {
  width: 86%;
  height: 36px;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  margin: 1px 0px 0px 1px;
  font-size: 0.9rem;
  color: #0b2342;
  padding: 0px 0px 0px 12px;
}

.input1:focus {
  outline: none;
}

.input1::-webkit-outer-spin-button,
.input1::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input1[type="number"] {
  -moz-appearance: textfield;
}

.inputp {
  width: 86%;
  height: 36px;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  margin: 1px 0px 0px 1px;
  font-size: 0.9rem;
  color: #0b2342;
  padding: 0px 0px 0px 12px;
}

.inputp:focus {
  outline: none;
}

.inputp::-webkit-outer-spin-button,
.inputp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputp[type="number"] {
  -moz-appearance: textfield;
}

.inputnew {
  width: 86%;
  height: 36px;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  margin: 1px 0px 0px 1px;
  font-size: 0.9rem;
  color: #0b2342;
  padding: 0px 0px 0px 12px;
}

.inputnew:focus {
  outline: none;
}

.inputnew::placeholder {
  color: #0b2342;
  opacity: 1;
}

.inputnew:-ms-input-placeholder {
  color: #0b2342;
}

.inputnew::-ms-input-placeholder {
  color: #0b2342;
}

.label {
  font-size: 0.9rem;
  color: #919191;
  margin-left: 0%;
  margin-top: 3%;
}

.onspan {
  display: flex;
  flex-direction: row;
}

.onspan p {
  font-size: 0.8rem;
  color: #747474;
  opacity: 1;
  margin: 1.8% 0% 0% 51%;
}

.onimg {
  width: 10%;
  margin: 0% 0% 0% 5%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 10%;
  height: 20px;
  margin-top: 8px;
  margin-left: 8px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #747474;
  border-radius: 34px;
  z-index: 1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: " ";
  height: 100%;
  width: 50%;
  /* / border: 1px solid grey; / */
  background-color: white;
  -webkit-transition: 0.4s;
  border-radius: 34px;
  transition: 0.4s;
}

.switch_input:checked + .slider {
  background-color: #1e118d;
}

.switch_input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch_input:checked + .slider:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(26px);
}

.on {
  display: none;
}

.on,
.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

.switch_input:checked + .slider .on {
  display: block;
}

.switch_input:checked + .slider .off {
  display: none;
}

@media screen and (max-width: 700px) {
  .sectionStyle {
    grid-template-columns: 1px auto;
  }
  .mainbox {
    width: 100vw;
    margin-left: -60px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .firstHeading {
    margin: 2% 2%;
  }
  .div {
    display: flex;
    flex-direction: column;
  }
  .left {
    margin-left: 3%;
  }
  .button {
    margin-left: 30%;
    margin-top: -2rem;
    padding: 0;
  }
  .Calendar {
    left: 10% !important;
  }
  .changeDiv {
    margin: 5% 2%;
  }
  .cross {
    /* position: relative; */
    top: -10%;
    left: -3%;
  }
}

@media screen and (max-width: 1080px) {
  .mainbox {
    overflow-y: auto;
    overflow-x: hidden;
    width: 87%;
    height: 85%;
    background-color: #ffffff;
    position: absolute;
    top: 10%;
    left: 11%;
    border: 1px solid #dedede;
  }
  /* .img2 {
    width: 4%;
    top: 28%;
    left: 13%;
    position: absolute;
  } */
  .headerStyle {
    padding: 0;
  }
}
