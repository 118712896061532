.container {
	background-color: #FFFFFF;
	border: 1px solid #EAEAEA;
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
}

.subContainer1 {
    background-color: #FFFFFF;
    margin: 6px;
	color: #333333;
    padding-bottom: 4px;
	cursor: pointer;
}

.subContainer1:hover {
	box-shadow: 0 0px 2px 2px #00000029;
    background-color: #1E118D;
    border-radius: 12px 0 0 0;
    margin: 6px;
    padding-bottom: 4px;
	color:#FFFFFF;
}

.subContainer2 {
	background-color: #FFFFFF;
    border-radius: 0 0 0 12px;
    margin: 6px;
    padding-bottom: 4px;
	cursor: pointer;
}

.subContainer2:hover {
	box-shadow: 0 0px 2px 2px #00000029;
    background-color: #1E118D;
    border-radius: 0 0 0 12px;
    margin: 6px;
	color:#FFFFFF;
    padding-bottom: 4px;
}

.subContainer1 .label {
	float: left;
	font-weight: 400;
	font-size: 11pt;
	color:#333333;
    margin: 8px 0 0 15px;
}

.subContainer2 .label {
	float: left;
	font-weight: 400;
	font-size: 11pt;
	color:#333333;
    margin: 6px 0 0 15px;
}

.subContainer1:hover .label, .subContainer2:hover .label {
	color:#FFFFFF;
}


.subContainer1 img {
	width: 11%;
    margin: 4% 0 0 0%;
}

.subContainer2 img {
	width: 11%;
	width: 11%;
    margin: 4% 0 0 0%;
}