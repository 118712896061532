.header {
  display: flex;
  z-index: 100;
  align-items: center;
  gap: 40px;
  width: 100%;
}
.blueButton {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 9.8vw;
  height: 5.32vh;
  margin: 6px 0px 0px 14px;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 1vw;
  cursor: pointer;
}
.whiteButton {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #ffffff;
  width: 134px;
  height: 35px;
  margin: 6px 0px 0px 14px;
  font-weight: 500;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #110766;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.header a {
  align-self: center;
  justify-self: self-start;
  float: left;
  color: blueviolet;
  text-align: left;
  opacity: 1;
  /* padding: 1% 0% 0% 12%; */
  text-decoration: none;
  font-size: 16px;
  line-height: 25px;
  border-radius: 4px;
}

.menu {
  display: none;
}

.cancel {
  display: none;
}

.logo {
  margin-top: -7px;
  height: 8.98vh;
  padding-left: 116px;
}

.btn_css {
  display: grid;
  grid-template-columns: auto auto;
}

.headerRight {
  /*align-self: center;
  justify-self: end;
   display: grid;
  grid-template-columns: auto auto auto auto auto; */
  /* float: right;
  padding: 0% 6% 0% 0%; */
}

.headerRight a {
  font-weight: 500;
  float: left;
  color: #333333;
  text-align: left;
  opacity: 1;
  padding: 12px;
  text-decoration: none;
  font-size: 16px;
  line-height: 25px;
}

.activeSide {
  color: #110766 !important;
  border-bottom: 5px solid #110766 !important;
  font-weight: bold !important;
}

/* .headerRight a:hover {
    background-color: #ddd;
    color: black;
  } */

.headerRight a.active {
  background-color: dodgerblue;
  color: white;
}

@media screen and (max-width: 575px) {
  .headerRight {
    /* display: none; */
  }
  .logo {
    margin-top: 8px;
    padding-left: 28px;
    height: 40px;
  }
}

@media screen and (max-width: 766px) {
  .headerRight {
    padding: 0% 1% 0% 0%;
  }
  .headerRight a {
    font-size: 1.46;
    padding: 1.17%;
  }
  /* .btn_css{
        margin-top: -8px;
    } */
  .logo {
    margin-top: 7px;
    padding-left: 28px;
    height: 45px;
  }
  .header a {
    font-size: 7.46vw;
  }
}

@media screen and (max-width: 1150px) {
  .logo {
    padding-left: 6.58vw;
  }
}

@media screen and (max-width: 600px) {
  .menu {
    display: block;
    margin-top: -9px;
    height: 7.98vh;
    padding-left: 0.55vw;
    margin-left: 47vw;
  }

  .headerRight {
    width: 0vw;
    height: 57rem;
    background-color: #393939;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 6vh;
    display: block;
  }
  .sidebar {
    width: 80vw;
    height: 57rem;
    background-color: #393939;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 6vh;
    display: block;
  }
  .side {
    font-size: 12.46vw;
    display: block;
    margin: 10%;
  }

  .activeSide {
    color: #110766 !important;
    border-bottom: 5px solid #110766 !important;
    font-weight: bolder;
    font-size: 12.46vw;
    display: block;
    margin: 10%;
  }

  .btn_css {
    display: block;
  }
  .whiteButton {
    height: 50px;
  }
}

@media screen and (max-width: 1080px) {
  .whiteButton {
    box-shadow: 0 0px 2px 2px #0000001f;
    background-color: #ffffff;
    width: 116px;
    height: 35px;
    margin: 6px 0px 0px 14px;
    font-weight: 500;
    border-radius: 14px;
    border: 1px solid #110766;
    opacity: 1;
    color: #110766;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
  }
  .headerRight a {
    font-size: 12px;
  }
  .logo {
    padding: 0px !important;
    height: 80px;
  }
  .header {
    display: flex;
  }
  .headerStyle {
    padding: 0;
  }
}
