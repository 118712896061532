/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap'); */

.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}

.background_img {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.centered_button {
  opacity: 1;
}

.button {
  float: right;
  margin: 0.4% 0px 0px 66%;
}

/* span {
    font-size: 24px;
  } */

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.centered {
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.content {
  display: grid;
  grid-template-rows: 20% 70%;
}

.title {
  /* background-color: red; */
  font-weight: 600;
  font-size: 20px;
  color: #333333;
  opacity: 1;
  /* font-family: 'Poppins', sans-serif; */
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
}

.image {
  background-color: white;
}

.image img {
  width: 30%;
  margin: 75px 0px 0px 328px;
}

@media screen and (max-width: 1380px) {
  .container {
    grid-template-rows: 4rem 57rem;
  }
}
@media screen and (max-width: 1080px) {
  .image img {
    width: 95%;
    margin: 0;
  }
  .headerStyle {
    padding: 0;
  }
  .centered {
    width: 86%;
    height: 75%;
    background-color: #ffffff;
    position: absolute;
    top: 12%;
    left: 12%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
