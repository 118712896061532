.whiteButton {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #ffffff;
  width: 9.8vw;
  height: 5.32vh;
  margin: 6px 0px 0px 14px;
  font-weight: 500;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #110766;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.blueButton {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 9.8vw;
  height: 5.32vh;
  margin: 6px 0px 0px 14px;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 1vw;
  cursor: pointer;
}

.LongButton {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #1e118d;
  font-weight: 500;
  width: 60%;
  height: 35px;
  margin: 6px 0px 0px 14px;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
@media screen and (max-width: 775px) {
  .blueButton {
    width: 20.8vw;
    font-size: 3vw;
  }
  .whiteButton {
    width: 20.8vw;
    font-size: 3vw;
  }
}
/* media query for ipad */
@media screen and (max-width: 1080px) {
  .blueButton {
    box-shadow: 0 0px 2px 2px #0000001f;
    background-color: #110766;
    font-weight: 500;
    width: 10.8vw;
    height: 4.32vh;
    margin-right: 80px;
    border-radius: 14px;
    border: 1px solid #110766;
    opacity: 1;
    color: #ffffff;
    text-align: center;
    font-size: 2vw;
    float: right;
    cursor: pointer;
  }
  .whiteButton {
    width: 13vw;
    height: 4.32vh;
  }
  .LongButton {
    height: 48px;
  }
  .headerStyle {
    padding: 0;
  }
}
