.container {
  display: block;
  background-color: #fafbfc;
  border-radius: 30px;
  padding: 20px 25px 20px 25px;
  margin-left: 10%;
}

.msg1 {
  font-size: 1.1rem;
  line-height: 0.5;
  font-weight: 500;
  padding: 2% 0% 0% 15%;
  opacity: 0.7;
}

.msg2 {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 2% 6% 0% 0%;
  opacity: 0.7;
  text-align: center;
}

.leftImg img {
  width: 40%;
  padding: 6% 0% 0% 25%;
}

.closeModal {
  float: right;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.signin1 {
  display: block;
  background-color: #110766;
  opacity: 1;
  width: 78%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 40px 0 0 40px;
  color: white;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}
