.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}

.para {
  color: #24242e;
  font-weight: 600;
}

.background_img {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.centered_button {
  opacity: 1;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.topsomepagewrapper {
  margin: 1% 11%;
}

.toprow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.topcolumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.editbtn {
  text-align: right;
}

.centered {
  display: flex;
  filter: drop-shadow(0px 3px 8px #00000015);
  flex-direction: row;
  flex-wrap: wrap;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
}

.line {
  margin: 0% 0% 2% -5%;
  opacity: 0.25;
}

.top {
  padding: 25px 0px 0px 35px;
  width: 35vw;
}

.top p {
  font-size: 14px;
  color: #24242e;
  margin: 5px 0px 5px 0px;
}

.orgimg {
  height: 21.9vh;
  width: 11.48vw;
  object-fit: contain;
}

.somepagewrapper {
  margin: 2% 4%;
}

.somepagewrapper2 {
  margin: 0% 4%;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.orangecolumn {
  background-color: orange;
  /* height: 100px; */
}

.bluecolumn {
  background-color: blue;
  /* height: 100px; */
}

.greencolumn {
  background-color: green;
  /* height: 100px; */
}

.doublecolumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}

.label1 {
  font-size: 0.9rem;
  opacity: 0.7;
  color: #000000;
  padding: 1% 0 0px 0px;
}

.input {
  margin-top: 2%;
  opacity: 1;
  text-align: justify;
  color: #0b2342;
  font-size: 0.9rem;
}
@media screen and (max-width: 775px) {
  .centered {
    display: flex;
    filter: drop-shadow(0px 3px 8px #00000015);
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    left: 5%;
    height: 75%;
    background-color: #ffffff;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .column {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
  }
  .para {
    margin-left: -30%;
  }
  .topsomepagewrapper {
    margin: 1% 8%;
  }
  .top {
    padding: 25px 0px 0px 14px;
  }
  .orgimg {
    width: 72.48vw;
  }
}
