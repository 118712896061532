.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.background_img {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}
.value {
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0 !important;
  width: 100%;
  padding: 3px;
  word-wrap: break-word;
}
.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.centered_button {
  opacity: 1;
}

.topsomepagewrapper {
  margin: 1% 11%;
}

.toprow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.topcolumn {
  display: flex;
  flex-direction: row;
  width: 92vw;
  justify-content: space-between;
  align-items: center;
}
.para {
}
.backarrow {
  display: flex;
  align-items: center;
  gap: 10px;
}

.backarrow > img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.accept {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 160px;
  height: 35px;
  /* margin-top: 5%; */
  margin-right: 5%;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.accepted {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: grey;
  font-weight: 500;
  width: 134px;
  height: 35px;
  margin-right: 27px;
  margin-top: -2vh;

  border-radius: 14px;
  border: 1px solid grey;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
}
.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 0%;
  height: 40%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 30%;
  position: absolute;
}

.addBlurr {
  filter: brightness(10px);
  -webkit-filter: blur(10px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}
.reject {
  /* box-shadow: 0 0px 2px 2px #0000001F; */
  background-color: white;
  font-weight: 500;
  width: 160px;
  height: 35px;
  margin-right: 9%;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  color: #110766;
  cursor: pointer;
}

.editbtn {
  text-align: right;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.heading img {
  width: 2%;
  margin-left: 135px;
  margin-top: 25px;
}

.heading p {
  font-weight: bold;
  margin-left: 11%;
  margin-top: 3%;
}

.centered {
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  /* margin-top: -3%; */
}

.borderbox {
  border: 1px solid #cfcfd2;
  margin: 26px;
  margin-top: 18px;
  /* height: 155%; */
  /* display: grid;
    grid-template-rows: 109px 137px 400px; */
}

.row1 {
  background-color: white;
  border-bottom: 1px solid #dedede;
  padding: 15px;
  /* display: grid;
    grid-template-columns: 95px 300px 368px; */
  overflow: hidden;
}

.row1box {
  display: grid;
  grid-template-columns: 20% 40% 40%;
}

.left {
  background-color: white;
}

.left img {
  width: 100%;
  margin-top: 5%;
}

.middle {
  background-color: white;
  margin-left: 10%;
  margin-top: 0%;
}

.table1 {
  font-size: 15px;
  min-width: 280px;
  margin-left: -10px;
}

.table1 td {
  padding: 2% 0 0 0;
}

.right {
  background-color: white;
  margin-top: 9%;
  /* padding-top: 20px; */
  margin-left: 10%;
}

.right_table1 {
  min-width: 230px;
}

.category {
  opacity: 0.6;
  color: #333333;
  font-size: 0.8rem;
  margin: 2px;
  padding: 3px;
  width: 27%;
}

.value {
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  font-weight: 600;
  margin: 3px;
  padding: 3px;
  width: 70%;
}

.row2 {
  background-color: white;
  border-bottom: 1px solid #dedede;
  height: 12%;
  padding: 15px;
  display: flex;
}

.row2left {
  width: 24%;
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.row2right {
  width: 76%;
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
  text-align: justify;
}

.lastview {
  text-align: end;
  padding: 1% 2% 1% 0;
}
.lastview label {
  cursor: pointer;
}

.lastview label:hover {
  color: #110766;
  font-weight: 700;
}

.row3 {
  background-color: white;
  padding: 15px;
}

.upper {
  display: flex;
  margin-top: 10px;
}

.upperleft {
  background-color: white;
  width: 24%;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.upperright {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
  width: 76%;
  text-align: justify;
}

.lower {
  display: flex;
  margin-top: 10px;
}

.lowerleft {
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
  width: 24%;
}
.abutton {
  /* margin: 3% 0% 1% 20%; */
  display: flex;
  margin-left: 5%;
}
.display {
  display: flex;
}
.lowerright {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  width: 76%;
  color: #333333;
  text-align: justify;
}

.row4 {
  padding: 15px;
  border-bottom: 1px solid #dedede;
  color: black;
  margin-top: 4%;
}

.row5 {
  background-color: white;
  /* border-bottom: 1px solid #DEDEDE; */
  height: 88px;
  padding: 15px;
  display: grid;
  /* grid-template-rows: 150px 150px; */
  /* grid-template-columns: repeat(3, 120px) 0px; */
  grid-template-columns: 12% 60%;
}

.row5left {
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.row5right {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
}

/* @media screen and (max-width: 1368px) {
  .row4 {
    margin-top: 12%;
  }
} */
@media screen and (max-width: 1368px) {
  .row4 {
    margin-top: 12%;
  }
  .heading p {
    margin-top: 2%;
  }
}
@media only screen and (max-width: 750px) {
  .value {
    width: 100%;
  }
  .display {
    display: block;
  }
  .centered {
    left: 0;
    width: 100vw;
  }
  .sectionStyle {
    /* / display: grid; / */
    grid-template-columns: 1px 1fr;
  }
  .reject {
    margin-top: 0.2rem;
    margin-left: 0;
    width: 110px;
  }
  .accept {
    width: 110px;
  }
  .row1box {
    display: flex;
    flex-direction: column;
  }
  .middle,
  .right {
    margin-left: 0;
  }
  .row2 {
    display: block;
  }
  .upper {
    display: block;
  }
  .lower {
    display: block;
  }
  .accepted {
    margin-left: 40vw;
  }
  /* .backarrow {
    margin-left: -30%;
    margin-top: 3%;
  }
  .para {
    margin-left: 8%;
    margin-top: 13%;
  }
  .abutton {
    margin: -19% 0% 1% 54%;
  } */
  .lowerright {
    display: block;
  }
  .changeDiv {
    left: 2%;
    top: 18%;
  }
}

@media only screen and (max-width: 1080px) {
  .centered {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 86%;
    height: 84%;
    background-color: #ffffff;
    position: absolute;
    /* margin-top: -3%; */
    left: 12%;
  }
}
