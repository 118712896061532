@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100%;
}

.background_img {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.centered_button {
  opacity: 100;
}

/* span {
    font-size: 24px;
  } */

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.centered {
  width: 60%;
  height: 72%;
  background-color: #ffffff;
  position: absolute;
  top: 18%;
  left: 20%;
  box-shadow: 0px 4px 15px #7272723c;
  overflow-y: scroll;
}

.content {
  display: grid;
  grid-template-rows: 20% 70%;
}

.title {
  /* background-color: red; */
  font-weight: 600;
  font-size: 20px;
  color: #333333;
  opacity: 100%;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
}

.image {
  background-color: white;
}

.image img {
  width: 30%;
  margin: 75px 0px 0px 328px;
}

.heading {
  display: grid;
  grid-template-columns: 200px 740px 300px;
}

.backarrow {
  /* background-color: red; */
  width: 0%;
  margin-left: 135px;
  margin-top: 25px;
}

.backarrow img {
  width: 20px;
}

.text {
  /* background-color: saddlebrown; */
  font-weight: bold;
  margin: 30px;
  margin-left: -35px;
  margin-top: 8px;
}

.header {
  width: 100%;
  position: sticky;
  top: 0px;
  background-color: rgb(151 164 222);
  height: 15%;
  border-bottom: 1px solid #70707030;
  display: grid;
  grid-template-columns: 13% 87%;
}

/* .left {
    background-color: red;
} */

.left img {
  width: 55px;
  height: 52px;
  margin: 10px 0px 0px 42px;
  border-radius: 50%;
}

/* .mid {
    background-color: yellow;
} */

.mid p {
  margin-top: -20px;
}

/* .right {
    background-color: rgb(155, 155, 155);
} */

.right img {
  margin: 30px 0px 0px 50px;
  width: 30px;
  height: 35px;
  cursor: pointer;
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #333333;
  opacity: 50%;
}

.sender {
  width: 242px;
  background-color: #fafafa;
  height: 10%;
  margin: 1px 0px 0px 30px;
  border: 1px solid #eaeaea;
  border-radius: 0px 15px 15px 15px;
}

.sender p {
  text-align: left;
  letter-spacing: -0.15px;
  color: #333333;
  opacity: 0.7;
  font-size: 11px;
  margin: 5px 0px 0px 18px;
}

.uReceiver {
  width: 540px;
  background-color: #fafafa;
  min-height: 10%;
  margin: 5px 0px 0px 33%;
  border: 1px solid #eaeaea;
  border-radius: 15px 15px 0px 15px;
  scroll-behavior: smooth;
  top: 0;
  left: 0;
  display: block;
  word-break: break-word;
}
.rReceiver {
  scroll-behavior: smooth;
  top: 0;
  left: 0;
  width: 540px;
  background-color: #fafafa;
  min-height: 10%;
  margin: 5px 20px 0px 2px;
  border: 1px solid #eaeaea;
  border-radius: 0px 15px 15px 15px;
  /* float: left; */
  display: block;
  word-break: break-word;
}

/* .receiver p {
    text-align: left;
    letter-spacing: -0.15px;
    color: #333333;
    opacity: 0.7;
    font-size: 11px;
    margin: 5px 0px 0px 18px;
} */

.downcontainer {
  width: 40%;
  height: 8%;
  border-radius: 30px;
  position: fixed;
  /* top:85%; */
  bottom: 1.5%;
  left: 30%;
  /* margin: 25% 0px 0px 23%; */
  box-shadow: 0px 4px 15px #7272723c;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 0% 90% 10%;
}
.mid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mid img {
  width: 20px;
  height: 20px;
}

/* .downleft {
    background-color: yellow;
} */

.downleft img {
  width: 25px;
  margin: 12px;
}

/* .downmid {
    background-color: blue;
} */

.downmid p {
  font-size: 15px;
  border-bottom: 1px solid #7272723c;
  color: #676767;
}

/* .downright {
    background-color: red;
} */

.downright img {
  width: 50px;
  margin: 2px -3px;
}

.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 25%;
  height: 45%;
  left: 20%;
  z-index: 150;
  margin: 15% 0% 0 15%;
  /* top: 30%; */
  position: absolute;
}
.more {
  width: 38px;
  cursor: pointer;
}
.more img {
  cursor: pointer;
  z-index: 1;
}
.modal_container {
  color: #333333;
  box-shadow: 0 2px 2px 2px #00000029;
  background-color: #ffffff;
  border-radius: 10px 0px 10px 10px;
  margin: -0.5% 0 0 -6%;
  padding: 1% 1% 1% 1%;
  width: 6%;
  opacity: 1;
  position: absolute;
}
.modal_label {
  border: none;
  background: none;
  font-size: 0.8rem;
  padding: 0%;
}

.modal_label button :hover {
  border: 1px solid #5a1c1c;
  cursor: pointer;
}
.changeDeleteDiv {
  display: block;
  position: absolute;
  margin: 6% 16%;
}

.addBlurr {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}
@media screen and (max-width: 700px) {
  .headerStyle {
    width: 90vw;
  }
  .sectionStyle {
    width: 90vw;
    overflow: hidden;
  }
  .centered {
    width: 90%;
    left: 5%;
    top: 16%;
    overflow-x: hidden;
  }
  .header {
    grid-template-columns: 30% 70% 0%;
  }
  .downcontainer {
    width: 80%;
    bottom: 10%;
    left: 10%;
    height: 0%;
  }

  .uReceiver {
    width: 215px;
    margin: 4px 20px 0px 20%;
    word-wrap: break-all;
  }
  .uReceiver p {
    /* font-size: 16px; */
  }
  .rReceiver {
    width: 215px;
    word-wrap: break-all;
  }
  .backarrow {
    margin-left: -10px;
    margin-top: 25px;
  }
  .text {
    margin-left: -180px;
    margin-top: 6px;
  }
}

@media screen and (max-width: 1080px) {
  .centered {
    top: 11%;
    height: 60%;
    overflow-x: hidden;
    width: 85%;
    left: 12%;
  }
  .header {
    grid-template-columns: 26% 72%;
  }
  .left {
    margin-top: 32px;
  }
  .heading {
    grid-template-columns: auto auto;
  }
  .downcontainer {
    top: 74%;
    left: 12%;
    width: 84%;
  }
  .rReceiver {
    width: 50%;
  }
  .uReceiver {
    width: 50%;
    margin: 7px 0 0 45%;
  }
  .downright img {
    margin: 18px -3px;
  }
  .headerStyle {
    padding: 0;
  }
}
