.maincontainer {
  background-color: white;
  height: 14rem;
}

.maincard {
  display: grid;
  grid-template-rows: 0px 150px;
  margin-left: 10px;
}

.card {
  border: 1px solid #cfcfd2;
  margin: 15px 0px 0px 0px;
  padding: 16px;
  height: 158px;
  width: 94%;
}

.modal_container {
  color: #333333;
  box-shadow: 0 0px 2px 2px #00000029;
  background-color: #ffffff;
  border-radius: 12px 0px 12px 12px;
  font-size: 9pt;
  margin: 0px 0px 0px -70px;
  padding-left: 1%;
  width: 70px;
  height: 40px;
  opacity: 1;
  position: absolute;
}

.openSquare {
  margin-left: 1px;
  margin-top: 16px;
  width: 25px;
  height: 25px;
  background-image: -webkit-linear-gradient(-45deg, #36b453 50%, #ffffff 50%);
}

.closeSquare {
  margin-left: 1px;
  margin-top: 16px;
  width: 25px;
  height: 25px;
  background-image: -webkit-linear-gradient(-45deg, #eb1a13 50%, #ffffff 50%);
}

.box1 {
  width: 80%;
}

.heading {
  display: flex;
  flex-direction: row;
  height: 50px;
}
.constantImg {
  display: flex;
  flex-direction: row;
  margin-top: -10px;
  cursor: pointer;
}
.constantImg1 {
  display: flex;
  flex-direction: row;
}

.lefthead {
  font-size: 0.9rem;
  width: 94%;
  font-weight: 700;
  overflow: hidden;
}

.lefthead p {
  margin: 0px 0px 0px 4px;
}

.lefthead p {
  opacity: 0.6;
  color: #333333;
  font-size: 8px;
  margin: 5px;
}

.shelf {
  flex: 1 1 auto;
  width: 93%;
  margin-bottom: 0.8%;
  margin-left: 1%;
  display: flex;
  flex-direction: row;
}
.shelf:last-child {
  margin-bottom: 0;
}

.labelbox {
  flex: 0 0 35%;
  opacity: 0.6;
  color: #333333;
  font-size: 12px;
}

.valuebox {
  flex: 0 0 65%;
  opacity: 1;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}

.detail {
  width: 38px;
}

.detail img {
  width: 24px;
  height: 25px;
  left: 665px;
  top: 44px;
  opacity: 1;
  cursor: pointer;
}

.more {
  width: 38px;
  cursor: pointer;
}

.more img {
  cursor: pointer;
  z-index: 1;
}
.lefthead1 {
  width: 94%;
  font-weight: 700;
}

.table {
  font-size: 15px;
  min-width: 548px;
}

.table tbody {
  margin: 25px;
}

.box2 {
  background-color: white;
}

.lefthead p {
  font-size: 1rem;
  opacity: 1;
}

.lefthead p span {
  font-size: 0.6rem;
  display: block;
  opacity: 0.6;
}

.card_image img {
  height: 10rem;
  width: 10rem;
  margin-top: -11.7rem;
  float: right;
}

.card_info {
  width: 30px;
}

.appliedImg {
  height: 10%;
}

.left img {
  width: 45px;
  height: 40px;
  margin-top: -3px;
}

.left p {
  color: #1e118d;
  margin-left: 62px;
  margin-top: -40px;
  font-size: 15px;
  font-weight: bold;
}

.eye {
  margin-right: 4%;
  margin-top: -14%;
}

.right img {
  width: 32px;
  height: 34px;
  margin-left: 257px;
  margin-top: -3px;
}

.right p {
  color: #747474;
  opacity: 1;
  font-size: 13px;
  margin-left: 300px;
  margin-top: -33px;
}

@media screen and (max-width: 1500px) {
  .lefthead {
    width: 84%;
  }
  .lefthead1 {
    width: 83%;
  }
}
@media screen and (max-width: 1300px) {
  .lefthead {
    width: 80%;
  }
  .lefthead1 {
    width: 78%;
  }
}

@media screen and (max-width: 1150px) {
  .lefthead {
    width: 76%;
    overflow: hidden;
  }
  .lefthead1 {
    width: 74%;
  }
  
}

@media screen and (max-width:767px){
  .box1{
    width: 100%;
  }
  .lefthead p{
    font-size: 3vw;
  }
  .card_image img{
    height: 5rem;
    width: 5rem;
    margin-top: -8.7rem;
    float: right;
  }
  .shelf{
    width: 69%;
  }
}
