/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.container {
  border: 1px solid #cfcfd2;
  margin: 13px;
  /* padding: 10px 10px 10px 10px; */
  /* height: 100px; */
}

.container:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  transform: scale(1, 1);
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.card {
  /* border: 1px solid #CFCFD2; */
  /* margin: 13px; */
  padding: 10px 10px 10px 10px;
  /* height: 100px; */
  display: grid;
  grid-template-columns: 10% 40% 40%;
  overflow: hidden;
  /* font-family: 'Poppins', sans-serif; 29.28vw*/
}

/* .card:hover {
    box-shadow: 10px 10px 12px -4px #00000015;
    transform: scale(1, 1);
} */

.left {
  width: 100px;
  height: 100px;
  /* height: 85px; */
}

.left img {
  height: 100%;
  width: 95%;
}

.middle p {
  font-size: 15px;
  margin: -6px 0px 0px 17px;
}

.table1 {
  font-size: 15px;
  min-width: 230px;
  margin: 0px 0px 0px 13px;
}

.right_table1 {
  min-width: 230px;
  margin: 15px;
}

.category {
  opacity: 0.6;
  color: #333333;
  /* font-size: 0.86vw; */
  font-size: 0.8rem;
  font-weight: bold;
  width: 6vw;
  border: 1px solid transparent;
  /* margin: 2px; */
  /* padding: 2px; */
  border-radius: 10px;
}

.value {
  /* opacity: 1; */
  /* color: #333333; */
  /* font-size: 0.86vw; */
  /* border: 1px solid transparent; */
  border-radius: 10px;
  /* margin: 2px; */
  /* padding: 2px; */
  border: 1px solid transparent;
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  width: 70%;
  font-weight: 600;
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.request {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #cfcfd2;
}

.request p {
  margin: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
}

.request:hover {
  background-color: #0f065f;
  color: white;
}

.request:hover p {
  background-color: #0f065f;
  color: white;
}

@media only screen and (max-width: 650px) {
  .card {
    display: grid;
    padding: 0;
    grid-template-columns: auto auto auto;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "left . ." "middle right .";
  }
  .middle {
    grid-area: middle;
  }
  .right {
    grid-area: right;
  }
  .left {
    grid-area: left;
    width: 250px;
    height: 150px;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .value {
    margin-left: 3rem;
  }
}

@media only screen and (max-width: 450px) {
  .card {
    grid-template-areas: "left . ." "middle . ." "right . .";
  }
}

@media only screen and (max-width: 1080px) {
  .card {
    /* border: 1px solid #CFCFD2; */
    /* margin: 13px; */
    padding: 10px 10px 10px 10px;
    /* height: 100px; */
    display: grid;
    grid-template-columns: 13% 46% 40%;
    overflow: hidden;
  }
}
