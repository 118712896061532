.container {
  display: block;
  background-color: #fafbfc;
  width: 100%;
  height: 110%;
  border-radius: 30px;
}

.closeModal {
  float: right;
  width: 25px;
  height: 25px;
  margin: 0 5% 0 0;
  cursor: pointer;
}

.para {
  padding: 5% 0 0 9%;
}

.formStyle {
  padding: 0 1% 6% 5%;
}

.formStyle input[type="text"] {
  display: flex;
  width: 70%;
  height: 25px;
  align-items: center;
  justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  border-radius: 8pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 0.9rem;
}
.formStyle input:focus {
  outline: none;
}

.allBtn2 {
  float: right;
  padding: 3% 12% 7% 0;
}

.mail2 {
  font-size: 0.9rem;
}
.signin2 {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 134px;
  height: 35px;
  margin: 6px 0px 0px 14px;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
}

.errorMsg {
  /* margin: 2% 2% 2% 11%; */
  color: red;
  font-size: 0.8rem;
  font-weight: normal;
}
