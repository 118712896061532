.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.nodata {
  display: block;
  width: 50%;
  margin: 5% auto;
}

.centered {
  width: 70%;
  height: 60%;
  margin: 1% 0% 0% 15%;
  box-shadow: 1px 1px 5px;
  background-color: white;
}

.div {
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.div2 {
  margin-top: 2%;
  opacity: 0.5;
  width: 100%;
}
.whiteButton {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #ffffff;
  /* / width: 9.80vw; / */
  height: 35px;
  margin: 6px 0px 0px 14px;
  font-weight: 500;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #110766;
  text-align: center;
  font-size: 1.17vw;
  cursor: pointer;
}
.div3 {
  display: flex;
  flex-direction: row;
}

.left {
  width: 48%;
  margin: 0px 0px 0px 30px;
  display: grid;
}

.right {
  width: 48%;
  margin: 0px 0px 0px 0px;
  display: grid;
}

.right1 {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-top: 8px;
}
.edit {
  margin-right: 30px;
  display: block;
  cursor: pointer;
}
.delete {
  width: 40%;
  display: block;
  margin-top: -13px;
  cursor: pointer;
}

.footer {
  width: 70.3%;
  height: 14%;
  background-color: #f7f7f7;
  position: absolute;
  top: 95%;
  left: 19.1%;
}

.firstHeading {
  margin: 2% 20%;
  position: absolute;
}

.label1 {
  margin-top: 3%;
  color: #919191;
  opacity: 1;
  margin-left: 13px;
  font-size: 0.9rem;
}

.input1 {
  width: 94%;
  margin-left: 11px;
  height: 65%;
  opacity: 0.5;
  margin-top: 5px;
  border-radius: 6px;
}

.input1:focus {
  outline: none;
}

.display_data {
  width: 80%;
  height: 25px;
  margin-left: 14px;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  color: #0b2342;
  font-size: 0.9rem;
  font-family: "Poppins", "Medium";
}
.display_info {
  width: 191%;
  height: 45px;
  overflow-y: auto;
  margin-left: 14px;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  color: #0b2342;
  font-size: 0.9rem;
  font-family: "Poppins", "Medium";
}

.div4 {
  display: flex;
  flex-direction: column;
}

.label2 {
  margin-left: 3%;
  margin-top: 3%;
  font-size: 0.9rem;
}

.input2 {
  margin-left: 3%;
  height: 70px;
  width: 91%;
  border-radius: 6px 6px 6px 6px;
  margin-top: 1%;
  border-color: #b9b9b9;
  letter-spacing: -1px;
  padding: 1% 0% 0% 2%;
}

.input2:focus {
  outline: none;
}

.div5 {
  display: flex;
  flex-direction: column;
}

.picdiv {
  width: 93%;
  height: auto;
  margin-top: 8px;
  margin-left: 3%;
  border: 1px solid #b9b9b9;
  border-radius: 6px 6px 6px 6px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  padding: 10px 0px 0px 10px;
  min-height: 50px;
  overflow: auto;
}

.images {
  width: 98%;
  height: 98px;
  margin: 1% 1% 1% 1%;
  background-color: rosybrown;
}
.image {
  height: 200px;
  width: 200px;
  margin-right: 10px;
}

.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px;
  z-index: 150;
  top: 50%;
  width: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.changeResponseDiv {
  background-color: #fafbfc;
  border-radius: 30px;
  z-index: 150;
  top: 50%;
  /* / width: 50%; / */
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.changeDeleteDiv {
  display: block;
  position: absolute;
  margin: 0% 6%;
}

.picdiv img {
  width: 80%;
}

.img {
  width: 5%;
  margin: -3% 0% 0% 48%;
}
.button {
  padding-top: 16px;
  margin-left: 84%;
}

.button1 {
  padding-top: 16px;
  margin-left: 84%;
  padding-bottom: 16px;
}

.deletebutton {
  width: 8%;
  height: 60%;
  margin: 3% 0% 0% 83%;
  border-radius: 16px;
  color: #1e118d;
  border-color: #1e118d;
  background-color: #ffffff;
}

.blockbutton {
  width: 10%;
  height: 40%;
  margin: 3% 6% 3% 0%;
  border-radius: 6px;
  color: #1e118d;
  border-color: #1e118d;
  background-color: #ffffff;
}

.nextbutton {
  width: 10%;
  height: 40%;
  margin: 3% 3% 3% 0%;
  border-radius: 6px;
  background-color: #1e118d;
  color: #ffffff;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-left: 72%;
  margin-bottom: 18px;
}

.imgUpload {
  margin-left: 49%;
  margin-top: -2%;
}
@media screen and (max-width: 1800px) {
  .button {
    margin-left: 82%;
  }
  .button1 {
    margin-left: 82%;
  }
  .buttons {
    margin-left: 69%;
  }
}

@media screen and (max-width: 1600px) {
  .display_data {
    margin-left: 0px;
  }
  .display_info {
    margin-left: 0px;
  }
  .input1 {
    width: 94%;
    margin-left: 0px;
  }
  .label1 {
    margin-left: 0px;
  }
  .button {
    margin-left: 81%;
  }
  .button1 {
    margin-left: 81%;
  }
  .buttons {
    margin-left: 66%;
  }
  .imgUpload {
    margin-left: 49%;
    margin-top: -2%;
    height: 42px;
  }
}
@media screen and (max-width: 1400px) {
  .input1 {
    width: 93%;
  }
  .button {
    margin-left: 78%;
  }
  .button1 {
    margin-left: 78%;
  }
  .buttons {
    margin-left: 62%;
  }
  .imgUpload {
    margin-left: 48%;
    margin-top: -3%;
  }
}

@media screen and (max-width: 1300px) {
  .input1 {
    width: 93%;
  }
  .button {
    margin-left: 78%;
  }
  .button1 {
    margin-left: 78%;
  }
  .buttons {
    margin-left: 60%;
  }
}

@media screen and (max-width: 700px) {
  .sectionStyle {
    grid-template-columns: 1px auto;
  }
  .div {
    width: 100vw;
    margin-left: -80px;
  }
  .button > .whiteButton {
    font-size: 1rem;
    margin-left: -30px;
    margin-top: -30px;
    height: 80px;
  }
  .label1 {
    padding: 0.5rem 0rem;
  }
  .delete {
    width: 80%;
  }
  .changeDiv {
    width: 97%;
  }
  .changeDeleteDiv {
    margin: 0% -11%;
  }
}

@media screen and (max-width: 1080px) {
  .firstHeading {
    margin: 5% 20%;
  }
  .div {
    top: 11%;
    height: 84%;
    width: 86%;

    left: 12%;
  }
  .headerStyle {
    padding: 0;
  }
  .changeDiv {
    width: 80%;
  }
}
