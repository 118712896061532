.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 100%;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.footer {
  width: 70.3%;
  height: 14%;
  background-color: transparent;
  position: absolute;
  top: 95%;
  left: 19.1%;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.heading p {
  font-weight: bold;
  margin: 30px;
  margin-left: 185px;
  margin-top: 23px;
}

.subheader {
  background-color: rgb(238, 238, 245);
  height: 38px;
  margin-top: 22px;
  margin-left: 135px;
  margin-right: 135px;
  display: grid;
  grid-template-columns: repeat(2, 1fr) 0px;
}

.leftheader {
  /* / background-color: white; / */
  margin-left: 30px;
  margin-top: 0px;
  font-weight: bold;
}

.rightheader {
  background-color: rgb(238, 238, 245);
  float: right;
  /* / margin-right: 20px; / */
}

.createnew {
  background-color: #1e118d;
  opacity: 1;
  border-radius: 8px;
  color: white;
  padding: 5px;
  margin-left: 366px;
  cursor: pointer;
}

.create_button {
  float: right;
}

/* .centered {
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
} */

.centered {
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: auto;
  max-height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 20%;
  left: 20%;
}

.nodata {
  overflow: hidden;
  font-weight: 500;
  width: 100%;
  height: auto;
  max-height: 75%;
  background-color: #ffffff;
  top: 19%;
  left: 20%;
}

.nodata img {
  padding: 2%;
  display: block;
  margin: 2% auto;
  width: 47%;
  overflow: hidden;
}

.subcentered {
  opacity: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  gap: 10px;
}

.column {
  float: left;
  width: 25%;
  padding: 0 10px;
}

.row {
  margin: 0 -5px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.loader_style {
  margin: 20% auto;
}

.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 17% 39%;
  z-index: 150;

  position: absolute;
}
.changeDiv1 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 40%;
  /* / height: 62%; / */
  left: 20%;
  z-index: 150;
  top: 30%;
  left: 30%;
  position: absolute;
}

.addBlurr {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

.topHeading {
  text-align: center;
  /* / position: relative; / */
  margin: 2% 0 0 0;
  display: grid;
  grid-template-columns: 40% 82%;
}

.btn_css {
  margin-top: -1.5%;
  display: flex;
  margin-left: 38%;
}
.button {
  margin-top: -10px;
}

.labelImg {
  height: 14px;
}
.filter1 {
  position: absolute;
  background-color: #ffffff;
  border-radius: 10% 0 10% 10%;
  border: 1px solid #e5e5e5;
  margin: -1% 0% 0% -24px;
  font-size: 9pt;
  filter: drop-shadow(0px 3px 6px #00000029);
  padding: 1% 2%;
  z-index: 5;
}

.filter1 > p:hover {
  color: blue;
}

.filter1 p {
  margin: 7% 0% 7% 0%;
  color: #333333;
  opacity: 1;
  font-weight: 400;
  cursor: pointer;
}

.label {
  /* / float: left; / */
  color: #24242e;
  font-weight: 600;
  font-size: 16px;
  margin: 0px 0 35px 26px;
}

.button {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 9.8vw;
  height: 5.32vh;
  margin: 6px 0px 0px 0px;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 1vw;
  cursor: pointer;
}
.changeDiv {
  margin: 15% 35%;
}
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1380px) and (min-width: 768px) {
  .container {
    display: grid;
    grid-template-rows: 4rem 100%;
  }
}

@media screen and (max-width: 767px) {
  .subcentered {
    opacity: 1;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    gap: 10px;
  }

  .centered {
    margin-top: 5%;
  }
  .label {
    font-size: 3vw;
  }
}

@media screen and (max-width: 700px) {
  subcentered {
    grid-template-columns: repeat(1, 1fr);
  }
  .centered {
    margin-top: 5%;
  }
  .changeDiv1 {
    width: 98%;
    left: 1%;
  }
  .changeDiv {
    margin: 15% 1%;
  }
  .button {
    position: absolute;
    top: 19%;
    left: 71%;
    width: 16.8vw;
    font-size: 2.3vw;
  }
  .topHeading {
    grid-template-columns: 74% 0%;
    text-align: left;
    margin: 2% 0 0 -4%;
    display: grid;
  }
  .label {
    font-size: 5vw;
    margin: 0px 0 35px 1px;
  }
  .centered {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 90%;
    height: auto;
    max-height: 69%;
    background-color: #ffffff;
    position: absolute;
    top: 24%;
    left: 5%;
  }
}

@media screen and (max-width: 1080px) {
  .centered {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 84%;
    height: 53%;
    max-height: 75%;
    background-color: #ffffff;
    position: absolute;
    top: 18%;
    left: 13%;
  }
  .button {
    box-shadow: 0 0px 2px 2px #0000001f;
    background-color: #110766;
    font-weight: 500;
    width: 10.8vw;
    height: 3.32vh;
    margin: 6px 0px 0px 0px;
    border-radius: 14px;
    border: 1px solid #110766;
    opacity: 1;
    color: #ffffff;
    text-align: center;
    font-size: 1.5vw;
    cursor: pointer;
  }
  .btn_css {
    margin-top: -2.5%;
    display: flex;
    margin-left: 38%;
  }
  .topHeading {
    display: flex;
    margin: 10% 0 0 15px;
  }
  .headerStyle {
    padding: 0;
  }
}
