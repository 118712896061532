.leftSection {
  width: 10vw;
  height: 57rem;
  background-color: #393939;
}

.menu {
  /* align-self: center; */
  /* justify-self: end; */

  display: flex;
  flex-direction: column;
}

.menu .active {
  position: relative;
  text-decoration: none;
  width: 100%;
  height: 100px;
  background-color: #f7f7f7;
  color: #110766;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu .active:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  right: 0px;
}
.menu .active:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;

  right: 0px;
}

.menu .active:after {
  text-decoration: none;
  top: -10px;
  background: radial-gradient(circle at top left, #393939 10px, #f7f7f7 11px);
}

.menu .active:before {
  text-decoration: none;
  bottom: -10px;
  background: radial-gradient(
    circle at bottom left,
    #393939 10px,
    #f7f7f7 11px
  );
}

/* .active1{  
  background-color: #F7F7F7; 
  width: 100%; 
  height: 100px;
  
  color: #110766; position: absolute;  
   text-decoration: none;

}*/
.inactive1 {
  /* background-color: #393939 ; */
  color: #ffffff;
  opacity: 0.5;
  width: 100%;
  height: 100px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.searchImg {
  /* margin: 5% 10% 2% 30%; */
  width: 2.56vw;
  height: 4.21vh;
}
.span {
  /* margin-left: 18%; */
  display: flex;
  font-size: 1.17vw;
}
.inactiveO {
  /* background-color: #393939 ; */
  color: #ffffff;
  opacity: 0.5;
  width: 100%;
  height: 100px;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  /* .leftSection{
    width: 16vw;
  } */
  .sidebar {
    width: 80vw;
    height: 57rem;
    background-color: #393939;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 6vh;
  }
  /* .menu {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  } */
  .leftSection {
    width: 0vw;
    height: 57rem;
    background-color: #393939;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 6vh;
  }
  .searchImg {
    /* margin: 5% 10% 2% 25%; */
    width: 35.56vw;
    height: 4.21vh;
  }
  .span {
    /* margin-left: 25%; */
    display: flex;
    font-size: 6vw;
  }
  .menu .active {
    height: auto;
  }
}

@media screen and (max-width: 1080px) {
  .leftSection {
    height: 90vh;
  }
  .headerStyle {
    padding: 0;
  }
}
