@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.background_img {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}
.footer {
  width: 70.3%;
  height: 14%;
  background-color: transparent;
  position: absolute;
  top: 95%;
  left: 19.1%;
}
.centered_button {
  opacity: 1;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.heading {
  display: grid;
  grid-template-columns: 200px 740px 300px;
}

.backarrow {
  /* background-color: red; */
  width: 0%;
  margin-left: 135px;
  margin-top: 25px;
}

.backarrow img {
  width: 20px;
}

.text {
  /* background-color: saddlebrown; */
  font-weight: bold;
  margin: 30px;
  margin-left: -35px;
  margin-top: 8px;
}

.filter {
  /* background-color: seagreen; */
  margin-left: 75px;
}

.filter img {
  width: 45px;
  margin-top: 14px;
  cursor: pointer;
}

.centered {
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
}

.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 45%;
  left: 20%;
  z-index: 150;
  margin: 15% 0% 0 5%;
  /* top: 30%; */
  position: absolute;
}

.addBlurr {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.header {
  width: 80%;
  height: 10%;
  margin: -3px 0px 0px 135px;
  /* background-color: red; */
}

.keyword {
  width: 85%;
  height: 66%;
  border: 1px solid #dbdbdb;
  background-color: white;
  margin: 13px 0px 0px 0px;
  display: flex;
}

.keyword img {
  width: 18px;
  padding: 10px;
}

.input {
  border: none;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
}

.options img {
  width: 40px;
  float: right;
  margin: -40px 72px 0px 0px;
}

@media only screen and (max-width: 768px) {
  .centered {
    font-family: "Poppins-medium";
    width: 100%;
    height: 75%;
    position: absolute;
    top: 20%;
    left: 0%;
    margin-left: 0%;
  }
  .headerStyle {
    width: 100vw;
  }
}

@media only screen and (max-width: 1024px) {
  .centered {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 86%;
    height: 83%;
    background-color: #ffffff;
    position: absolute;
    top: 11%;
    left: 12%;
  }
  .headerStyle {
    width: 100vw;
  }
}
