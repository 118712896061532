.header {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: 0 2% 0 3%;
  /* width: 80%; */
  /* min-width: fit-content; */
}

.noOfNotification {
  padding: 0px 5px;
  margin: 0px;
  font-size: 15px;
  color: white;
  background: #110766;
  border-radius: 100%;
  position: absolute;
  right: 5%;
  top: 10%;
}

.header a {
  align-self: center;
  justify-self: self-start;
  opacity: 1;
}
.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 5% 35%;
  z-index: 150;

  position: absolute;
}
/* .changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 48%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 30%;
  position: absolute;
} */
.logo {
  margin-top: -7px;
  height: 8.98vh;
  padding-left: 6.55vw;

  /* margin-left: 100%; */
}
.menu {
  display: none;
}

.headerRight {
  align-self: center;
  justify-self: end;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  float: right;
  padding: 0% 6% 0% 0%;
}

.headerRight a img {
  height: 31.17px;
}

.profileUpdate {
  position: absolute;
  background-color: rgba(128, 128, 128, 0.322);
  color: black;
  top: 50%;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.headerRight a {
  font-weight: 500;
  float: left;
  color: #927f7f;
  text-align: left;
  opacity: 1;
  padding: 12px;
  text-decoration: none;
  font-size: 16px;
  line-height: 25px;
}

.profileImage {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  /* padding: 0 20px; */
  border: 4px solid #ffffff;
  opacity: 1;
}

.profileSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -19px;
}

.bellIconStyle {
  height: 25.17px;
  padding: 0 20px;
  cursor: pointer;
}

.bellIconStyle:hover {
  height: 25.17px;
  margin-top: 28px;
  margin-right: 11px;
  padding: 0px 10px 16px 10px;
  cursor: pointer;
  border-bottom: 4px solid #110766;
  background-image: linear-gradient(180deg, #ffffff00 63%, #110766 150%);
}

.bellIconStyleActive {
  height: 25.17px;
  margin-top: 28px;
  margin-right: 11px;
  padding: 0px 10px 16px 10px;
  cursor: pointer;
  border-bottom: 4px solid #110766;
  background-image: linear-gradient(180deg, #ffffff00 63%, #110766 150%);
}

.dropdownIconStyle {
  height: 22px;
  padding: 0 10px;
  cursor: pointer;
}

.orgNameStyle {
  font-size: 16px;
  color: #525252;
  opacity: 1;
  padding: 0 2px;
  text-transform: capitalize;
  font-weight: 400;
}

.dropStyle {
  position: relative;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.modalStyle {
  position: absolute;
  font-size: 11pt;
  background-color: #ffffff;
  width: 129pt;
  height: 50pt;

  top: 75%;
  right: 26%;
  border-radius: 16pt 0 0 16pt;
}

/* //*************************responsive******************************************** */

@media screen and (max-width: 600px) {
  .header {
    /* float: left; */
    height: auto;
    /* display: block; */
    /* text-align: left; */
  }

  /* .header_right {
    float: none;
  } */
  .profileSection {
    /* display: none; */
  }
  .menu {
    display: block;
    margin-top: 3px;
    height: 6.98vh;
    padding-left: 0.55vw;
    margin-left: -5vw;
  }
  .logo {
    display: none;
  }
  .noOfNotification {
    top: 10%;
  }
}

@media screen and (max-width: 1080px) {
  .header {
    padding: 0;
  }
  .headerStyle {
    padding: 0;
  }
}
