.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100%;
}

.background_img {
  width: 80%;
  height: 110%;
  margin: -3% 8;
}

.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 6.7% 90%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.centered {
  font-family: "Poppins-medium";
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.topHeading {
  text-align: center;
  /* position: relative; */
  margin: 1.5% 0 0 -62%;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 8%;
  /* background-color: aquamarine; */
}
.span {
  font-size: 12pt;
  font-family: "Poppins" "Medium";
  color: #333333;
  max-width: 300px;
  margin: 1.2% 0% 0% 3%;
}
.timespan {
  position: absolute;
  font-size: 7pt;
  color: #333333;
  opacity: 0.6;
  margin: 1rem;
  right: 0;
}
@media screen and (max-width: 700px) {
  .centered {
    width: 92%;
    left: 5%;
  }
  .topHeading {
    margin: 1.5% 0 0 -78%;
  }
}

@media screen and (max-width: 1080px) {
  .headerStyle {
    padding: 0;
  }
  .centered {
    font-family: "Poppins-medium";
    width: 87%;
    height: 75%;
    background-color: #ffffff;
    position: absolute;
    top: 10%;
    left: 12%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
