.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100vh;
  width: 100%;

  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.background_img {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}
.headerStyle {
  width: 100vw;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.nodata {
  overflow: hidden;
  font-weight: 500;
  width: 70%;
  height: auto;
  max-height: 75%;
  background-color: #ffffff;
  top: 19%;
  left: 20%;
}
.footer {
  width: 70.3%;
  height: 14%;
  background-color: transparent;
  position: absolute;
  top: 95%;
  left: 19.1%;
}

.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.centered_button {
  opacity: 1;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.heading {
  display: grid;
  grid-template-columns: 200px 740px 300px;
}

.backarrow {
  width: 0%;
  margin-left: 135px;
  margin-top: 25px;
}

.backarrow img {
  width: 20px;
}

.text {
  font-weight: bold;
  margin: 30px;
  margin-left: -35px;
  margin-top: 8px;
}

.filter {
  margin-left: 75px;
}

.filter img {
  width: 45px;
  margin-top: 14px;
  cursor: pointer;
}

.centered {
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 48%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 30%;
  position: absolute;
}
.changeDiv1 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;

  left: 38%;
  z-index: 150;

  top: 23%;

  position: absolute;
}
.changeDiv3 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 40%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 12%;
  position: absolute;
}
.addBlurr {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

.header {
  width: 83%;
  height: 6%;
  left: 24%;
  margin-top: 1%;
}

.keyword {
  height: 65%;

  border: 1px solid #dbdbdb;
  background-color: white;
  display: flex;
  margin-left: 13.5%;
  margin-top: 1%;
}

.keyword img {
  width: 18px;
  padding: 10px;
}

.input {
  border: none;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
  width: 100%;
}
.input:focus {
  outline: none;
}
.options img {
  width: 40px;
  height: 37.5px;
  float: right;

  margin-top: -2.3rem;
  margin-right: -3rem;
}

@media screen and (max-width: 767px) {
  .centered {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 93%;
    margin-top: 1rem;
    background-color: #ffffff;
    position: absolute;
    top: 18%;
    left: 5%;
    font-family: "Poppins-medium";
    height: 74%;
  }
  .sectionStyle {
    grid-template-columns: 0px 1fr;
  }
  .topHeading {
    padding-top: 1rem;
  }
  .header {
    margin-top: -0.8rem;
  }
  .keyword {
    padding: 0.5rem;
    height: 109%;
    margin-left: 4.5%;
    position: sticky;
  }

  .headerStyle {
    width: 100vw;
  }
  .options img {
    width: 40px;
    height: 37.5px;
    float: right;
    margin-right: -2rem;
  }

  .changeDiv {
    width: 97%;
    left: 1%;
  }
  .changeDiv1 {
    left: 2%;
  }
}
@media screen and (max-width: 767px) {
  /* .leftSection{
    width: 16vw;
  } */
  .sidebar {
    width: 80vw;
    height: 57rem;
    background-color: #393939;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 6vh;
  }
  /* .menu {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  } */
  .leftSection {
    width: 0vw;
    height: 57rem;
    background-color: #393939;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 6vh;
  }
  .searchImg {
    margin: 5% 10% 2% 25%;
    width: 35.56vw;
    height: 4.21vh;
  }
  .span {
    margin-left: 25%;
    display: flex;
    font-size: 6.5vw;
  }
  .changeDiv3 {
    width: 93%;
    left: 3%;
    top: 2%;
  }
  .changeDiv2 {
    width: 85%;
    left: 5%;
  }
}

@media screen and (max-width: 1080px) {
  .centered {
    width: 86%;
    height: 77%;
    background-color: #ffffff;
    position: absolute;
    top: 16%;
    left: 12%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .header {
    width: 80%;
    height: 6%;
    position: absolute;
    left: 7%;
    top: 10%;
  }
  .changeDiv,
  .changeDiv1,
  .changeDiv2 {
    background-color: #fafbfc;
    border-radius: 30px 30px 30px 30px;
    width: 60%;
    height: 36%;
    left: 24%;
    z-index: 150;
    margin: 15% 0% 0 5;
    top: 30%;
    position: absolute;
  }
  .changeDiv3 {
    background-color: #fafbfc;
    border-radius: 30px 30px 30px 30px;
    width: 73%;
    height: 43%;
    left: 18%;
    z-index: 150;
    margin: 15% 0% 0 5;
    top: 30%;
    position: absolute;
  }
  .headerStyle {
    padding: 0;
  }
}
