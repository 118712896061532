.container {
  background-color: rgb(240, 239, 239);
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 16rem auto auto;
  height: 100vh;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  color: white;

  z-index: 100;
}

.loader_styles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered {
  width: 70%;
  height: 75%;
  margin-top: 2%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.editButton {
  width: 8%;
  height: 4%;
  margin-top: 0.8%;
  color: #1e118d;
  background-color: white;
  border-color: #1e118d;
  border-radius: 14px;
  margin-right: 1%;
}

.topHeading {
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  justify-content: space-between;
  align-items: center;
  height: 9vh;
  width: 70vw;
}

.para {
  color: #24242e;
  font-weight: 600;
  font-size: 1.1rem;
  margin-left: 1rem;
  margin-bottom: -1rem;
}

.left {
  display: flex;
  padding: 15px;
}

.leftinner1 {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.leftinner2 {
  display: flex;
  flex-direction: column;
  margin: 8% 0% 0% 0%;
}

.label1 {
  /* font-size: 12pt; */
  color: #919191;
  margin: 3% 0% 0% 3%;
}

.span1 {
  /* font-size: 12pt; */
  color: #0b2342;
  opacity: 0.6;
  margin: 1% 0% 0% 3%;
}

.span2 {
  color: #0b2342;
  opacity: 0.6;
  margin: -0.6vw;
}

.span4 {
  color: #0b2342;
  opacity: 0.6;
  margin: -0.4vw;
}

.address {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "middle . right";
}
.middle {
  grid-area: middle;
}
.right {
  grid-area: right;
}

.divimg {
  width: 18%;
  height: 90%;
}

.img {
  /* margin-top: 1rem;
  width: 13rem;
  height: 13rem; */
  height: 31.65vh;
  margin-top: 2.43vh;
  width: 15.22vw;
  margin-left: 10%;
  border-radius: 1rem;
}

.img1 {
  height: 46px;
  margin-left: 32%;
  margin-top: -12%;
}

.middle {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  width: 30%;
}

.right {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.label3 {
  /* font-size: 12pt; */
  color: #919191;
  margin-top: 0px;
  padding: 5%;
}

.span3 {
  /* font-size: 12pt; */
  color: #0b2342;
  opacity: 1;
  margin-left: 5%;
  margin-top: -3%;
}

.label {
  margin: 0% 0% 0% 0%;
  /* font-size: 14pt; */
  font-weight: 600;
}

.about {
  width: 70%;
  color: #0b2342;
  opacity: 1;
  /* font-size: 14px; */
}
.editBtn {
  margin: 1rem;
}
.div {
  display: flex;
}
@media screen and (max-width: 740px) {
  .left {
    flex-direction: column;
  }
  .span1 {
    margin: 0;
  }
  .leftinner2 {
    margin-top: 1rem;
  }
  .img {
    width: 200px;
    height: 200px;
  }
  .label,
  .about {
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .label1 {
    margin-left: 0px !important;
  }
  .divimg {
    margin-bottom: 1.5rem;
    grid-area: divimg;
    margin-left: 1rem;
  }

  .centered {
    width: 100vw;
    left: 0;
  }

  .div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: ". divimg ." "middle . right";
  }
  .middle {
    grid-area: middle;
    width: 100%;
    margin-left: 5%;
  }
  .right {
    grid-area: right;
    margin-left: 5%;
    width: 100%;
  }
  .para {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .editBtn {
    margin: 1rem;
  }
  .sectionStyle {
    grid-template-columns: 1px 1fr;
  }
  .topHeading {
    width: 90vw !important;
  }
}

@media only screen and (max-width: 1100px) {
  .topHeading {
    width: 65vw;
  }
}

@media only screen and (max-width: 400px) {
  .div {
    grid-template-areas: "divimg . ." "middle . ." "right . .";
    grid-template-rows: auto auto auto;
  }
  .img {
    width: 300px;
    height: 150px;
  }
}

@media only screen and (max-width: 1080px) {
  .topHeading {
    width: 87vw;
    position: absolute;
    left: 12%;
  }
  .centered {
    width: 86%;
    height: 75%;
    margin-top: 2%;
    background-color: #ffffff;
    position: absolute;
    top: 13%;
    left: 12%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .img {
    height: 14.65vh;
  }
}
