* {
    box-sizing: content-box;
    font-family: 'Poppins';
}

html {
    min-height: 100%;
}

body {
    margin: 0;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Poppins', monospace;
}

@font-face {
    font-family: "SilverAvenueRegular";
    src: local("SilverAvenueRegular"), url("./fonts/StarAvenue/FontsFree-Net-staravenue.ttf") format("truetype");
    font-weight: bold;
}