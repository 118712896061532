

.container {
  border: 1px solid #cfcfd2;
  margin: 13px;
  
  cursor: pointer;
}

.container:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  transform: scale(1, 1);
}

.card {
  padding: 10px 10px 10px 10px;

  display: grid;
  grid-template-columns: 10% 40% 40%;
  overflow: hidden;
}

.left {
  width: 100px;
  height: 85px;
}

.left img {
  height: 100%;
  width: 95%;
  margin-left: 45vw;
}

.middle p {
  font-size: 15px;
  margin: -6px 0px 0px 17px;
}

.table1 {
  font-size: 15px;
  min-width: 230px;
  margin: 0% 0% 0% 40%;
}

.right_table1 {
  min-width: 230px;
  margin: 15px;
}

.category {
  opacity: 0.6;
  color: #333333; 
  font-size: 0.8rem;
  font-weight: bold;
  width: 6vw;
  border: 1px solid transparent;
  
  border-radius: 10px;
}

.value {
 
  border-radius: 10px;
  
  border: 1px solid transparent;
  opacity: 1;
  color: #333333;
  font-size: 1.2rem;
  width: 120%;
  font-weight: 600;
  margin-left: 3%;
}

.value1 {
  margin-left: 3%;
  margin-top: 3%;
  text-align: left;
  font: normal normal normal 15px/30px Poppins;
  letter-spacing: -0.15px;
  color: #333333;
  opacity: 1;
}

.request {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #cfcfd2;
}

.request p {
  margin: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
}

.request:hover {
  background-color: #0f065f;
  color: white;
}

.request:hover p {
  background-color: #0f065f;
  color: white;
}

@media screen and (max-width:767px){
  .card {
    grid-template-columns: 0% 0% 0%;
  }
  .left img {
    width: 70%;
    height: 85%;
    margin-left: 50vw;
  }
  .value {
    font-size: 2.5vw;
  }
  .value1{
    font-size: 2vw;
  }
}


@media screen and (max-width:575px){
  .card {
    grid-template-columns: 0% 0% 0%;
  }
  .left img {
    width: 55%;
    height: 60%;
    margin-left: 35vw;
  }
  .value {
    font-size: 2.5vw;
    max-width: 100px;
  }
  .value1{
    font-size: 2vw;
  }
}