.container {
  /* width: 35%; */
  border-radius: 30px;
  background-color: #ffffff;
}

.closeModal {
  float: right;
  width: 25px;
  height: 25px;
  margin: 2% 4% 0 0;
  cursor: pointer;
}
.whiteButton {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #ffffff;
  width: 134px;
  height: 35px;
  margin: 6px 0px 0px 14px;
  font-weight: 500;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #110766;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.para {
  font-size: 18px;
  padding: 5% 0% 0% 7%;
}

.form1 {
  display: block;
}

.label {
  display: inline-flex;
  color: #919191;
  font-size: 11pt;
  justify-content: right;
  margin: 10px 0 5px 40px;
  font-weight: 500;
}

.input {
  display: flex;
  width: 64%;
  height: 23px;
  align-items: center;
  justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 6pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 11pt;
  margin-bottom: -1rem;
}

.input:focus,
.input1.focus {
  border: 1pt solid #b9b9b9;
}

.subheading {
  display: inline-flex;
  justify-content: right;
  margin: 10px 0 5px 40px;
  font-weight: 600;
  margin-top: 4%;
  color: #333333;
  font-size: 12pt;
}

.input1 {
  display: flex;
  width: 90%;
  height: 23pt;
  align-items: center;
  justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 6pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 11pt;
  margin-bottom: -1rem;
}

.input1:focus {
  border: 1pt solid #7e7e7e;
}

.fourth {
  display: flex;
  flex-wrap: wrap;
}

.fourth2 {
  /* margin-left: 55px; */
}

.fourth3 {
  display: flex;
  justify-content: space-between;
  width: 35vw;
}

.btn_css {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 91%;
  margin-top: 1rem;
}
.date_style {
  margin-left: 33%;
  margin-top: 2%;
  cursor: pointer;
  position: absolute !important;
}
.date_style1 {
  margin-left: 33%;
  margin-top: 2%;
  cursor: pointer;
  position: absolute !important;
}
.Calendar {
  margin-top: 5%;
  margin-left: 7%;
  /* width: 150px; */
  /* padding: calc(0.75em / 0.75) calc(0.5em / 0.75); */
  /* line-height: 1.125em; */
}
/* .react-calendar__tile {
      padding: 0 !important;
  } */

.errorMsgbelow {
  font-size: 0.7rem;
  color: red;
  padding: 2.5% 0 0 6%;
}
@media screen and (max-width: 775px) {
  .whiteButton {
    width: 20.8vw;
    font-size: 3vw;
  }
  .btn_css {
    padding: 28px 14px 30px 53px;
  }

  .fourth {
    display: block;
  }
  .input1 {
    width: 63%;
  }
  .fourth2 {
    margin-left: 3px;
    margin-top: 3%;
  }
  .Calendar {
    /* position: absolute !important; */
    left: 1vw !important;
    top: 135%;
  }
  .date_style1 {
    right: 2.5rem;
    margin: 0;
  }
  .date_style {
    right: 2.5rem;
    margin: 0;
  }
  .fourth3 {
    width: 70vw;
  }
}

@media screen and (max-width: 1080px) {
  .btn_css {
    position: relative;
    width: 100%;
    margin-top: 3rem;
    display: flex;
    padding: 0;
    justify-content: space-around;
  }
  .headerStyle {
    padding: 0;
  }
  .whiteButton {
    height: 48px;
  }
  .input1 {
    width: 59%;
  }
  .fourth2 {
    margin-left: 32px;
  }
}
