.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
  overflow-y: auto;
}

.background_img {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.button {
  float: right;
  margin: 1% 0px 0px 75%;
}

.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.centered_button {
  opacity: 1;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.centered {
  width: 70%;
  height: 75%;
  background-color: #f7f7f7;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-y: auto;
}

.profilesuccesscss {
  margin: 3% 0 0 1%;
  font-size: 0.8rem;
  font-weight: 400;
  color: #707070;
}

.profilesuccessimg {
  width: 60% !important;
  margin: 15% 0 0 10%;
}

.content {
  display: grid;
  grid-template-rows: 20% 70%;
}

.maindiv {
  /* background-color: red; */
  font-weight: 600;
  font-size: 20px;
  color: #333333;
  opacity: 1;
  /* font-family: 'Poppins', sans-serif; */
  display: flex;
  flex-direction: column;
  /* justify-content: center;
        text-align: center; */
  /* margin-top: 30px; */
  height: 100%;
  width: 100%;
}

.image {
  background-color: white;
}

.image img {
  width: 30%;
  margin: 75px 0px 0px 328px;
}

.box1 {
  width: 96%;
  height: 22%;
  padding: 2%;
  height: -2%;
  /* margin: 3% 0% 0% 0%; */
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  /* border: 1px solid black; */
}

.box1 img {
  width: 13%;
  height: 100%;
}

.midbox1 {
  width: 100%;
}

.profile {
  display: flex;
  flex-direction: row;
}

.profile p2 {
  margin: 19px 0% 0% 1%;
  color: #707070;
  opacity: 1;
  font-size: 15px;
}

.profile h1 {
  margin: 9px 0% 0% 2%;
}

.profile h2 {
  margin: 15px 0% 0% 0%;
  font-size: 22px;
}

.greydiv {
  height: 8%;
  width: 90%;
  margin: -1% 0% 0% 2%;
  border-radius: 8px;
  background-color: #e6e7e8;
}

.greydiv img {
  width: 8%;
  height: 625%;
  float: right;
  margin: -3% -5% 0% 0%;
}

.box2 {
  width: 100%;
  height: 25%;
  height: -2%;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
}

.box2heading {
  font-size: 1rem;
  margin: 2% 0% 1% 2%;
  font-weight: 700;
}

.leftbox2 {
  margin: 1%;
  /* background-color: pink; */
  width: 50%;
  display: flex;
  flex-direction: column;
}

.leftbox2 h1 {
  /* width: 11%; */
  font-size: 45px;
  /* float: right; */
  /* justify-content: center; */
  margin: 0% 0% 0% 42%;
}

.leftbox2 p {
  margin: 0% 0% 0% 0%;
  font-size: 13px;
  color: #24242e;
  opacity: 0.6;
  justify-content: center;
  text-align: center;
}

.line {
  margin: 3%;
}

.rightbox2 {
  margin: 1%;
  /* background-color: grey; */
  width: 50%;
}

.rightbox2 h1 {
  /* width: 11%; */
  font-size: 45px;
  /* float: right; */
  /* justify-content: center; */
  margin: 0% 0% 0% 42%;
}

.rightbox2 p {
  margin: 0% 0% 0% 0%;
  font-size: 13px;
  color: hsl(240, 12%, 16%);
  opacity: 0.6;
  justify-content: center;
  text-align: center;
}

.midbox1 table {
  font-size: 0.7rem;
  font-weight: 400;
  margin: 0% 0% 0% 3%;
  opacity: 1;
  color: #707070;
}

.midbox1 p1 {
  margin: 24px;
}

.heading {
  display: flex;
  flex-direction: row;
}

.link {
  float: right;
  margin: -3% 2% 0% 0%;
  font-size: 15px;
  cursor: pointer;
}

.link :hover {
  color: #110766;
  font-weight: 700;
}

.box3 {
  width: 100%;
  height: 39%;
  height: -2%;
  margin: 0% 0% 0% 0%;
  background-color: #ffffff;
  /* border: 1px solid black; */
}
.skillls {
  display: flex;
}
.box3heading {
  font-size: 1rem;
  margin: 2% 0% 1% 2%;
  font-weight: 700;
}
@media screen and (max-width: 1600px) {
  .greydiv img {
    margin: -3% -5% 0% 0%;
  }
  .button {
    margin: 1% 0px 0px 75%;
  }
}

@media screen and (max-width: 1450px) {
  .button {
    margin: 0.5% 0px 0px 75%;
  }
}
@media screen and (max-width: 1400px) {
  .greydiv img {
    margin: -4.5% -5% 0% 0%;
  }
}
@media screen and (max-width: 1150px) {
  .button {
    margin: 1rem 0px 0px 65%;
  }
}
@media screen and (max-width: 575px) {
  .headerStyle {
    width: 100vw;
  }
  .greydiv img {
    margin: -9.5% -5% 0% 0%;
  }
  .box1 {
    height: 43%;
  }
  .box1 img {
    width: 13%;
    height: 35%;
    margin-top: 10%;
  }
  .centered {
    width: 90%;
    left: 5%;
    top: 20%;
    overflow-x: hidden;
  }
  .midbox1 p1 {
    margin: 10px;
  }
  .profilesuccesscss {
    margin: 10% 0 0 1%;
  }
  .greydiv img {
    width: 19%;
    height: 310%;
    margin-top: -7%;
  }
  .skillls {
    display: block;
  }
}
/* media query for ipad */
@media screen and (max-width: 1080px) {
  .headerStyle {
    padding: 0;
  }
  .centered {
    top: 14%;
    height: 85%;
    left: 12%;
    width: 85%;
  }
  .container {
    height: 100vh;
  }
  .greydiv img {
    height: 70px;
  }
  .box1 {
    height: 19%;
  }
}
