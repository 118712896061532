@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
* {
    box-sizing: content-box;
    font-family: 'Poppins';
}

html {
    min-height: 100%;
}

body {
    margin: 0;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Poppins', monospace;
}

@font-face {
    font-family: "SilverAvenueRegular";
    src: local("SilverAvenueRegular"), url(/static/media/FontsFree-Net-staravenue.f3e6a3a7.ttf) format("truetype");
    font-weight: bold;
}
.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.font-face-gm {
  font-family: "SilverAvenueRegular";
}

.toast {
  -webkit-flex-basis: 350px;
          flex-basis: 350px;
  max-width: 400px;
  width: 100px;
  font-size: 0.875rem;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  z-index: 200;
  position: absolute;
  bottom: 5%;
  right: 10%;
  min-width: 200px;
}

.toastheader {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toastbody {
  padding: 0.75rem;
}
.toasthide {
  opacity: 0;
  display: none;
  position: absolute;
  bottom: 20;
  right: 20;
  min-width: 200px;
}

@media screen and (max-width: 775px) {
  .toast {
    position: absolute;
    top: 46%;
    left: 6%;
    min-width: 268px;
    z-index: 200;
    bottom: 23%;
  }
  .toasthide {
    position: absolute;
    top: 46%;
    left: 6%;
    min-width: 268px;
    z-index: 200;
    bottom: 23%;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.Backdrop {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(100, 98, 98, 0.8);
}


.BackdropOpen {
    display: block;
}

.BackdropClosed {
    display: none;
}
.signup_container__T-YCM {
  display: grid;
  grid-template-columns: 55% 55%;
}

.signup_leftContainer__2tTIY {
  background-color: #fafbfc;
  width: 100%;
  height: 100%;
  border-radius: 30px 0 0 30px;
}

.signup_leftContainer__2tTIY .signup_leftLogo__22B2L {
  padding: 6% 0 0 37%;
}

.signup_leftContainer__2tTIY .signup_leftPara__1QUrD {
  font-size: 0.9rem;
  line-height: 1.3;
  font-weight: 500;
  padding: 2% 0 0 6%;
  opacity: 0.7;
}

.signup_leftContainer__2tTIY .signup_leftImg__36dYc img {
  width: 80%;
  padding: 7% 0 0 10%;
  /* / height: 100%; / */
}

.signup_rightContainer__4MkHJ {
  background-color: #ffffff;
  border-radius: 0 30px 30px 0;
  width: 100%;
  height: 100%;
}

.signup_rightContainer__4MkHJ .signup_title__SvPJW {
  font-size: 20pt;
  font-weight: 600;
  color: #333333;
  padding: 4% 0% 0% 10%;
  line-height: 0.2;
}

.signup_rightContainer__4MkHJ .signup_subTitle__P9duC p {
  font-size: 11.5pt;
  opacity: 0.5;
}

.signup_rightContainer__4MkHJ .signup_formStyle__xLTA0 {
  padding-top: 0%;
}

.signup_errorMsg__O_pgP {
  font-size: 0.7rem;
  color: red;
  margin-left: 11%;
}

.signup_perrorMsg__IfTKi {
  font-size: 0.7rem;
  color: red;
  margin-right: 11%;
  margin-bottom: 3%;
  float: left;
  padding-left: 13%;
}
.signup_anchorStyle__2qdta {
  color: #1e118d;
  font-weight: 500;
  opacity: 1;
  font-size: 11pt;
  cursor: pointer;
}

.signup_rightContainer__4MkHJ .signup_subTitle__P9duC {
  padding: 0% 0% 0% 10%;
  opacity: 0.5;
  display: inline;
  font-size: 1rem;
}

.signup_closeModal__RD79N {
  float: right;
  width: 25px;
  height: 25px;
  margin: 5%;
  cursor: pointer;
}

.signup_orgRadio__2CFY9 {
  grid-area: orgRadio;
  margin-bottom: 2%;
}

.signup_recRadio__3h0uW {
  grid-area: recRadio;
  margin-bottom: 2%;
}

.signup_emailName2__L9Gpm {
  padding-top: 0%;
}

.signup_orgname2__3UzEX {
  padding-top: 4%;
}

.signup_forgot__HdWPZ {
  color: #1e118d;
  opacity: 1;
  background: none;
  font-weight: 500;
  float: right;
  border: none;
  padding: 0 54px 0 0;
}

.signup_allBtn__3mE3K {
  padding: 14px 0 0 0;
}

.signup_allBtn2__2kiVx {
  padding: 10px 0 0 0;
}

.signup_formStyle__xLTA0 input[type="text"],
.signup_formStyle__xLTA0 input[type="password"],
.signup_formStyle__xLTA0 input[type="email"] {
  display: -webkit-flex;
  display: flex;
  width: 60%;
  height: 21px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 8pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 11pt;
}
.signup_formStyle__xLTA0 input:focus {
  outline: none;
}

.signup_formStyle__xLTA0 input[type="radio"] {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
  padding: 0.25em;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  border-radius: 50%;
  opacity: 1;
  margin: 0 41% 0 0;
  float: right;
  outline: none;
  border: 2px solid #737373;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  margin: 6% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: #110766;
}

input[type="radio"]:checked {
  border-color: #110766;
}

.signup_tabLinks__1WkOx {
  background: none !important;
  border: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #333333;
  opacity: 0.8;
  cursor: pointer;
}

.signup_activeTab__2XxbC {
  color: #1e118d;
  font-weight: 600;
  font-size: 0.9rem;
  opacity: 1;
  border-bottom: 6px solid #1e118d;
  border-radius: 2pt 3pt 0 0;
}

.signup_lineStyle__3fhNe {
  width: 80%;
  margin-left: 8%;
  margin-top: 1%;
}

.signup_mailtext__PNBjh {
  width: 340px;
  height: 25px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0.25em;
  margin-left: 40px;
  border-radius: 8px;
  border-color: rgb(160, 156, 156);
  opacity: 0.5;
}

.signup_mail__Io0Xm {
  display: -webkit-inline-flex;
  display: inline-flex;
  font-size: 0.9rem;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 0px 0 5px 40px;
  font-weight: 500;
  opacity: 0.8;
}

.signup_mail2__3IkuC {
  display: -webkit-inline-flex;
  display: inline-flex;
  font-size: 0.9rem;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 10px 0 5px 40px;
  font-weight: 500;
  opacity: 0.8;
}

.signup_radioStyle__2mypH {
  display: grid;
  margin: 0 0 -9px 40px;
  font-weight: 500;
  opacity: 1;
  color: #5c5c5c;
  font-size: 0.9rem;
  grid-template-areas:
    "orgRadio"
    "recRadio";
}

.signup_password__1gBUC {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 10px 0 5px 38px;
  opacity: 0.9;
}

.signup_signin1__3l3_q {
  display: block;
  background-color: #110766;
  opacity: 1;
  width: 77%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 24px 0 31px 40px;
  color: white;
  border: none;
  font-size: 11pt;
  cursor: pointer;
  height: 40px;
}

.signup_signin2__1FXQY {
  display: block;
  background-color: #110766;
  opacity: 1;
  width: 77%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 24px 0 60px 40px;
  color: white;
  border: none;
  font-size: 11pt;
  cursor: pointer;
  height: 40px;
}

.signup_iconOrg__TbXCI {
  margin: -3.3% 0 0 43%;
  position: absolute;
  height: 3.7%;
}
.signup_icon2__3yVUy {
  margin: -3.6% 0 0 43%;
  position: absolute;
  height: 20px;
}

.signup_icon3__3uK41 {
  margin: -3.9% 0 0 43%;
  position: absolute;
  height: 25px;
}

.signup_icon33__343zj {
  margin: -3.3% 0 0 43%;
  position: absolute;
  height: 19px;
}
.signup_tab__24M-m {
  padding: 0% 0% 0% 10%;
  display: inline;
  overflow: hidden;
}

.signup_tab__24M-m button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 0 0 5%;
  transition: 0.3s;
  font-size: 17px;
}

.signup_tab__24M-m button:hover {
  background-color: #ddd;
}

.signup_tab__24M-m button.signup_active__vJFgd {
  background-color: #ccc;
}

.signup_firstTabContent__2GlKG {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.signup_secondTabContent__19Lat {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
@media screen and (max-width: 700px) {
  .signup_leftContainer__2tTIY .signup_leftPara__1QUrD {
    font-size: 3.9vw;
  }
  .signup_rightContainer__4MkHJ .signup_subTitle__P9duC {
    padding: 0% 0% 0% 1%;
  }
  .signup_activeTab__2XxbC {
    font-size: 2.9vw;
  }
  .signup_tabLinks__1WkOx {
    font-size: 2.9vw;
  }
  .signup_mail2__3IkuC {
    margin: 10px 0 5px 13px;
  }
  .signup_formStyle__xLTA0 input[type="text"],
  .signup_formStyle__xLTA0 input[type="password"],
  .signup_formStyle__xLTA0 input[type="email"] {
    margin-left: 13px;
  }
  .signup_icon2__3yVUy {
    margin: -6.6% 0 0 40%;
    height: 12px;
  }
  .signup_icon3__3uK41 {
    margin: -6.9% 0 0 40%;
    height: 12px;
  }
  .signup_icon33__343zj {
    margin: -6.9% 0 0 40%;
    height: 12px;
  }
  .signup_tab__24M-m {
    padding: 0% 0% 0% 1%;
  }
  .signup_signin1__3l3_q {
    margin: 24px 0 31px 20px;
  }
}

/* media query for ipad */
@media screen and (max-width: 1080px) {
  .signup_container__T-YCM {
    grid-template-columns: 55% 94%;
  }
  .signup_icon2__3yVUy,
  .signup_icon3__3uK41,
  .signup_iconOrg__TbXCI {
    margin: -6.6% 0 0 74%;
  }
  .signup_headerStyle__sR6CW {
    padding: 0;
  }
}

.login_container__3WCYy {
  display: grid;
  grid-template-columns: 55% 55%;
}

.login_leftContainer__2yxES {
  background-color: #fafbfc;
  width: 100%;
  height: 110%;
  border-radius: 30px 0 0 30px;
}

.login_leftContainer__2yxES .login_leftLogo__n8xCp {
  padding: 6% 0 0 37%;
}

.login_leftContainer__2yxES .login_leftPara__32hfB {
  font-size: 0.9rem;
  line-height: 1.3;
  font-weight: 500;
  padding: 2% 0 0 6%;
  opacity: 0.7;
}

.login_leftContainer__2yxES .login_leftImg__2BW3- img {
  width: 80%;
  padding: 7% 0 0 10%;
  height: 200px;
}

.login_rightContainer__3CCvQ {
  background-color: #ffffff;
  border-radius: 0 30px 30px 0;
  width: 100%;
  height: 110%;
}

.login_rightContainer__3CCvQ .login_title__12VF1 {
  font-size: 20pt;
  font-weight: 600;
  color: #333333;
  padding: 20% 0% 0% 10%;
  line-height: 0.2;
}

.login_rightContainer__3CCvQ .login_subTitle__2QQkP p {
  font-size: 11.5pt;
  opacity: 0.5;
}

.login_rightContainer__3CCvQ .login_formStyle__139ks {
  padding-top: 1%;
}

.login_anchorStyle__8fHWT {
  color: #1e118d;
  font-weight: 500;
  opacity: 1;
  font-size: 11pt;
  cursor: pointer;
}

.login_rightContainer__3CCvQ .login_subTitle__2QQkP {
  padding: 0% 0% 0% 10%;
  opacity: 0.5;
  display: inline;
  font-size: 11pt;
}

.login_closeModal__1aqXo {
  float: right;
  width: 25px;
  height: 25px;
  margin: 5%;
  cursor: pointer;
}

.login_orgRadio__1CVcJ {
  grid-area: orgRadio;
  margin-bottom: 2%;
}

.login_recRadio__1s4Ns {
  grid-area: recRadio;
  margin-bottom: 2%;
}

.login_emailName2__1r_Ir {
  padding-top: 4%;
}

.login_forgot__19yIJ {
  color: #1e118d;
  opacity: 1;
  background: none;
  font-weight: 500;
  float: right;
  border: none;
  padding: 0 54px 0 0;
  cursor: pointer;
}

.login_allBtn__16aH0 {
  padding: 14px 0 0 0;
}

.login_allBtn2__3vjON {
  padding: 10px 0 0 0;
}

.login_formStyle__139ks input[type="text"],
.login_formStyle__139ks input[type="password"],
.login_formStyle__139ks input[type="email"] {
  display: -webkit-flex;
  display: flex;
  width: 60%;
  height: 25px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 8pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 11pt;
}
.login_formStyle__139ks input:focus {
  outline: none;
}

.login_formStyle__139ks input[type="radio"] {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
  padding: 0.25em;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  border-radius: 50%;
  opacity: 1;
  margin: 0 41% 0 0;
  float: right;
  outline: none;
  border: 2px solid #737373;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  margin: 6% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: #110766;
}

input[type="radio"]:checked {
  border-color: #110766;
}

.login_tabLinks__2DZ5p {
  background: none !important;
  border: none;
  font-weight: 600;
  font-size: 11pt;
  color: #333333;
  opacity: 0.8;
  cursor: pointer;
}

.login_activeTab__1mHZV {
  color: #1e118d;
  font-weight: 600;
  font-size: 11pt;
  opacity: 1;
  border-bottom: 6px solid #1e118d;
  border-radius: 2pt 3pt 0 0;
}

.login_lineStyle__3hlfA {
  width: 80%;
  margin-left: 8%;
  margin-top: 1%;
}

.login_mailtext__Qdc8g {
  width: 340px;
  height: 25px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0.25em;
  margin-left: 40px;
  border-radius: 8px;
  border-color: rgb(160, 156, 156);
  opacity: 0.5;
}

.login_mail__CDrTz {
  display: -webkit-inline-flex;
  display: inline-flex;
  font-size: 11pt;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 10px 0 5px 40px;
  font-weight: 500;
  opacity: 0.8;
}

.login_mail2__3XSb3 {
  display: -webkit-inline-flex;
  display: inline-flex;
  font-size: 11pt;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 10px 0 5px 40px;
  font-weight: 500;
  opacity: 0.8;
}

.login_radioStyle__KTnYx {
  display: grid;
  margin: 0 0 -9px 40px;
  font-weight: 500;
  opacity: 1;
  color: #5c5c5c;
  font-size: 11pt;
  grid-template-areas:
    "orgRadio"
    "recRadio";
}

.login_password__1vkLx {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 10px 0 5px 38px;
  opacity: 0.9;
}

.login_signin1__3FNwY {
  display: block;
  background-color: #110766;
  opacity: 1;
  width: 78%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 40px 0 0 40px;
  color: white;
  border: none;
  font-size: 11pt;
  cursor: pointer;
}

.login_signin2__2kuBX {
  display: block;
  background-color: #110766;
  opacity: 1;
  width: 78%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 40px 0 0 40px;
  color: white;
  border: none;
  font-size: 11pt;
  cursor: pointer;
}

.login_icon2__PWvLr {
  margin: -4% 0 0 43%;
  position: absolute;
  height: 20px;
}

.login_icon3__2WCTt {
  margin: -4% 0 0 43%;
  position: absolute;
  height: 23px;
}

.login_icon33__3Ve7A {
  margin: -4% 0 0 43%;
  position: absolute;
  height: 20px;
}
.login_tab__3p5Ok {
  padding: 0% 0% 0% 10%;
  display: inline;
  overflow: hidden;
}

.login_tab__3p5Ok button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 0 0 5%;
  transition: 0.3s;
  font-size: 17px;
}

.login_tab__3p5Ok button:hover {
  background-color: #ddd;
}
.login_tab__3p5Ok button.login_active__3efu3 {
  background-color: #ccc;
}

.login_firstTabContent__30zxG {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.login_secondTabContent__3VNyH {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.login_errorMsg__iRL0J {
  font-size: 0.7rem;
  color: red;
  /* float: right; */
  margin-left: 12%;
}

@media screen and (max-width: 700px) {
  .login_leftContainer__2yxES .login_leftPara__32hfB {
    font-size: 3.9vw;
  }
  .login_rightContainer__3CCvQ .login_subTitle__2QQkP {
    padding: 0% 0% 0% 1%;
  }
  .login_activeTab__1mHZV {
    font-size: 2.9vw;
  }
  .login_tabLinks__2DZ5p {
    font-size: 2.9vw;
  }
  .login_mail2__3XSb3 {
    margin: 10px 0 5px 2px;
  }
  .login_formStyle__139ks input[type="text"],
  .login_formStyle__139ks input[type="password"],
  .login_formStyle__139ks input[type="email"] {
    margin-left: 2px;
    width: 79%;
  }
  .login_icon2__PWvLr {
    margin: -6.6% 0 0 49%;
    height: 10px;
  }
  .login_icon3__2WCTt {
    margin: -6.9% 0 0 49%;
    height: 12px;
  }
  .login_icon33__3Ve7A {
    margin: -6.9% 0 0 49%;
    height: 12px;
  }
  .login_tab__3p5Ok {
    padding: 0% 0% 0% 1%;
  }
}

/* media query for ipad */
@media screen and (max-width: 1080px) {
  .login_container__3WCYy {
    display: grid;
    grid-template-columns: 55% 85% !important;
  }
  .login_icon2__PWvLr,
  .login_icon3__2WCTt {
    margin: -7% 0 0 68%;
  }
  .login_headerStyle__22RY5 {
    padding: 0;
  }
}

.OTPScreen_container__8Ckms {
  display: grid;
  grid-template-columns: 55% 55%;
}

.OTPScreen_leftContainer__11emp {
  background-color: #fafbfc;
  width: 100%;
  /* height: 110%; */
  border-radius: 30px 0 0 30px;
}

.OTPScreen_leftContainer__11emp .OTPScreen_leftLogo__33cBs {
  padding: 6% 0 0 37%;
}

.OTPScreen_leftContainer__11emp .OTPScreen_leftPara__1bD6q {
  font-size: 0.9rem;
  line-height: 1.3;
  font-weight: 500;
  padding: 2% 0 0 6%;
  opacity: 0.7;
}

.OTPScreen_leftContainer__11emp .OTPScreen_leftImg__2nR0T img {
  width: 80%;
  padding: 7% 0 0 10%;
  height: 200px;
}

.OTPScreen_rightContainer__27XM6 {
  background-color: #ffffff;
  border-radius: 0 30px 30px 0;
  width: 100%;
  /* height: 110%; */
}
.OTPScreen_closeModal__2_oz4 {
  float: right;
  width: 25px;
  height: 25px;
  margin: 5%;
  cursor: pointer;
}

.OTPScreen_centered__2Fxt7 {
  background-color: whitesmoke;
  margin-left: 450px;
  margin-right: 450px;
  height: 400px;
  width: 500px;
  margin-top: 130px;
  border-radius: 15px 15px 15px 15px;
  padding-left: 25px;
}

.OTPScreen_upper__13j49 img {
  background-color: whitesmoke;
  float: right;
  margin-top: 4%;
  margin-right: 2%;
}

.OTPScreen_logoimg__3qYLp {
  height: 8%;
  margin-left: 40%;
}
.OTPScreen_heading__3RebH {
  margin-top: 10%;
  margin-left: 34%;
  height: 28%;
}
.OTPScreen_para__x8BJ0 {
  margin-left: 25%;
  margin-top: 10%;
  opacity: 0.8;
}
.OTPScreen_flex__1fc0a {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 80%;

  padding-top: 12%;
}
.OTPScreen_text__37RkJ {
  height: 10%;
  margin-top: 5%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: 30%;
}
.OTPScreen_text__37RkJ div div input {
  width: 1.6em !important;
}

.OTPScreen_button1__irUey {
  box-shadow: 0 0px 2px 2px #0000001f;
  border: 1px solid #110766;
  width: 50%;
  height: 40%;
  background-color: #110766;
  margin-top: 10%;
  margin-left: 34%;
  color: white;
  font-size: 17px;
  border-radius: 14px;
  cursor: pointer;
}
.OTPScreen_button2__1l_eu {
  box-shadow: 0 0px 2px 2px #0000001f;
  border: 1px solid #110766;
  width: 50%;
  height: 52%;
  background-color: white;
  margin-top: 4%;
  margin-left: 34%;
  padding: 10px;
  color: 1px solid #110766;
  font-size: 17px;
  border-radius: 14px;
  cursor: pointer;
}
.OTPScreen_para__x8BJ0 {
  width: 50%;
  height: 40%;

  margin-top: 10%;
  margin-left: 38%;
  color: 1px solid #110766;
  font-size: 15px;
}
.OTPScreen_errorMsg__3kEaY {
  font-size: 1rem;
  color: red;
  float: right;
  margin-left: 40%;

  margin-top: 2%;
}
@media screen and (max-width: 700px) {
  .OTPScreen_leftContainer__11emp .OTPScreen_leftPara__1bD6q {
    font-size: 3.9vw;
  }
  .OTPScreen_heading__3RebH {
    margin-left: 0%;
  }
  .OTPScreen_text__37RkJ div div input {
    width: 0.9em !important;
  }
  .OTPScreen_text__37RkJ {
    margin-left: 0%;
    height: 5%;
  }
  .OTPScreen_para__x8BJ0 {
    margin-left: 0%;
    width: 100%;
  }
  .OTPScreen_button2__1l_eu {
    width: 55%;
    margin-left: 20%;
    font-size: 11px;
  }
  .OTPScreen_button1__irUey {
    width: 55%;
    margin-left: 25%;
    font-size: 11px;
  }
}

@media screen and (max-width: 1080px) {
  .OTPScreen_container__8Ckms {
    grid-template-columns: 50% 90%;
  }
  .OTPScreen_headerStyle__f2hc3 {
    padding: 0;
  }
}

.ResponseAlert_container__1TVDh {
  display: block;
  background-color: #fafbfc;
  width: 28rem;
  height: 21rem;
  /* top: 50%;
    position: absolute;
   
    transform: translate(35%, -56%); */
  border-radius: 30px;
}

.ResponseAlert_result__3IT2j {
  text-align: center;
  padding: 25% 30px;
}

.ResponseAlert_allBtn2__2qunZ {
  float: right;
  padding: 3% 12% 7% 0;
}

.ResponseAlert_ok_btn__22yMR {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 20%;
  height: 35px;
  margin: 0% 2% 0% 17%;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  margin-left: 0px;
}

.ResponseAlert_message__b1waL {
  /* margin: 2% 2% 2% 11%; */
  /* color: red; */
  font-size: 10pt;
  font-weight: normal;
}
@media screen and (max-width: 700px) {
  .ResponseAlert_container__1TVDh {
    width: 19rem;
    height: 19rem;
  }
}

@media screen and (max-width: 1080px) {
  .ResponseAlert_result__3IT2j {
    text-align: center;
    padding: 33% 30px;
  }
  .ResponseAlert_headerStyle__12t3Z {
    padding: 0;
  }
}

.SignupOTPScreen_container__3ZYAI {
  display: grid;
  grid-template-columns: 55% 55%;
}

.SignupOTPScreen_leftContainer__b9F66 {
  background-color: #fafbfc;
  width: 100%;
  height: 110%;
  border-radius: 30px 0 0 30px;
}

.SignupOTPScreen_leftContainer__b9F66 .SignupOTPScreen_leftLogo__MIsXI {
  padding: 6% 0 0 37%;
}

.SignupOTPScreen_leftContainer__b9F66 .SignupOTPScreen_leftPara__29jD6 {
  font-size: 0.9rem;
  line-height: 1.3;
  font-weight: 500;
  padding: 2% 0 0 6%;
  opacity: 0.7;
}

.SignupOTPScreen_leftContainer__b9F66 .SignupOTPScreen_leftImg__2Mok9 img {
  width: 80%;
  padding: 7% 0 0 10%;
  height: 200px;
}

.SignupOTPScreen_rightContainer__1kaSr {
  background-color: #ffffff;
  border-radius: 0 30px 30px 0;
  width: 100%;
  height: 110%;
}
.SignupOTPScreen_closeModal__2w7PA {
  float: right;
  width: 25px;
  height: 25px;
  margin: 5%;
  cursor: pointer;
}

.SignupOTPScreen_centered__2LPxF {
  background-color: whitesmoke;
  margin-left: 450px;
  margin-right: 450px;
  height: 400px;
  width: 500px;
  margin-top: 130px;
  border-radius: 15px 15px 15px 15px;
  padding-left: 25px;
}

.SignupOTPScreen_upper__kaxRp img {
  background-color: whitesmoke;
  float: right;
  margin-top: 4%;
  margin-right: 2%;
}

.SignupOTPScreen_logoimg__2__wY {
  height: 8%;
  margin-left: 40%;
}
.SignupOTPScreen_heading__11-CZ {
  margin-top: 10%;
  margin-left: 34%;
  height: 28%;
  font-size: 25px;
}
.SignupOTPScreen_para__39uzj {
  margin-left: 25%;
  margin-top: 10%;
  opacity: 0.8;
}
.SignupOTPScreen_flex__3wTcG {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 80%;

  padding-top: 12%;
}
.SignupOTPScreen_text__2G1mQ {
  height: 10%;
  margin-top: 5%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: 30%;
}
.SignupOTPScreen_text__2G1mQ div div input {
  width: 1.6em !important;
}

.SignupOTPScreen_button1__76GeC {
  box-shadow: 0 0px 2px 2px #0000001f;
  border: 1px solid #110766;
  width: 50%;
  height: 40%;
  background-color: #110766;
  margin-top: 10%;
  margin-left: 34%;
  color: white;
  font-size: 17px;
  border-radius: 14px;
  cursor: pointer;
}
.SignupOTPScreen_button2__3TZLI {
  box-shadow: 0 0px 2px 2px #0000001f;
  border: 1px solid #110766;
  width: 50%;
  height: 52%;
  background-color: white;
  margin-top: 4%;
  margin-left: 34%;
  color: 1px solid #110766;
  font-size: 12px;
  border-radius: 14px;
  cursor: pointer;
  padding: 10px;
}
.SignupOTPScreen_para__39uzj {
  width: 50%;
  height: 40%;

  margin-top: 10%;
  margin-left: 38%;
  color: 1px solid #110766;
  font-size: 15px;
}
.SignupOTPScreen_errorMsg__1Cx2i {
  font-size: 1rem;
  color: red;
  float: right;
  margin-left: 40%;

  margin-top: 2%;
}
@media screen and (max-width: 700px) {
  .SignupOTPScreen_leftContainer__b9F66 .SignupOTPScreen_leftPara__29jD6 {
    font-size: 3.9vw;
  }
  .SignupOTPScreen_heading__11-CZ {
    margin-left: 0%;
  }
  .SignupOTPScreen_text__2G1mQ div div input {
    width: 0.9em !important;
  }
  .SignupOTPScreen_text__2G1mQ {
    margin-left: 0%;
    height: 5%;
  }
  .SignupOTPScreen_para__39uzj {
    margin-left: 0%;
    width: 100%;
  }
  .SignupOTPScreen_button2__3TZLI {
    width: 55%;
    margin-left: 20%;
    font-size: 10px;
    padding: 10px;
  }
  .SignupOTPScreen_button1__76GeC {
    width: 55%;
    margin-left: 25%;
    font-size: 11px;
  }
}

.ResetPassword_container__x2StU {
  display: grid;
  grid-template-columns: 55% 55%;
}

.ResetPassword_leftContainer__2UsBL {
  background-color: #fafbfc;
  width: 100%;
  height: 110%;
  border-radius: 30px 0 0 30px;
}

.ResetPassword_leftContainer__2UsBL .ResetPassword_leftLogo__10Yrj {
  padding: 6% 0 0 37%;
}

.ResetPassword_leftContainer__2UsBL .ResetPassword_leftPara__1s33m {
  font-size: 0.9rem;
  line-height: 1.3;
  font-weight: 500;
  padding: 2% 0 0 6%;
  opacity: 0.7;
}

.ResetPassword_leftContainer__2UsBL .ResetPassword_leftImg__2w5dD img {
  width: 80%;
  padding: 7% 0 0 10%;
  height: 200px;
}

.ResetPassword_rightContainer__CVUZ5 {
  background-color: #ffffff;
  border-radius: 0 30px 30px 0;
  width: 100%;
  height: 110%;
}

.ResetPassword_rightContainer__CVUZ5 .ResetPassword_title__1h4ts {
  font-size: 20pt;
  font-weight: 600;
  color: #333333;
  padding: 20% 0% 0% 10%;
  line-height: 0.2;
}

.ResetPassword_rightContainer__CVUZ5 .ResetPassword_subTitle__3ad1L p {
  font-size: 11.5pt;
  opacity: 0.5;
}

.ResetPassword_rightContainer__CVUZ5 .ResetPassword_formStyle__RbFX1 {
  padding-top: 1%;
}

.ResetPassword_anchorStyle__2TKXA {
  color: #1e118d;
  font-weight: 500;
  opacity: 1;
  font-size: 11pt;
  cursor: pointer;
}

.ResetPassword_rightContainer__CVUZ5 .ResetPassword_subTitle__3ad1L {
  padding: 0% 0% 0% 10%;
  opacity: 0.5;
  display: inline;
  font-size: 11pt;
}

.ResetPassword_closeModal__1fbRG {
  float: right;
  width: 25px;
  height: 25px;
  margin: 5%;
  cursor: pointer;
}

.ResetPassword_orgRadio__2-IMP {
  grid-area: orgRadio;
  margin-bottom: 2%;
}

.ResetPassword_recRadio__3LUnZ {
  grid-area: recRadio;
  margin-bottom: 2%;
}

.ResetPassword_emailName2__2MPFe {
  padding-top: 4%;
}

.ResetPassword_forgot__2XpEp {
  color: #1e118d;
  opacity: 1;
  background: none;
  font-weight: 500;
  float: right;
  border: none;
  padding: 0 54px 0 0;
}

.ResetPassword_allBtn__2XP6M {
  padding: 14px 0 0 0;
}

.ResetPassword_allBtn2__222x1 {
  padding: 10px 0 0 0;
}

.ResetPassword_formStyle__RbFX1 input[type="text"],
.ResetPassword_formStyle__RbFX1 input[type="password"],
.ResetPassword_formStyle__RbFX1 input[type="email"] {
  display: -webkit-flex;
  display: flex;
  width: 60%;
  height: 25px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 8pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 11pt;
}
.ResetPassword_formStyle__RbFX1 input:focus {
  outline: none;
}

.ResetPassword_formStyle__RbFX1 input[type="radio"] {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
  padding: 0.25em;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  border-radius: 50%;
  opacity: 1;
  margin: 0 41% 0 0;
  float: right;
  outline: none;
  border: 2px solid #737373;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  margin: 6% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: #110766;
}

input[type="radio"]:checked {
  border-color: #110766;
}

.ResetPassword_tabLinks__3AGdq {
  background: none !important;
  border: none;
  font-weight: 600;
  font-size: 11pt;
  color: #333333;
  opacity: 0.8;
  cursor: pointer;
}

.ResetPassword_activeTab__2kAat {
  color: #1e118d;
  font-weight: 600;
  font-size: 11pt;
  opacity: 1;
  border-bottom: 6px solid #1e118d;
  border-radius: 2pt 3pt 0 0;
}

.ResetPassword_lineStyle__2t29h {
  width: 80%;
  margin-left: 8%;
  margin-top: 1%;
}

.ResetPassword_mailtext__2WraX {
  width: 340px;
  height: 25px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0.25em;
  margin-left: 40px;
  border-radius: 8px;
  border-color: rgb(160, 156, 156);
  opacity: 0.5;
}

.ResetPassword_mail__1my8w {
  display: -webkit-inline-flex;
  display: inline-flex;
  font-size: 11pt;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 10px 0 5px 40px;
  font-weight: 500;
  opacity: 0.8;
}

.ResetPassword_mail2__1T_OM {
  display: -webkit-inline-flex;
  display: inline-flex;
  font-size: 11pt;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 10px 0 5px 40px;
  font-weight: 500;
  opacity: 0.8;
}

.ResetPassword_radioStyle__149VX {
  display: grid;
  margin: 0 0 -9px 40px;
  font-weight: 500;
  opacity: 1;
  color: #5c5c5c;
  font-size: 11pt;
  grid-template-areas:
    "orgRadio"
    "recRadio";
}

.ResetPassword_password__3IBf0 {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 10px 0 5px 38px;
  opacity: 0.9;
}

.ResetPassword_signin2__1xlE3 {
  display: block;
  background-color: #110766;
  opacity: 1;
  width: 78%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 40px 0 0 40px;
  color: white;
  border: none;
  font-size: 11pt;
  cursor: pointer;
}

.ResetPassword_icon2__1QlcT {
  margin: -4% 0 0 43%;
  position: absolute;
  height: 20px;
}

.ResetPassword_icon3__3BcAx {
  margin: -4% 0 0 43%;
  position: absolute;
  height: 23px;
}

.ResetPassword_icon33__21DvN {
  margin: -4% 0 0 43%;
  position: absolute;
  height: 20px;
}
.ResetPassword_tab__2X288 {
  padding: 0% 0% 0% 10%;
  display: inline;
  overflow: hidden;
}

.ResetPassword_tab__2X288 button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 0 0 5%;
  transition: 0.3s;
  font-size: 17px;
}

.ResetPassword_tab__2X288 button:hover {
  background-color: #ddd;
}
.ResetPassword_tab__2X288 button.ResetPassword_active__Yd1BE {
  background-color: #ccc;
}

.ResetPassword_firstTabContent__3LKNU {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.ResetPassword_secondTabContent__jduRI {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.ResetPassword_errorMsg__1HRs- {
  font-size: 0.7rem;
  color: red;
  margin: 2px 6px 0 43px;
  font-weight: 500;
  text-align: start;
  padding-right: 10%;
}

.confirm_container__3tCHC {
  display: block;
  background-color: #fafbfc;
  border-radius: 30px;
  padding: 20px 25px 20px 25px;
  margin-left: 10%;
}

.confirm_msg1__MjBdv {
  font-size: 1.1rem;
  line-height: 0.5;
  font-weight: 500;
  padding: 2% 0% 0% 15%;
  opacity: 0.7;
}

.confirm_msg2__20Gs9 {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 2% 6% 0% 0%;
  opacity: 0.7;
  text-align: center;
}

.confirm_leftImg__DR_cp img {
  width: 40%;
  padding: 6% 0% 0% 25%;
}

.confirm_closeModal__2uRBM {
  float: right;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.confirm_signin1__2D1QG {
  display: block;
  background-color: #110766;
  opacity: 1;
  width: 78%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 40px 0 0 40px;
  color: white;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.button_whiteButton__tUCoY {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #ffffff;
  width: 9.8vw;
  height: 5.32vh;
  margin: 6px 0px 0px 14px;
  font-weight: 500;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #110766;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.button_blueButton__1tI8G {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 9.8vw;
  height: 5.32vh;
  margin: 6px 0px 0px 14px;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 1vw;
  cursor: pointer;
}

.button_LongButton__208J6 {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #1e118d;
  font-weight: 500;
  width: 60%;
  height: 35px;
  margin: 6px 0px 0px 14px;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
@media screen and (max-width: 775px) {
  .button_blueButton__1tI8G {
    width: 20.8vw;
    font-size: 3vw;
  }
  .button_whiteButton__tUCoY {
    width: 20.8vw;
    font-size: 3vw;
  }
}
/* media query for ipad */
@media screen and (max-width: 1080px) {
  .button_blueButton__1tI8G {
    box-shadow: 0 0px 2px 2px #0000001f;
    background-color: #110766;
    font-weight: 500;
    width: 10.8vw;
    height: 4.32vh;
    margin-right: 80px;
    border-radius: 14px;
    border: 1px solid #110766;
    opacity: 1;
    color: #ffffff;
    text-align: center;
    font-size: 2vw;
    float: right;
    cursor: pointer;
  }
  .button_whiteButton__tUCoY {
    width: 13vw;
    height: 4.32vh;
  }
  .button_LongButton__208J6 {
    height: 48px;
  }
  .button_headerStyle__2PcK2 {
    padding: 0;
  }
}

.forgotpassword_container__1LdGy {
  display: grid;
  grid-template-columns: 23rem 23rem;
}

.forgotpassword_leftContainer__1itTW {
  background-color: #fafbfc;
  width: 100%;
  height: 107%;
  border-radius: 30px 0 0 30px;
}

.forgotpassword_leftContainer__1itTW .forgotpassword_leftLogo__1Yn26 {
  padding: 6% 0 0 37%;
}

.forgotpassword_leftContainer__1itTW .forgotpassword_leftPara__2Vpjt {
  font-size: 0.9rem;
  line-height: 1.3;
  font-weight: 500;
  padding: 2% 0 0 6%;
  opacity: 0.7;
}

.forgotpassword_leftContainer__1itTW .forgotpassword_leftImg__37qTh img {
  width: 80%;
  padding: 7% 0 0 10%;
  height: 200px;
}

.forgotpassword_rightContainer__5yRP6 {
  background-color: #ffffff;
  border-radius: 0 30px 30px 0;
  width: 100%;
  height: 107%;
}

.forgotpassword_rightContainer__5yRP6 .forgotpassword_title__1HCN- {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333333;
  padding: 16% 0% 0% 10%;
  line-height: 0.2;
}

.forgotpassword_rightContainer__5yRP6 .forgotpassword_subTitle__3uaxf p {
  font-size: 0.9rem;

  opacity: 0.5;
}

.forgotpassword_rightContainer__5yRP6 .forgotpassword_formStyle__2WOhe {
  padding-top: 1%;
}

.forgotpassword_anchorStyle__37zzq {
  color: #1e118d;
  font-weight: 500;
  opacity: 1;
  font-size: 0.9rem;
}

.forgotpassword_rightContainer__5yRP6 .forgotpassword_subTitle__3uaxf {
  padding: 0% 0% 0% 10%;
  opacity: 0.5;
  display: inline;
  font-size: 0.9rem;
}

.forgotpassword_closeModal__I3cL- {
  float: right;
  width: 25px;
  height: 25px;
  margin: 5%;
  cursor: pointer;
}

.forgotpassword_orgRadio__1xuqT {
  grid-area: orgRadio;
  margin-bottom: 2%;
}

.forgotpassword_recRadio__3vJ6y {
  grid-area: recRadio;
  margin-bottom: 2%;
}

.forgotpassword_orgName__b0Ubn {
  grid-area: orgname;
}

.forgotpassword_emailName__3-7NI {
  grid-area: emailname;
}

.forgotpassword_pass__1Jnn6 {
  grid-area: pass;
}

.forgotpassword_allBtn__3-BUR {
  grid-area: allbtn;
}

.forgotpassword_formStyle__2WOhe input[type="text"],
.forgotpassword_formStyle__2WOhe input[type="password"],
.forgotpassword_formStyle__2WOhe input[type="email"] {
  display: -webkit-flex;
  display: flex;
  width: 60%;
  height: 25px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 8pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 0.9rem;
}
.forgotpassword_formStyle__2WOhe input:focus {
  outline: none;
}

.forgotpassword_formStyle__2WOhe input[type="radio"] {
  height: 20px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0.25em;
  border-color: rgb(160, 156, 156);
  opacity: 0.5;
  margin: 0 41% 0 0;
  float: right;
}

.forgotpassword_tabLinks__3tUFs {
  background: none !important;
  border: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #333333;
  opacity: 0.8;
  cursor: pointer;
}

.forgotpassword_activeTab__3rnNA {
  color: #1e118d;
  font-weight: 600;
  font-size: 0.9rem;

  border-bottom: 5px solid #1e118d;
}

.forgotpassword_errorMsg__1q-Zz {
  font-size: 0.7rem;
  color: red;
  float: right;
  margin-right: 11%;
}

.forgotpassword_lineStyle__1YIn1 {
  width: 88%;
  margin-left: 8%;
  margin-top: 1%;
}

.forgotpassword_mailtext__2uy7b {
  width: 340px;
  height: 25px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0.25em;
  margin-left: 40px;
  border-radius: 8px;
  border-color: rgb(160, 156, 156);
  opacity: 0.5;
}

.forgotpassword_mail__3SnIh {
  display: -webkit-inline-flex;
  display: inline-flex;
  font-size: 0.9rem;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 25px 0 5px 40px;
  font-weight: 500;
  opacity: 0.8;
}

.forgotpassword_radioStyle__RVj64 {
  display: grid;
  margin: 0 0 -9px 40px;
  font-weight: 500;
  opacity: 1;
  color: #5c5c5c;
  font-size: 0.9rem;
  grid-template-areas:
    "orgRadio"
    "recRadio";
}

.forgotpassword_password__13fUU {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: right;
          justify-content: right;
  margin-left: 38px;
  margin-top: 10px;
  margin-bottom: 5px;
  opacity: 0.9;
}
.forgotpassword_button1__3kJ7A {
  display: block;
  background-color: #ffffff;
  opacity: 1;
  width: 78%;
  height: 22%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 25px 0 0 40px;
  color: #110766;
  border: 1px solid #110766;
  font-size: 0.9rem;
  cursor: pointer;
}
.forgotpassword_signup__yZ21W {
  display: block;
  background-color: #110766;
  opacity: 1;
  width: 78%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 25px 0 0 40px;
  color: white;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
}

.forgotpassword_icon1__26HdO {
  margin: -3.5% 0 0 40.5%;
  position: absolute;
  height: 3.7%;
}

.forgotpassword_lastPara__3wMvQ {
  margin: 30px 6px 0 40px;
  font-size: 0.8rem;
  opacity: 0.5;
  color: #333333;
  font-weight: 500;
  text-align: start;
  padding-right: 10%;
}

@media screen and (max-width: 1400px) {
  .forgotpassword_container__1LdGy {
    display: grid;
    grid-template-columns: 21rem 21rem;
  }
  .forgotpassword_leftContainer__1itTW {
    height: 100%;
  }
  .forgotpassword_rightContainer__5yRP6 {
    height: 100%;
  }
}

@media screen and (max-width: 700px) {
  .forgotpassword_icon1__26HdO {
    margin: -6.5% 0 0 79.5%;
  }
}

@media screen and (max-width: 1080px) {
  .forgotpassword_container__1LdGy {
    grid-template-columns: 13rem 21rem;
  }
  .forgotpassword_icon1__26HdO {
    margin: -4.5% 0 0 49.5%;
    position: absolute;
    height: 3.7%;
  }
  .forgotpassword_headerStyle__2iEQA {
    padding: 0;
  }
}

.homepage_container__1jA7U {
  display: grid;

  grid-template-rows: 4rem 57rem;
  height: 100%;
  width: 100vw;
}

.homepage_background_img__1rGQM {
  width: 30%;
  height: 100%;
  float: right;
  margin: 0% 8%;
  background-repeat: no-repeat;
  margin-top: 8rem;
  background-size: cover;
}

.homepage_centered_text__3t-9F {
  font-size: 2.92vw;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.homepage_centered_button__KJxC6 {
  opacity: 1;
}

.homepage_spanStyle__2MTV6 {
  font-size: 24px;
}

.homepage_sectionStyle__10HkH {
  z-index: 90;
  overflow: hidden;
}

.homepage_loader_style__3ng1H {
  margin: 20% auto;
}

.homepage_headerStyle__9FUUV {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.homepage_footerStyle__3QQGy {
  z-index: 100;
  padding-left: 5%;
  box-shadow: 0 0px 3px 8px #00000029;
}

.homepage_centered__1WO1S {
  font-family: "Poppins";
  font-weight: 500;
  z-index: 1;
  max-width: 30%;
  position: absolute;
  top: 32%;
  left: 18%;
}

.homepage_tagline__dUZLI {
  color: #8f36b3;
  font-size: 1.5rem;
}

.homepage_changeDiv__1wDTv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 11% 29%;
  z-index: 150;
  position: absolute;
}
.homepage_changeDiv1__2ZSgY {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 12% 34%;
  z-index: 150;
  position: absolute;
}
.homepage_changeDiv2__q206d {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 17% 39%;
  z-index: 150;
  position: absolute;
}
.homepage_addBlurr__1aT6M {
  filter: brightness(40px);
  -webkit-filter: blur(40px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

@media screen and (max-width: 1600px) {
  .homepage_changeDiv__1wDTv {
    margin: 4% 28%;
  }
  .homepage_changeDiv1__2ZSgY {
    margin: 12% 34%;
  }
  .homepage_changeDiv2__q206d {
    margin: 15% 35%;
  }
}

@media screen and (max-width: 1400px) {
  .homepage_changeDiv__1wDTv {
    margin: 2% 27%;
  }
  .homepage_changeDiv1__2ZSgY {
    margin: 12% 34%;
  }
  .homepage_changeDiv2__q206d {
    margin: 12% 35%;
  }
}
@media screen and (max-width: 1366px) {
  .homepage_changeDiv__1wDTv {
    margin: 2% 27%;
  }
  .homepage_changeDiv1__2ZSgY {
    margin: 8% 34%;
  }
  .homepage_changeDiv2__q206d {
    margin: 10% 35%;
  }
}

@media (max-width: 575px) {
  .homepage_background_img__1rGQM {
    height: 350px;
  }
}

@media screen and (max-width: 766px) {
  .homepage_centered_text__3t-9F {
    font-size: 2.19vw;
  }
  .homepage_centered__1WO1S {
    position: absolute;
    top: 32%;
    left: 8%;
  }
  .homepage_changeDiv__1wDTv {
    margin: 5% 5%;
    width: 85vw;
  }
  .homepage_spanStyle__2MTV6 {
    font-size: 1.31vw;
  }
  .homepage_background_img__1rGQM {
    height: 60.97vh;
  }
  .homepage_changeDiv1__2ZSgY {
    margin: 21% 5%;
  }
  .homepage_changeDiv2__q206d {
    margin: 10% 2%;
  }
}

/* media query for ipad */
@media screen and (max-width: 1080px) {
  .homepage_changeDiv__1wDTv {
    margin: 10% 25%;
  }
  .homepage_headerStyle__9FUUV {
    padding: 0;
  }
  .homepage_changeDiv2__q206d,
  .homepage_changeDiv__1wDTv,
  .homepage_changeDiv1__2ZSgY {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 40%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .homepage_changeDiv1__2ZSgY {
    padding: 10px;
  }
  .homepage_centered__1WO1S {
    font-family: "Poppins";
    font-weight: 500;
    z-index: 1;
    max-width: 41%;
    position: absolute;
    top: 18%;
    left: 12%;
  }
}

.header_header__1JYzc {
  display: -webkit-flex;
  display: flex;
  z-index: 100;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 40px;
  gap: 40px;
  width: 100%;
}
.header_blueButton__56gq6 {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 9.8vw;
  height: 5.32vh;
  margin: 6px 0px 0px 14px;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 1vw;
  cursor: pointer;
}
.header_whiteButton__3EROF {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #ffffff;
  width: 134px;
  height: 35px;
  margin: 6px 0px 0px 14px;
  font-weight: 500;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #110766;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.header_header__1JYzc a {
  -webkit-align-self: center;
          align-self: center;
  justify-self: self-start;
  float: left;
  color: blueviolet;
  text-align: left;
  opacity: 1;
  /* padding: 1% 0% 0% 12%; */
  text-decoration: none;
  font-size: 16px;
  line-height: 25px;
  border-radius: 4px;
}

.header_menu__KAqKy {
  display: none;
}

.header_cancel__1x4B6 {
  display: none;
}

.header_logo__2iy6M {
  margin-top: -7px;
  height: 8.98vh;
  padding-left: 116px;
}

.header_btn_css__2C34P {
  display: grid;
  grid-template-columns: auto auto;
}

.header_headerRight__21FE_ {
  /*align-self: center;
  justify-self: end;
   display: grid;
  grid-template-columns: auto auto auto auto auto; */
  /* float: right;
  padding: 0% 6% 0% 0%; */
}

.header_headerRight__21FE_ a {
  font-weight: 500;
  float: left;
  color: #333333;
  text-align: left;
  opacity: 1;
  padding: 12px;
  text-decoration: none;
  font-size: 16px;
  line-height: 25px;
}

.header_activeSide__3gchF {
  color: #110766 !important;
  border-bottom: 5px solid #110766 !important;
  font-weight: bold !important;
}

/* .headerRight a:hover {
    background-color: #ddd;
    color: black;
  } */

.header_headerRight__21FE_ a.header_active__3azbt {
  background-color: dodgerblue;
  color: white;
}

@media screen and (max-width: 575px) {
  .header_headerRight__21FE_ {
    /* display: none; */
  }
  .header_logo__2iy6M {
    margin-top: 8px;
    padding-left: 28px;
    height: 40px;
  }
}

@media screen and (max-width: 766px) {
  .header_headerRight__21FE_ {
    padding: 0% 1% 0% 0%;
  }
  .header_headerRight__21FE_ a {
    font-size: 1.46;
    padding: 1.17%;
  }
  /* .btn_css{
        margin-top: -8px;
    } */
  .header_logo__2iy6M {
    margin-top: 7px;
    padding-left: 28px;
    height: 45px;
  }
  .header_header__1JYzc a {
    font-size: 7.46vw;
  }
}

@media screen and (max-width: 1150px) {
  .header_logo__2iy6M {
    padding-left: 6.58vw;
  }
}

@media screen and (max-width: 600px) {
  .header_menu__KAqKy {
    display: block;
    margin-top: -9px;
    height: 7.98vh;
    padding-left: 0.55vw;
    margin-left: 47vw;
  }

  .header_headerRight__21FE_ {
    width: 0vw;
    height: 57rem;
    background-color: #393939;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 6vh;
    display: block;
  }
  .header_sidebar__2X4le {
    width: 80vw;
    height: 57rem;
    background-color: #393939;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 6vh;
    display: block;
  }
  .header_side__35yCM {
    font-size: 12.46vw;
    display: block;
    margin: 10%;
  }

  .header_activeSide__3gchF {
    color: #110766 !important;
    border-bottom: 5px solid #110766 !important;
    font-weight: bolder;
    font-size: 12.46vw;
    display: block;
    margin: 10%;
  }

  .header_btn_css__2C34P {
    display: block;
  }
  .header_whiteButton__3EROF {
    height: 50px;
  }
}

@media screen and (max-width: 1080px) {
  .header_whiteButton__3EROF {
    box-shadow: 0 0px 2px 2px #0000001f;
    background-color: #ffffff;
    width: 116px;
    height: 35px;
    margin: 6px 0px 0px 14px;
    font-weight: 500;
    border-radius: 14px;
    border: 1px solid #110766;
    opacity: 1;
    color: #110766;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
  }
  .header_headerRight__21FE_ a {
    font-size: 12px;
  }
  .header_logo__2iy6M {
    padding: 0px !important;
    height: 80px;
  }
  .header_header__1JYzc {
    display: -webkit-flex;
    display: flex;
  }
  .header_headerStyle__3FtuY {
    padding: 0;
  }
}

.homepage_container__v9y1p {
  display: grid;
  /* / grid-template-rows: 4rem 36rem 5rem; / */
  grid-template-rows: 4rem 57rem;
  height: 100%;
  width: 100vw;
}

.homepage_background_img__3awVS {
  width: 92%;
  height: 100%;
  margin: 0% 8%;
  background-repeat: no-repeat;
  background-size: cover;
}

.homepage_centered_text__2i4J0 {
  font-size: 2.92vw;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.homepage_centered_button__3PduI {
  opacity: 1;
}

.homepage_spanStyle__2BdbI {
  font-size: 24px;
}

.homepage_sectionStyle__1iex7 {
  z-index: 90;
  overflow: hidden;
}

.homepage_loader_style__1OpIM {
  margin: 20% auto;
}

.homepage_headerStyle__2DPfH {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.homepage_footerStyle__3FEta {
  z-index: 100;
  padding-left: 5%;
  box-shadow: 0 0px 3px 8px #00000029;
}

.homepage_centered__2rmxE {
  font-family: "Poppins";
  font-weight: 500;
  z-index: 1;
  position: absolute;
  top: 32%;
  left: 18%;
}

.homepage_changeDiv__1VUXS {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 11% 29%;
  z-index: 150;
  position: absolute;
}
.homepage_changeDiv1__PTueA {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 12% 34%;
  z-index: 150;
  position: absolute;
}
.homepage_changeDiv2__3XXSY {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 17% 39%;
  z-index: 150;
  position: absolute;
}
.homepage_addBlurr__T4X82 {
  filter: brightness(40px);
  -webkit-filter: blur(40px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

@media screen and (max-width: 1600px) {
  .homepage_changeDiv__1VUXS {
    margin: 4% 28%;
  }
  .homepage_changeDiv1__PTueA {
    margin: 12% 34%;
  }
  .homepage_changeDiv2__3XXSY {
    margin: 15% 35%;
  }
}

@media screen and (max-width: 1400px) {
  .homepage_changeDiv__1VUXS {
    margin: 2% 27%;
  }
  .homepage_changeDiv1__PTueA {
    margin: 12% 34%;
  }
  .homepage_changeDiv2__3XXSY {
    margin: 12% 35%;
  }
}
@media screen and (max-width: 1366px) {
  .homepage_changeDiv__1VUXS {
    margin: 2% 27%;
  }
  .homepage_changeDiv1__PTueA {
    margin: 8% 34%;
  }
  .homepage_changeDiv2__3XXSY {
    margin: 10% 35%;
  }
}

@media (max-width: 575px) {
  .homepage_background_img__3awVS {
    height: 350px;
  }
}

@media screen and (max-width: 766px) {
  .homepage_centered_text__2i4J0 {
    font-size: 2.19vw;
  }
  .homepage_centered__2rmxE {
    position: absolute;
    top: 32%;
    left: 8%;
  }
  .homepage_changeDiv__1VUXS {
    margin: 5% 5%;
    width: 85vw;
  }
  .homepage_spanStyle__2BdbI {
    font-size: 1.31vw;
  }
  .homepage_background_img__3awVS {
    height: 60.97vh;
  }
  .homepage_changeDiv1__PTueA {
    margin: 21% 5%;
  }
  .homepage_changeDiv2__3XXSY {
    margin: 10% 2%;
  }
}

.homepage_hero__hO5vq {
  max-height: 400px;
  border-radius: 30px 30px 0 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  background: #fff;
}
.homepage_left__3cy9M {
  margin-top: 10%;
  padding: 20px;
  width: 30%;
}
.homepage_left__3cy9M p:nth-child(1) {
  font-size: 18px;
}
.homepage_left__3cy9M p:nth-child(2) {
  font-size: 18px;
}
.homepage_left__3cy9M button {
  background: #9675e4 0% 0% no-repeat padding-box;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 10px;
  color: #fff;
  box-shadow: 0px 3px 6px #00000029;
}
.homepage_right__2yRT_ {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  position: relative;
  width: 50%;
}
.homepage_right__2yRT_ img:nth-child(1) {
  height: 400px;
  width: 100%;
  margin-top: 100px;
}
.homepage_right__2yRT_ img:nth-child(2) {
  position: absolute;
  top: 60%;
  left: 50%;
  height: 50px;
  width: 50px;
}
.homepage_provide__2aLDr {
  background: #eff4f8;
  padding: 20px;
}
.homepage_provideTitle__3fUM0 {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 80px auto 40px auto;
  width: 90%;
}
.homepage_provideTitle__3fUM0 div {
  border: 1px solid #8674ec;
  width: 100px;
}

.homepage_provideBox__1l854 {
  display: grid;
  width: 90%;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
  grid-gap: 20px;
}

.homepage_provideBoxItem__215WO {
  background: #fff;
  box-shadow: 5px 30px 40px #00000040;
  border-radius: 30px;
  padding: 20px;
  /* width: 40%; */
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.homepage_provideBoxItem__215WO:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background: linear-gradient(264deg, #6a68d2 0%, #5f5dbd 100%);
}
.homepage_provideBoxItem__215WO img {
  margin-left: 40px;
}
.homepage_provideBoxItem__215WO .homepage_withHover__1cgh8 {
  display: none;
}
.homepage_provideBoxItem__215WO:hover .homepage_withHover__1cgh8 {
  display: block;
}
.homepage_provideBoxItem__215WO:hover .homepage_withoutHover__2_Cyb {
  display: none;
}
.homepage_provideBoxItem__215WO p {
  margin: 0;
  /* width: 35%; */
  padding: 2px 0;
  margin-left: 40px;
  color: #000;
}
.homepage_provideBoxItem__215WO:hover p {
  color: #fff;
}
.homepage_provideBoxItem__215WO p:nth-child(2) {
  font-weight: bold;
}
.homepage_provideBoxItem__215WO button {
  border: 1px solid #9977e8;
  border-radius: 9px;
  outline: none;
  cursor: pointer;
  color: #9977e8;
  background: transparent;
  padding: 10px;
  margin-left: 40px;
  margin-top: 20px;
}
.homepage_provideBoxItem__215WO:hover button {
  border: 1px solid #fff;
  color: #fff;
}

.homepage_choose__5hkiI {
  background: #fff;
  padding: 20px;
}
.homepage_chooseTitle__2C5b4 {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 30px auto 40px auto;
  width: 90%;
}
.homepage_chooseTitle__2C5b4 div {
  border: 1px solid #8674ec;
  width: 100px;
}

.homepage_item__2BpR4 {
  width: 90%;
  margin: auto;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
}
.homepage_item__2BpR4 div {
  border-radius: 30px;
  box-shadow: 2px 10px 20px #0000001a;
  background: #f5f8fb;
  text-align: center;
  padding: 40px;
}
.homepage_item__2BpR4 div p:nth-child(2) {
  font-weight: bold;
}
.homepage_trusted__r__dO {
  padding: 20px;
  background: #eff4f8;
}
.homepage_trustTitle__1BVHf {
  width: 90%;
  margin: 20px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.homepage_trustTitle__1BVHf div:nth-child(1),
.homepage_trustTitle__1BVHf div:nth-child(3) {
  background: #8674ec;
  width: 10%;
  height: 2px;
}
.homepage_trustTitle__1BVHf div:nth-child(2) {
  font-size: 1.8em;
  font-weight: bold;
}

.homepage_companies__32zoR {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
}
.homepage_companies__32zoR div {
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
}
.homepage_companies__32zoR div img {
  /* width:150px;
  height: 100px; */
}

@media screen and (max-width: 900px) {
  .homepage_left__3cy9M {
    width: 100%;
    text-align: center;
    height: auto;
  }
  .homepage_left__3cy9M p:nth-child(1) {
    font-size: 20px;
  }
  .homepage_left__3cy9M p:nth-child(2) {
    font-size: 16px;
  }
  .homepage_right__2yRT_ {
    display: none;
  }
  .homepage_chooseTitle__2C5b4 {
    font-size: 20px;
  }
  .homepage_item__2BpR4 {
    grid-template-columns: 1fr;
  }
  .homepage_provideTitle__3fUM0 {
    font-size: 20px;
  }
  .homepage_provideTitle__3fUM0 div {
    width: 89px;
  }
  .homepage_provideBox__1l854 {
    grid-template-columns: 1fr;
  }
  .homepage_trustTitle__1BVHf div:nth-child(2) {
    font-size: 12px;
  }
  .homepage_landingPage__DHYdT {
    /* margin-top: 0; */
  }
  .homepage_companies__32zoR {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
  .homepage_companies__32zoR div img {
    /* height: 50px; */
    width: 120px;
  }
}
@media screen and (max-width: 600px) {
  .homepage_provideTitle__3fUM0 {
    font-size: 18px;
    margin-top: 10px;
  }
  .homepage_provideTitle__3fUM0 div {
    width: 70px;
  }

  .homepage_chooseTitle__2C5b4 {
    font-size: 18px;
  }
  .homepage_chooseTitle__2C5b4 div {
    width: 70px;
  }
  .homepage_companies__32zoR {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
  .homepage_companies__32zoR div img {
    /* height: 50px; */
    width: 100px;
  }
  .homepage_trustTitle__1BVHf {
  }
  .homepage_trustTitle__1BVHf div:nth-child(1),
  .homepage_trustTitle__1BVHf div:nth-child(3) {
    background: #8674ec;
    width: 5%;
    height: 2px;
  }
  .homepage_trustTitle__1BVHf div:nth-child(2) {
    font-size: 0.8em;
  }
  /* .trustTitle div:nth-child(2){
      font-size: 12px;
  } */
}
@media screen and (max-width: 400px) {
  .homepage_trustTitle__1BVHf div:nth-child(2) {
    font-size: 0.6em;
  }
}

/* media query for ipad */
@media screen and (max-width: 1080px) {
  .homepage_changeDiv__1VUXS {
    margin: 10% 25%;
  }
  .homepage_headerStyle__2DPfH {
    padding: 0;
  }
  .homepage_changeDiv2__3XXSY,
  .homepage_changeDiv__1VUXS,
  .homepage_changeDiv1__PTueA {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 40%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .homepage_changeDiv1__PTueA {
    padding: 10px;
  }
}

.homepage_container__1I-Rq {
  display: grid;
  /* / grid-template-rows: 4rem 36rem 5rem; / */
  grid-template-rows: 4rem 57rem;
  height: 100%;
  width: 100vw;
}

.homepage_background_img__126Vv {
  width: 92%;
  height: 100%;
  margin: 0% 8%;
  background-repeat: no-repeat;
  background-size: cover;
}

.homepage_centered_text__33Q_k {
  font-size: 2.92vw;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.homepage_input__1p1k2 {
  box-sizing: border-box;
  width: 80%;
  margin: 10px auto;
  position: relative;
  box-shadow: 2px 4px 8px #0000001a;
  display: grid;
  grid-template-columns: 0.5fr 4fr;
  border-radius: 10px;
}
.homepage_input__1p1k2 div {
  height: 80%;
  margin: auto 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-right: 1px solid lightgrey;
}
.homepage_input__1p1k2 input {
  padding: 20px 20px;
  border-radius: 10px;
  outline: none;
  width: 100%;
  border: none;
  box-sizing: border-box;
  font-size: large;
  font-family: serif;
  font-family: initial;
}
.homepage_inputOne__1AOwy {
  box-sizing: border-box;
  width: 80%;
  margin: 10px auto;
  position: relative;
  box-shadow: 2px 4px 8px #0000001a;
  display: grid;
  grid-template-columns: 0.5fr 3.5fr 0.5fr;
  border-radius: 10px;
}
.homepage_inputOne__1AOwy input {
  padding: 20px 20px;
  border-radius: 10px;
  outline: none;
  width: 100%;
  border: none;
  box-sizing: border-box;
}
.homepage_inputOne__1AOwy div:nth-of-type(1) {
  height: 80%;
  margin: auto 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-right: 1px solid lightgrey;
}
.homepage_inputOne__1AOwy div:nth-of-type(2) {
  height: 80%;
  margin: auto 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.homepage_submit__3hY-5 {
  width: 80%;
  margin: auto;
  text-align: center;
  margin-top: 3%;
}
.homepage_submit__3hY-5 .homepage_enabled__3rUBB {
  background: #8d6dd7;
  cursor: pointer;
  width: 50%;
  outline: none;
  font-size: 1em;
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 50px;
  color: #fff;
}
.homepage_phone_no__1iWpo {
  margin-left: 11%;
  font-weight: bold;
}
.homepage_email__ewzT2 {
  font-size: 18px;
  margin-left: 11%;
  font-weight: bold;
}
.homepage_phoneimg__1lZVv {
  width: 35px;
}

.homepage_emailimg__WrOWz {
  width: 24px;
  height: 23px;
  margin-left: 3px;
  opacity: 0.39;
}

.homepage_centered_button__xr9UU {
  opacity: 1;
}

.homepage_spanStyle__QMY-s {
  font-size: 24px;
}

.homepage_sectionStyle__1zrBi {
  z-index: 90;
  overflow: hidden;
}

.homepage_loader_style__3yDSl {
  margin: 20% auto;
}

.homepage_headerStyle___rVEP {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.homepage_footerStyle__2VY_0 {
  z-index: 100;
  padding-left: 5%;
  box-shadow: 0 0px 3px 8px #00000029;
}

.homepage_centered__2XzaR {
  font-family: "Poppins";
  font-weight: 500;
  z-index: 1;
  position: absolute;
  top: 32%;
  left: 18%;
}

.homepage_changeDiv__1tBcC {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 11% 29%;
  z-index: 150;
  position: absolute;
}
.homepage_changeDiv1__1GJoa {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 12% 34%;
  z-index: 150;
  position: absolute;
}
.homepage_changeDiv2__2UfI5 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 17% 39%;
  z-index: 150;
  position: absolute;
}
.homepage_addBlurr__1jSrh {
  filter: brightness(40px);
  -webkit-filter: blur(40px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

@media screen and (max-width: 1600px) {
  .homepage_changeDiv__1tBcC {
    margin: 4% 28%;
  }
  .homepage_changeDiv1__1GJoa {
    margin: 12% 34%;
  }
  .homepage_changeDiv2__2UfI5 {
    margin: 15% 35%;
  }
}

@media screen and (max-width: 1400px) {
  .homepage_changeDiv__1tBcC {
    margin: 2% 27%;
  }
  .homepage_changeDiv1__1GJoa {
    margin: 12% 34%;
  }
  .homepage_changeDiv2__2UfI5 {
    margin: 12% 35%;
  }
}
@media screen and (max-width: 1366px) {
  .homepage_changeDiv__1tBcC {
    margin: 2% 27%;
  }
  .homepage_changeDiv1__1GJoa {
    margin: 8% 34%;
  }
  .homepage_changeDiv2__2UfI5 {
    margin: 10% 35%;
  }
}

@media (max-width: 575px) {
  .homepage_background_img__126Vv {
    height: 350px;
  }
}

@media screen and (max-width: 766px) {
  .homepage_centered_text__33Q_k {
    font-size: 2.19vw;
  }
  .homepage_centered__2XzaR {
    position: absolute;
    top: 32%;
    left: 8%;
  }
  .homepage_changeDiv__1tBcC {
    margin: 5% 5%;
    width: 85vw;
  }
  .homepage_spanStyle__QMY-s {
    font-size: 1.31vw;
  }
  .homepage_background_img__126Vv {
    height: 60.97vh;
  }
  .homepage_changeDiv1__1GJoa {
    margin: 21% 5%;
  }
  .homepage_changeDiv2__2UfI5 {
    margin: 10% 2%;
  }
}

.homepage_hero__2CNjd {
  margin-top: 5%;
  max-height: 400px;
  border-radius: 30px 30px 0 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  background: #fff;
}
.homepage_left__2tBVP {
  padding: 20px;
  width: 30%;
}
.homepage_left__2tBVP p:nth-child(2) {
  font-size: 20px;
}
.homepage_left__2tBVP button {
  background: #9675e4 0% 0% no-repeat padding-box;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 10px;
  color: #fff;
  box-shadow: 0px 3px 6px #00000029;
}
.homepage_right__1FD7E {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  position: relative;
  width: 50%;
}
.homepage_right__1FD7E img:nth-child(1) {
  height: 400px;
  width: 100%;
  margin-top: 100px;
}
.homepage_right__1FD7E img:nth-child(2) {
  position: absolute;
  top: 60%;
  left: 50%;
  height: 50px;
  width: 50px;
}
.homepage_provide__2Sdkh {
  background: #eff4f8;
  padding: 20px;
}
.homepage_provideTitle__3m5bo {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 80px auto 40px auto;
  width: 90%;
}
.homepage_provideTitle__3m5bo div {
  border: 1px solid #8674ec;
  width: 100px;
}

.homepage_provideBox__2NFVX {
  display: grid;
  width: 90%;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
  grid-gap: 20px;
}

.homepage_provideBoxItem__33ZGJ {
  background: #fff;
  box-shadow: 5px 30px 40px #00000040;
  border-radius: 30px;
  padding: 20px;
  /* width: 40%; */
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.homepage_provideBoxItem__33ZGJ:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background: linear-gradient(264deg, #6a68d2 0%, #5f5dbd 100%);
}
.homepage_provideBoxItem__33ZGJ img {
  margin-left: 40px;
}
.homepage_provideBoxItem__33ZGJ .homepage_withHover__3QLhC {
  display: none;
}
.homepage_provideBoxItem__33ZGJ:hover .homepage_withHover__3QLhC {
  display: block;
}
.homepage_provideBoxItem__33ZGJ:hover .homepage_withoutHover__w-3TS {
  display: none;
}
.homepage_provideBoxItem__33ZGJ p {
  margin: 0;
  /* width: 35%; */
  padding: 2px 0;
  margin-left: 40px;
  color: #000;
}
.homepage_provideBoxItem__33ZGJ:hover p {
  color: #fff;
}
.homepage_provideBoxItem__33ZGJ p:nth-child(2) {
  font-weight: bold;
}
.homepage_provideBoxItem__33ZGJ button {
  border: 1px solid #9977e8;
  border-radius: 9px;
  outline: none;
  cursor: pointer;
  color: #9977e8;
  background: transparent;
  padding: 10px;
  margin-left: 40px;
  margin-top: 20px;
}
.homepage_provideBoxItem__33ZGJ:hover button {
  border: 1px solid #fff;
  color: #fff;
}

.homepage_choose__3WEot {
  background: #fff;
  padding: 20px;
}
.homepage_chooseTitle__2x0-o {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 30px auto 40px auto;
  width: 90%;
}
.homepage_chooseTitle__2x0-o div {
  border: 1px solid #8674ec;
  width: 100px;
}

.homepage_item__dSsdg {
  width: 90%;
  margin: auto;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
}
.homepage_item__dSsdg div {
  border-radius: 30px;
  box-shadow: 2px 10px 20px #0000001a;
  background: #f5f8fb;
  text-align: center;
  padding: 40px;
}
.homepage_item__dSsdg div p:nth-child(2) {
  font-weight: bold;
}
.homepage_trusted__iHTrf {
  padding: 20px;
  background: #eff4f8;
}
.homepage_trustTitle__1sdSv {
  width: 90%;
  margin: 20px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.homepage_trustTitle__1sdSv div:nth-child(1),
.homepage_trustTitle__1sdSv div:nth-child(3) {
  background: #8674ec;
  width: 10%;
  height: 2px;
}
.homepage_trustTitle__1sdSv div:nth-child(2) {
  font-size: 1.8em;
  font-weight: bold;
}

.homepage_companies__2GpVq {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
}
.homepage_companies__2GpVq div {
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
}
.homepage_companies__2GpVq div img {
  /* width:150px;
  height: 100px; */
}

@media screen and (max-width: 900px) {
  .homepage_left__2tBVP {
    width: 100%;
    text-align: center;
    height: auto;
  }
  .homepage_left__2tBVP p:nth-child(1) {
    font-size: 20px;
  }
  .homepage_left__2tBVP p:nth-child(2) {
    font-size: 16px;
  }
  .homepage_right__1FD7E {
    display: none;
  }
  .homepage_chooseTitle__2x0-o {
    font-size: 20px;
  }
  .homepage_item__dSsdg {
    grid-template-columns: 1fr;
  }
  .homepage_provideTitle__3m5bo {
    font-size: 20px;
  }
  .homepage_provideTitle__3m5bo div {
    width: 89px;
  }
  .homepage_provideBox__2NFVX {
    grid-template-columns: 1fr;
  }
  .homepage_trustTitle__1sdSv div:nth-child(2) {
    font-size: 12px;
  }
  .homepage_landingPage__2CZji {
    /* margin-top: 0; */
  }
  .homepage_companies__2GpVq {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
  .homepage_companies__2GpVq div img {
    /* height: 50px; */
    width: 120px;
  }
}
@media screen and (max-width: 600px) {
  .homepage_provideTitle__3m5bo {
    font-size: 18px;
    margin-top: 10px;
  }
  .homepage_provideTitle__3m5bo div {
    width: 70px;
  }

  .homepage_chooseTitle__2x0-o {
    font-size: 18px;
  }
  .homepage_chooseTitle__2x0-o div {
    width: 70px;
  }
  .homepage_companies__2GpVq {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
  .homepage_companies__2GpVq div img {
    /* height: 50px; */
    width: 100px;
  }
  .homepage_trustTitle__1sdSv {
  }
  .homepage_trustTitle__1sdSv div:nth-child(1),
  .homepage_trustTitle__1sdSv div:nth-child(3) {
    background: #8674ec;
    width: 5%;
    height: 2px;
  }
  .homepage_trustTitle__1sdSv div:nth-child(2) {
    font-size: 0.8em;
  }
  /* .trustTitle div:nth-child(2){
      font-size: 12px;
  } */
}
@media screen and (max-width: 400px) {
  .homepage_trustTitle__1sdSv div:nth-child(2) {
    font-size: 0.6em;
  }
}
/* media query for ipad */
@media screen and (max-width: 1080px) {
  .homepage_changeDiv__1tBcC {
    margin: 10% 25%;
  }
  .homepage_headerStyle___rVEP {
    padding: 0;
  }
  .homepage_changeDiv2__2UfI5,
  .homepage_changeDiv__1tBcC,
  .homepage_changeDiv1__1GJoa {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 40%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .homepage_changeDiv1__1GJoa {
    padding: 10px;
  }
  .homepage_email__ewzT2 {
    margin-left: 0;
  }
}

.homepage_container__3iaXU {
  display: grid;
  /* / grid-template-rows: 4rem 36rem 5rem; / */
  grid-template-rows: 4rem 57rem;
  height: 100%;
  width: 100vw;
}

.homepage_background_img__1cM47 {
  width: 92%;
  height: 100%;
  margin: 0% 8%;
  background-repeat: no-repeat;
  background-size: cover;
}

.homepage_centered_text__2Ytjq {
  font-size: 2.92vw;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.homepage_centered_button__1hKZT {
  opacity: 1;
}

.homepage_spanStyle__21B7t {
  font-size: 24px;
}

.homepage_sectionStyle__2Km2R {
  z-index: 90;
  overflow: hidden;
}

.homepage_loader_style__NOrpl {
  margin: 20% auto;
}

.homepage_headerStyle__3Zd5s {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.homepage_footerStyle__I5zry {
  z-index: 100;
  padding-left: 5%;
  box-shadow: 0 0px 3px 8px #00000029;
}

.homepage_centered__2pElh {
  font-family: "Poppins";
  font-weight: 500;
  z-index: 1;
  position: absolute;
  top: 32%;
  left: 18%;
}

.homepage_changeDiv__3IdBM {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 11% 29%;
  z-index: 150;
  position: absolute;
}
.homepage_changeDiv1__1F8fX {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 12% 34%;
  z-index: 150;
  position: absolute;
}
.homepage_changeDiv2__6cD_4 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 17% 39%;
  z-index: 150;
  position: absolute;
}
.homepage_addBlurr__1R1Qc {
  filter: brightness(40px);
  -webkit-filter: blur(40px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

@media screen and (max-width: 1600px) {
  .homepage_changeDiv__3IdBM {
    margin: 4% 28%;
  }
  .homepage_changeDiv1__1F8fX {
    margin: 12% 34%;
  }
  .homepage_changeDiv2__6cD_4 {
    margin: 15% 35%;
  }
}

@media screen and (max-width: 1400px) {
  .homepage_changeDiv__3IdBM {
    margin: 2% 27%;
  }
  .homepage_changeDiv1__1F8fX {
    margin: 12% 34%;
  }
  .homepage_changeDiv2__6cD_4 {
    margin: 12% 35%;
  }
}
@media screen and (max-width: 1366px) {
  .homepage_changeDiv__3IdBM {
    margin: 2% 27%;
  }
  .homepage_changeDiv1__1F8fX {
    margin: 8% 34%;
  }
  .homepage_changeDiv2__6cD_4 {
    margin: 10% 35%;
  }
}

@media (max-width: 575px) {
  .homepage_background_img__1cM47 {
    height: 350px;
  }
}

@media screen and (max-width: 766px) {
  .homepage_centered_text__2Ytjq {
    font-size: 2.19vw;
  }
  .homepage_centered__2pElh {
    position: absolute;
    top: 32%;
    left: 8%;
  }
  .homepage_changeDiv__3IdBM {
    margin: 5% 5%;
    width: 85vw;
  }
  .homepage_spanStyle__21B7t {
    font-size: 1.31vw;
  }
  .homepage_background_img__1cM47 {
    height: 60.97vh;
  }
  .homepage_changeDiv1__1F8fX {
    margin: 21% 5%;
  }
  .homepage_changeDiv2__6cD_4 {
    margin: 10% 2%;
  }
}

.homepage_hero__2frk0 {
  max-height: 400px;
  border-radius: 30px 30px 0 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  background: #fff;
}
.homepage_left__2BSfa {
  padding: 20px;
  width: 40%;
  margin-top: 3%;
}
.homepage_left__2BSfa p:nth-child(1) {
  font-size: 16px;
}
.homepage_left__2BSfa p:nth-child(2) {
  font-size: 16px;
}
.homepage_left__2BSfa button {
  background: #9675e4 0% 0% no-repeat padding-box;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 10px;
  color: #fff;
  box-shadow: 0px 3px 6px #00000029;
}
.homepage_right__2u4R8 {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  position: relative;
  width: 50%;
}
.homepage_right__2u4R8 img:nth-child(1) {
  height: 400px;
  width: 100%;
  margin-top: 100px;
}
.homepage_right__2u4R8 img:nth-child(2) {
  position: absolute;
  top: 60%;
  left: 50%;
  height: 50px;
  width: 50px;
}
.homepage_provide__2isRp {
  background: #eff4f8;
  padding: 20px;
}
.homepage_provideTitle__1KtbA {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 80px auto 40px auto;
  width: 90%;
}
.homepage_provideTitle__1KtbA div {
  border: 1px solid #8674ec;
  width: 100px;
}

.homepage_provideBox__1n5mW {
  display: grid;
  width: 90%;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
  grid-gap: 20px;
}

.homepage_provideBoxItem__ss0JD {
  background: #fff;
  box-shadow: 5px 30px 40px #00000040;
  border-radius: 30px;
  padding: 20px;
  /* width: 40%; */
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.homepage_provideBoxItem__ss0JD:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background: linear-gradient(264deg, #6a68d2 0%, #5f5dbd 100%);
}
.homepage_provideBoxItem__ss0JD img {
  margin-left: 40px;
}
.homepage_provideBoxItem__ss0JD .homepage_withHover__M5hn7 {
  display: none;
}
.homepage_provideBoxItem__ss0JD:hover .homepage_withHover__M5hn7 {
  display: block;
}
.homepage_provideBoxItem__ss0JD:hover .homepage_withoutHover__1rzz_ {
  display: none;
}
.homepage_provideBoxItem__ss0JD p {
  margin: 0;
  /* width: 35%; */
  padding: 2px 0;
  margin-left: 40px;
  color: #000;
}
.homepage_provideBoxItem__ss0JD:hover p {
  color: #fff;
}
.homepage_provideBoxItem__ss0JD p:nth-child(2) {
  font-weight: bold;
}
.homepage_provideBoxItem__ss0JD button {
  border: 1px solid #9977e8;
  border-radius: 9px;
  outline: none;
  cursor: pointer;
  color: #9977e8;
  background: transparent;
  padding: 10px;
  margin-left: 40px;
  margin-top: 20px;
}
.homepage_provideBoxItem__ss0JD:hover button {
  border: 1px solid #fff;
  color: #fff;
}

.homepage_choose__CtHcW {
  background: #fff;
  padding: 20px;
}
.homepage_chooseTitle__3IWAw {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 30px auto 40px auto;
  width: 90%;
}
.homepage_chooseTitle__3IWAw div {
  border: 1px solid #8674ec;
  width: 100px;
}

.homepage_item__1h1aO {
  width: 90%;
  margin: auto;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
}
.homepage_item__1h1aO div {
  border-radius: 30px;
  box-shadow: 2px 10px 20px #0000001a;
  background: #f5f8fb;
  text-align: center;
  padding: 40px;
}
.homepage_item__1h1aO div p:nth-child(2) {
  font-weight: bold;
}
.homepage_trusted__3yXeS {
  padding: 20px;
  background: #eff4f8;
}
.homepage_trustTitle__104aF {
  width: 90%;
  margin: 20px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.homepage_trustTitle__104aF div:nth-child(1),
.homepage_trustTitle__104aF div:nth-child(3) {
  background: #8674ec;
  width: 10%;
  height: 2px;
}
.homepage_trustTitle__104aF div:nth-child(2) {
  font-size: 1.8em;
  font-weight: bold;
}

.homepage_companies__25aHg {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
}
.homepage_companies__25aHg div {
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
}
.homepage_companies__25aHg div img {
  /* width:150px;
  height: 100px; */
}

@media screen and (max-width: 900px) {
  .homepage_left__2BSfa {
    width: 100%;
    text-align: center;
    height: auto;
  }
  .homepage_left__2BSfa p:nth-child(1) {
    font-size: 20px;
  }
  .homepage_left__2BSfa p:nth-child(2) {
    font-size: 16px;
  }
  .homepage_right__2u4R8 {
    display: none;
  }
  .homepage_chooseTitle__3IWAw {
    font-size: 20px;
  }
  .homepage_item__1h1aO {
    grid-template-columns: 1fr;
  }
  .homepage_provideTitle__1KtbA {
    font-size: 20px;
  }
  .homepage_provideTitle__1KtbA div {
    width: 89px;
  }
  .homepage_provideBox__1n5mW {
    grid-template-columns: 1fr;
  }
  .homepage_trustTitle__104aF div:nth-child(2) {
    font-size: 12px;
  }
  .homepage_landingPage__18w74 {
    /* margin-top: 0; */
  }
  .homepage_companies__25aHg {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
  .homepage_companies__25aHg div img {
    /* height: 50px; */
    width: 120px;
  }
}
@media screen and (max-width: 600px) {
  .homepage_provideTitle__1KtbA {
    font-size: 18px;
    margin-top: 10px;
  }
  .homepage_provideTitle__1KtbA div {
    width: 70px;
  }

  .homepage_chooseTitle__3IWAw {
    font-size: 18px;
  }
  .homepage_chooseTitle__3IWAw div {
    width: 70px;
  }
  .homepage_companies__25aHg {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
  .homepage_companies__25aHg div img {
    /* height: 50px; */
    width: 100px;
  }
  .homepage_trustTitle__104aF {
  }
  .homepage_trustTitle__104aF div:nth-child(1),
  .homepage_trustTitle__104aF div:nth-child(3) {
    background: #8674ec;
    width: 5%;
    height: 2px;
  }
  .homepage_trustTitle__104aF div:nth-child(2) {
    font-size: 0.8em;
  }
  /* .trustTitle div:nth-child(2){
      font-size: 12px;
  } */
}
@media screen and (max-width: 400px) {
  .homepage_trustTitle__104aF div:nth-child(2) {
    font-size: 0.6em;
  }
}

/* media query for ipad */
@media screen and (max-width: 1080px) {
  .homepage_changeDiv__3IdBM {
    margin: 10% 25%;
  }
  .homepage_headerStyle__3Zd5s {
    padding: 0;
  }
  .homepage_changeDiv2__6cD_4,
  .homepage_changeDiv__3IdBM,
  .homepage_changeDiv1__1F8fX {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 40%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .homepage_changeDiv1__1F8fX {
    padding: 10px;
  }
}

.homepage_container__348XP {
  display: grid;
  /* / grid-template-rows: 4rem 36rem 5rem; / */
  grid-template-rows: 4rem 57rem;
  height: 100%;
  width: 100vw;
}

.homepage_background_img__3mo3y {
  width: 92%;
  height: 100%;
  margin: 0% 8%;
  background-repeat: no-repeat;
  background-size: cover;
}

.homepage_link__hXFN4:visited {
  color: blue;
}

.homepage_centered_text__2f-Q_ {
  font-size: 2.92vw;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.homepage_centered_button__3S7XX {
  opacity: 1;
}

.homepage_spanStyle__PAbYO {
  font-size: 24px;
}

.homepage_sectionStyle__1qjuR {
  z-index: 90;
  overflow: hidden;
}

.homepage_loader_style__3a1xi {
  margin: 20% auto;
}

.homepage_headerStyle__36Lyp {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.homepage_footerStyle__2Dj6q {
  z-index: 100;
  padding-left: 5%;
  box-shadow: 0 0px 3px 8px #00000029;
}

.homepage_centered__3UElN {
  font-family: "Poppins";
  font-weight: 500;
  z-index: 1;
  position: absolute;
  top: 32%;
  left: 18%;
}

.homepage_changeDiv__2-uOI {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 11% 29%;
  z-index: 150;
  position: absolute;
}
.homepage_changeDiv1__--pIU {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 12% 34%;
  z-index: 150;
  position: absolute;
}
.homepage_changeDiv2__f2rZD {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 17% 39%;
  z-index: 150;
  position: absolute;
}
.homepage_addBlurr__jM_3b {
  filter: brightness(40px);
  -webkit-filter: blur(40px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

@media screen and (max-width: 1600px) {
  .homepage_changeDiv__2-uOI {
    margin: 4% 28%;
  }
  .homepage_changeDiv1__--pIU {
    margin: 12% 34%;
  }
  .homepage_changeDiv2__f2rZD {
    margin: 15% 35%;
  }
}

@media screen and (max-width: 1400px) {
  .homepage_changeDiv__2-uOI {
    margin: 2% 27%;
  }
  .homepage_changeDiv1__--pIU {
    margin: 12% 34%;
  }
  .homepage_changeDiv2__f2rZD {
    margin: 12% 35%;
  }
}
@media screen and (max-width: 1366px) {
  .homepage_changeDiv__2-uOI {
    margin: 2% 27%;
  }
  .homepage_changeDiv1__--pIU {
    margin: 8% 34%;
  }
  .homepage_changeDiv2__f2rZD {
    margin: 10% 35%;
  }
}

@media (max-width: 575px) {
  .homepage_background_img__3mo3y {
    height: 350px;
  }
}

@media screen and (max-width: 766px) {
  .homepage_centered_text__2f-Q_ {
    font-size: 2.19vw;
  }
  .homepage_centered__3UElN {
    position: absolute;
    top: 32%;
    left: 8%;
  }
  .homepage_changeDiv__2-uOI {
    margin: 5% 5%;
    width: 85vw;
  }
  .homepage_spanStyle__PAbYO {
    font-size: 1.31vw;
  }
  .homepage_background_img__3mo3y {
    height: 60.97vh;
  }
  .homepage_changeDiv1__--pIU {
    margin: 21% 5%;
  }
  .homepage_changeDiv2__f2rZD {
    margin: 10% 2%;
  }
}

.homepage_hero__3EE-f {
  max-height: 400px;
  border-radius: 30px 30px 0 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  background: #fff;
}
.homepage_left__1466Z {
  /* padding-left: 20px; */
  width: 40%;
  margin-top: 1rem;
}
.homepage_left__1466Z p:nth-child(1) {
  font-size: 20px;
}
.homepage_left__1466Z p:nth-child(2) {
  font-size: 20px;
}
.homepage_left__1466Z button {
  background: #9675e4 0% 0% no-repeat padding-box;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 10px;
  color: #fff;
  box-shadow: 0px 3px 6px #00000029;
}
.homepage_right__1PMCs {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  position: relative;
  width: 50%;
}
.homepage_right__1PMCs img:nth-child(1) {
  height: 400px;
  width: 100%;
  margin-top: 100px;
}
.homepage_right__1PMCs img:nth-child(2) {
  position: absolute;
  top: 60%;
  left: 50%;
  height: 50px;
  width: 50px;
}
.homepage_provide__3NF2y {
  background: #eff4f8;
  padding: 20px;
}
.homepage_provideTitle__2ZURX {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 80px auto 40px auto;
  width: 90%;
}
.homepage_provideTitle__2ZURX div {
  border: 1px solid #8674ec;
  width: 100px;
}

.homepage_provideBox__2BYY1 {
  display: grid;
  width: 90%;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
  grid-gap: 20px;
}

.homepage_provideBoxItem__2BcIL {
  background: #fff;
  box-shadow: 5px 30px 40px #00000040;
  border-radius: 30px;
  padding: 20px;
  /* width: 40%; */
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.homepage_provideBoxItem__2BcIL:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background: linear-gradient(264deg, #6a68d2 0%, #5f5dbd 100%);
}
.homepage_provideBoxItem__2BcIL img {
  margin-left: 40px;
}
.homepage_provideBoxItem__2BcIL .homepage_withHover__1wH7h {
  display: none;
}
.homepage_provideBoxItem__2BcIL:hover .homepage_withHover__1wH7h {
  display: block;
}
.homepage_provideBoxItem__2BcIL:hover .homepage_withoutHover__rc_vX {
  display: none;
}
.homepage_provideBoxItem__2BcIL p {
  margin: 0;
  /* width: 35%; */
  padding: 2px 0;
  margin-left: 40px;
  color: #000;
}
.homepage_provideBoxItem__2BcIL:hover p {
  color: #fff;
}
.homepage_provideBoxItem__2BcIL p:nth-child(2) {
  font-weight: bold;
}
.homepage_provideBoxItem__2BcIL button {
  border: 1px solid #9977e8;
  border-radius: 9px;
  outline: none;
  cursor: pointer;
  color: #9977e8;
  background: transparent;
  padding: 10px;
  margin-left: 40px;
  margin-top: 20px;
}
.homepage_provideBoxItem__2BcIL:hover button {
  border: 1px solid #fff;
  color: #fff;
}

.homepage_choose__36vfR {
  background: #fff;
  padding: 20px;
}
.homepage_chooseTitle__yX9Rd {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 30px auto 40px auto;
  width: 90%;
}
.homepage_chooseTitle__yX9Rd div {
  border: 1px solid #8674ec;
  width: 100px;
}

.homepage_item__mGO0i {
  width: 90%;
  margin: auto;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
}
.homepage_item__mGO0i div {
  border-radius: 30px;
  box-shadow: 2px 10px 20px #0000001a;
  background: #f5f8fb;
  text-align: center;
  padding: 40px;
}
.homepage_item__mGO0i div p:nth-child(2) {
  font-weight: bold;
}
.homepage_trusted__2fXaD {
  padding: 20px;
  background: #eff4f8;
}
.homepage_trustTitle__3huDt {
  width: 90%;
  margin: 20px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.homepage_trustTitle__3huDt div:nth-child(1),
.homepage_trustTitle__3huDt div:nth-child(3) {
  background: #8674ec;
  width: 10%;
  height: 2px;
}
.homepage_trustTitle__3huDt div:nth-child(2) {
  font-size: 1.8em;
  font-weight: bold;
}

.homepage_companies__1fGb9 {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
}
.homepage_companies__1fGb9 div {
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
}
.homepage_companies__1fGb9 div img {
  /* width:150px;
  height: 100px; */
}

@media screen and (max-width: 900px) {
  .homepage_hero__3EE-f {
    width: 99vw;
  }
  .homepage_left__1466Z {
    width: 99vw;
    text-align: center;
    height: auto;
  }
  a {
    display: block;
    word-wrap: break-word;
  }
  .homepage_left__1466Z p:nth-child(1) {
    font-size: 20px;
  }
  .homepage_left__1466Z p:nth-child(2) {
    font-size: 16px;
  }
  .homepage_right__1PMCs {
    display: none;
  }
  .homepage_chooseTitle__yX9Rd {
    font-size: 20px;
  }
  .homepage_item__mGO0i {
    grid-template-columns: 1fr;
  }
  .homepage_provideTitle__2ZURX {
    font-size: 20px;
  }
  .homepage_provideTitle__2ZURX div {
    width: 89px;
  }
  .homepage_provideBox__2BYY1 {
    grid-template-columns: 1fr;
  }
  .homepage_trustTitle__3huDt div:nth-child(2) {
    font-size: 12px;
  }
  .homepage_landingPage__3TyWn {
    /* margin-top: 0; */
  }
  .homepage_companies__1fGb9 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
  .homepage_companies__1fGb9 div img {
    /* height: 50px; */
    width: 120px;
  }
}
@media screen and (max-width: 600px) {
  .homepage_provideTitle__2ZURX {
    font-size: 18px;
    margin-top: 10px;
  }
  .homepage_provideTitle__2ZURX div {
    width: 70px;
  }

  .homepage_chooseTitle__yX9Rd {
    font-size: 18px;
  }
  .homepage_chooseTitle__yX9Rd div {
    width: 70px;
  }
  .homepage_companies__1fGb9 {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
  .homepage_companies__1fGb9 div img {
    /* height: 50px; */
    width: 100px;
  }
  .homepage_trustTitle__3huDt {
  }
  .homepage_trustTitle__3huDt div:nth-child(1),
  .homepage_trustTitle__3huDt div:nth-child(3) {
    background: #8674ec;
    width: 5%;
    height: 2px;
  }
  .homepage_trustTitle__3huDt div:nth-child(2) {
    font-size: 0.8em;
  }
  /* .trustTitle div:nth-child(2){
      font-size: 12px;
  } */
}
@media screen and (max-width: 400px) {
  .homepage_trustTitle__3huDt div:nth-child(2) {
    font-size: 0.6em;
  }
}

/* media query for ipad */
@media screen and (max-width: 1080px) {
  .homepage_changeDiv__2-uOI {
    margin: 10% 25%;
  }
  .homepage_headerStyle__36Lyp {
    padding: 0;
  }
  .homepage_changeDiv2__f2rZD,
  .homepage_changeDiv__2-uOI,
  .homepage_changeDiv1__--pIU {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 40%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .homepage_changeDiv1__--pIU {
    padding: 10px;
  }
  .homepage_left__1466Z {
    margin-top: 10%;
  }
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_container__Xvyaq {
  border: 1px solid #cfcfd2;
  margin: 13px;
  /* padding: 10px 10px 10px 10px; */
  /* height: 100px; */
  cursor: pointer;
}

.cards_container__Xvyaq:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_card__3vpaa {
  /* border: 1px solid #CFCFD2; */
  /* margin: 13px; */
  padding: 10px 10px 10px 10px;
  /* height: 100px; */
  display: grid;
  grid-template-columns: 95px 400px 380px;
  overflow: hidden;
  /* font-family: 'Poppins', sans-serif; */
}

/* .card:hover {
    box-shadow: 10px 10px 12px -4px #00000015;
    transform: scale(1, 1);
} */

.cards_right__imHfO {
  width: 100px;
  height: 85px;
  margin-left: 50vw;
  
}

.cards_right__imHfO img {
  height: 100%;
  width: 95%;
}

.cards_left__fWPqm{
  color:black;
  width:20vw;
}
.cards_left__fWPqm p{
  margin: 2px;
}
.cards_time__124DT{
  text-align: left;
font: normal normal normal 12px/30px Poppins;
letter-spacing: -0.12px;
color: #333333;
opacity: 0.6;
}

.cards_orgname__2DKq4{
  text-align: left;
font: normal normal normal 15px/30px Poppins;
letter-spacing: -0.15px;
color: #333333;
opacity: 1;
}

.cards_oppdes__3_aa2{
  text-align: left;
font: normal normal 600 20px/32px Poppins;
letter-spacing: -0.2px;
color: #333333;
opacity: 1;
}








/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */



/* .request p {
  margin: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
} */

.cards_request__1Gf-T:hover {
  background-color: #0f065f;
  color: white;
}

.cards_request__1Gf-T:hover p {
  background-color: #0f065f;
  color: white;
}
@media screen and (max-width:575px){
  .cards_right__imHfO{
    width: 75px;
    height: 75px;
    margin-left: 29vw;
  }
  .cards_left__fWPqm{
    width: 60vw;
  }
}
.student_container__FhrW5 {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.student_background_img__2_OtQ {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.student_centered_text__2iEn2 {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}
.student_loader__33jNq {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.student_centered_button__eP8i0 {
  opacity: 1;
}

.student_sectionStyle__1QNDt {
  display: grid;
  grid-template-columns: 10% 90%;
}

.student_headerStyle__1xL_z {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.student_heading__3LmM6 {
  display: grid;
  grid-template-columns: 200px 740px 300px;
}

.student_backarrow__3JUyE {
  /* background-color: red; */
  width: 0%;
  margin-left: 135px;
  margin-top: 25px;
}

.student_backarrow__3JUyE img {
  width: 20px;
  cursor: pointer;
}

.student_text__26tI0 {
  /* background-color: saddlebrown; */
  font-weight: bold;
  margin: 30px;
  margin-left: -35px;
  margin-top: 8px;
}

.student_filter__1T-Rf {
  /* background-color: seagreen; */
  margin-left: 75px;
}

.student_filter__1T-Rf img {
  width: 45px;
  margin-top: 14px;
  cursor: pointer;
}

.student_centered__yNPvg {
  /* font-family: 'Poppins-medium';
    width: 70%;
    height: 100%;
    background-color: #FFFFFF;
    position: absolute;
    top: 19%;
    left: 20%; */
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
}

.student_changeDiv__3DYtT {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 45%;
  left: 20%;
  z-index: 150;
  margin: 15% 0% 0 5%;
  /* top: 30%; */
  position: absolute;
}

.student_addBlurr__3SwSz {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.student_header__3lnz6 {
  width: 80%;
  height: 10%;
  margin: -3px 0px 0px 135px;
  /* background-color: red; */
}

.student_keyword__1cAbQ {
  width: 85%;
  height: 66%;
  border: 1px solid #dbdbdb;
  background-color: white;
  margin: 13px 0px 0px 0px;
  display: -webkit-flex;
  display: flex;
}

.student_keyword__1cAbQ img {
  width: 18px;
  padding: 10px;
}

.student_input__1kP0E {
  border: none;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
}

.student_options__2E7hr img {
  width: 40px;
  float: right;
  margin: -40px 72px 0px 0px;
}

@media screen and (max-width: 575px) {
  .student_headerStyle__1xL_z {
    width: 100vw;
  }

  .student_centered__yNPvg {
    width: 90%;
    left: 5%;
    top: 18%;
    overflow-x: hidden;
    height: 100vh;
  }
  .student_backarrow__3JUyE {
    margin-left: -101px;
  }
}

@media screen and (max-width: 1080px) {
  .student_centered__yNPvg {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 87%;
    height: 84%;
    background-color: #ffffff;
    position: absolute;
    top: 11%;
    left: 12%;
  }
  .student_headerStyle__1xL_z {
    padding: 0;
  }
}

.orgHeader_header__2oAFF {
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  grid-template-columns: auto auto;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 2% 0 3%;
  /* width: 80%; */
  /* min-width: fit-content; */
}

.orgHeader_noOfNotification__2jUfF {
  padding: 0px 5px;
  margin: 0px;
  font-size: 15px;
  color: white;
  background: #110766;
  border-radius: 100%;
  position: absolute;
  right: 5%;
  top: 10%;
}

.orgHeader_header__2oAFF a {
  -webkit-align-self: center;
          align-self: center;
  justify-self: self-start;
  opacity: 1;
}
.orgHeader_changeDiv__3r_7S {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 5% 35%;
  z-index: 150;

  position: absolute;
}
/* .changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 48%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 30%;
  position: absolute;
} */
.orgHeader_logo__3O_DG {
  margin-top: -7px;
  height: 8.98vh;
  padding-left: 6.55vw;

  /* margin-left: 100%; */
}
.orgHeader_menu__2VXXF {
  display: none;
}

.orgHeader_headerRight__3h1eb {
  align-self: center;
  justify-self: end;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  float: right;
  padding: 0% 6% 0% 0%;
}

.orgHeader_headerRight__3h1eb a img {
  height: 31.17px;
}

.orgHeader_profileUpdate__36TGf {
  position: absolute;
  background-color: rgba(128, 128, 128, 0.322);
  color: black;
  top: 50%;
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
  border: 1px solid;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 10px;
}

.orgHeader_headerRight__3h1eb a {
  font-weight: 500;
  float: left;
  color: #927f7f;
  text-align: left;
  opacity: 1;
  padding: 12px;
  text-decoration: none;
  font-size: 16px;
  line-height: 25px;
}

.orgHeader_profileImage__2YNwY {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  /* padding: 0 20px; */
  border: 4px solid #ffffff;
  opacity: 1;
}

.orgHeader_profileSection__1NK_x {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: -19px;
}

.orgHeader_bellIconStyle__BmzUK {
  height: 25.17px;
  padding: 0 20px;
  cursor: pointer;
}

.orgHeader_bellIconStyle__BmzUK:hover {
  height: 25.17px;
  margin-top: 28px;
  margin-right: 11px;
  padding: 0px 10px 16px 10px;
  cursor: pointer;
  border-bottom: 4px solid #110766;
  background-image: linear-gradient(180deg, #ffffff00 63%, #110766 150%);
}

.orgHeader_bellIconStyleActive__1os7Z {
  height: 25.17px;
  margin-top: 28px;
  margin-right: 11px;
  padding: 0px 10px 16px 10px;
  cursor: pointer;
  border-bottom: 4px solid #110766;
  background-image: linear-gradient(180deg, #ffffff00 63%, #110766 150%);
}

.orgHeader_dropdownIconStyle__3uW0I {
  height: 22px;
  padding: 0 10px;
  cursor: pointer;
}

.orgHeader_orgNameStyle__1zhnN {
  font-size: 16px;
  color: #525252;
  opacity: 1;
  padding: 0 2px;
  text-transform: capitalize;
  font-weight: 400;
}

.orgHeader_dropStyle__2-eHC {
  position: relative;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.orgHeader_modalStyle__14E-D {
  position: absolute;
  font-size: 11pt;
  background-color: #ffffff;
  width: 129pt;
  height: 50pt;

  top: 75%;
  right: 26%;
  border-radius: 16pt 0 0 16pt;
}

/* //*************************responsive******************************************** */

@media screen and (max-width: 600px) {
  .orgHeader_header__2oAFF {
    /* float: left; */
    height: auto;
    /* display: block; */
    /* text-align: left; */
  }

  /* .header_right {
    float: none;
  } */
  .orgHeader_profileSection__1NK_x {
    /* display: none; */
  }
  .orgHeader_menu__2VXXF {
    display: block;
    margin-top: 3px;
    height: 6.98vh;
    padding-left: 0.55vw;
    margin-left: -5vw;
  }
  .orgHeader_logo__3O_DG {
    display: none;
  }
  .orgHeader_noOfNotification__2jUfF {
    top: 10%;
  }
}

@media screen and (max-width: 1080px) {
  .orgHeader_header__2oAFF {
    padding: 0;
  }
  .orgHeader_headerStyle__1PiQt {
    padding: 0;
  }
}

.profileDropdown_container__2jUih {
	background-color: #FFFFFF;
	border: 1px solid #EAEAEA;
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
}

.profileDropdown_subContainer1__10leR {
    background-color: #FFFFFF;
    margin: 6px;
	color: #333333;
    padding-bottom: 4px;
	cursor: pointer;
}

.profileDropdown_subContainer1__10leR:hover {
	box-shadow: 0 0px 2px 2px #00000029;
    background-color: #1E118D;
    border-radius: 12px 0 0 0;
    margin: 6px;
    padding-bottom: 4px;
	color:#FFFFFF;
}

.profileDropdown_subContainer2__2n8FP {
	background-color: #FFFFFF;
    border-radius: 0 0 0 12px;
    margin: 6px;
    padding-bottom: 4px;
	cursor: pointer;
}

.profileDropdown_subContainer2__2n8FP:hover {
	box-shadow: 0 0px 2px 2px #00000029;
    background-color: #1E118D;
    border-radius: 0 0 0 12px;
    margin: 6px;
	color:#FFFFFF;
    padding-bottom: 4px;
}

.profileDropdown_subContainer1__10leR .profileDropdown_label__1e7rA {
	float: left;
	font-weight: 400;
	font-size: 11pt;
	color:#333333;
    margin: 8px 0 0 15px;
}

.profileDropdown_subContainer2__2n8FP .profileDropdown_label__1e7rA {
	float: left;
	font-weight: 400;
	font-size: 11pt;
	color:#333333;
    margin: 6px 0 0 15px;
}

.profileDropdown_subContainer1__10leR:hover .profileDropdown_label__1e7rA, .profileDropdown_subContainer2__2n8FP:hover .profileDropdown_label__1e7rA {
	color:#FFFFFF;
}


.profileDropdown_subContainer1__10leR img {
	width: 11%;
    margin: 4% 0 0 0%;
}

.profileDropdown_subContainer2__2n8FP img {
	width: 11%;
	width: 11%;
    margin: 4% 0 0 0%;
}
.ResponseAlert_container__2bTSv {
  display: block;
  background-color: #fafbfc;
  width: 25rem;
  height: 14rem;
  /* top: 50%;
    position: absolute;
   
    transform: translate(35%, -56%); */
  border-radius: 30px;
}

.ResponseAlert_result__1sy5n {
 text-align: center;

}


.ResponseAlert_ok_btn__1DyaO {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 20%;
  height: 35px;
  /* margin: 0% 2% 0% 17%; */
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  margin-left: 0px;
 /* margin-top: 8%; */
}

.ResponseAlert_message__3RY0y {
  /* margin: 2% 2% 2% 11%; */
  /* color: red; */
  font-size: 22px;
  font-weight: normal;
  color: black;
 /* padding-top: 18%; */
}
.ResponseAlert_error__12Iim{
  font-size: 22px;
  font-weight: normal;
  color: black;
margin-right: 65%;
margin-top: 14%;
}
@media screen and (max-width:700px){
  .ResponseAlert_container__2bTSv{
    width: 19rem;
    height: 19rem;
  }
}
.profileUpdate_container__XVhgW {
  width: 400px;
  height: 250px;
  border-radius: 10px;
  background-color: white;
}

.sidebar_leftSection__2QgWF {
  width: 10vw;
  height: 57rem;
  background-color: #393939;
}

.sidebar_menu__2UJW3 {
  /* align-self: center; */
  /* justify-self: end; */

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.sidebar_menu__2UJW3 .sidebar_active__3NkjQ {
  position: relative;
  text-decoration: none;
  width: 100%;
  height: 100px;
  background-color: #f7f7f7;
  color: #110766;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.sidebar_menu__2UJW3 .sidebar_active__3NkjQ:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  right: 0px;
}
.sidebar_menu__2UJW3 .sidebar_active__3NkjQ:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;

  right: 0px;
}

.sidebar_menu__2UJW3 .sidebar_active__3NkjQ:after {
  text-decoration: none;
  top: -10px;
  background: radial-gradient(circle at top left, #393939 10px, #f7f7f7 11px);
}

.sidebar_menu__2UJW3 .sidebar_active__3NkjQ:before {
  text-decoration: none;
  bottom: -10px;
  background: radial-gradient(
    circle at bottom left,
    #393939 10px,
    #f7f7f7 11px
  );
}

/* .active1{  
  background-color: #F7F7F7; 
  width: 100%; 
  height: 100px;
  
  color: #110766; position: absolute;  
   text-decoration: none;

}*/
.sidebar_inactive1__2fb9C {
  /* background-color: #393939 ; */
  color: #ffffff;
  opacity: 0.5;
  width: 100%;
  height: 100px;
  text-decoration: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.sidebar_searchImg__2yQhh {
  /* margin: 5% 10% 2% 30%; */
  width: 2.56vw;
  height: 4.21vh;
}
.sidebar_span__DJMN_ {
  /* margin-left: 18%; */
  display: -webkit-flex;
  display: flex;
  font-size: 1.17vw;
}
.sidebar_inactiveO__27cHw {
  /* background-color: #393939 ; */
  color: #ffffff;
  opacity: 0.5;
  width: 100%;
  height: 100px;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  /* .leftSection{
    width: 16vw;
  } */
  .sidebar_sidebar__1Msoe {
    width: 80vw;
    height: 57rem;
    background-color: #393939;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 6vh;
  }
  /* .menu {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  } */
  .sidebar_leftSection__2QgWF {
    width: 0vw;
    height: 57rem;
    background-color: #393939;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 6vh;
  }
  .sidebar_searchImg__2yQhh {
    /* margin: 5% 10% 2% 25%; */
    width: 35.56vw;
    height: 4.21vh;
  }
  .sidebar_span__DJMN_ {
    /* margin-left: 25%; */
    display: -webkit-flex;
    display: flex;
    font-size: 6vw;
  }
  .sidebar_menu__2UJW3 .sidebar_active__3NkjQ {
    height: auto;
  }
}

@media screen and (max-width: 1080px) {
  .sidebar_leftSection__2QgWF {
    height: 90vh;
  }
  .sidebar_headerStyle__1W5Ey {
    padding: 0;
  }
}

.appliedfilterc_container__iJjLJ {
  /* width: 35%; */
  border-radius: 30px;
  background-color: #ffffff;
}

.appliedfilterc_closeModal__2WaqW {
  float: right;
  width: 25px;
  height: 25px;
  margin: 2% 4% 0 0;
  cursor: pointer;
}
.appliedfilterc_whiteButton__W5uqN {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #ffffff;
  width: 134px;
  height: 35px;
  margin: 6px 0px 0px 14px;
  font-weight: 500;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #110766;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.appliedfilterc_para__2a9iI {
  font-size: 18px;
  padding: 5% 0% 0% 7%;
}

.appliedfilterc_form1__1-XNr {
  display: block;
}

.appliedfilterc_label___Bw06 {
  display: -webkit-inline-flex;
  display: inline-flex;
  color: #919191;
  font-size: 11pt;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 10px 0 5px 40px;
  font-weight: 500;
}

.appliedfilterc_input__1XZ2A {
  display: -webkit-flex;
  display: flex;
  width: 64%;
  height: 23px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 6pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 11pt;
  margin-bottom: -1rem;
}

.appliedfilterc_input__1XZ2A:focus,
.appliedfilterc_input1__289LK.appliedfilterc_focus__PMtPP {
  border: 1pt solid #b9b9b9;
}

.appliedfilterc_subheading__25zVN {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 10px 0 5px 40px;
  font-weight: 600;
  margin-top: 4%;
  color: #333333;
  font-size: 12pt;
}

.appliedfilterc_input1__289LK {
  display: -webkit-flex;
  display: flex;
  width: 90%;
  height: 23pt;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 6pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 11pt;
  margin-bottom: -1rem;
}

.appliedfilterc_input1__289LK:focus {
  border: 1pt solid #7e7e7e;
}

.appliedfilterc_fourth__1TO14 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.appliedfilterc_fourth2__3YpFl {
  /* margin-left: 55px; */
}

.appliedfilterc_fourth3__104So {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 35vw;
}

.appliedfilterc_btn_css__2Wu-s {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 91%;
  margin-top: 1rem;
}
.appliedfilterc_date_style__1qmNx {
  margin-left: 33%;
  margin-top: 2%;
  cursor: pointer;
  position: absolute !important;
}
.appliedfilterc_date_style1__3OWb4 {
  margin-left: 33%;
  margin-top: 2%;
  cursor: pointer;
  position: absolute !important;
}
.appliedfilterc_Calendar__1Sk5M {
  margin-top: 5%;
  margin-left: 7%;
  /* width: 150px; */
  /* padding: calc(0.75em / 0.75) calc(0.5em / 0.75); */
  /* line-height: 1.125em; */
}
/* .react-calendar__tile {
      padding: 0 !important;
  } */

.appliedfilterc_errorMsgbelow__1AkR8 {
  font-size: 0.7rem;
  color: red;
  padding: 2.5% 0 0 6%;
}
@media screen and (max-width: 775px) {
  .appliedfilterc_whiteButton__W5uqN {
    width: 20.8vw;
    font-size: 3vw;
  }
  .appliedfilterc_btn_css__2Wu-s {
    padding: 28px 14px 30px 53px;
  }

  .appliedfilterc_fourth__1TO14 {
    display: block;
  }
  .appliedfilterc_input1__289LK {
    width: 63%;
  }
  .appliedfilterc_fourth2__3YpFl {
    margin-left: 3px;
    margin-top: 3%;
  }
  .appliedfilterc_Calendar__1Sk5M {
    /* position: absolute !important; */
    left: 1vw !important;
    top: 135%;
  }
  .appliedfilterc_date_style1__3OWb4 {
    right: 2.5rem;
    margin: 0;
  }
  .appliedfilterc_date_style__1qmNx {
    right: 2.5rem;
    margin: 0;
  }
  .appliedfilterc_fourth3__104So {
    width: 70vw;
  }
}

@media screen and (max-width: 1080px) {
  .appliedfilterc_btn_css__2Wu-s {
    position: relative;
    width: 100%;
    margin-top: 3rem;
    display: -webkit-flex;
    display: flex;
    padding: 0;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }
  .appliedfilterc_headerStyle__2b67F {
    padding: 0;
  }
  .appliedfilterc_whiteButton__W5uqN {
    height: 48px;
  }
  .appliedfilterc_input1__289LK {
    width: 59%;
  }
  .appliedfilterc_fourth2__3YpFl {
    margin-left: 32px;
  }
}

.OpportunityDetails_container__26X47 {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100;
  background-color: rgd(238, 238, 245);
}
.OpportunityDetails_button__2lkz0 {
}
.OpportunityDetails_display__2mzzW {
  display: -webkit-flex;
  display: flex;
  margin: 5px;
}
.OpportunityDetails_apply__1gt18 {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 134px;
  height: 35px;
  margin: -3vh 0vw 0vh 57vw;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.OpportunityDetails_loader__1w3dD {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.OpportunityDetails_applied__2TinR {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: grey;
  font-weight: 500;
  width: 134px;
  height: 35px;
  margin: -3vh 0vw 0vh 57vw;
  border-radius: 14px;
  border: 1px solid grey;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
}
.OpportunityDetails_background_img__3tfxz {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.OpportunityDetails_centered_text__2mJHW {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.OpportunityDetails_centered_button__NXLA_ {
  opacity: 1;
}

.OpportunityDetails_nodata__17NOr {
  display: block;
  margin: 3% auto;
  width: 50%;
}

.OpportunityDetails_sectionStyle__3has9 {
  display: grid;
  grid-template-columns: 10% 90%;
}
.OpportunityDetails_button__2lkz0 {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #1e118d;
  font-weight: 500;
  width: 15%;
  margin-top: -3vh;
  margin-left: 65vw;

  height: 32px;
  border-radius: 14px;
  border: 1px solid #110766;
  color: #ffffff;
  text-align: center;
  font-size: 1.2rem;
  cursor: pointer;
  float: right;
}

.OpportunityDetails_topHeading__2pRdc {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: 11%;
  margin-top: 2%;
}

.OpportunityDetails_headerStyle__2EPL3 {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.OpportunityDetails_aboutcss__2SHgi {
  font-weight: 400;
  margin: -19px 0 0 180px;
  font-size: 0.8rem;
}

.OpportunityDetails_about_rec__1OKSp {
  font-weight: 400;
  margin: -19px 0 0 345px;
  font-size: 0.8rem;
}

.OpportunityDetails_centered__3UX-o {
  font-weight: 400;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 22%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.OpportunityDetails_para__1wquB {
  color: #24242e;
  font-weight: 600;
  font-size: 1.1rem;
  margin-left: 1rem;
}

.OpportunityDetails_arrow__1A7mJ {
  width: 100%;
  height: 2%;
  margin: 0.5% -5% 0px 0px;
  cursor: pointer;
}

.OpportunityDetails_arrow__1A7mJ img {
  width: 35%;
  /* / margin-top: 5%; / */
  cursor: pointer;
}

.OpportunityDetails_cricket__fF9RG {
  width: 7rem;
  height: 7rem;
  margin-left: 30px;
  margin-top: 15px;
  border-radius: 5px;
}

.OpportunityDetails_para1__3JXzd {
  margin-left: 15px;
  font-size: 1.2rem;
  margin-top: 15px;
  opacity: 0.7;
  font-weight: 900;
}

.OpportunityDetails_para2__252H6 {
  font-weight: 400;
  margin-left: 1rem;
  font-size: 0.8rem;
  margin-top: -7px;
}

.OpportunityDetails_para3__2lsqQ {
  font-weight: 400;
  margin-left: 1rem;
  font-size: 0.55rem;
  opacity: 0.5;
}

.OpportunityDetails_para4__lFT35 {
  font-weight: 400;
  margin-left: 6rem;
  font-size: 0.55rem;
  margin-top: -20px;
  opacity: 0.5;
}

.OpportunityDetails_line__3Vl-M {
  margin-top: 0.1%;
  opacity: 0.5;
  width: 100%;
  margin-left: 0px;
}

.OpportunityDetails_activeTab__1dI64 {
  color: #1e118d;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  opacity: 0.9;
  border-bottom: 4px solid #1e118d;
  margin-left: 30px;
}
.OpportunityDetails_inactiveTab__1sl5- {
  font-weight: 400;
  margin-left: 30px;
  font-size: 0.8rem;
  cursor: pointer;
}
.OpportunityDetails_inaactiveTab__1ssZz {
  font-weight: 400;
  font-size: 0.8rem;
  cursor: pointer;
}
.OpportunityDetails_aactiveTab__1AxUP {
  color: #1e118d;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  opacity: 0.9;
  border-bottom: 4px solid #1e118d;
}

.OpportunityDetails_high__1sX6T {
  margin-left: 30px;
  font-size: 1rem;
  font-weight: 600;
  opacity: 1;
  color: #333333;
  margin-top: 15px;
  margin-bottom: 1.3rem;
}

.OpportunityDetails_table__3CpTq {
  font-size: 0.9rem;
  min-width: 548px;
  margin: 25px 0px;
  margin-left: 28px;
  margin-top: -15px;
}

.OpportunityDetails_category__1hYN_ {
  opacity: 0.6;
  color: #333333;
  font-size: 0.9rem;
  margin-left: 0px;
  font-weight: 500;
  width: 10vw;
}

.OpportunityDetails_value__2FgIc {
  color: #333333;
  font-size: 0.9rem;
  font-weight: 700;
  font-weight: 500;
  width: 60vw;
  color: #333333;
}

.OpportunityDetails_orgimg__1K9W4 {
  margin-left: 31px;
  margin-top: 25px;
  height: 13.5rem;
  width: 26rem;
}

.OpportunityDetails_about__2Y-1l {
  margin-top: -224px;
  margin-left: 492px;
  font-weight: 600;
  font-size: 0.9rem;
  color: #333333;
}

.OpportunityDetails_paraabout__3SQUK {
  margin-left: 492px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-right: 20px;
  height: 12.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
  padding-right: 8px;
  margin-top: -8px;
}
.OpportunityDetails_orgimgRecr__3_61o {
  margin-left: 31px;
  margin-top: 25px;
  height: 16rem;
  width: 13.5rem;
}
.OpportunityDetails_aboutRecr__1_yg2 {
  margin-left: 30px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 0.9rem;
  color: #333333;
}

.OpportunityDetails_paraaboutRecr__2mp93 {
  margin-left: 30px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-right: 20px;
  margin-top: -7px;
  text-align: justify;
  height: auto;
  max-height: 15.5rem;
  overflow-x: hidden;
  padding-right: 8px;
  overflow-y: auto;
}
.OpportunityDetails_name__1wdA6 {
  margin-left: 31px;
  font-weight: 500;
  color: #333333;
  font-size: 1.1rem;
  opacity: 0.7;
  margin-top: -10px;
}

.OpportunityDetails_name1__2VKx4 {
  margin-left: 31px;
  font-weight: 500;
  color: #333333;
  font-size: 0.9rem;
  font-weight: 900;
  opacity: 0.9;
  margin-bottom: 25px;
}

.OpportunityDetails_head3__29ykG {
  margin-left: 30px;
  font-weight: 600;
  font-size: 1rem;
  margin-top: -15px;
}

.OpportunityDetails_paraOpp__gPpDo {
  margin-left: 30px;
  margin-right: 40px;
  font-weight: 400;
  font-size: 0.9rem;
  margin-top: -7px;
  text-align: justify;
  color: #333333;
}

.OpportunityDetails_head4__3Tk2j {
  margin-left: 30px;
  font-weight: 600;
  font-size: 1rem;
}

.OpportunityDetails_recruiter__2qCHn {
  margin-left: 30px;
  width: 50px;
  margin-top: -10px;
}

.OpportunityDetails_table2__G3V_F {
  margin-left: 31px;
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: -10px;
  opacity: 0.6;
}
.OpportunityDetails_playerName__1VARM {
  font-weight: 500;
  color: black;
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: 31px;
}
.OpportunityDetails_gameName__94KEA {
  font-weight: 500;
  color: #333333;
  font-size: 0.9rem;
  opacity: 0.7;
  margin-top: -14px;
  margin-left: 31px;
}
@media screen and (max-width: 575px) {
  .OpportunityDetails_centered__3UX-o {
    width: 90%;
    left: 5%;
  }
  .OpportunityDetails_cricket__fF9RG {
    width: 5rem;
    height: 5rem;
    margin-left: 5px;
  }
  .OpportunityDetails_para2__252H6 {
    margin-top: -19px;
  }
  .OpportunityDetails_inactiveTab__1sl5- {
    margin-left: 5px;
    font-size: 0.6rem;
  }
  .OpportunityDetails_activeTab__1dI64 {
    margin-left: 5px;
    font-size: 0.6rem;
  }
  .OpportunityDetails_inaactiveTab__1ssZz {
    font-size: 0.6rem;
  }
  .OpportunityDetails_aactiveTab__1AxUP {
    font-size: 0.6rem;
  }
  .OpportunityDetails_aboutcss__2SHgi {
    margin: -19px 0 0 105px;
  }
  .OpportunityDetails_about_rec__1OKSp {
    margin: -19px 0 0 213px;
  }
  .OpportunityDetails_high__1sX6T {
    margin-left: 5px;
  }
  .OpportunityDetails_table__3CpTq {
    margin-left: 5px;
  }
  .OpportunityDetails_head3__29ykG {
    margin-left: 5px;
  }
  .OpportunityDetails_paraOpp__gPpDo {
    margin-left: 5px;
  }
  .OpportunityDetails_head4__3Tk2j {
    margin-left: 5px;
  }
  .OpportunityDetails_orgimg__1K9W4 {
    margin-left: 5px;
    margin-top: 10px;
    height: 10.5rem;
    width: 19rem;
  }
  .OpportunityDetails_name__1wdA6 {
    margin-left: 5px;
  }
  .OpportunityDetails_name1__2VKx4 {
    margin-left: 5px;
  }
  .OpportunityDetails_value__2FgIc {
    width: 100%;
  }
  .OpportunityDetails_category__1hYN_ {
    width: 20%;
  }
  .OpportunityDetails_display__2mzzW {
    display: block;
  }
  .OpportunityDetails_orgimgRecr__3_61o {
    margin-left: 5px;
    margin-top: 10px;
    height: 10rem;
    width: 19.5rem;
  }
  .OpportunityDetails_playerName__1VARM {
    margin-left: 5px;
  }
  .OpportunityDetails_gameName__94KEA {
    margin-left: 5px;
  }
  .OpportunityDetails_aboutRecr__1_yg2 {
    margin-left: -310px;
    margin-top: 264px;
  }
  .OpportunityDetails_paraaboutRecr__2mp93 {
    margin-left: -310px;
  }
  .OpportunityDetails_arrow__1A7mJ {
    width: 4vw;
    height: 2vh;
    margin: 0.5% -5% 0px -60px;
  }
  .OpportunityDetails_para__1wquB {
    margin-left: -11vw;
  }
  .OpportunityDetails_button__2lkz0 {
    width: 30%;
    margin-left: 60vw;
    margin-top: 1vh;
  }
  .OpportunityDetails_applied__2TinR {
    margin: 1vh 0vw 0vh 46vw;
    width: 88px;
  }
  .OpportunityDetails_apply__1gt18 {
    margin: 1vh 0vw 0vh 46vw;
    width: 88px;
  }
}

@media screen and (max-width: 1080px) {
  .OpportunityDetails_topHeading__2pRdc {
    margin-top: 7%;
  }
  .OpportunityDetails_centered__3UX-o {
    top: 16%;
    font-weight: 400;
    width: 84%;
    height: 75%;
    background-color: #ffffff;
    position: absolute;

    left: 13%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .OpportunityDetails_headerStyle__2EPL3 {
    padding: 0;
  }
  .OpportunityDetails_orgimg__1K9W4 {
    width: 15rem;
  }
  .OpportunityDetails_about__2Y-1l {
    margin-left: 367px;
  }
  .OpportunityDetails_paraabout__3SQUK {
    margin-left: 367px;
  }
  .OpportunityDetails_orgimgRecr__3_61o {
    height: 12rem;
  }
}

.cards_maincontainer__2HahA {
  background-color: white;
  height: 14rem;
}

.cards_maincard__1TK-S {
  display: grid;
  grid-template-rows: 0px 150px;
  margin-left: 10px;
}

.cards_card__1cEqw {
  border: 1px solid #cfcfd2;
  margin: 15px 0px 0px 0px;
  padding: 16px;
  height: 158px;
  width: 94%;
}

/*.card:hover {
    box-shadow: 20px 20px 18px -8px #00000015;
    transform: scale(1, 1);
}*/

.cards_square__3Lwu2 {
  margin-left: 1px;
  margin-top: 16px;
  width: 25px;
  height: 25px;
  background-image: -webkit-linear-gradient(-45deg, #36b453 50%, #ffffff 50%);
}

.cards_box1__2FnM1 {
  width: 80%;
}

.cards_heading__1mnPX {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  /* background-color: #1E118D; */
  height: 50px;
}
.cards_constantImg__2x2MS {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: -10px;
}
.cards_constantImg1__1zZlm {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.cards_lefthead__hJE3f {
  font-size: 0.9rem;
  width: 94%;
  font-weight: 700;
}

.cards_lefthead__hJE3f p {
  margin: 0px 0px 0px 4px;
}

.cards_lefthead__hJE3f p {
  opacity: 0.6;
  color: #333333;
  font-size: 8px;
  margin: 5px;
}

.cards_detail__2DAS3 {
  /* background-color: #b63f49; */
  width: 38px;
}

.cards_detail__2DAS3 img {
  width: 24px;
  height: 25px;
  left: 665px;
  top: 44px;
  opacity: 1;
}

.cards_more__x-GzL {
  /* background-color: #3a48c5; */
  width: 38px;
  cursor: pointer;
}

.cards_more__x-GzL img {
  cursor: pointer;
  z-index: 1;
}
.cards_lefthead1__2Hpnz {
  width: 94%;
  font-weight: 700;
}

/* .box1 img {
    width: 24px;
    height: 21px;
    left: 665px;
    top: 44px;
    
} */

.cards_table__1ANpP {
  font-size: 15px;
  min-width: 548px;
  /* margin: 25px 0px; */
}

.cards_table__1ANpP tbody {
  margin: 25px;
}

.cards_shelf__3oTnP {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 93%;
  margin-bottom: 0.8%;
  margin-left: 1%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.cards_shelf__3oTnP:last-child {
  margin-bottom: 0;
}

.cards_labelbox__2mSEU {
  -webkit-flex: 0 0 35%;
          flex: 0 0 35%;
  opacity: 0.6;
  color: #333333;
  font-size: 12px;
}

.cards_valuebox__2qUpE {
  -webkit-flex: 0 0 65%;
          flex: 0 0 65%;
  opacity: 1;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}

.cards_box2__1upSq {
  background-color: white;
}

.cards_card_image__2O4C9 img {
  height: 10rem;
  width: 10rem;
  margin-top: -11.7rem;
  margin-right: 10px;
  float: right;
}

.cards_card_info__2M654 {
  width: 30px;
}

.cards_appliedImg__3Zzlz {
  height: 10%;
}

.cards_left__2pgsE img {
  width: 45px;
  height: 40px;
  margin-top: -3px;
}

.cards_left__2pgsE p {
  color: #1e118d;
  margin-left: 62px;
  margin-top: -40px;
  font-size: 15px;
  font-weight: bold;
}

.cards_eye__1BzPT {
  margin-right: 4%;
    margin-top: -14%;
}

.cards_right__184DF img {
  width: 32px;
  height: 34px;
  margin-left: 257px;
  margin-top: -3px;
}

.cards_right__184DF p {
  color: #747474;
  opacity: 1;
  font-size: 13px;
  margin-left: 300px;
  margin-top: -33px;
}

@media screen and (max-width: 1500px) {
  .cards_lefthead__hJE3f {
    width: 84%;
  }
  .cards_lefthead1__2Hpnz {
    width: 83%;
  }
}
@media screen and (max-width: 1300px) {
  .cards_lefthead__hJE3f {
    width: 80%;
  }
  .cards_lefthead1__2Hpnz {
    width: 78%;
  }
}
@media screen and (max-width: 1150px) {
  .cards_lefthead__hJE3f {
    width: 76%;
  }
  .cards_lefthead1__2Hpnz {
    width: 74%;
  }
}
@media screen and (max-width:575px){
  .cards_box1__2FnM1{
    width: 100%;
  }
  .cards_card_image__2O4C9 img {
    height: 5rem;
    width: 5rem;
    margin-top: -18vh;
    margin-right: 1px;
  }
}
.s_opportunities_container__cDwbE {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}
.s_opportunities_changeDiv1__3lf_X {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 40%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 12%;
  position: absolute;
}

/* .heading {
    height: 60px;
    display: grid;
    grid-template-columns: 200px 740px 300px;
}
.arrow img {
    width: 10%;
    margin: 35px 0px 0px 160px;
}
.text p {
    font-weight: bold;
    margin: 30px;
    margin: 30px 0px 0px 0px;
}*/

.s_opportunities_filter__11lHE {
  height: 50px;
  margin: -10px 0 0px 175px;
  cursor: pointer;
}

.s_opportunities_centered_text__3BvlM {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.s_opportunities_topHeading__3UjXx {
  width: 100%;
  height: 5%;
  margin: 2% 0 0 0;
  margin: 2% 0 0 0;
  padding: 0% 11%;
}

.s_opportunities_labelImg__3OME- {
  height: 14px;
  cursor: pointer;
}

.s_opportunities_label__13Nhp {
  /* float: left; */
  color: #24242e;
  font-weight: 600;
  font-size: 12pt;
  margin: 0px 0 35px 13px;
}

.s_opportunities_centered_button__2tEY_ {
  opacity: 1;
}

.s_opportunities_options__3fOjF img {
  margin-left: 55rem !important;
  margin-top: -2.5rem !important;
}

.s_opportunities_changeDiv__1BcES {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  /* / height: 52%; / */
  left: 20%;
  z-index: 150;
  margin: 10% 0% 0 5%;
  /* / top: 30%; / */
  position: absolute;
  box-shadow: 20pt 23pt 100pt;
}

.s_opportunities_sectionStyle__vLvmw {
  display: grid;
  grid-template-columns: 10% 90%;
}

.s_opportunities_headerStyle__1UgbT {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.s_opportunities_centered__22JtP {
  width: 70%;
  height: 75%;
  background-color: #f7f7f7;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-y: auto;
}

.s_opportunities_header__1DMYb {
  width: 70.3%;
  height: 14%;
  background-color: #f7f7f7;
  position: absolute;
  top: 10%;
  left: 19.1%;
}

.s_opportunities_footer__2W8rC {
  width: 70.3%;
  height: 14%;
  background-color: #f7f7f7;
  position: absolute;
  top: 94%;
  left: 19.1%;
}

.s_opportunities_keyword__1IPi7 {
  width: 94%;
  height: 42%;
  border: 1px solid #dbdbdb;
  background-color: white;
  margin: 10px;
  display: -webkit-flex;
  display: flex;
}

.s_opportunities_keyword__1IPi7 img {
  width: 18px;
  padding: 10px;
}

.s_opportunities_input__22d3B {
  border: none;
  width: 100%;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
}

.s_opportunities_input__22d3B:focus {
  outline: none;
}

.s_opportunities_changeDiv__1BcES {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  /* height: 52%; */
  left: 20%;
  z-index: 150;
  margin: 10% 0% 0 5%;
  /* top: 30%; */
  position: absolute;
  box-shadow: 20pt 23pt 100pt;
}

.s_opportunities_addBlurr__2tTZa {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}
.s_opportunities_container__cDwbE {
  overflow: hidden;
}
@media screen and (max-width: 480px) {
  .s_opportunities_sectionStyle__vLvmw {
    grid-template-columns: 11% 81%;
  }

  .s_opportunities_button__33Rk1 {
    width: 110px;
    margin-left: 45%;
  }
}

@media screen and (max-width: 575px) {
  .s_opportunities_headerStyle__1UgbT {
    width: 100vw;
  }
  .s_opportunities_changeDiv1__3lf_X {
    width: 85%;
    left: 5%;
  }
  .s_opportunities_header__1DMYb {
    width: 92.3%;
    left: 1.1%;
  }
  .s_opportunities_centered__22JtP {
    width: 90%;
    left: 5%;
    top: 19%;
    overflow-x: hidden;
    height: 100vh;
  }
  .s_opportunities_labelImg__3OME- {
    margin-left: -16%;
  }
  .s_opportunities_footer__2W8rC {
    width: 25.3%;
    top: 95%;
    left: 35.1%;
    height: 5%;
  }

  .s_opportunities_options__3fOjF img {
    margin-left: 14.5rem !important;
  }
  .s_opportunities_label__13Nhp {
    font-size: 10.5pt;
  }
  .s_opportunities_centered__22JtP {
    height: 75vh;
  }
}

@media screen and (max-width: 1080px) {
  .s_opportunities_header__1DMYb {
    top: 9%;
  }
  .s_opportunities_keyword__1IPi7 {
    height: 30%;
  }
  .s_opportunities_options__3fOjF {
    top: 41%;
  }
  .s_opportunities_centered__22JtP {
    width: 90%;
    left: 10%;
    top: 11%;
    overflow-x: hidden;
    height: 80vh;
  }
  .s_opportunities_container__cDwbE {
    height: 100vh;
  }
  .s_opportunities_headerStyle__1UgbT {
    padding: 0;
  }
}

    .appliedfilterc_container__3MxhI {
        /* width: 35%; */
        border-radius: 30px;
        background-color: #FFFFFF;
        ;
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .appliedfilterc_closeModal__gmzRJ {
        float: right;
        width: 25px;
        height: 25px;
        margin: 2% 4% 0 0;
        cursor: pointer;
    }
    
    .appliedfilterc_para__1u_9k {
        font-size: 18px;
        padding: 5% 0% 0% 7%;
    }
    
    .appliedfilterc_form1__2tndO {
        display: block;
    }
    
    .appliedfilterc_label__2Wy8u {
        display: -webkit-inline-flex;
        display: inline-flex;
        color: #919191;
        font-size: 11pt;
        -webkit-justify-content: right;
                justify-content: right;
        margin: 10px 0 5px 40px;
        font-weight: 500;
        ;
    }
    
    .appliedfilterc_input__XeP8T {
        display: -webkit-flex;
        display: flex;
        width: 64%;
        height: 23px;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        padding: 1% 13% 1% 4%;
        margin-left: 40px;
        border-radius: 6pt;
        border: 1pt solid #B9B9B9;
        opacity: 0.5;
        font-size: 11pt;
    }
    
    .appliedfilterc_keyword__374Bp img {
        width: 5%;
    }
    
    .appliedfilterc_skilldiv__3Xkgi {
        display: -webkit-flex;
        display: flex;
        width: 81%;
        height: 38px;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        /* padding: 1% 0% 1% 4%; */
        margin-left: 40px;
        border-radius: 6pt;
        border: 1pt solid #B9B9B9;
        opacity: 0.5;
        font-size: 11pt;
    }
    
    .appliedfilterc_inputcategory__3U2z2 {
        display: -webkit-flex;
        display: flex;
        width: 93%;
        height: 100%;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        /* padding: 1% 13% 1% 4%; */
        /* margin-left: 51px; */
        border-radius: 6pt;
        /* border: 1pt solid #B9B9B9; */
        border: none;
        opacity: 0.5;
        font-size: 11pt;
    }
    
    .appliedfilterc_input__XeP8T:focus,
    .appliedfilterc_input1__1zzsx:focus,
    .appliedfilterc_input2__2AccS:focus,
    .appliedfilterc_inputcategory__3U2z2 {
        outline: none;
    }
    
    .appliedfilterc_subheading__3P2Qe {
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-justify-content: right;
                justify-content: right;
        margin: 10px 0 5px 40px;
        font-weight: 600;
        ;
        color: #333333;
        font-size: 12pt;
    }
    
    .appliedfilterc_inputcalendar__3SL1C {
        width: 116%;
        height: 50%;
        margin: 2% 0% 0% 20%;
        border-radius: 6pt;
        border: 1pt solid #B9B9B9;
        display: -webkit-flex;
        display: flex;
    }
    
    .appliedfilterc_input1__1zzsx {
        border: 1px solid #B9B9B9;
        /* border: none; */
        width: 100%;
        font-size: 15px;
        opacity: 0.5;
        color: #333333;
        margin: 2% 0% 0% 16%;
        height: 55%;
        border-radius: 9px;
    }
    
    .appliedfilterc_input2__2AccS {
        border: 1px solid #B9B9B9;
        width: 110%;
        font-size: 15px;
        opacity: 0.5;
        color: #333333;
        margin: 2% 0% 0% 15%;
        height: 55%;
        border-radius: 9px;
    }
    /* .input1 {
    display: flex;
    width: 90%;
    height: 23pt;
    align-items: center;
    justify-content: flex-end;
    padding: 1% 13% 1% 4%;
    margin-left: 40px;
    margin-top: -20px;
    border-radius: 6pt;
    border: 1pt solid #B9B9B9;
    opacity: 0.5;
    font-size: 11pt;
}*/
    
    .appliedfilterc_calendarimg__2cozN {
        width: 12%;
        height: 56%;
        margin: 4% 2% 0% 0%
    }
    
    .appliedfilterc_modalStyle__lUaru {
        position: absolute;
        margin: 7% 0% 0% 0%;
        width: 41%;
        border-radius: 5%;
    }
    
    .appliedfilterc_inputcalendar2__3pqu_ {
        width: 116%;
        height: 50%;
        margin: 2% 0% 0% 20%;
        border-radius: 6pt;
        border: 1pt solid #B9B9B9;
        display: -webkit-flex;
        display: flex;
    }
    
    .appliedfilterc_calendarimg2__3mHot {
        width: 12%;
        height: 56%;
        margin: 4% 2% 0% 0%
    }
    
    .appliedfilterc_modalStyle2__2bsD- {
        position: absolute;
        margin: 7% 0% 0% 0%;
        width: 41%;
        border-radius: 5%;
    }
    
    .appliedfilterc_fourth__1or_7 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    
    .appliedfilterc_fourth1__2J_bH {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        width: 45%;
    }
    
    .appliedfilterc_fourth2__DnuFz {
        margin-left: 55px
    }
    .appliedfilterc_errorMsgbelow__1YzAz {
        font-size: 0.7rem;
        color: red;
        padding: 2.5% 0 0 6%;
    }
    .appliedfilterc_btn_css__2pHhv {
        position: relative;
        width: 100%;
        padding: 28px 14px 30px 219px;
    }
    .appliedfilterc_whiteButton__3Ia21 {
        box-shadow: 0 0px 2px 2px #0000001F;
        background-color: #FFFFFF;
        width: 134px;
        height: 35px;
        margin: 6px 0px 0px 14px;
        font-weight: 500;
        border-radius: 14px;
        border: 1px solid #110766;
        opacity: 1;
        color: #110766;
        text-align: center;
        font-size: 16px;
        cursor: pointer;
    }

    @media screen and (max-width: 700px){
    .appliedfilterc_whiteButton__3Ia21{
        width: 20.8vw;
        font-size: 3vw;
    }
    .appliedfilterc_btn_css__2pHhv{
        padding: 28px 14px 30px 54px;
    }
    }
.showPagination{
    width: 23px;
    height: 23px;
    margin-left: 3px;    
    display: inline-block;
    color: black;
    font-size: 17px;
    text-align: inherit;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.pagination{
    display: block;
    position: absolute;
    right: 0;
    left:0;
    text-align: center;
}

.active1{
    border-radius: 100%;
    width: 23px;
    height: 23px;
    margin-left: 3px;    
    display: inline-block;
    font-size: 20px;
    padding: 0;
    font-weight: bold;
    color:rgb(63, 170, 138);;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-decoration: underline;
}

.dot{
    display: inline-block;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-left: 10px; 
}
.arrow:hover{
    font-size: x-large !important;
    transition: .2s font-size;
}
.leftdiv {
    display: inline-block;
}
.dashboard_container__QFPU9 {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}

.dashboard_background_img__2YARM {
  width: 80%;
  height: 110%;
}

.dashboard_centered_text__3I3Y9 {
  font-size: 3.125rem;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.dashboard_centered_button__1a7uN {
  opacity: 1;
}

/* span {
          font-size: 24px;
        } */

.dashboard_sectionStyle__1MLbi {
  display: grid;
  grid-template-columns: 10% 90%;
}

.dashboard_headerStyle__3USVh {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}
.dashboard_loader_style__2Of-f {
  margin: 20% auto;
}
.dashboard_rows__1WFfx {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: 20%;
  margin-top: 3%;
}

.dashboard_flexGap__2-KlJ {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.dashboard_flexGap__2-KlJ > div {
  margin-left: 14px;
}

.dashboard_div2__1n6tB img {
  width: 60%;

  height: 20rem;
  margin: 5% 0% 0% 20%;
  border: 1 px solid lightgray;
  object-fit: contain;
  background: white;
}

.dashboard_div2__1n6tB h1 {
  font-size: 1.125rem;
  font-weight: bold;
  color: #161233;
  text-transform: capitalize;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  padding-top: 3px;
}

.dashboard_div2__1n6tB p {
  opacity: 1;
  color: #707070;
  display: -webkit-flex;
  display: flex;
  margin-top: -10px;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  font-size: 0.75rem;
}

.dashboard_whiteBackground__lI8jd {
  width: 60%;
  height: 4rem;
  margin: -1.5% 0% 0% 20%;
  background-color: #ffffff;
}

.dashboard_div2__1n6tB a {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  color: #122190;
  opacity: 1;
}

.dashboard_div3__cT0ZJ {
  background-color: #ffffff;
  text-align: center;
  border-radius: 15px;
  padding: 1% 7% 1% 7%;
}

.dashboard_div3__cT0ZJ h1 {
  font-weight: bold;
  font-size: 3.125rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.dashboard_div3__cT0ZJ p {
  font-weight: bold;
  margin-top: -44px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.dashboard_div4__l90ZU {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 1% 5% 2% 5%;
  margin-left: 5%;
}

.dashboard_div4__l90ZU h1 {
  font-weight: bold;
  font-size: 3.125rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.dashboard_div4__l90ZU p {
  font-weight: bold;
  margin-top: -44px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.dashboard_div5__3Az5U {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 1% 4% 1% 4%;
  margin-left: 5%;
}

.dashboard_div5__3Az5U h1 {
  font-weight: bold;
  font-size: 3.125rem;
  color: #1e118d;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.dashboard_div5__3Az5U p {
  font-weight: bold;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  margin-top: -42px;
}

.dashboard_imgIcon__1KR8- {
  position: absolute;
  margin-left: 11%;
  margin-top: -5%;

  cursor: pointer;
}

@media screen and (max-width: 1550px) and (min-width: 745px) {
  .dashboard_div2__1n6tB img {
    width: 60%;
    height: 15rem;
    margin: 5% 0% 0% 20%;
    object-fit: contain;
  }

  .dashboard_div3__cT0ZJ {
    background-color: #ffffff;
    text-align: center;
    border-radius: 15px;
    padding: 1% 7% 1% 7%;
  }
  .dashboard_whiteBackground__lI8jd {
    margin: -1.8% 0% 0% 20%;
  }

  .dashboard_div3__cT0ZJ h1 {
    font-weight: bold;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    margin-top: 20px;
  }

  .dashboard_div3__cT0ZJ p {
    font-weight: bold;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
  }
  .dashboard_mainDiv__27adT {
    display: -webkit-flex;
    display: flex;
  }

  .dashboard_div4__l90ZU {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 1% 5% 2% 5%;
    margin-left: 3%;
  }

  .dashboard_div4__l90ZU h1 {
    font-weight: bold;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    margin-top: 20px;
  }

  .dashboard_div4__l90ZU p {
    font-weight: bold;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
  }

  .dashboard_div5__3Az5U {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 1% 2% 1% 2%;
    margin-left: 3%;
  }

  .dashboard_div5__3Az5U h1 {
    font-weight: bold;

    color: #1e118d;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    margin-top: 20px;
  }

  .dashboard_div5__3Az5U p {
    font-weight: bold;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    margin-top: -42px;
  }

  .dashboard_imgIcon__1KR8- {
    position: absolute;
    margin-left: 12%;
    margin-top: -7%;
    cursor: pointer;
  }
}

@media screen and (max-width: 1368px) and (min-width: 745px) {
  .dashboard_imgIcon__1KR8- {
    position: absolute;
    margin-left: 13%;
    margin-top: -7%;
    cursor: pointer;
  }

  .dashboard_div2__1n6tB img {
    width: 60%;
    height: 11rem;
    margin: 5% 0% 0% 20%;
    object-fit: contain;
    background-color: white;
  }
  .dashboard_whiteBackground__lI8jd {
    margin: -2% 0% 0% 20%;
  }
}
@media screen and (max-width: 775px) {
  .dashboard_div2__1n6tB img {
    width: 100%;
    height: 21vh;
    margin: 20px 20px 20px -22px;
  }
  .dashboard_whiteBackground__lI8jd {
    width: 100vw;
    height: 0%;
    margin: -10.5% 0% 0% -12%;
    background-color: #ffffff;
  }
  .dashboard_rows__1WFfx {
    margin-left: -2%;
    margin-top: 6%;
  }
  .dashboard_div3__cT0ZJ {
    height: 120px;
  }
  .dashboard_div4__l90ZU {
    height: 120px;
  }
  .dashboard_div5__3Az5U {
    margin-left: -1%;
    margin-top: 40%;
    height: 130px;
    position: absolute;
  }
  .dashboard_imgIcon__1KR8- {
    margin-left: 96%;
    margin-top: -50%;
    cursor: pointer;
    position: absolute;
  }
}

@media screen and (max-width: 1080px) {
  .dashboard_rows__1WFfx {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 3%;
    margin-left: 0;
  }
  .dashboard_imgIcon__1KR8- {
    position: absolute;
    margin-left: 17%;
    margin-top: -13%;
    cursor: pointer;
  }
  .dashboard_headerStyle__3USVh {
    padding: 0;
  }
}

.profile_container__2kj1M {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}

.profile_para__2FTk5 {
  color: #24242e;
  font-weight: 600;
}

.profile_background_img__BJXXO {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.profile_centered_text__1KRZ1 {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.profile_centered_button__2aQxA {
  opacity: 1;
}

.profile_sectionStyle__2oJg0 {
  display: grid;
  grid-template-columns: 10% 90%;
}

.profile_headerStyle__1Ecld {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.profile_topsomepagewrapper__2l_wz {
  margin: 1% 11%;
}

.profile_toprow__1B4gh {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.profile_topcolumn__dAbnh {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.profile_editbtn__3eM-9 {
  text-align: right;
}

.profile_centered__3fF0A {
  display: -webkit-flex;
  display: flex;
  -webkit-filter: drop-shadow(0px 3px 8px #00000015);
          filter: drop-shadow(0px 3px 8px #00000015);
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
}

.profile_line__Lolhm {
  margin: 0% 0% 2% -5%;
  opacity: 0.25;
}

.profile_top__J30dr {
  padding: 25px 0px 0px 35px;
  width: 35vw;
}

.profile_top__J30dr p {
  font-size: 14px;
  color: #24242e;
  margin: 5px 0px 5px 0px;
}

.profile_orgimg__3DbfP {
  height: 21.9vh;
  width: 11.48vw;
  object-fit: contain;
}

.profile_somepagewrapper__3toMv {
  margin: 2% 4%;
}

.profile_somepagewrapper2__3kNyS {
  margin: 0% 4%;
}

.profile_row__2TDHD {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.profile_column__GjtVZ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.profile_orangecolumn__1WORE {
  background-color: orange;
  /* height: 100px; */
}

.profile_bluecolumn__K5ZRs {
  background-color: blue;
  /* height: 100px; */
}

.profile_greencolumn__1bS9Y {
  background-color: green;
  /* height: 100px; */
}

.profile_doublecolumn__Y5NsO {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex: 2 1;
          flex: 2 1;
}

.profile_label1__3PULm {
  font-size: 0.9rem;
  opacity: 0.7;
  color: #000000;
  padding: 1% 0 0px 0px;
}

.profile_input__1f3Xl {
  margin-top: 2%;
  opacity: 1;
  text-align: justify;
  color: #0b2342;
  font-size: 0.9rem;
}
@media screen and (max-width: 775px) {
  .profile_centered__3fF0A {
    display: -webkit-flex;
    display: flex;
    -webkit-filter: drop-shadow(0px 3px 8px #00000015);
            filter: drop-shadow(0px 3px 8px #00000015);
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 90%;
    left: 5%;
    height: 75%;
    background-color: #ffffff;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .profile_column__GjtVZ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .profile_para__2FTk5 {
    margin-left: -30%;
  }
  .profile_topsomepagewrapper__2l_wz {
    margin: 1% 8%;
  }
  .profile_top__J30dr {
    padding: 25px 0px 0px 14px;
  }
  .profile_orgimg__3DbfP {
    width: 72.48vw;
  }
}

.EditProfile_container__JmM9- {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}

.EditProfile_para__27wyQ {
  color: #24242e;
  font-weight: 600;
}

.EditProfile_sectionStyle__3Z-b3 {
  display: grid;
  grid-template-columns: 10% 90%;
}

.EditProfile_headerStyle__xea_W {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.EditProfile_topsomepagewrapper__1KR0h {
  margin: 1% 11%;
}

.EditProfile_toprow__PaQme {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.EditProfile_topcolumn__3umEN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.EditProfile_editbtn__3ssyL {
  text-align: right;
}

.EditProfile_centered__1S4o1 {
  display: -webkit-flex;
  display: flex;
  -webkit-filter: drop-shadow(0px 3px 8px #00000015);
          filter: drop-shadow(0px 3px 8px #00000015);
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
}

.EditProfile_line__1EyVm {
  margin: 0% 0% 2% -5%;
  opacity: 0.25;
}

.EditProfile_top__3V0yq {
  padding: 25px 0px 0px 35px;
  position: relative;
}

.EditProfile_top__3V0yq p {
  font-size: 14px;
  color: #24242e;
  margin: 5px 0px 5px 0px;
}

.EditProfile_img2__2QxR_ {
  width: 40px;
  position: absolute;
  height: 40px;
  bottom: -7%;
  left: 48%;
}

.EditProfile_orgimg__C64FZ {
  min-height: 9em;
  height: 9em;
  width: 16em;
  object-fit: contain;
}

.EditProfile_somepagewrapper__22m1F {
  margin: 2% 4%;
  width: 100%;
}

.EditProfile_somepagewrapper2__xm6PS {
  margin: 0% 4%;
}

.EditProfile_row__JaCnJ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.EditProfile_column__1UXLa {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.EditProfile_orangecolumn__3rE-v {
  background-color: orange;
  /* height: 100px; */
}

.EditProfile_bluecolumn__1AOqK {
  background-color: blue;
  /* height: 100px; */
}

.EditProfile_greencolumn__3N-NB {
  background-color: green;
  /* height: 100px; */
}

.EditProfile_doublecolumn__jSQ_x {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex: 2 1;
          flex: 2 1;
}

.EditProfile_upimg__v7e89 {
  margin-left: -16.5em;
  margin-top: 7.5rem;
  width: 2.5rem;
  position: absolute;
}

.EditProfile_label1__2q5PX {
  font-size: 0.9rem;
  opacity: 0.7;
  width: 85%;
  color: #000000;
  /* padding: 17px 0 5px 15px; */
}

select {
  cursor: pointer;
}

.EditProfile_input__aQ2Zg,
.EditProfile_inputName__3VZcH {
  width: 85%;
  height: 35px;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1pt solid #eaeaea;
  color: #0b2342;
  font-size: 0.9rem;
  padding: 0% 0% 0% 4%;
  margin: 2% 0% 4% 0%;
  opacity: 1;
  color: #0b2342;
}

.EditProfile_inputName__3VZcH:disabled {
  background-color: #999 !important;
  opacity: 0.4;
}

.EditProfile_input__aQ2Zg:focus {
  outline: none;
}

.EditProfile_input__aQ2Zg::-webkit-outer-spin-button,
.EditProfile_input__aQ2Zg::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.EditProfile_input__aQ2Zg[type="number"] {
  -moz-appearance: textfield;
}

.EditProfile_input2__X5JEw {
  width: 85%;
  height: 100px;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1pt solid #eaeaea;
  color: #0b2342;
  font-size: 0.9rem;
  padding: 0% 0% 0% 4%;
  margin: 2% 0% 4% 0%;
  opacity: 1;
  color: #0b2342;
  resize: none;
}

.EditProfile_input2__X5JEw:focus {
  outline: none;
}

.EditProfile_errorMsg__3oDI2 {
  font-size: 0.7rem;
  color: red;
  padding: 0 0 2% 0;
}

.EditProfile_changeDiv__1Sini {
  display: block;
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 5% 35%;
  z-index: 150;
  position: absolute;
}

.EditProfile_phonediv__2lrvM {
  display: -webkit-flex;
  display: flex;
  /* margin-top: 3%; */
}

.EditProfile_phonedivleft__32zCf {
  -webkit-flex: 0 0 12%;
          flex: 0 0 12%;
  max-width: 15%;
  margin: 1.5% 0 0 0;
}

.EditProfile_phonedivright__3Qzi4 {
  -webkit-flex: 0 0 80%;
          flex: 0 0 80%;
  max-width: 85%;
  margin: 0 0 0 5%;
}
@media screen and (max-width: 775px) {
  .EditProfile_centered__1S4o1 {
    width: 90%;
    left: 5%;
    top: 25%;
  }
  .EditProfile_top__3V0yq {
    padding: 25px 0px 0px 12px;
  }
  .EditProfile_editbtn__3ssyL {
    display: -webkit-flex;
    display: flex;
    margin-top: 1rem;
  }
  .EditProfile_orgimg__C64FZ {
    width: 74vw;
  }
  .EditProfile_upimg__v7e89 {
    margin-left: -9.5em;
  }
  .EditProfile_column__1UXLa {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
  }
  .EditProfile_para__27wyQ {
    margin-left: -30%;
  }
  .EditProfile_topsomepagewrapper__1KR0h {
    margin: 0% 8%;
  }
  .EditProfile_changeDiv__1Sini {
    margin: 5% 2%;
  }
  .EditProfile_img2__2QxR_ {
    width: 40px;
    position: absolute;
    height: 40px;
    bottom: 67%;
    left: 40%;
  }
}

.cards_maincontainer__1T1vs {
  background-color: white;
  height: 14rem;
}

.cards_maincard__H6Yfr {
  display: grid;
  grid-template-rows: 0px 150px;
  margin-left: 10px;
}

.cards_card__4E0sM {
  border: 1px solid #cfcfd2;
  margin: 15px 0px 0px 0px;
  padding: 16px;
  height: 158px;
  width: 94%;
}

.cards_modal_container__18w1t {
  color: #333333;
  box-shadow: 0 0px 2px 2px #00000029;
  background-color: #ffffff;
  border-radius: 12px 0px 12px 12px;
  font-size: 9pt;
  margin: 0px 0px 0px -70px;
  padding-left: 1%;
  width: 70px;
  height: 40px;
  opacity: 1;
  position: absolute;
}

.cards_openSquare__8uMth {
  margin-left: 1px;
  margin-top: 16px;
  width: 25px;
  height: 25px;
  background-image: -webkit-linear-gradient(-45deg, #36b453 50%, #ffffff 50%);
}

.cards_closeSquare__1fDzl {
  margin-left: 1px;
  margin-top: 16px;
  width: 25px;
  height: 25px;
  background-image: -webkit-linear-gradient(-45deg, #eb1a13 50%, #ffffff 50%);
}

.cards_box1__3v62E {
  width: 80%;
}

.cards_heading__11fTX {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 50px;
}
.cards_constantImg__29XPj {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: -10px;
  cursor: pointer;
}
.cards_constantImg1__xRuIh {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.cards_lefthead__11PPm {
  font-size: 0.9rem;
  width: 94%;
  font-weight: 700;
  overflow: hidden;
}

.cards_lefthead__11PPm p {
  margin: 0px 0px 0px 4px;
}

.cards_lefthead__11PPm p {
  opacity: 0.6;
  color: #333333;
  font-size: 8px;
  margin: 5px;
}

.cards_shelf__2ZmNo {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 93%;
  margin-bottom: 0.8%;
  margin-left: 1%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.cards_shelf__2ZmNo:last-child {
  margin-bottom: 0;
}

.cards_labelbox__1KJlD {
  -webkit-flex: 0 0 35%;
          flex: 0 0 35%;
  opacity: 0.6;
  color: #333333;
  font-size: 12px;
}

.cards_valuebox__2u67P {
  -webkit-flex: 0 0 65%;
          flex: 0 0 65%;
  opacity: 1;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}

.cards_detail__2ZOmN {
  width: 38px;
}

.cards_detail__2ZOmN img {
  width: 24px;
  height: 25px;
  left: 665px;
  top: 44px;
  opacity: 1;
  cursor: pointer;
}

.cards_more__2a6Ql {
  width: 38px;
  cursor: pointer;
}

.cards_more__2a6Ql img {
  cursor: pointer;
  z-index: 1;
}
.cards_lefthead1__3gT9F {
  width: 94%;
  font-weight: 700;
}

.cards_table__2LIHm {
  font-size: 15px;
  min-width: 548px;
}

.cards_table__2LIHm tbody {
  margin: 25px;
}

.cards_box2__w8NMT {
  background-color: white;
}

.cards_lefthead__11PPm p {
  font-size: 1rem;
  opacity: 1;
}

.cards_lefthead__11PPm p span {
  font-size: 0.6rem;
  display: block;
  opacity: 0.6;
}

.cards_card_image__2z8Co img {
  height: 10rem;
  width: 10rem;
  margin-top: -11.7rem;
  float: right;
}

.cards_card_info__1oBcZ {
  width: 30px;
}

.cards_appliedImg__2Phsl {
  height: 10%;
}

.cards_left__3Abk2 img {
  width: 45px;
  height: 40px;
  margin-top: -3px;
}

.cards_left__3Abk2 p {
  color: #1e118d;
  margin-left: 62px;
  margin-top: -40px;
  font-size: 15px;
  font-weight: bold;
}

.cards_eye__UTZNS {
  margin-right: 4%;
  margin-top: -14%;
}

.cards_right__kqo60 img {
  width: 32px;
  height: 34px;
  margin-left: 257px;
  margin-top: -3px;
}

.cards_right__kqo60 p {
  color: #747474;
  opacity: 1;
  font-size: 13px;
  margin-left: 300px;
  margin-top: -33px;
}

@media screen and (max-width: 1500px) {
  .cards_lefthead__11PPm {
    width: 84%;
  }
  .cards_lefthead1__3gT9F {
    width: 83%;
  }
}
@media screen and (max-width: 1300px) {
  .cards_lefthead__11PPm {
    width: 80%;
  }
  .cards_lefthead1__3gT9F {
    width: 78%;
  }
}

@media screen and (max-width: 1150px) {
  .cards_lefthead__11PPm {
    width: 76%;
    overflow: hidden;
  }
  .cards_lefthead1__3gT9F {
    width: 74%;
  }
  
}

@media screen and (max-width:767px){
  .cards_box1__3v62E{
    width: 100%;
  }
  .cards_lefthead__11PPm p{
    font-size: 3vw;
  }
  .cards_card_image__2z8Co img{
    height: 5rem;
    width: 5rem;
    margin-top: -8.7rem;
    float: right;
  }
  .cards_shelf__2ZmNo{
    width: 69%;
  }
}

.opportunities_container__1xNXW {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100vh;
  overflow: hidden;
}

/* .heading {
    height: 60px;
    display: grid;
    grid-template-columns: 200px 740px 300px;
}
.arrow img {
    width: 10%;
    margin: 35px 0px 0px 160px;
}
.text p {
    font-weight: bold;
    margin: 30px;
    margin: 30px 0px 0px 0px;
}*/

.opportunities_filter__nCGCS {
  height: 50px;
  margin: -10px 0 0px 175px;
  cursor: pointer;
}

.opportunities_centered_text__1EXU3 {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.opportunities_topHeading__2PN5w {
  width: 100%;
  height: 5%;
  margin: 2% 0 0 0;
  margin: 2% 0 0 0;
  padding: 0% 11%;
}

.opportunities_labelImg__1gwem {
  height: 14px;
  cursor: pointer;
}

.opportunities_label__3YBtx {
  /* float: left; */
  color: #24242e;
  font-weight: 600;
  font-size: 12pt;
  margin: 0px 0 35px 13px;
}

.opportunities_nodata__2C74p {
  overflow: hidden;
  font-weight: 500;
  width: 100%;
  height: auto;
  max-height: 75%;
  background-color: #ffffff;
  top: 19%;
  left: 20%;
}

.opportunities_nodata__2C74p img {
  padding: 2%;
  display: block;
  margin: 2% auto;
  width: 47%;
  overflow: hidden;
}

.opportunities_centered_button__3bLVR {
  opacity: 1;
}

.opportunities_sectionStyle__2GmGd {
  display: grid;
  grid-template-columns: 10% 90%;
}

.opportunities_headerStyle__1nBX7 {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
  width: 100vw;
}

.opportunities_centered__281l3 {
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.opportunities_header__Vx5Fp {
  width: 70.3%;
  height: 14%;
  background-color: #f7f7f7;
  position: absolute;
  top: 10%;
  left: 19.1%;
}

.opportunities_footer__2Unmk {
  width: 70.3%;
  height: 14%;
  background-color: #f7f7f7;
  position: absolute;
  top: 94%;
  left: 19.1%;
}

.opportunities_keyword__30-PH {
  width: 94%;
  height: 42%;
  border: 1px solid #dbdbdb;
  background-color: white;
  margin: 10px;
  display: -webkit-flex;
  display: flex;
}

.opportunities_keyword__30-PH img {
  width: 18px;
  padding: 10px;
}

.opportunities_input__2pfE4 {
  border: none;
  width: 100%;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
}

.opportunities_input__2pfE4:focus {
  outline: none;
}

.opportunities_options__6krz9 img {
  width: 38px;
  float: right;
  margin: -48px 0px 0px 0px;
}

.opportunities_changeDiv__z9gTT {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  /* height: 52%; */
  left: 20%;
  z-index: 150;
  margin: 10% 0% 0 5%;
  /* top: 30%; */
  position: absolute;
  box-shadow: 20pt 23pt 100pt;
}
.opportunities_changeDiv3__gD3a6 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 85%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 8%;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  /* position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 98, 98, 0.8); */
}

.opportunities_backdrop__P20E8 {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 98, 98, 0.8);
}

.opportunities_addBlurr__eXsI9 {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

/* @media screen and (max-width: 480px) {
    .container {
        background-color: #F7F7F7;
        background-color: red;
        display: grid;
        grid-template-rows: 4rem 37rem;
        height: 100%;
        width: 100%;
    }
    .sidemenu {
        display: none;
    }
    .centered {
        width: 70%;
        height: 75%;
        background-color: #f7f7f7;
        position: absolute;
        top: 19%;
        left: 20%;
        overflow-y: auto;
    }
} */
@media screen and (max-width: 767px) {
  .opportunities_centered__281l3 {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 90%;
    height: auto;
    max-height: 76%;
    background-color: #ffffff;
    position: absolute;
    top: 18%;
    left: 5%;
  }

  .opportunities_label__3YBtx {
    width: 100vh;
    margin: 1% 0% 0% 1%;
  }
  .opportunities_Initial__pXWh2 {
    width: 100vh;
    margin: 1% 0% 0% -18%;
  }
  .opportunities_changeDiv3__gD3a6 {
    width: 85%;
    left: 5%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1080px) {
  .opportunities_centered__281l3 {
    width: 85%;
    height: 82%;
    background-color: #ffffff;
    position: absolute;
    top: 11%;
    left: 13%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .opportunities_headerStyle__1nBX7 {
    padding: 0;
  }
}

.managementCard_modal_container__LetgW {
  position: absolute;
  background-color: #ffffff;
  border-radius: 10% 0 10% 10%;
  border: 1px solid #e5e5e5;
  margin: 0% 0% 0% 24px;
  font-size: 9pt;
  -webkit-filter: drop-shadow(0px 3px 6px #00000029);
          filter: drop-shadow(0px 3px 6px #00000029);
  padding: 2% 7%;
}

.managementCard_modal_container__LetgW p {
  margin: 7% 0% 7% 0%;
  color: #333333;
  opacity: 1;
  font-weight: 400;
  cursor: pointer;
}
.managementCard_modal_label__1TVjJ:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.managementCard_maincard__21VrR {
  display: grid;
  grid-template-rows: auto auto;
  margin: 3% 4% 3% 0;
  /* grid-template-rows: 10px 150px;
    margin: 16px;
    margin-left: 3px; */
}

.managementCard_card__25Zdv {
  border: 1px solid #cfcfd2;
  margin: 2% 0% 0% 5%;
  position: relative;
  border-radius: 2%;
  height: 13rem;
  box-shadow: 0px 0px 6pt #675aff3d;
  overflow: hidden;
}

/* .card:hover {
    box-shadow: 20px 20px 18px -8px #00000015;
    transform: scale(1, 1);
} */

.managementCard_activeSquare__1Xz60 {
  width: 5%;
  position: absolute;
  padding: 8% 3% 0 0;
  border-top-left-radius: 4%;
  background-image: -webkit-linear-gradient(-45deg, #36b453 50%, #ffffff 50%);
}

.managementCard_inactiveSquare__2zwIX {
  width: 5%;
  position: absolute;
  padding: 8% 3% 0 0;
  border-top-left-radius: 4%;
  background-image: -webkit-linear-gradient(-45deg, #ffb031 50%, #ffffff 50%);
}

.managementCard_upperdiv__3lGcB {
  /* position: absolute; */
  padding: 4% 10% 0% 10%;
  /* height: 65px; */
  /* background-color: red; */
  display: grid;
  grid-template-columns: repeat(2, 1fr) 0px;
}

.managementCard_headleft__ZhoiH {
  background-color: white;
}

.managementCard_headleft__ZhoiH img {
  height: 79px;
  width: 85px;
  border-radius: 54%;
  margin-top: 7px;
}

.managementCard_headright__28V4J {
  background-color: white;
  display: grid;
  /* grid-template-rows: 150px 150px; */
  grid-template-columns: 50px 50px;
}

.managementCard_img1__2emQw img {
  margin-left: 124px;
  background-color: white;
  height: 19px;
}

.managementCard_img2__1FQ9Z img {
  margin-left: 48px;
  background-color: white;
  height: 19px;
}

.managementCard_lowerdiv__2VUzX {
  background-color: white;
  /* width: 266px; */
  padding: 0% 0% 0% 10%;
}

/* .detail {
    display: flex;
    flex-direction: row;
    width: 110%;
    height: 80px;
    background-color: saddlebrown;
} */

.managementCard_leftdetail__2KK0F {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.managementCard_table__21CFJ {
  min-width: 220px;
  /* margin-top: -13px; */
  /* margin: 25px 0px; */
}

.managementCard_table__21CFJ tbody {
  margin: 25px;
}

/* .detail {
    display: flex;
    flex-direction: column;
    height: 20%;
} */

/* .login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
} */

.managementCard_category__tDKOm {
  /* display: flex; */
  opacity: 0.6;
  color: #333333;
  font-size: 0.9rem;
  /* line-height: 4%; */
}

.managementCard_category1__qsjAx {
  opacity: 0.6;
  color: #333333;
  font-size: 0.8rem;
  /* line-height: 4%; */
}

.managementCard_value__2L7eP {
  opacity: 1;
  color: #333333;
  font-size: 0.9rem;
  font-weight: bold;
}

@media screen and (max-width: 1550px) {
  .managementCard_img1__2emQw img {
    margin-left: 120px;
  }
  .managementCard_img2__1FQ9Z img {
    margin-left: 37px;
  }
}

@media screen and (max-width: 1392px) {
  .managementCard_img1__2emQw img {
    margin-left: 114px;
  }
  .managementCard_img2__1FQ9Z img {
    margin-left: 35px;
  }
}
@media screen and (max-width:575px){
  .managementCard_upperdiv__3lGcB{
    padding: 4% 18% 0% 0%;
  }

}

.Delete_centered__3vke2 {
  /* / background-color: rgb(171, 171, 245); / */
  background-color: whitesmoke;
  margin: 6% 15%;
  position: fixed;
  z-index: 100;
  height: 300px;
  width: 33%;
  border-radius: 15px;
  padding-left: 25px;
}

.Delete_header__6Tfnq {
  display: grid;
  grid-template-rows: repeat(2, 30px) 0px;
  padding: 15px;
}

.Delete_alignImg__3FPQF {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}

.Delete_upper__21wcE img {
  background-color: #fafbfc;
  float: right;
  margin-top: 2px;
}

.Delete_delete__RamVe img {
  width: 45%;
  margin: -20px 0px 0px 83px;
}

.Delete_delete__RamVe p {
  font-size: 15px;
  letter-spacing: -0.24px;
  color: #333333;
  opacity: 1;
  margin: 3px 0px 0px 65px;
}

.Delete_reset__1oApI {
  background-color: #ffffff;
  border: 1px solid #1e118d;
  opacity: 1;
  border-radius: 10px;
  color: #1e118d;
  padding: 5px;
  float: right;
  margin-left: 370px;
  width: 57px;
}

.Delete_btn__32HNZ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 10rem;
  margin-left: 2rem;
}

.Delete_apply__3GPkB {
  background-color: #1e118d;
  border: 1px solid #1e118d;
  opacity: 1;
  border-radius: 10px;
  color: #ffffff;
  padding: 5px;
  float: right;
  margin-left: 277px;
  width: 57px;
}

.Delete_inputage__xgaeo {
  display: grid;
  grid-template-columns: 48% 48%;
  height: 100%;
  /* / background-color: slateblue; / */
}

/* .inputleft input {
  width: 75%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid #B9B9B9;
  ;
  font-size: 11pt;
  margin: 0px;
} */

.Delete_input1__1CWIZ {
  width: 95%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  font-size: 11pt;
  margin: 0px;
  padding: 3px;
}

.Delete_input1__1CWIZ {
  width: 95%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  font-size: 11pt;
  margin: 0px;
  padding: 3px;
}

.Delete_input1__1CWIZ option {
  color: #0b2342;
  opacity: 1;
}

.Delete_location__2bu73 {
  margin-top: 30px;
}

.Delete_form__1zibM input {
  margin: 0px;
  width: 78%;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  font-size: 11pt;
  margin: 0px;
}

@media screen and (max-width: 700px) {
  .Delete_centered__3vke2 {
    width: 85%;
  }
}

@media screen and (max-width: 1080px) {
  .Delete_centered__3vke2 {
    width: 50%;
    height: 325px;
  }
  .Delete_btn__32HNZ {
    margin-left: 4rem;
  }
  .Delete_headerStyle__2Yq0T {
    padding: 0;
  }
}

.management_container__30nUC {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 100%;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.management_footer__3T7L8 {
  width: 70.3%;
  height: 14%;
  background-color: transparent;
  position: absolute;
  top: 95%;
  left: 19.1%;
}

.management_sectionStyle__1tQgf {
  display: grid;
  grid-template-columns: 10% 90%;
}

.management_headerStyle__c-AYE {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.management_heading__3_BJ5 p {
  font-weight: bold;
  margin: 30px;
  margin-left: 185px;
  margin-top: 23px;
}

.management_subheader__ddszo {
  background-color: rgb(238, 238, 245);
  height: 38px;
  margin-top: 22px;
  margin-left: 135px;
  margin-right: 135px;
  display: grid;
  grid-template-columns: repeat(2, 1fr) 0px;
}

.management_leftheader__gHSXw {
  /* / background-color: white; / */
  margin-left: 30px;
  margin-top: 0px;
  font-weight: bold;
}

.management_rightheader__2o5Gl {
  background-color: rgb(238, 238, 245);
  float: right;
  /* / margin-right: 20px; / */
}

.management_createnew__1Foo_ {
  background-color: #1e118d;
  opacity: 1;
  border-radius: 8px;
  color: white;
  padding: 5px;
  margin-left: 366px;
  cursor: pointer;
}

.management_create_button__2Gmld {
  float: right;
}

/* .centered {
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
} */

.management_centered__25fHh {
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: auto;
  max-height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 20%;
  left: 20%;
}

.management_nodata__mxUzf {
  overflow: hidden;
  font-weight: 500;
  width: 100%;
  height: auto;
  max-height: 75%;
  background-color: #ffffff;
  top: 19%;
  left: 20%;
}

.management_nodata__mxUzf img {
  padding: 2%;
  display: block;
  margin: 2% auto;
  width: 47%;
  overflow: hidden;
}

.management_subcentered__3CEa7 {
  opacity: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
}

.management_column__1F_Dr {
  float: left;
  width: 25%;
  padding: 0 10px;
}

.management_row__3xC4e {
  margin: 0 -5px;
}

.management_row__3xC4e:after {
  content: "";
  display: table;
  clear: both;
}

.management_loader_style__2oUxG {
  margin: 20% auto;
}

.management_changeDiv__1rlkI {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 17% 39%;
  z-index: 150;

  position: absolute;
}
.management_changeDiv1__qI1Nt {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 40%;
  /* / height: 62%; / */
  left: 20%;
  z-index: 150;
  top: 30%;
  left: 30%;
  position: absolute;
}

.management_addBlurr__3s-2C {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

.management_topHeading__35lfY {
  text-align: center;
  /* / position: relative; / */
  margin: 2% 0 0 0;
  display: grid;
  grid-template-columns: 40% 82%;
}

.management_btn_css__Xc-3U {
  margin-top: -1.5%;
  display: -webkit-flex;
  display: flex;
  margin-left: 38%;
}
.management_button__2bLdu {
  margin-top: -10px;
}

.management_labelImg__2fm7n {
  height: 14px;
}
.management_filter1__TJdgm {
  position: absolute;
  background-color: #ffffff;
  border-radius: 10% 0 10% 10%;
  border: 1px solid #e5e5e5;
  margin: -1% 0% 0% -24px;
  font-size: 9pt;
  -webkit-filter: drop-shadow(0px 3px 6px #00000029);
          filter: drop-shadow(0px 3px 6px #00000029);
  padding: 1% 2%;
  z-index: 5;
}

.management_filter1__TJdgm > p:hover {
  color: blue;
}

.management_filter1__TJdgm p {
  margin: 7% 0% 7% 0%;
  color: #333333;
  opacity: 1;
  font-weight: 400;
  cursor: pointer;
}

.management_label__1il04 {
  /* / float: left; / */
  color: #24242e;
  font-weight: 600;
  font-size: 16px;
  margin: 0px 0 35px 26px;
}

.management_button__2bLdu {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 9.8vw;
  height: 5.32vh;
  margin: 6px 0px 0px 0px;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 1vw;
  cursor: pointer;
}
.management_changeDiv__1rlkI {
  margin: 15% 35%;
}
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1380px) and (min-width: 768px) {
  .management_container__30nUC {
    display: grid;
    grid-template-rows: 4rem 100%;
  }
}

@media screen and (max-width: 767px) {
  .management_subcentered__3CEa7 {
    opacity: 1;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
  }

  .management_centered__25fHh {
    margin-top: 5%;
  }
  .management_label__1il04 {
    font-size: 3vw;
  }
}

@media screen and (max-width: 700px) {
  subcentered {
    grid-template-columns: repeat(1, 1fr);
  }
  .management_centered__25fHh {
    margin-top: 5%;
  }
  .management_changeDiv1__qI1Nt {
    width: 98%;
    left: 1%;
  }
  .management_changeDiv__1rlkI {
    margin: 15% 1%;
  }
  .management_button__2bLdu {
    position: absolute;
    top: 19%;
    left: 71%;
    width: 16.8vw;
    font-size: 2.3vw;
  }
  .management_topHeading__35lfY {
    grid-template-columns: 74% 0%;
    text-align: left;
    margin: 2% 0 0 -4%;
    display: grid;
  }
  .management_label__1il04 {
    font-size: 5vw;
    margin: 0px 0 35px 1px;
  }
  .management_centered__25fHh {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 90%;
    height: auto;
    max-height: 69%;
    background-color: #ffffff;
    position: absolute;
    top: 24%;
    left: 5%;
  }
}

@media screen and (max-width: 1080px) {
  .management_centered__25fHh {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 84%;
    height: 53%;
    max-height: 75%;
    background-color: #ffffff;
    position: absolute;
    top: 18%;
    left: 13%;
  }
  .management_button__2bLdu {
    box-shadow: 0 0px 2px 2px #0000001f;
    background-color: #110766;
    font-weight: 500;
    width: 10.8vw;
    height: 3.32vh;
    margin: 6px 0px 0px 0px;
    border-radius: 14px;
    border: 1px solid #110766;
    opacity: 1;
    color: #ffffff;
    text-align: center;
    font-size: 1.5vw;
    cursor: pointer;
  }
  .management_btn_css__Xc-3U {
    margin-top: -2.5%;
    display: -webkit-flex;
    display: flex;
    margin-left: 38%;
  }
  .management_topHeading__35lfY {
    display: -webkit-flex;
    display: flex;
    margin: 10% 0 0 15px;
  }
  .management_headerStyle__c-AYE {
    padding: 0;
  }
}

.creation_container__1TY_z {
  display: block;
  background-color: #fafbfc;
  width: 100%;
  height: 110%;
  border-radius: 30px;
}

.creation_closeModal__3Lz34 {
  float: right;
  width: 25px;
  height: 25px;
  margin: 0 5% 0 0;
  cursor: pointer;
}

.creation_para__1lN8y {
  padding: 5% 0 0 9%;
}

.creation_formStyle__3Ua2I {
  padding: 0 1% 6% 5%;
}

.creation_formStyle__3Ua2I input[type="text"] {
  display: -webkit-flex;
  display: flex;
  width: 70%;
  height: 25px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  border-radius: 8pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 0.9rem;
}
.creation_formStyle__3Ua2I input:focus {
  outline: none;
}

.creation_allBtn2__HMKHF {
  float: right;
  padding: 3% 12% 7% 0;
}

.creation_mail2__1M1AT {
  font-size: 0.9rem;
}
.creation_signin2__Iq_Or {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 134px;
  height: 35px;
  margin: 6px 0px 0px 14px;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
}

.creation_errorMsg__22H8N {
  /* margin: 2% 2% 2% 11%; */
  color: red;
  font-size: 0.8rem;
  font-weight: normal;
}

.StudentDashboard_container__1ox_9 {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
  overflow-y: auto;
}

.StudentDashboard_background_img__3v36h {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.StudentDashboard_button__2zseB {
  float: right;
  margin: 1% 0px 0px 75%;
}

.StudentDashboard_centered_text__1Atk3 {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.StudentDashboard_centered_button__1q_tM {
  opacity: 1;
}

.StudentDashboard_sectionStyle__SK7EI {
  display: grid;
  grid-template-columns: 10% 90%;
}

.StudentDashboard_headerStyle__3F2zH {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.StudentDashboard_centered__15dTw {
  width: 70%;
  height: 75%;
  background-color: #f7f7f7;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-y: auto;
}

.StudentDashboard_profilesuccesscss__MHbLH {
  margin: 3% 0 0 1%;
  font-size: 0.8rem;
  font-weight: 400;
  color: #707070;
}

.StudentDashboard_profilesuccessimg__1btWa {
  width: 60% !important;
  margin: 15% 0 0 10%;
}

.StudentDashboard_content__3MgTZ {
  display: grid;
  grid-template-rows: 20% 70%;
}

.StudentDashboard_maindiv__1x6YU {
  /* background-color: red; */
  font-weight: 600;
  font-size: 20px;
  color: #333333;
  opacity: 1;
  /* font-family: 'Poppins', sans-serif; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* justify-content: center;
        text-align: center; */
  /* margin-top: 30px; */
  height: 100%;
  width: 100%;
}

.StudentDashboard_image__Vepb4 {
  background-color: white;
}

.StudentDashboard_image__Vepb4 img {
  width: 30%;
  margin: 75px 0px 0px 328px;
}

.StudentDashboard_box1__1xcXD {
  width: 96%;
  height: 22%;
  padding: 2%;
  height: -2%;
  /* margin: 3% 0% 0% 0%; */
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  /* border: 1px solid black; */
}

.StudentDashboard_box1__1xcXD img {
  width: 13%;
  height: 100%;
}

.StudentDashboard_midbox1__iCn2p {
  width: 100%;
}

.StudentDashboard_profile__2oMtU {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.StudentDashboard_profile__2oMtU p2 {
  margin: 19px 0% 0% 1%;
  color: #707070;
  opacity: 1;
  font-size: 15px;
}

.StudentDashboard_profile__2oMtU h1 {
  margin: 9px 0% 0% 2%;
}

.StudentDashboard_profile__2oMtU h2 {
  margin: 15px 0% 0% 0%;
  font-size: 22px;
}

.StudentDashboard_greydiv__XZZsp {
  height: 8%;
  width: 90%;
  margin: -1% 0% 0% 2%;
  border-radius: 8px;
  background-color: #e6e7e8;
}

.StudentDashboard_greydiv__XZZsp img {
  width: 8%;
  height: 625%;
  float: right;
  margin: -3% -5% 0% 0%;
}

.StudentDashboard_box2__36AtD {
  width: 100%;
  height: 25%;
  height: -2%;
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.StudentDashboard_box2heading__j87F3 {
  font-size: 1rem;
  margin: 2% 0% 1% 2%;
  font-weight: 700;
}

.StudentDashboard_leftbox2__Rz3Uz {
  margin: 1%;
  /* background-color: pink; */
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.StudentDashboard_leftbox2__Rz3Uz h1 {
  /* width: 11%; */
  font-size: 45px;
  /* float: right; */
  /* justify-content: center; */
  margin: 0% 0% 0% 42%;
}

.StudentDashboard_leftbox2__Rz3Uz p {
  margin: 0% 0% 0% 0%;
  font-size: 13px;
  color: #24242e;
  opacity: 0.6;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.StudentDashboard_line__11FT2 {
  margin: 3%;
}

.StudentDashboard_rightbox2__2Ne1b {
  margin: 1%;
  /* background-color: grey; */
  width: 50%;
}

.StudentDashboard_rightbox2__2Ne1b h1 {
  /* width: 11%; */
  font-size: 45px;
  /* float: right; */
  /* justify-content: center; */
  margin: 0% 0% 0% 42%;
}

.StudentDashboard_rightbox2__2Ne1b p {
  margin: 0% 0% 0% 0%;
  font-size: 13px;
  color: hsl(240, 12%, 16%);
  opacity: 0.6;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.StudentDashboard_midbox1__iCn2p table {
  font-size: 0.7rem;
  font-weight: 400;
  margin: 0% 0% 0% 3%;
  opacity: 1;
  color: #707070;
}

.StudentDashboard_midbox1__iCn2p p1 {
  margin: 24px;
}

.StudentDashboard_heading__3UQT7 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.StudentDashboard_link__2l89b {
  float: right;
  margin: -3% 2% 0% 0%;
  font-size: 15px;
  cursor: pointer;
}

.StudentDashboard_link__2l89b :hover {
  color: #110766;
  font-weight: 700;
}

.StudentDashboard_box3__ONgNw {
  width: 100%;
  height: 39%;
  height: -2%;
  margin: 0% 0% 0% 0%;
  background-color: #ffffff;
  /* border: 1px solid black; */
}
.StudentDashboard_skillls__2v5UO {
  display: -webkit-flex;
  display: flex;
}
.StudentDashboard_box3heading__3N1FM {
  font-size: 1rem;
  margin: 2% 0% 1% 2%;
  font-weight: 700;
}
@media screen and (max-width: 1600px) {
  .StudentDashboard_greydiv__XZZsp img {
    margin: -3% -5% 0% 0%;
  }
  .StudentDashboard_button__2zseB {
    margin: 1% 0px 0px 75%;
  }
}

@media screen and (max-width: 1450px) {
  .StudentDashboard_button__2zseB {
    margin: 0.5% 0px 0px 75%;
  }
}
@media screen and (max-width: 1400px) {
  .StudentDashboard_greydiv__XZZsp img {
    margin: -4.5% -5% 0% 0%;
  }
}
@media screen and (max-width: 1150px) {
  .StudentDashboard_button__2zseB {
    margin: 1rem 0px 0px 65%;
  }
}
@media screen and (max-width: 575px) {
  .StudentDashboard_headerStyle__3F2zH {
    width: 100vw;
  }
  .StudentDashboard_greydiv__XZZsp img {
    margin: -9.5% -5% 0% 0%;
  }
  .StudentDashboard_box1__1xcXD {
    height: 43%;
  }
  .StudentDashboard_box1__1xcXD img {
    width: 13%;
    height: 35%;
    margin-top: 10%;
  }
  .StudentDashboard_centered__15dTw {
    width: 90%;
    left: 5%;
    top: 20%;
    overflow-x: hidden;
  }
  .StudentDashboard_midbox1__iCn2p p1 {
    margin: 10px;
  }
  .StudentDashboard_profilesuccesscss__MHbLH {
    margin: 10% 0 0 1%;
  }
  .StudentDashboard_greydiv__XZZsp img {
    width: 19%;
    height: 310%;
    margin-top: -7%;
  }
  .StudentDashboard_skillls__2v5UO {
    display: block;
  }
}
/* media query for ipad */
@media screen and (max-width: 1080px) {
  .StudentDashboard_headerStyle__3F2zH {
    padding: 0;
  }
  .StudentDashboard_centered__15dTw {
    top: 14%;
    height: 85%;
    left: 12%;
    width: 85%;
  }
  .StudentDashboard_container__1ox_9 {
    height: 100vh;
  }
  .StudentDashboard_greydiv__XZZsp img {
    height: 70px;
  }
  .StudentDashboard_box1__1xcXD {
    height: 19%;
  }
}

.cards_maincontainer__3qgNn {
  background-color: white;
  height: 14rem;
}

.cards_maincard__2icQh {
  display: grid;
  grid-template-rows: 0px 150px;
  margin-left: 10px;
}

.cards_card__1_jEr {
  border: 1px solid #cfcfd2;
  margin: 15px 0px 0px 0px;
  padding: 16px;
  height: 158px;
  width: 94%;
}

/*.card:hover {
    box-shadow: 20px 20px 18px -8px #00000015;
    transform: scale(1, 1);
}*/

.cards_square__33iC5 {
  margin-left: 1px;
  margin-top: 16px;
  width: 25px;
  height: 25px;
  background-image: -webkit-linear-gradient(-45deg, #36b453 50%, #ffffff 50%);
}

.cards_box1__3a2t9 {
  width: 80%;
}

.cards_heading__1Yv3C {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  /* background-color: #1E118D; */
  height: 50px;
}
.cards_constantImg__DtGco {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: -10px;
}
.cards_constantImg1__UbYzv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.cards_lefthead__1cJAx {
  font-size: 0.9rem;
  width: 94%;
  font-weight: 700;
}

.cards_lefthead__1cJAx p {
  margin: 0px 0px 0px 4px;
}

.cards_lefthead__1cJAx p {
  opacity: 0.6;
  color: #333333;
  font-size: 8px;
  margin: 5px;
}

.cards_detail__2b-FV {
  /* background-color: #b63f49; */
  width: 38px;
}

.cards_detail__2b-FV img {
  width: 24px;
  height: 25px;
  left: 665px;
  top: 44px;
  opacity: 1;
}

.cards_more__2lR6y {
  /* background-color: #3a48c5; */
  width: 38px;
  cursor: pointer;
}

.cards_more__2lR6y img {
  cursor: pointer;
  z-index: 1;
}
.cards_lefthead1__JD6QZ {
  width: 94%;
  font-weight: 700;
}

/* .box1 img {
    width: 24px;
    height: 21px;
    left: 665px;
    top: 44px;
    
} */

.cards_table__2_d1w {
  font-size: 15px;
  min-width: 548px;
  /* margin: 25px 0px; */
}

.cards_table__2_d1w tbody {
  margin: 25px;
}

.cards_shelf__3xfNW {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 93%;
  margin-bottom: 0.8%;
  margin-left: 1%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.cards_shelf__3xfNW:last-child {
  margin-bottom: 0;
}

.cards_labelbox__3zFKP {
  -webkit-flex: 0 0 35%;
          flex: 0 0 35%;
  opacity: 0.6;
  color: #333333;
  font-size: 12px;
}

.cards_valuebox__3VKCD {
  -webkit-flex: 0 0 65%;
          flex: 0 0 65%;
  opacity: 1;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}

.cards_box2__1gpPH {
  background-color: white;
}

.cards_card_image__33h5m img {
  height: 10rem;
  width: 10rem;
  margin-top: -11.7rem;
  margin-right: 10px;
  float: right;
}

.cards_card_info__FBhD4 {
  width: 30px;
}

.cards_appliedImg__2tPk3 {
  height: 10%;
}

.cards_left__iig1l img {
  width: 45px;
  height: 40px;
  margin-top: -3px;
}

.cards_left__iig1l p {
  color: #1e118d;
  margin-left: 62px;
  margin-top: -40px;
  font-size: 15px;
  font-weight: bold;
}

.cards_eye__1JNk1 {
  margin-right: 4%;
    margin-top: -14%;
}

.cards_right__1FXzu img {
  width: 32px;
  height: 34px;
  margin-left: 257px;
  margin-top: -3px;
}

.cards_right__1FXzu p {
  color: #747474;
  opacity: 1;
  font-size: 13px;
  margin-left: 300px;
  margin-top: -33px;
}

@media screen and (max-width: 1500px) {
  .cards_lefthead__1cJAx {
    width: 84%;
  }
  .cards_lefthead1__JD6QZ {
    width: 83%;
  }
}
@media screen and (max-width: 1300px) {
  .cards_lefthead__1cJAx {
    width: 80%;
  }
  .cards_lefthead1__JD6QZ {
    width: 78%;
  }
}
@media screen and (max-width: 1150px) {
  .cards_lefthead__1cJAx {
    width: 76%;
  }
  .cards_lefthead1__JD6QZ {
    width: 74%;
  }
}
@media screen and (max-width:575px){
  .cards_box1__3a2t9{
    width: 100%;
  }
  .cards_card_image__33h5m img {
    height: 5rem;
    width: 5rem;
    margin-top: -18vh;
    margin-right: 1px;
  }
}
.SEditProfile_container__o6hC6 {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}

.SEditProfile_sectionStyle__28Pmg {
  display: grid;
  grid-template-columns: 10% 90%;
}

.SEditProfile_headerStyle__11Gte {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}
.SEditProfile_topHeading__1swGp {
  text-align: center;
  /* / position: relative; / */
  margin: -0.2% 0 0 0;
  display: grid;
  grid-template-columns: 50% 62%;
}
.SEditProfile_button__UCa4D {
  display: -webkit-flex;
  display: flex;

  -webkit-flex-direction: row;

          flex-direction: row;
  padding: 3%;
  margin-left: 116%;
}
.SEditProfile_para__3diBu {
  color: #24242e;
  font-weight: 600;
  font-size: 0.9rem;
  margin: 2% 0 0% -38%;
}
.SEditProfile_changeDiv__1zoXj {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;

  margin: 8% 40%;
  z-index: 150;
  /* / top: 10%; / */
  position: absolute;
}
.SEditProfile_firstHeading__1x4_k {
  margin: 2% 20%;
  position: absolute;
}
.SEditProfile_Calendar__2Jbg5 {
  position: absolute !important;
}
.SEditProfile_mainbox__3pKiH {
  overflow-y: auto;
  overflow-x: hidden;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  border: 1px solid #dedede;
}
.SEditProfile_datePicker__1XiBF > div:first-child,
.SEditProfile_datePicker__1XiBF > div > div:first-child {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}

.SEditProfile_date_style__2gGld {
  margin: 0% 0% -1% -7%;
  cursor: pointer;
}
/*  */

.SEditProfile_errorMsg__3Sfpj {
  font-size: 0.7rem;
  color: red;
  float: right;
  margin-right: 11%;
}

.SEditProfile_text__38Ov7 {
  margin-bottom: 10px;
  font-size: 0.9rem;
  /* / color: #0011ff; / */
}

.SEditProfile_div__19zQU {
  display: grid;
  grid-template-columns: 50% 50%;
  /* / background-color: aqua; / */
  height: 70%;
}

.SEditProfile_left__1wn9O {
  /* / background-color: #0B2342; / */
  margin-left: 8%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.SEditProfile_img1__3Ac6Z {
  width: 12rem;
  height: 9rem;
  margin-left: 0%;
  border-radius: 15px;
}

.SEditProfile_uploadImg__1VFdS {
  position: relative;
}

.SEditProfile_img2__2JQmX {
  width: 9%;
  bottom: -8%;
  left: 18%;
  position: absolute;
}

.SEditProfile_heading__Z4J0D {
  font-size: 0.9rem;
  margin-top: 8%;
  margin-left: 0%;
  color: #24242e;
  opacity: 1;
}

.SEditProfile_yourinput__1HUgB {
  width: 83%;
  height: 35%;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  font-size: 0.9rem;
  color: #0b2342;
  padding-left: 4%;
  opacity: 1;
  resize: none;
}

.SEditProfile_yourinput__1HUgB:focus {
  outline: none;
}

.SEditProfile_right__13xgS {
  display: grid;
  grid-template-rows: repeat(10, 1rf);
  margin: 3%;
}

.SEditProfile_phonediv__38937 {
  display: -webkit-flex;
  display: flex;
  /* / margin-top: 3%; / */
}

.SEditProfile_phonedivleft__3caCI {
  -webkit-flex: 0 0 12%;
          flex: 0 0 12%;
  max-width: 15%;
}

.SEditProfile_phonedivright__39HwH {
  -webkit-flex: 0 0 80%;
          flex: 0 0 80%;
  max-width: 85%;
  margin: 0 0 0 5%;
}

.SEditProfile_input1__19nn_ {
  width: 86%;
  height: 36px;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  margin: 1px 0px 0px 1px;
  font-size: 0.9rem;
  color: #0b2342;
  padding: 0px 0px 0px 12px;
}

.SEditProfile_input1__19nn_:focus {
  outline: none;
}

.SEditProfile_input1__19nn_::-webkit-outer-spin-button,
.SEditProfile_input1__19nn_::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.SEditProfile_input1__19nn_[type="number"] {
  -moz-appearance: textfield;
}

.SEditProfile_inputp__1rwRf {
  width: 86%;
  height: 36px;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  margin: 1px 0px 0px 1px;
  font-size: 0.9rem;
  color: #0b2342;
  padding: 0px 0px 0px 12px;
}

.SEditProfile_inputp__1rwRf:focus {
  outline: none;
}

.SEditProfile_inputp__1rwRf::-webkit-outer-spin-button,
.SEditProfile_inputp__1rwRf::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.SEditProfile_inputp__1rwRf[type="number"] {
  -moz-appearance: textfield;
}

.SEditProfile_inputnew__pcS92 {
  width: 86%;
  height: 36px;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  margin: 1px 0px 0px 1px;
  font-size: 0.9rem;
  color: #0b2342;
  padding: 0px 0px 0px 12px;
}

.SEditProfile_inputnew__pcS92:focus {
  outline: none;
}

.SEditProfile_inputnew__pcS92::-webkit-input-placeholder {
  color: #0b2342;
  opacity: 1;
}

.SEditProfile_inputnew__pcS92:-ms-input-placeholder {
  color: #0b2342;
  opacity: 1;
}

.SEditProfile_inputnew__pcS92::placeholder {
  color: #0b2342;
  opacity: 1;
}

.SEditProfile_inputnew__pcS92:-ms-input-placeholder {
  color: #0b2342;
}

.SEditProfile_inputnew__pcS92::-ms-input-placeholder {
  color: #0b2342;
}

.SEditProfile_label__28Oxh {
  font-size: 0.9rem;
  color: #919191;
  margin-left: 0%;
  margin-top: 3%;
}

.SEditProfile_onspan__IrYKs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.SEditProfile_onspan__IrYKs p {
  font-size: 0.8rem;
  color: #747474;
  opacity: 1;
  margin: 1.8% 0% 0% 51%;
}

.SEditProfile_onimg__1bH4F {
  width: 10%;
  margin: 0% 0% 0% 5%;
}

.SEditProfile_switch__3nMku {
  position: relative;
  display: inline-block;
  width: 10%;
  height: 20px;
  margin-top: 8px;
  margin-left: 8px;
}

.SEditProfile_switch__3nMku input {
  display: none;
}

.SEditProfile_slider__1amCV {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #747474;
  border-radius: 34px;
  z-index: 1;
  transition: 0.4s;
}

.SEditProfile_slider__1amCV:before {
  position: absolute;
  content: " ";
  height: 100%;
  width: 50%;
  /* / border: 1px solid grey; / */
  background-color: white;
  -webkit-transition: 0.4s;
  border-radius: 34px;
  transition: 0.4s;
}

.SEditProfile_switch_input__1cftw:checked + .SEditProfile_slider__1amCV {
  background-color: #1e118d;
}

.SEditProfile_switch_input__1cftw:focus + .SEditProfile_slider__1amCV {
  box-shadow: 0 0 1px #2196f3;
}

.SEditProfile_switch_input__1cftw:checked + .SEditProfile_slider__1amCV:before {
  -webkit-transform: translateX(55px);
  transform: translateX(26px);
}

.SEditProfile_on__2w2-Q {
  display: none;
}

.SEditProfile_on__2w2-Q,
.SEditProfile_off__2fdcT {
  color: white;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

.SEditProfile_switch_input__1cftw:checked + .SEditProfile_slider__1amCV .SEditProfile_on__2w2-Q {
  display: block;
}

.SEditProfile_switch_input__1cftw:checked + .SEditProfile_slider__1amCV .SEditProfile_off__2fdcT {
  display: none;
}

@media screen and (max-width: 700px) {
  .SEditProfile_sectionStyle__28Pmg {
    grid-template-columns: 1px auto;
  }
  .SEditProfile_mainbox__3pKiH {
    width: 100vw;
    margin-left: -60px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .SEditProfile_firstHeading__1x4_k {
    margin: 2% 2%;
  }
  .SEditProfile_div__19zQU {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .SEditProfile_left__1wn9O {
    margin-left: 3%;
  }
  .SEditProfile_button__UCa4D {
    margin-left: 30%;
    margin-top: -2rem;
    padding: 0;
  }
  .SEditProfile_Calendar__2Jbg5 {
    left: 10% !important;
  }
  .SEditProfile_changeDiv__1zoXj {
    margin: 5% 2%;
  }
  .SEditProfile_cross__DoNFY {
    /* position: relative; */
    top: -10%;
    left: -3%;
  }
}

@media screen and (max-width: 1080px) {
  .SEditProfile_mainbox__3pKiH {
    overflow-y: auto;
    overflow-x: hidden;
    width: 87%;
    height: 85%;
    background-color: #ffffff;
    position: absolute;
    top: 10%;
    left: 11%;
    border: 1px solid #dedede;
  }
  /* .img2 {
    width: 4%;
    top: 28%;
    left: 13%;
    position: absolute;
  } */
  .SEditProfile_headerStyle__11Gte {
    padding: 0;
  }
}

.SViewProfile_container__3CnhM {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.SViewProfile_background_img__2rCQa {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.SViewProfile_centered_text__QsZHZ {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.SViewProfile_centered_button__325aw {
  opacity: 1;
}

.SViewProfile_topsomepagewrapper__B6oLm {
  margin: 1% 11%;
}

.SViewProfile_toprow__dVlpU {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.SViewProfile_topcolumn__Jcupw {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.SViewProfile_editbtn__2DwwG {
  text-align: right;
}

.SViewProfile_sectionStyle__wvRmK {
  display: grid;
  grid-template-columns: 10% 90%;
}

.SViewProfile_headerStyle__OeyPx {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.SViewProfile_heading__3br7K img {
  width: 2%;
  margin-left: 135px;
  margin-top: 25px;
}

.SViewProfile_heading__3br7K p {
  font-weight: bold;
  margin-left: 11%;
  margin-top: 3%;
}

.SViewProfile_centered__eywg1 {
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
}

.SViewProfile_borderbox__k3DCd {
  border: 1px solid #cfcfd2;
  margin: 26px;
  margin-top: 18px;
  /* height: 155%; */
  /* display: grid;
    grid-template-rows: 109px 137px 400px; */
}

.SViewProfile_row1__319qm {
  background-color: white;
  border-bottom: 1px solid #dedede;
  padding: 15px;
  /* display: grid;
    grid-template-columns: 95px 300px 368px; */
  overflow: hidden;
}

.SViewProfile_row1box__1OymH {
  display: grid;
  grid-template-columns: 20% 40% 40%;
}

.SViewProfile_left__2AUeQ {
  background-color: white;
}

.SViewProfile_left__2AUeQ img {
  width: 100%;
  margin-top: 5%;
}

.SViewProfile_middle__3t2wz {
  background-color: white;
  margin-left: 10%;
  margin-top: 0%;
}

.SViewProfile_table1__NF_4L {
  font-size: 15px;
  min-width: 280px;
  margin-left: -10px;
}

.SViewProfile_table1__NF_4L td {
  padding: 2% 0 0 0;
}

.SViewProfile_right__1YqLz {
  background-color: white;
  margin-top: 9%;
  /* padding-top: 20px; */
  margin-left: 10%;
}

.SViewProfile_right_table1__flafw {
  min-width: 230px;
}

.SViewProfile_category__1IjjZ {
  opacity: 0.6;
  color: #333333;
  font-size: 0.8rem;
  margin: 3px;
  padding: 3px;
}

.SViewProfile_value__15uWS {
  margin: 1px;
  padding: 3px;
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  font-weight: 600;
}

.SViewProfile_row2__92Qc8 {
  background-color: white;
  border-bottom: 1px solid #dedede;
  height: 12%;
  padding: 15px;
  display: -webkit-flex;
  display: flex;
}

.SViewProfile_row2left__1R5Yt {
  width: 24%;
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.SViewProfile_row2right__aRSnv {
  width: 76%;
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
  text-align: justify;
}

.SViewProfile_lastview__3CCY1 {
  text-align: end;
  padding: 1% 2% 1% 0;
}
.SViewProfile_lastview__3CCY1 label {
  cursor: pointer;
}

.SViewProfile_lastview__3CCY1 label:hover {
  color: #110766;
  font-weight: 700;
}

.SViewProfile_row3__32I5g {
  background-color: white;
  padding: 15px;
}

.SViewProfile_upper__3E4KZ {
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
}

.SViewProfile_upperleft__2vC6Y {
  background-color: white;
  width: 24%;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.SViewProfile_upperright__1zxpX {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
  width: 76%;
  text-align: justify;
}

.SViewProfile_lower__1lchV {
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
}

.SViewProfile_lowerleft__15ICv {
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
  width: 24%;
}

.SViewProfile_lowerright__3SZL_ {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  width: 76%;
  color: #333333;
  text-align: justify;
}

.SViewProfile_row4__2D8Hj {
  padding: 15px;
  border-bottom: 1px solid #dedede;
  color: black;
  margin-top: 4%;
}

.SViewProfile_row5__3U_1e {
  background-color: white;
  /* border-bottom: 1px solid #DEDEDE; */
  height: 88px;
  padding: 15px;
  display: grid;
  /* grid-template-rows: 150px 150px; */
  /* grid-template-columns: repeat(3, 120px) 0px; */
  grid-template-columns: 12% 60%;
}

.SViewProfile_row5left__2p372 {
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.SViewProfile_row5right__t2jAi {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
}

/* @media screen and (max-width: 1368px) {
  .row4 {
    margin-top: 12%;
  }
} */
@media screen and (max-width: 1368px) {
  .SViewProfile_row4__2D8Hj {
    margin-top: 12%;
  }
  .SViewProfile_heading__3br7K p {
    margin-top: 2%;
  }
}
@media screen and (max-width: 700px) {
  .SViewProfile_sectionStyle__wvRmK {
    display: grid;
    grid-template-columns: 1px auto;
  }
  .SViewProfile_centered__eywg1 {
    width: 100vw;
    margin-top: 0.5rem;
  }
  .SViewProfile_topsomepagewrapper__B6oLm {
    margin: 0;
  }
  .SViewProfile_middle__3t2wz {
    margin-left: 0;
  }
  .SViewProfile_right__1YqLz {
    margin-left: -3%;
  }
  .SViewProfile_row1box__1OymH {
    display: block;
  }
  .SViewProfile_row2__92Qc8 {
    display: block;
  }
  .SViewProfile_upperright__1zxpX {
    margin-left: 25%;
  }
  .SViewProfile_profile__10JM_ {
    display: block;
  }
  .SViewProfile_lowerright__3SZL_ {
    display: block;
  }
}

@media screen and (max-width: 1080px) {
  .SViewProfile_centered__eywg1 {
    height: 82%;
    left: 12%;
    top: 13%;
    width: 87%;
  }
  .SViewProfile_headerStyle__OeyPx {
    padding: 0;
  }
}

.SkillDetails_container__3d85Q {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}

.SkillDetails_sectionStyle__f5gO2 {
  display: grid;
  grid-template-columns: 10% 90%;
}

.SkillDetails_headerStyle__180ri {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.SkillDetails_nodata__UgMpp {
  display: block;
  width: 50%;
  margin: 5% auto;
}

.SkillDetails_centered__3b2Ji {
  width: 70%;
  height: 60%;
  margin: 1% 0% 0% 15%;
  box-shadow: 1px 1px 5px;
  background-color: white;
}

.SkillDetails_div__1pxat {
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.SkillDetails_div2__3bTMm {
  margin-top: 2%;
  opacity: 0.5;
  width: 100%;
}
.SkillDetails_whiteButton__1jTXd {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #ffffff;
  /* / width: 9.80vw; / */
  height: 35px;
  margin: 6px 0px 0px 14px;
  font-weight: 500;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #110766;
  text-align: center;
  font-size: 1.17vw;
  cursor: pointer;
}
.SkillDetails_div3__dr5MQ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.SkillDetails_left__3xNLt {
  width: 48%;
  margin: 0px 0px 0px 30px;
  display: grid;
}

.SkillDetails_right__1gi_n {
  width: 48%;
  margin: 0px 0px 0px 0px;
  display: grid;
}

.SkillDetails_right1__XTE9S {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 50%;
  margin-top: 8px;
}
.SkillDetails_edit__1B2vt {
  margin-right: 30px;
  display: block;
  cursor: pointer;
}
.SkillDetails_delete__1mna3 {
  width: 40%;
  display: block;
  margin-top: -13px;
  cursor: pointer;
}

.SkillDetails_footer__1eq9x {
  width: 70.3%;
  height: 14%;
  background-color: #f7f7f7;
  position: absolute;
  top: 95%;
  left: 19.1%;
}

.SkillDetails_firstHeading__1m911 {
  margin: 2% 20%;
  position: absolute;
}

.SkillDetails_label1__g8nFQ {
  margin-top: 3%;
  color: #919191;
  opacity: 1;
  margin-left: 13px;
  font-size: 0.9rem;
}

.SkillDetails_input1__2xmkS {
  width: 94%;
  margin-left: 11px;
  height: 65%;
  opacity: 0.5;
  margin-top: 5px;
  border-radius: 6px;
}

.SkillDetails_input1__2xmkS:focus {
  outline: none;
}

.SkillDetails_display_data__3crJm {
  width: 80%;
  height: 25px;
  margin-left: 14px;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  color: #0b2342;
  font-size: 0.9rem;
  font-family: "Poppins", "Medium";
}
.SkillDetails_display_info__1t9Mf {
  width: 191%;
  height: 45px;
  overflow-y: auto;
  margin-left: 14px;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  color: #0b2342;
  font-size: 0.9rem;
  font-family: "Poppins", "Medium";
}

.SkillDetails_div4__2wsoT {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.SkillDetails_label2__1UiSZ {
  margin-left: 3%;
  margin-top: 3%;
  font-size: 0.9rem;
}

.SkillDetails_input2__1_w2Q {
  margin-left: 3%;
  height: 70px;
  width: 91%;
  border-radius: 6px 6px 6px 6px;
  margin-top: 1%;
  border-color: #b9b9b9;
  letter-spacing: -1px;
  padding: 1% 0% 0% 2%;
}

.SkillDetails_input2__1_w2Q:focus {
  outline: none;
}

.SkillDetails_div5__2sbxS {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.SkillDetails_picdiv__TsP_K {
  width: 93%;
  height: auto;
  margin-top: 8px;
  margin-left: 3%;
  border: 1px solid #b9b9b9;
  border-radius: 6px 6px 6px 6px;
}

.SkillDetails_gridContainer__2GQeF {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  padding: 10px 0px 0px 10px;
  min-height: 50px;
  overflow: auto;
}

.SkillDetails_images__3Ojzf {
  width: 98%;
  height: 98px;
  margin: 1% 1% 1% 1%;
  background-color: rosybrown;
}
.SkillDetails_image__1BcRY {
  height: 200px;
  width: 200px;
  margin-right: 10px;
}

.SkillDetails_changeDiv__1c-EU {
  background-color: #fafbfc;
  border-radius: 30px;
  z-index: 150;
  top: 50%;
  width: 50%;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.SkillDetails_changeResponseDiv__2x325 {
  background-color: #fafbfc;
  border-radius: 30px;
  z-index: 150;
  top: 50%;
  /* / width: 50%; / */
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.SkillDetails_changeDeleteDiv__ym_0r {
  display: block;
  position: absolute;
  margin: 0% 6%;
}

.SkillDetails_picdiv__TsP_K img {
  width: 80%;
}

.SkillDetails_img__l3SKE {
  width: 5%;
  margin: -3% 0% 0% 48%;
}
.SkillDetails_button__NTGu6 {
  padding-top: 16px;
  margin-left: 84%;
}

.SkillDetails_button1__1pKPD {
  padding-top: 16px;
  margin-left: 84%;
  padding-bottom: 16px;
}

.SkillDetails_deletebutton__2rC1y {
  width: 8%;
  height: 60%;
  margin: 3% 0% 0% 83%;
  border-radius: 16px;
  color: #1e118d;
  border-color: #1e118d;
  background-color: #ffffff;
}

.SkillDetails_blockbutton__1PBYU {
  width: 10%;
  height: 40%;
  margin: 3% 6% 3% 0%;
  border-radius: 6px;
  color: #1e118d;
  border-color: #1e118d;
  background-color: #ffffff;
}

.SkillDetails_nextbutton__2mV-J {
  width: 10%;
  height: 40%;
  margin: 3% 3% 3% 0%;
  border-radius: 6px;
  background-color: #1e118d;
  color: #ffffff;
}

.SkillDetails_buttons__3jJih {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: 72%;
  margin-bottom: 18px;
}

.SkillDetails_imgUpload__1uLho {
  margin-left: 49%;
  margin-top: -2%;
}
@media screen and (max-width: 1800px) {
  .SkillDetails_button__NTGu6 {
    margin-left: 82%;
  }
  .SkillDetails_button1__1pKPD {
    margin-left: 82%;
  }
  .SkillDetails_buttons__3jJih {
    margin-left: 69%;
  }
}

@media screen and (max-width: 1600px) {
  .SkillDetails_display_data__3crJm {
    margin-left: 0px;
  }
  .SkillDetails_display_info__1t9Mf {
    margin-left: 0px;
  }
  .SkillDetails_input1__2xmkS {
    width: 94%;
    margin-left: 0px;
  }
  .SkillDetails_label1__g8nFQ {
    margin-left: 0px;
  }
  .SkillDetails_button__NTGu6 {
    margin-left: 81%;
  }
  .SkillDetails_button1__1pKPD {
    margin-left: 81%;
  }
  .SkillDetails_buttons__3jJih {
    margin-left: 66%;
  }
  .SkillDetails_imgUpload__1uLho {
    margin-left: 49%;
    margin-top: -2%;
    height: 42px;
  }
}
@media screen and (max-width: 1400px) {
  .SkillDetails_input1__2xmkS {
    width: 93%;
  }
  .SkillDetails_button__NTGu6 {
    margin-left: 78%;
  }
  .SkillDetails_button1__1pKPD {
    margin-left: 78%;
  }
  .SkillDetails_buttons__3jJih {
    margin-left: 62%;
  }
  .SkillDetails_imgUpload__1uLho {
    margin-left: 48%;
    margin-top: -3%;
  }
}

@media screen and (max-width: 1300px) {
  .SkillDetails_input1__2xmkS {
    width: 93%;
  }
  .SkillDetails_button__NTGu6 {
    margin-left: 78%;
  }
  .SkillDetails_button1__1pKPD {
    margin-left: 78%;
  }
  .SkillDetails_buttons__3jJih {
    margin-left: 60%;
  }
}

@media screen and (max-width: 700px) {
  .SkillDetails_sectionStyle__f5gO2 {
    grid-template-columns: 1px auto;
  }
  .SkillDetails_div__1pxat {
    width: 100vw;
    margin-left: -80px;
  }
  .SkillDetails_button__NTGu6 > .SkillDetails_whiteButton__1jTXd {
    font-size: 1rem;
    margin-left: -30px;
    margin-top: -30px;
    height: 80px;
  }
  .SkillDetails_label1__g8nFQ {
    padding: 0.5rem 0rem;
  }
  .SkillDetails_delete__1mna3 {
    width: 80%;
  }
  .SkillDetails_changeDiv__1c-EU {
    width: 97%;
  }
  .SkillDetails_changeDeleteDiv__ym_0r {
    margin: 0% -11%;
  }
}

@media screen and (max-width: 1080px) {
  .SkillDetails_firstHeading__1m911 {
    margin: 5% 20%;
  }
  .SkillDetails_div__1pxat {
    top: 11%;
    height: 84%;
    width: 86%;

    left: 12%;
  }
  .SkillDetails_headerStyle__180ri {
    padding: 0;
  }
  .SkillDetails_changeDiv__1c-EU {
    width: 80%;
  }
}

.EditAddSkill_container__3IUmN {
  background-color: #f7f7f7;
  display: block;
  height: 90vh;
  overflow: auto;
  border-radius: 8px;
}

.EditAddSkill_mainheading__2x5bW {
  padding: 4% 0% 0% 6%;
  height: 100%;
  text-decoration: underline;
  width: 50%;
  opacity: 0.5;
}
.EditAddSkill_errorMsgbelow__1H2me {
  font-size: 0.7rem;
  color: red;

  padding: -0.5% 0 0 0%;
  margin-top: -1.5%;
}
.EditAddSkill_imgUpload__2SY_p {
  height: 20%;
  margin: -7% auto 0% auto !important;
  cursor: "pointer";
  padding-top: 1rem;
}

.EditAddSkill_img2__1_24y {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 48%;
  bottom: 25%;
}
.EditAddSkill_closeModal__240jq {
  padding: 4% 4% 0% 85%;
  height: 100%;
  width: 8%;
  cursor: pointer;
}

.EditAddSkill_form_style__cni-W {
  padding: 2% 3% 3% 3%;
}

.EditAddSkill_row__3BQ6P {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.EditAddSkill_rowone__1IMx- {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.EditAddSkill_row2__2PwRZ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.EditAddSkill_col__2SMr- {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 48%;
  margin: 0 0 0 3%;
}

.EditAddSkill_colone__2xNxv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 48%;
  margin: 0 0 0 3%;
}

.EditAddSkill_col2__2mZp3 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 0 0 3%;
}

.EditAddSkill_category_select__2Sc88 {
  width: 95%;
  height: 27px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  margin: 2% 0 4% 0;
  border-radius: 6px;
}

.EditAddSkill_picdiv__2Ej3j {
  width: 103%;
  height: auto;
  margin: 2% 0 4% 0;
  border: 1px solid #b9b9b9;
  border-radius: 6px 6px 6px 6px;
  background-color: white;
}

.EditAddSkill_mediadiv__32s_F {
  width: 105%;
  height: auto;
  margin: 2% 0 4% 0;
  border: 1px solid #b9b9b9;
  border-radius: 6px 6px 6px 6px;
  background-color: white;
}

.EditAddSkill_gridContainer__3CEuG {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  min-height: 70px;
  min-width: 570px;
  height: 120px;
  overflow-x: hidden;
  overflow-y: auto;
}

.EditAddSkill_heading__1FEUn {
  opacity: 0.5;
}

.EditAddSkill_item__AB5n- {
  position: relative;
}

.EditAddSkill_errorshow__3Oy3r {
  font-size: 0.7rem;
  color: red;
  margin: -3% 0 2% 0;
}

.EditAddSkill_myCheckbox1css__nwhiC {
  display: none;
}

.EditAddSkill_imglabel__2eBez {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

.EditAddSkill_imglabel__2eBez:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.EditAddSkill_images__1xjfL {
  width: 98%;
  height: 98px;
  margin: 1% 1% 1% 1%;
  background-color: rosybrown;
}

.EditAddSkill_img__3jQCF {
  /* / height: 13vh; / */
  height: 12vh;
  width: 16vh;
  transition-duration: 0.2s;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.EditAddSkill_img1__1qIC2 {
  height: 12vh;
  width: 16vh;
  transition-duration: 0.2s;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  margin-left: 8px;
  margin-top: 25px;
}
.EditAddSkill_cross__3yqmV {
  position: absolute;
  margin-top: 14px;
  margin-left: -13px;
  cursor: pointer;
}

.EditAddSkill_warning__2f6R9 {
  font-size: 0.7rem;
  color: red;
  margin: -3% 0px 0px 0px;
}
:checked + .EditAddSkill_imglabel__2eBez {
  border-color: #ddd;
}

:checked + .EditAddSkill_imglabel__2eBez:before {
  content: "✓";
  background-color: #36b453;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
}

:checked + .EditAddSkill_imglabel__2eBez img {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  /* / box-shadow: 0 0 5px #333; / */
  z-index: -1;
}

.EditAddSkill_textarea_style__2uqh2 {
  height: 70px;
  width: 95.5%;
  border-radius: 6px;
  margin: 2% 0 4% 0;
  border-color: #b9b9b9;
  padding: 1% 0% 0% 2%;
  resize: none;
}
.EditAddSkill_textarea_style__2uqh2:focus,
.EditAddSkill_category_select__2Sc88:focus {
  outline: none;
}

.EditAddSkill_button_row__1cSBk {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.EditAddSkill_imgDelete__3tp9y {
  height: 20%;
  margin: -6% auto -4% auto;
}

.EditAddSkill_showvideos__2ozXm {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 700px) {
  .EditAddSkill_gridContainer__3CEuG {
    min-width: 259px;
  }
  .EditAddSkill_imgUpload__2SY_p {
    margin-top: -13% !important;
  }
}

@media screen and (max-width: 1080px) {
  .EditAddSkill_container__3IUmN {
    height: 60vh;
  }
  .EditAddSkill_headerStyle__3tsYn {
    padding: 0;
  }
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap'); */

.Dashboard_container__NanSz {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}

.Dashboard_background_img__30kPQ {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.Dashboard_centered_text__2MoWX {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.Dashboard_centered_button__1FKpW {
  opacity: 1;
}

.Dashboard_button__1Tj8o {
  float: right;
  margin: 0.4% 0px 0px 66%;
}

/* span {
    font-size: 24px;
  } */

.Dashboard_sectionStyle__vcolR {
  display: grid;
  grid-template-columns: 10% 90%;
}

.Dashboard_headerStyle__161rH {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.Dashboard_centered__24Y0- {
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.Dashboard_content__2aNXh {
  display: grid;
  grid-template-rows: 20% 70%;
}

.Dashboard_title__hY2YA {
  /* background-color: red; */
  font-weight: 600;
  font-size: 20px;
  color: #333333;
  opacity: 1;
  /* font-family: 'Poppins', sans-serif; */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  margin-top: 30px;
}

.Dashboard_image__38oZF {
  background-color: white;
}

.Dashboard_image__38oZF img {
  width: 30%;
  margin: 75px 0px 0px 328px;
}

@media screen and (max-width: 1380px) {
  .Dashboard_container__NanSz {
    grid-template-rows: 4rem 57rem;
  }
}
@media screen and (max-width: 1080px) {
  .Dashboard_image__38oZF img {
    width: 95%;
    margin: 0;
  }
  .Dashboard_headerStyle__161rH {
    padding: 0;
  }
  .Dashboard_centered__24Y0- {
    width: 86%;
    height: 75%;
    background-color: #ffffff;
    position: absolute;
    top: 12%;
    left: 12%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.REditProfile_container__3Th28 {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
  overflow: hidden;
}

.REditProfile_sectionStyle__1TF1U {
  display: grid;
  grid-template-columns: 10% 90%;
}

.REditProfile_headerStyle__2aD1s {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.REditProfile_loader_styles__2U6hE {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.REditProfile_changeDiv__fcBOf {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 5% 39%;
  z-index: 150;
  position: absolute;
}

.REditProfile_button__D6K-O {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: 20%;
  padding-top: 1rem;
}

.REditProfile_centered__2sjmm {
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 22%;
  left: 20%;
  overflow-y: auto;
}
.REditProfile_icon__2PFcA {
  display: inline-table;
  position: relative;
}
.REditProfile_icon__2PFcA i {
  top: 6%;
}
.REditProfile_topHeading__CoYyC {
  text-align: center;
  margin: -0.2% 0 0 0;
  display: grid;
  grid-template-columns: 50% 62%;
}

.REditProfile_cancleButton__2rFB0 {
  width: 8%;
  height: 4%;
  margin-top: 3.5%;
  color: #1e118d;
  background-color: white;
  border-color: #1e118d;
  border-radius: 14px;
  margin-right: 1%;
}

.REditProfile_saveButton__2eTE1 {
  width: 8%;
  height: 4%;
  color: white;
  background-color: #1e118d;
  border-radius: 14px;
}

.REditProfile_para__3RAU1 {
  color: #24242e;
  font-weight: 600;
  font-size: 1.5rem;
  padding-right: 16.5rem;
}

.REditProfile_left__1hlEd {
  display: -webkit-flex;
  display: flex;
  padding: 15px;
}

.REditProfile_leftinner1__3i5tv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 50%;
}

.REditProfile_leftinner2__QtQt_ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 8% 0% 0% 0%;
}

.REditProfile_label1__2sv5P {
  font-size: 10pt;
  color: #919191;
  margin: 3% 0% 0% 3%;
}

.REditProfile_span1__3Zscd {
  font-size: 12pt;
  color: #0b2342;
  opacity: 0.6;
  margin: 1% 0% 0% 3%;
}

.REditProfile_label2__3e_sr {
  font-size: 0.9rem;
  color: #919191;
  margin-left: 50%;
  margin-top: -4%;
}

.REditProfile_span2__2av3M {
  font-size: 12pt;
  color: #0b2342;
  opacity: 0.6;
  margin-left: 50%;
  margin-top: -2.2%;
}

.REditProfile_div__1csc5 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 65%;
}

.REditProfile_leftd__2JoJG {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 50%;
}

.REditProfile_img1__1-gBM {
  width: 15rem;
  height: 14rem;
  margin: 5% 0% 0% 27%;
}

.REditProfile_img2__2ero1 {
  height: 46px;
  margin-left: 19%;
  margin-top: -12%;
}

.REditProfile_heading__nReSq {
  font-size: 14pt;
  margin: 7% 0% 0% 6%;
  font-weight: 600;
}

.REditProfile_yourinput__2u249 {
  width: 80%;
  height: 8em;
  border-radius: 6px 6px 6px 6px;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
  opacity: 1;
  margin: 3% 0% 0% 5%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  color: #0b2342;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 2% 5% 2% 2%;
  font-size: 12pt;
  letter-spacing: 0px;
  resize: none;
}

.REditProfile_upimg__2kegx {
  width: 40px;
  margin-left: -9rem;
  margin-top: 14rem;
  position: absolute;
}

.REditProfile_errorMsgLeft__174aY {
  font-size: 0.7rem;
  color: red;
  padding: 9.2% 0 0 25%;
}

.REditProfile_yourinput__2u249:focus {
  outline: none;
}

.REditProfile_errorMsg__2gdzJ {
  font-size: 0.7rem;
  color: red;
  float: right;
  margin-right: 11%;
}

.REditProfile_right__1zV37 {
  margin-top: 0.5%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 40%;
}

.REditProfile_input1__1SpxM {
  height: 36px;
  border-radius: 6px 6px 6px 6px;
  border: 1pt solid #b9b9b9;
  opacity: 1;
  border-color: #b9b9b9;
  color: #0b2342;
  margin: 3% 0% 0% 0%;
  padding: 2%;
}

.REditProfile_input1__1SpxM:focus {
  outline: none;
}

.REditProfile_label__3tJIU {
  font-size: 12pt;
  color: #919191;
  margin: 7% 0% 0% 0%;
}

@media only screen and (max-width: 750px) {
  .REditProfile_img2__2ero1 {
    margin-left: 45%;
  }
  .REditProfile_centered__2sjmm {
    width: 100vw;
    left: 0;
    margin-top: 1.5rem;
  }
  .REditProfile_div__1csc5 {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .REditProfile_sectionStyle__1TF1U {
    grid-template-columns: 0px auto;
  }
  .REditProfile_upimg__2kegx {
    margin-left: 6rem !important;
    margin-top: -1.5rem !important;
    margin-right: 3rem !important;
  }
  .REditProfile_topHeading__CoYyC {
    margin: 0;
    grid-template-columns: 1fr 3fr;
  }
  .REditProfile_icon__2PFcA i {
    top: 6%;
    left: 98%;
  }

  .REditProfile_button__D6K-O {
    padding-top: 21px;
    margin-left: 19%;
  }
  .REditProfile_para__3RAU1 {
    margin: 0;
    font-size: 1.6rem;
    padding-top: 2rem;
    padding-right: 0;
  }

  .REditProfile_right__1zV37 {
    margin-top: 0.5%;
    width: 35%;
    margin-left: 10px;
  }
  .REditProfile_img1__1-gBM {
    margin: 5% 0% 0% 2%;
    width: 15rem !important;
    height: 10rem !important;
  }

  .REditProfile_yourinput__2u249 {
    width: 80vw;
  }
  .REditProfile_input1__1SpxM {
    width: 80vw;
  }
  .REditProfile_left__1hlEd {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .REditProfile_changeDiv__fcBOf {
    margin: 5% 2%;
  }
}

@media screen and (max-width: 1368px) {
  .REditProfile_img1__1-gBM {
    width: 13rem;
    height: 12rem;
    margin: 5% 0% 0% 2%;
  }
  .REditProfile_upimg__2kegx {
    width: 40px;
    margin-left: -8rem;
    margin-top: 12.2rem;
    position: absolute;
  }

  .REditProfile_errorMsgLeft__174aY {
    font-size: 0.7rem;
    color: red;
    padding: 6% 0 0 25%;
  }
}

@media screen and (max-width: 1080px) {
  .REditProfile_centered__2sjmm {
    width: 85%;
    height: 75%;
    background-color: #ffffff;
    position: absolute;
    top: 14%;
    left: 13%;
    overflow-y: auto;
  }
  .REditProfile_headerStyle__2aD1s {
    padding: 0;
  }
}

.RViewProfile_container__1Q6Ue {
  background-color: rgb(240, 239, 239);
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.RViewProfile_sectionStyle__3wmm4 {
  display: grid;
  grid-template-columns: 16rem auto auto;
  height: 100vh;
}

.RViewProfile_headerStyle__1Zc-f {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  color: white;

  z-index: 100;
}

.RViewProfile_loader_styles__3fYTk {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.RViewProfile_centered__3p18C {
  width: 70%;
  height: 75%;
  margin-top: 2%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.RViewProfile_editButton__38wn8 {
  width: 8%;
  height: 4%;
  margin-top: 0.8%;
  color: #1e118d;
  background-color: white;
  border-color: #1e118d;
  border-radius: 14px;
  margin-right: 1%;
}

.RViewProfile_topHeading__1Gj7X {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  /* align-items: flex-start; */
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  height: 9vh;
  width: 70vw;
}

.RViewProfile_para__2r2uQ {
  color: #24242e;
  font-weight: 600;
  font-size: 1.1rem;
  margin-left: 1rem;
  margin-bottom: -1rem;
}

.RViewProfile_left__3j4kd {
  display: -webkit-flex;
  display: flex;
  padding: 15px;
}

.RViewProfile_leftinner1__3wyi4 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 50%;
}

.RViewProfile_leftinner2__3rAbP {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 8% 0% 0% 0%;
}

.RViewProfile_label1__25oc8 {
  /* font-size: 12pt; */
  color: #919191;
  margin: 3% 0% 0% 3%;
}

.RViewProfile_span1__1XZFf {
  /* font-size: 12pt; */
  color: #0b2342;
  opacity: 0.6;
  margin: 1% 0% 0% 3%;
}

.RViewProfile_span2__37pvq {
  color: #0b2342;
  opacity: 0.6;
  margin: -0.6vw;
}

.RViewProfile_span4__2VQwf {
  color: #0b2342;
  opacity: 0.6;
  margin: -0.4vw;
}

.RViewProfile_address__1MEx4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "middle . right";
}
.RViewProfile_middle__1T58u {
  grid-area: middle;
}
.RViewProfile_right__xVq8G {
  grid-area: right;
}

.RViewProfile_divimg__2y5zn {
  width: 18%;
  height: 90%;
}

.RViewProfile_img___k0q3 {
  /* margin-top: 1rem;
  width: 13rem;
  height: 13rem; */
  height: 31.65vh;
  margin-top: 2.43vh;
  width: 15.22vw;
  margin-left: 10%;
  border-radius: 1rem;
}

.RViewProfile_img1__wPFZg {
  height: 46px;
  margin-left: 32%;
  margin-top: -12%;
}

.RViewProfile_middle__1T58u {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 10%;
  width: 30%;
}

.RViewProfile_right__xVq8G {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 30%;
}

.RViewProfile_label3__Iyt3o {
  /* font-size: 12pt; */
  color: #919191;
  margin-top: 0px;
  padding: 5%;
}

.RViewProfile_span3__3xRTO {
  /* font-size: 12pt; */
  color: #0b2342;
  opacity: 1;
  margin-left: 5%;
  margin-top: -3%;
}

.RViewProfile_label__3UUdA {
  margin: 0% 0% 0% 0%;
  /* font-size: 14pt; */
  font-weight: 600;
}

.RViewProfile_about__1K8j7 {
  width: 70%;
  color: #0b2342;
  opacity: 1;
  /* font-size: 14px; */
}
.RViewProfile_editBtn__x8BUz {
  margin: 1rem;
}
.RViewProfile_div__1xVrw {
  display: -webkit-flex;
  display: flex;
}
@media screen and (max-width: 740px) {
  .RViewProfile_left__3j4kd {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .RViewProfile_span1__1XZFf {
    margin: 0;
  }
  .RViewProfile_leftinner2__3rAbP {
    margin-top: 1rem;
  }
  .RViewProfile_img___k0q3 {
    width: 200px;
    height: 200px;
  }
  .RViewProfile_label__3UUdA,
  .RViewProfile_about__1K8j7 {
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .RViewProfile_label1__25oc8 {
    margin-left: 0px !important;
  }
  .RViewProfile_divimg__2y5zn {
    margin-bottom: 1.5rem;
    grid-area: divimg;
    margin-left: 1rem;
  }

  .RViewProfile_centered__3p18C {
    width: 100vw;
    left: 0;
  }

  .RViewProfile_div__1xVrw {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: ". divimg ." "middle . right";
  }
  .RViewProfile_middle__1T58u {
    grid-area: middle;
    width: 100%;
    margin-left: 5%;
  }
  .RViewProfile_right__xVq8G {
    grid-area: right;
    margin-left: 5%;
    width: 100%;
  }
  .RViewProfile_para__2r2uQ {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .RViewProfile_editBtn__x8BUz {
    margin: 1rem;
  }
  .RViewProfile_sectionStyle__3wmm4 {
    grid-template-columns: 1px 1fr;
  }
  .RViewProfile_topHeading__1Gj7X {
    width: 90vw !important;
  }
}

@media only screen and (max-width: 1100px) {
  .RViewProfile_topHeading__1Gj7X {
    width: 65vw;
  }
}

@media only screen and (max-width: 400px) {
  .RViewProfile_div__1xVrw {
    grid-template-areas: "divimg . ." "middle . ." "right . .";
    grid-template-rows: auto auto auto;
  }
  .RViewProfile_img___k0q3 {
    width: 300px;
    height: 150px;
  }
}

@media only screen and (max-width: 1080px) {
  .RViewProfile_topHeading__1Gj7X {
    width: 87vw;
    position: absolute;
    left: 12%;
  }
  .RViewProfile_centered__3p18C {
    width: 86%;
    height: 75%;
    margin-top: 2%;
    background-color: #ffffff;
    position: absolute;
    top: 13%;
    left: 12%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .RViewProfile_img___k0q3 {
    height: 14.65vh;
  }
}

.cards_maincontainer__-HNn0 {
  background-color: white;
  height: 14rem;
}

.cards_maincard__g5uoz {
  display: grid;
  grid-template-rows: 0px 150px;
  margin-left: 10px;
}

.cards_card__2RT-k {
  border: 1px solid #cfcfd2;
  margin: 15px 0px 0px 0px;
  padding: 16px;
  height: 158px;
  width: 94%;
}

.cards_modal_label__1uKu5{
  border: none;
  background: none;
  font-size: 0.8rem;
  padding: 0%;
}

.cards_modal_label__1uKu5 button :hover {
  border: 1px solid #5a1c1c;
  cursor: pointer;
}

.cards_modal_container__Gk3OS {
  color: #333333;
  box-shadow: 0 2px 2px 2px #00000029;
  background-color: #FFFFFF;
  border-radius: 10px 0px 10px 10px;
  margin: -0.5% 0 0 -6%;
  padding: 1% 1% 1% 1%;
  width: 6%;
  opacity: 1;
  position: absolute;
}

.cards_openSquare__2sIrC {
  margin-left: 1px;
  margin-top: 16px;
  width: 25px;
  height: 25px;
  background-image: -webkit-linear-gradient(-45deg, #36b453 50%, #ffffff 50%);
}

.cards_closeSquare__2wWJG {
  margin-left: 1px;
  margin-top: 16px;
  width: 25px;
  height: 25px;
  background-image: -webkit-linear-gradient(-45deg, #eb1a13 50%, #ffffff 50%);
}

.cards_box1__13JLu {
  width: 80%;
}

.cards_heading__e6PLI {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 50px;
}
.cards_constantImg__2uCoJ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: -10px;
}
.cards_constantImg1__2lQ58 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.cards_lefthead__3W1hT {
  font-size: 0.9rem;
  width: 94%;
  font-weight: 700;
}

.cards_lefthead__3W1hT p {
  margin: 0px 0px 0px 4px;
}

.cards_lefthead__3W1hT p {
  opacity: 0.6;
  color: #333333;
  font-size: 8px;
  margin: 5px;
}

.cards_detail__2YPqB {
  width: 38px;
}

.cards_detail__2YPqB img {
  width: 24px;
  height: 25px;
  left: 665px;
  top: 44px;
  opacity: 1;
  cursor: pointer;
}

.cards_more__o2tPK {
  width: 38px;
  cursor: pointer;
}

.cards_more__o2tPK img {
  cursor: pointer;
  z-index: 1;
}
.cards_lefthead1__1zhOr {
  width: 94%;
  font-weight: 700;
}

.cards_table__2eT1z {
  font-size: 15px;
  min-width: 548px;
}

.cards_table__2eT1z tbody {
  margin: 25px;
}

.cards_category__s7n6q {
  opacity: 0.6;
  color: #333333;
  font-size: 12px;
}

.cards_value__3MDmU {
  opacity: 1;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}

.cards_box2__289Ja {
  background-color: white;
}

.cards_lefthead__3W1hT p {
  font-size: 1rem;
  opacity: 1;
}

.cards_lefthead__3W1hT p span {
  font-size: 0.6rem;
    display: block;
    opacity: 0.6;
}

.cards_card_image__2zT9f img {
  height: 10rem;
  width: 10rem;
  margin-top: -11.7rem;
  float: right;
}

.cards_card_info__yVnIz {
  width: 30px;
}

.cards_appliedImg__1SZZF {
  height: 10%;
}

.cards_left__2yjhE img {
  width: 45px;
  height: 40px;
  margin-top: -3px;
}

.cards_left__2yjhE p {
  color: #1e118d;
  margin-left: 62px;
  margin-top: -40px;
  font-size: 15px;
  font-weight: bold;
}

.cards_shelf__29oly {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 93%;
  margin-bottom: 0.8%;
  margin-left: 1%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.cards_shelf__29oly:last-child {
  margin-bottom: 0;
}

.cards_labelbox__3hCJ- {
  -webkit-flex: 0 0 35%;
          flex: 0 0 35%;
  opacity: 0.6;
  color: #333333;
  font-size: 12px;
}

.cards_valuebox__Qln0s {
  -webkit-flex: 0 0 65%;
          flex: 0 0 65%;
  opacity: 1;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}

.cards_eye__3Iosn {
  margin-left: 4%;
    margin-top: -14%;
}

.cards_right__2qFAy img {
  width: 32px;
  height: 34px;
  margin-left: 257px;
  margin-top: -3px;
}

.cards_right__2qFAy p {
  color: #747474;
  opacity: 1;
  font-size: 13px;
  margin-left: 300px;
  margin-top: -33px;
}

@media screen and (max-width: 1500px) {
  .cards_lefthead__3W1hT {
    width: 84%;
  }
  .cards_lefthead1__1zhOr {
    width: 83%;
  }
}
@media screen and (max-width: 1300px) {
  .cards_lefthead__3W1hT {
    width: 80%;
  }
  .cards_lefthead1__1zhOr {
    width: 78%;
  }
}
@media screen and (max-width: 1150px) {
  .cards_lefthead__3W1hT {
    width: 76%;
    overflow: hidden;
  }
  .cards_lefthead1__1zhOr {
    width: 74%;
  }
}

@media only screen and (max-width: 700px){
  .cards_card_image__2zT9f img {
    height: 5rem;
    width: 5rem;
    margin-top: -9rem;
  }
  .cards_modal_container__Gk3OS{
    padding-right: 3rem;
  }
}
.r_opportunities_container__2iemp {
  background-color: rgb(240, 239, 239);
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.r_opportunities_headerSection__H-oJM {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}
.r_opportunities_newdiv__vSXLI {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 20%;
  left: 20%;
  z-index: 100;
}
.r_opportunities_loader__2wlaW {
  /* background-color: #fafbfc; */
  /* border-radius: 30px 30px 30px 30px; */
  z-index: 150;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.r_opportunities_changeDiv3__f312Z {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 85%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 8%;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
}
.r_opportunities_sectionStyle__XXYzv {
  display: grid;
  grid-template-columns: 17rem auto auto;
  height: 100vh;
}

.r_opportunities_topHeading__2g2F8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  /* align-items: flex-start; */
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  height: 9vh;
  width: 69vw;
}

.r_opportunities_leftHead__2jPIL {
  color: #333333;
  padding: rem;
}
.r_opportunities_rightHead__1IAII {
  color: #333333;
  padding-top: 0.5rem;
  font-weight: bold;
}
.r_opportunities_footer__3s5yr {
  width: 70.3%;
  height: 14%;
  background-color: transparent;
  position: absolute;
  top: 95%;
  left: 19.1%;
}
.r_opportunities_filter__DoHdZ {
  height: 50px;
  margin: -4% 0 0 0;
  cursor: pointer;
  visibility: hidden;
}

.r_opportunities_centered_text__3rHch {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.r_opportunities_labelImg__1h9C2 {
  height: 35%;
  width: 5%;
  cursor: pointer;
}

.r_opportunities_nodata__3TNBN {
  display: block;
  width: 50%;
  margin: 5% auto;
}

.r_opportunities_tagline__Q8ETS {
  color: #24242e;
  font-weight: 600;
  font-size: 1rem;
}

.r_opportunities_centered_button__1EnLl {
  opacity: 1;
}

.r_opportunities_centered__3-gqt {
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.r_opportunities_changeDiv__1jjmy {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  left: 20%;
  z-index: 150;
  margin: 10% 0% 0 5%;
  position: absolute;
  box-shadow: 20pt 23pt 100pt;
}

.r_opportunities_addBlurr__177IR {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

.r_opportunities_createOpp__3ofCz {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #1e118d;
  font-weight: 500;
  height: 1.3em;
  width: 100%;
  border-radius: 14px;
  border: 1px solid #110766;
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  margin-top: -2.5%;
}

.r_opportunities_options__2jqmc img {
  margin-left: 25em;
}

/* tabs */
@media only screen and (max-width: 768px) {
  .r_opportunities_sectionStyle__XXYzv {
    grid-template-columns: 0px auto auto;
  }
  .r_opportunities_newdiv__vSXLI {
    left: -11%;
    top: 38%;
  }
  .r_opportunities_headerStyle__SeMl8 {
    width: 100vw;
  }
  .r_opportunities_topHeading__2g2F8 {
    margin: 0;
    width: 95vw;
  }
  .r_opportunities_centered__3-gqt {
    font-family: "Poppins-medium";
    width: 100%;
    height: 75%;
    position: absolute;
    top: 20%;
    left: 0%;
    margin-left: 0%;
  }
  .r_opportunities_leftHead__2jPIL {
    padding-top: 2rem;
  }
  .r_opportunities_options__2jqmc img {
    margin-left: 0;
  }
  .r_opportunities_tagline__Q8ETS {
    font-size: 1rem;
  }
  .r_opportunities_rightHead__1IAII {
    margin-top: 0.3rem;
  }
  .r_opportunities_createOpp__3ofCz {
    padding: 0.5rem;
    height: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .r_opportunities_leftHead__2jPIL {
    padding: 0;
  }
  .r_opportunities_tagline__Q8ETS {
    font-size: 0.8rem;
  }
  .r_opportunities_createOpp__3ofCz {
    font-size: 0.8rem;
    height: 100%;
  }
  .r_opportunities_changeDiv3__f312Z {
    width: 85%;
    left: 5%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1080px) {
  .r_opportunities_headerSection__H-oJM {
    padding: 0;
  }
  .r_opportunities_centered__3-gqt {
    width: 85%;
    height: 80%;
    background-color: #ffffff;
    position: absolute;
    top: 14%;
    left: 13%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .r_opportunities_options__2jqmc {
    margin-left: -8rem;
  }
  .r_opportunities_sectionStyle__XXYzv {
    display: grid;
    grid-template-columns: 14% 57% 26%;
    height: 100vh;
  }
  .r_opportunities_createOpp__3ofCz {
    height: 3.3em;
  }
  .r_opportunities_changeDiv3__f312Z {
    width: 65%;
    left: 19%;
    height: auto;
    padding-bottom: 10px;
  }
}

.CreateOpportunity_container__1O5Mx {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100%;
}

.CreateOpportunity_sectionStyle__1WyBb {
  display: grid;
  grid-template-columns: 10% 90%;
}

.CreateOpportunity_headerStyle__CPKG7 {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}
.CreateOpportunity_loader__2YLst {
  background-color: #fafbfc5e;
  /* border-radius: 30px 30px 30px 30px;  */
  z-index: 150;

  width: 100vw;
  height: 100vh;
}

.CreateOpportunity_loader__2YLst img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.CreateOpportunity_centered__3FNme {
  width: 70%;
  height: 80%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.CreateOpportunity_para__24s6b {
  margin-left: 12%;
  font-size: 1.1rem;
  margin-top: 2.2%;
}

.CreateOpportunity_heading__25SFP {
  font-size: 14px;
  margin-bottom: 1%;
  color: #24242e;
  padding: 2% 0 0% 0;
}

.CreateOpportunity_img__c2Us8 {
  width: 30%;
  margin-top: 0%;
  border-radius: 6px 6px 6px 6px;
  background-color: #f7f7f7;
  object-fit: contain;
}

.CreateOpportunity_img1__3HGC- {
  width: 2.5rem;
  margin-top: -2.3%;
  position: absolute;
}

.CreateOpportunity_divFirst__3-ToW {
  display: grid;
  grid-template-columns: repeat(2, 1rf);
  margin: auto;
}

.CreateOpportunity_label1__2ND_z {
  color: #919191;
  font-size: 13px;
  margin-left: 4%;
  margin-top: 1.8%;
  margin-bottom: 0.8%;
}

.CreateOpportunity_input1__38lOx {
  color: #0b2342;
  font-size: 13px;
  margin-left: 4%;
  border-radius: 6px;
  width: 92%;
  height: 110%;
  opacity: 1;
  padding: 2px;
  border: 1pt solid #b9b9b9;
}

.CreateOpportunity_input1__38lOx:focus {
  outline: none;
}

.CreateOpportunity_line__2oHfk {
  margin-top: 2.5%;
  color: #707070;
  opacity: 0.5;
}
.CreateOpportunity_baseone__1cQ_C {
  display: block;
  padding: 0 4%;
}
.CreateOpportunity_basetwo__1-C8t {
  display: block;
  width: 30%;
  text-align: center;
}
.CreateOpportunity_div2__2YmHo {
  display: grid;
  grid-template-columns: 50% 50%;
}

.CreateOpportunity_left__Pkehh {
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 120%;
}

.CreateOpportunity_right__1V11U {
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 70%;
  height: 1%;
}

.CreateOpportunity_form1__1QAax {
  display: grid;
  grid-template-rows: repeat(6);
}

.CreateOpportunity_form2__3OU84 {
  display: grid;
  grid-template-rows: repeat(2);
}

.CreateOpportunity_label2__3ag0j {
  color: #919191;
  font-size: 13px;
  margin-left: 6%;
  margin-top: 2rem;
  margin-bottom: 0.8%;
}

.CreateOpportunity_input2__WSzU- {
  font-size: 13px;
  margin-left: 6%;
  padding: 2px;
  border-radius: 6px 6px 6px 6px;
  width: 75%;
  opacity: 1;
  height: 110%;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
  color: #0b2342;
}

.CreateOpportunity_input2__WSzU-:focus {
  outline: none;
}

.CreateOpportunity_input2__WSzU- option {
  background: none;
}
.CreateOpportunity_errorMsg__LwaTa {
  font-size: 0.7rem;
  color: red;
  padding: 2% 0 0 4%;
}

.CreateOpportunity_errorMsgbelow__3mjWY {
  font-size: 0.7rem;
  color: red;
  padding: 2.5% 0 0 6%;
}

.CreateOpportunity_errorMsgta__291nE {
  font-size: 0.7rem;
  color: red;
  padding: 10% 0 0 6%;
  pointer-events: none;
}

.CreateOpportunity_location__K328U {
  font-size: 13px;
  margin-left: 6%;
  padding: 3px;
  border-radius: 6px 6px 6px 6px;
  width: 75%;
  opacity: 1;
  height: 110%;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
  color: #0b2342;
}

.CreateOpportunity_location__K328U:focus {
  outline: none;
}

.CreateOpportunity_label3__26rkO {
  color: #919191;
  font-size: 13px;
  margin-left: 6%;
  margin-top: 2rem;
  margin-bottom: 0.8%;
}

.CreateOpportunity_input3__bqaBr {
  font-size: 13px;
  margin-left: 6%;
  border-radius: 6px 6px 6px 6px;
  width: 125%;
  opacity: 1;
  height: 110%;
  padding: 2px;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
}

.CreateOpportunity_input3__bqaBr:focus {
  outline: none;
}

.CreateOpportunity_textarea1__1KXzR {
  font-size: 13px;
  padding: 10px;
  margin-left: 6%;
  border-radius: 6px 6px 6px 6px;
  width: 125%;
  opacity: 1;
  height: 140%;
  padding: 2px;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
  resize: none;
}

.CreateOpportunity_textarea1__1KXzR:focus {
  outline: none;
}

.CreateOpportunity_button1__3KTy4 {
  height: 8%;
  width: 10%;
  background-color: #1e118d;
  color: white;
  border-radius: 14px 14px 14px 14px;
}

.CreateOpportunity_createButton__KGYzU {
  height: 6%;
  width: 10%;
  border-radius: 14px 14px 14px 14px;
  border-color: #1e118d;
  color: #1e118d;
  margin: 8% 2% 3% 60%;
}

.CreateOpportunity_saveButton__1xYvl {
  height: 6%;
  width: 10%;
  border-radius: 14px 14px 14px 14px;
  color: white;
  background-color: #1e118d;
}

.CreateOpportunity_changeDiv__3P0kf {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 10% 33%;
  z-index: 150;
  position: absolute;
}

.CreateOpportunity_topHeading__bfQwd {
  display: block;
  width: 100%;
  height: 5%;
}
.CreateOpportunity_whiteBtn__iiQqU {
  margin: 5% 0% 0% 63%;
}
.CreateOpportunity_blueBtn__2Zh2d {
  margin: -5% 0% 0% 80%;
}
.CreateOpportunity_buttonDiv__2Ox52 {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  right: 1.7rem;
  margin-top: 2rem;
}

@media only screen and (max-width: 700px) {
  .CreateOpportunity_centered__3FNme {
    width: 100%;
    left: 0;
  }
  .CreateOpportunity_whiteBtn__iiQqU {
    margin-left: 40%;
    margin-top: 2rem;
    padding: 1px 6px;
  }
  .CreateOpportunity_blueBtn__2Zh2d {
    margin-left: 65%;
    margin-top: -3rem;
  }
  .CreateOpportunity_img1__3HGC- {
    margin-top: -9%;
    margin-left: -3%;
  }
  .CreateOpportunity_div2__2YmHo {
    grid-template-columns: 1fr;
    margin-left: -1rem;
  }
  .CreateOpportunity_form2__3OU84 {
    margin-left: 0.6rem;
  }
  .CreateOpportunity_textarea1__1KXzR {
    width: 80vw !important;
  }
  .CreateOpportunity_buttonDiv__2Ox52 {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 1080px) {
  .CreateOpportunity_centered__3FNme {
    width: 86%;
    height: 80%;
    background-color: #ffffff;
    position: absolute;
    top: 11%;
    left: 12%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .CreateOpportunity_headerStyle__CPKG7 {
    padding: 0;
  }
}

.EditOpportunity_container__3lSy_ {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100%;
}

.EditOpportunity_sectionStyle__2E5Ci {
  display: grid;
  grid-template-columns: 10% 90%;
}

.EditOpportunity_headerStyle__CCYBJ {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.EditOpportunity_centered__rUnKr {
  width: 70%;
  height: 80%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.EditOpportunity_para__1KHwn {
  margin-left: 11%;
  font-size: 1.1rem;
  margin-top: 2.2%;
}

.EditOpportunity_heading__3AuLg {
  font-size: 14px;
  margin-bottom: 1%;
  color: #24242e;
  padding: 1% 0px 0% 47px;
}

.EditOpportunity_img__1n8rM {
  /* width: 20%;  */
  height: 100px;
  width: 150px;
  margin-top: 0%;
  margin-left: 5%;
  border-radius: 6px 6px 6px 6px;
  background-color: #f7f7f7;
  object-fit: contain;
}

.EditOpportunity_img1__1gzgO {
  width: 2.5rem;
  margin-top: 9%;
  margin-left: -10%;
  position: absolute;
}

.EditOpportunity_divFirst__3Vqi0 {
  display: grid;
  grid-template-columns: repeat(2, 1rf);
  margin: auto;
}

.EditOpportunity_label1__8sKdy {
  color: #919191;
  font-size: 13px;
  margin-left: 4%;
  margin-top: 1.8%;
  margin-bottom: 0.8%;
}

.EditOpportunity_input1__2dbtC {
  color: #0b2342;
  font-size: 13px;
  margin-left: 4%;
  border-radius: 6px;
  width: 92%;
  height: 110%;
  opacity: 1;
  padding: 2px;
  border: 1pt solid #b9b9b9;
}

.EditOpportunity_input1__2dbtC:focus {
  outline: none;
}

.EditOpportunity_line__3AXg5 {
  margin-top: 2.5%;
  color: #707070;
  opacity: 0.5;
}
.EditOpportunity_baseone__3p-4E {
  display: block;
  padding: 0 4%;
}
.EditOpportunity_basetwo__8Wxpp {
  display: block;
  width: 30%;
  text-align: center;
}
.EditOpportunity_div2__3pf30 {
  display: grid;
  grid-template-columns: 50% 50%;
}

.EditOpportunity_left__EhfQZ {
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 120%;
}

.EditOpportunity_right__2FU04 {
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 70%;
  height: 1%;
}

.EditOpportunity_form1__3cw67 {
  display: grid;
  grid-template-rows: repeat(6);
}

.EditOpportunity_form2__2E6sp {
  display: grid;
  grid-template-rows: repeat(2);
}

.EditOpportunity_label2__c0-6m {
  color: #919191;
  font-size: 13px;
  margin-left: 6%;
  margin-top: 2rem;
  margin-bottom: 0.8%;
}

.EditOpportunity_input2__1TqPO {
  font-size: 13px;
  margin-left: 6%;
  padding: 2px;
  border-radius: 6px 6px 6px 6px;
  width: 75%;
  opacity: 1;
  height: 110%;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
  color: #0b2342;
}

.EditOpportunity_input2__1TqPO:focus {
  outline: none;
}

.EditOpportunity_input2__1TqPO option {
  background: none;
}
.EditOpportunity_errorMsg__3lvXI {
  font-size: 0.7rem;
  color: red;
  padding: 2% 0 0 4%;
}

.EditOpportunity_errorMsgbelow__K-pgs {
  font-size: 0.7rem;
  color: red;
  padding: 2.5% 0 0 6%;
  pointer-events: none;
}

.EditOpportunity_errorMsgta__2lGx- {
  font-size: 0.7rem;
  color: red;
  padding: 10% 0 0 6%;
  pointer-events: none;
}

.EditOpportunity_location__3Yudm {
  font-size: 13px;
  margin-left: 6%;
  padding: 3px;
  border-radius: 6px 6px 6px 6px;
  width: 75%;
  opacity: 1;
  height: 110%;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
  color: #0b2342;
}

.EditOpportunity_location__3Yudm:focus {
  outline: none;
}

.EditOpportunity_label3__r4L9w {
  color: #919191;
  font-size: 13px;
  margin-left: 6%;
  margin-top: 2rem;
  margin-bottom: 0.8%;
}

.EditOpportunity_input3__7oKr6 {
  font-size: 13px;
  margin-left: 6%;
  border-radius: 6px 6px 6px 6px;
  width: 125%;
  opacity: 1;
  height: 110%;
  padding: 2px;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
}

.EditOpportunity_input3__7oKr6:focus {
  outline: none;
}

.EditOpportunity_textarea1__1rYqk {
  font-size: 13px;
  padding: 10px;
  margin-left: 6%;
  border-radius: 6px 6px 6px 6px;
  width: 125%;
  opacity: 1;
  height: 140%;
  padding: 2px;
  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
  resize: none;
}

.EditOpportunity_textarea1__1rYqk:focus {
  outline: none;
}

.EditOpportunity_button1__Rm2WE {
  height: 8%;
  width: 10%;
  background-color: #1e118d;
  color: white;
  border-radius: 14px 14px 14px 14px;
}

.EditOpportunity_createButton__rWi2b {
  height: 6%;
  width: 10%;
  border-radius: 14px 14px 14px 14px;
  border-color: #1e118d;
  color: #1e118d;
  margin: 8% 2% 3% 60%;
}

.EditOpportunity_saveButton__1N44R {
  height: 6%;
  width: 10%;
  border-radius: 14px 14px 14px 14px;
  color: white;
  background-color: #1e118d;
}

.EditOpportunity_changeDiv__3oCwo {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 10% 33%;
  z-index: 150;
  position: absolute;
}

.EditOpportunity_topHeading__1cc4N {
  display: block;
  width: 100%;
  height: 5%;
}
.EditOpportunity_whiteBtn__2zNPT {
  margin: 5% 0% 0% 63%;
}
.EditOpportunity_blueBtn__2gSqx {
  margin: -5% 0% 0% 80%;
}
.EditOpportunity_buttonDiv__3fD5T {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  right: 28px;
  margin-top: 2rem;
}

@media only screen and (max-width: 700px) {
  .EditOpportunity_centered__rUnKr {
    width: 100%;
    left: 0;
  }
  .EditOpportunity_whiteBtn__2zNPT {
    margin-left: 40%;
    margin-top: 2rem;
    padding: 1px 6px;
  }
  .EditOpportunity_blueBtn__2gSqx {
    margin-left: 65%;
    margin-top: -3rem;
  }
  .EditOpportunity_img1__1gzgO {
    margin-top: 21%;
    margin-left: -33%;
  }
  .EditOpportunity_div2__3pf30 {
    grid-template-columns: 1fr;
    margin-left: -1rem;
  }
  .EditOpportunity_form2__2E6sp {
    margin-left: 0.6rem;
  }
  .EditOpportunity_textarea1__1rYqk {
    width: 80vw !important;
  }
  .EditOpportunity_buttonDiv__3fD5T {
    margin-top: 1rem;
  }
}

.SViewProfile_container__2-XpT {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.SViewProfile_background_img__3Pagg {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}
.SViewProfile_value__1Uwvs {
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0 !important;
  width: 100%;
  padding: 3px;
  word-wrap: break-word;
}
.SViewProfile_centered_text__3_7iR {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.SViewProfile_centered_button__2ZH0O {
  opacity: 1;
}

.SViewProfile_topsomepagewrapper__3XcD2 {
  margin: 1% 11%;
}

.SViewProfile_toprow__BhkiV {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.SViewProfile_topcolumn__30duL {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 92vw;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.SViewProfile_para__5XtQf {
}
.SViewProfile_backarrow__1yBVi {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.SViewProfile_backarrow__1yBVi > img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.SViewProfile_accept__231VT {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 160px;
  height: 35px;
  /* margin-top: 5%; */
  margin-right: 5%;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.SViewProfile_accepted__2E31I {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: grey;
  font-weight: 500;
  width: 134px;
  height: 35px;
  margin-right: 27px;
  margin-top: -2vh;

  border-radius: 14px;
  border: 1px solid grey;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
}
.SViewProfile_changeDiv__2CbjR {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 0%;
  height: 40%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 30%;
  position: absolute;
}

.SViewProfile_addBlurr__38RD9 {
  filter: brightness(10px);
  -webkit-filter: blur(10px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}
.SViewProfile_reject__37c2v {
  /* box-shadow: 0 0px 2px 2px #0000001F; */
  background-color: white;
  font-weight: 500;
  width: 160px;
  height: 35px;
  margin-right: 9%;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  color: #110766;
  cursor: pointer;
}

.SViewProfile_editbtn__2doWZ {
  text-align: right;
}

.SViewProfile_sectionStyle__1c6Mn {
  display: grid;
  grid-template-columns: 10% 90%;
}

.SViewProfile_headerStyle__36FQU {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.SViewProfile_heading__36Rzq img {
  width: 2%;
  margin-left: 135px;
  margin-top: 25px;
}

.SViewProfile_heading__36Rzq p {
  font-weight: bold;
  margin-left: 11%;
  margin-top: 3%;
}

.SViewProfile_centered__3oIXC {
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  /* margin-top: -3%; */
}

.SViewProfile_borderbox__Sc6er {
  border: 1px solid #cfcfd2;
  margin: 26px;
  margin-top: 18px;
  /* height: 155%; */
  /* display: grid;
    grid-template-rows: 109px 137px 400px; */
}

.SViewProfile_row1__3rvHU {
  background-color: white;
  border-bottom: 1px solid #dedede;
  padding: 15px;
  /* display: grid;
    grid-template-columns: 95px 300px 368px; */
  overflow: hidden;
}

.SViewProfile_row1box__ogWkS {
  display: grid;
  grid-template-columns: 20% 40% 40%;
}

.SViewProfile_left__2ZMIx {
  background-color: white;
}

.SViewProfile_left__2ZMIx img {
  width: 100%;
  margin-top: 5%;
}

.SViewProfile_middle__37-TV {
  background-color: white;
  margin-left: 10%;
  margin-top: 0%;
}

.SViewProfile_table1__2q4Z9 {
  font-size: 15px;
  min-width: 280px;
  margin-left: -10px;
}

.SViewProfile_table1__2q4Z9 td {
  padding: 2% 0 0 0;
}

.SViewProfile_right__2qSpD {
  background-color: white;
  margin-top: 9%;
  /* padding-top: 20px; */
  margin-left: 10%;
}

.SViewProfile_right_table1__2P3h- {
  min-width: 230px;
}

.SViewProfile_category__2kBAk {
  opacity: 0.6;
  color: #333333;
  font-size: 0.8rem;
  margin: 2px;
  padding: 3px;
  width: 27%;
}

.SViewProfile_value__1Uwvs {
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  font-weight: 600;
  margin: 3px;
  padding: 3px;
  width: 70%;
}

.SViewProfile_row2__28buJ {
  background-color: white;
  border-bottom: 1px solid #dedede;
  height: 12%;
  padding: 15px;
  display: -webkit-flex;
  display: flex;
}

.SViewProfile_row2left__XG9JS {
  width: 24%;
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.SViewProfile_row2right__3tBL2 {
  width: 76%;
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
  text-align: justify;
}

.SViewProfile_lastview__2b8fI {
  text-align: end;
  padding: 1% 2% 1% 0;
}
.SViewProfile_lastview__2b8fI label {
  cursor: pointer;
}

.SViewProfile_lastview__2b8fI label:hover {
  color: #110766;
  font-weight: 700;
}

.SViewProfile_row3__3lwUi {
  background-color: white;
  padding: 15px;
}

.SViewProfile_upper__2iJgC {
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
}

.SViewProfile_upperleft__1s6HR {
  background-color: white;
  width: 24%;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.SViewProfile_upperright__a74Ls {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
  width: 76%;
  text-align: justify;
}

.SViewProfile_lower__KrHcO {
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
}

.SViewProfile_lowerleft__2PYGc {
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
  width: 24%;
}
.SViewProfile_abutton__YJyr3 {
  /* margin: 3% 0% 1% 20%; */
  display: -webkit-flex;
  display: flex;
  margin-left: 5%;
}
.SViewProfile_display__11g-h {
  display: -webkit-flex;
  display: flex;
}
.SViewProfile_lowerright__1dzDH {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  width: 76%;
  color: #333333;
  text-align: justify;
}

.SViewProfile_row4__35NWh {
  padding: 15px;
  border-bottom: 1px solid #dedede;
  color: black;
  margin-top: 4%;
}

.SViewProfile_row5__2Q4et {
  background-color: white;
  /* border-bottom: 1px solid #DEDEDE; */
  height: 88px;
  padding: 15px;
  display: grid;
  /* grid-template-rows: 150px 150px; */
  /* grid-template-columns: repeat(3, 120px) 0px; */
  grid-template-columns: 12% 60%;
}

.SViewProfile_row5left__Hslhc {
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.SViewProfile_row5right__2zsKC {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
}

/* @media screen and (max-width: 1368px) {
  .row4 {
    margin-top: 12%;
  }
} */
@media screen and (max-width: 1368px) {
  .SViewProfile_row4__35NWh {
    margin-top: 12%;
  }
  .SViewProfile_heading__36Rzq p {
    margin-top: 2%;
  }
}
@media only screen and (max-width: 750px) {
  .SViewProfile_value__1Uwvs {
    width: 100%;
  }
  .SViewProfile_display__11g-h {
    display: block;
  }
  .SViewProfile_centered__3oIXC {
    left: 0;
    width: 100vw;
  }
  .SViewProfile_sectionStyle__1c6Mn {
    /* / display: grid; / */
    grid-template-columns: 1px 1fr;
  }
  .SViewProfile_reject__37c2v {
    margin-top: 0.2rem;
    margin-left: 0;
    width: 110px;
  }
  .SViewProfile_accept__231VT {
    width: 110px;
  }
  .SViewProfile_row1box__ogWkS {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .SViewProfile_middle__37-TV,
  .SViewProfile_right__2qSpD {
    margin-left: 0;
  }
  .SViewProfile_row2__28buJ {
    display: block;
  }
  .SViewProfile_upper__2iJgC {
    display: block;
  }
  .SViewProfile_lower__KrHcO {
    display: block;
  }
  .SViewProfile_accepted__2E31I {
    margin-left: 40vw;
  }
  /* .backarrow {
    margin-left: -30%;
    margin-top: 3%;
  }
  .para {
    margin-left: 8%;
    margin-top: 13%;
  }
  .abutton {
    margin: -19% 0% 1% 54%;
  } */
  .SViewProfile_lowerright__1dzDH {
    display: block;
  }
  .SViewProfile_changeDiv__2CbjR {
    left: 2%;
    top: 18%;
  }
}

@media only screen and (max-width: 1080px) {
  .SViewProfile_centered__3oIXC {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 86%;
    height: 84%;
    background-color: #ffffff;
    position: absolute;
    /* margin-top: -3%; */
    left: 12%;
  }
}

.RejectModal_container__22vBZ {
  display: block;
  background-color: #fafbfc;
  border-radius: 30px;
  padding: 20px 25px 20px 25px;
  margin-left: 1%;
  width: 450px;
  height: 280px;
}

.RejectModal_msg1__3_U1u {
  font-size: 1.1rem;
  line-height: 0.5;
  font-weight: 500;
  padding: 2% 0% 0% 15%;
  opacity: 0.7;
}

/* .msg2 {
    font-size: 0.9rem;
    font-weight: 500;
    padding: 2% 6% 0% 0%;
    opacity: 0.7;
    text-align: center;
  }
   */
.RejectModal_leftImg__d4UPj img {
  width: 40%;
  padding: 6% 0% 0% 25%;
}

.RejectModal_closeModal__1tJR1 {
  float: right;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.RejectModal_signin1__s-jau {
  display: block;
  background-color: #110766;
  opacity: 1;
  width: 78%;
  padding: 10px 0px 10px 8px;
  border-radius: 14px;
  box-sizing: border-box;
  margin: 40px 0 0 40px;
  color: white;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}
.RejectModal_input__uVelT {
  font-size: 13px;
  padding: 0 10px;
  border-radius: 6px;
  width: 90%;
  opacity: 1;
  height: 120px;

  border-color: #b9b9b9;
  border: 1pt solid #b9b9b9;
  resize: none;
}
.RejectModal_input__uVelT:focus {
  outline: #b9b9b9;
}
@media only screen and (max-width: 700px) {
  .RejectModal_container__22vBZ {
    width: 258px;
  }
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_container__XC7Bk {
  border: 1px solid #cfcfd2;
  margin: 13px;
 cursor: pointer;
}

.cards_container__XC7Bk:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_card__2g2P5 {
  /* border: 1px solid #CFCFD2; */
  /* margin: 13px; */
  padding: 10px 10px 10px 10px;
  /* height: 100px; */
  display: grid;
  grid-template-columns: 20% 40% 40%;
  overflow: hidden;
  /* font-family: 'Poppins', sans-serif; 29.28vw*/
}

/* .card:hover {
    box-shadow: 10px 10px 12px -4px #00000015;
    transform: scale(1, 1);
} */

.cards_left__3ygZp {
  width: 100px;
  /* height: 85px; */
}

.cards_left__3ygZp img {
  height: 100%;
  width: 95%;
}

.cards_middle__RyuHi p {
  font-size: 15px;
  margin: -6px 0px 0px 17px;
}

.cards_table1__1yBJi {
  font-size: 15px;
  min-width: 230px;
  margin: 0px 0px 0px 13px;
}

.cards_right_table1__3AjHW {
  min-width: 230px;
  margin: 15px;

}

.cards_category__yD-z3 {
  opacity: 0.6;
  color: #333333;
  /* font-size: 0.86vw; */
  font-size: 0.8rem;
  font-weight: bold;
  width: 6vw;
  border: 1px solid transparent;
  /* margin: 2px; */
  /* padding: 2px; */
  border-radius: 10px;
  
}


.cards_value__12g5W {
  /* opacity: 1; */
  /* color: #333333; */
  /* font-size: 0.86vw; */
  /* border: 1px solid transparent; */
  border-radius: 10px;
  /* margin: 2px; */
  /* padding: 2px; */
  border: 1px solid transparent;
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  width: 70%;
  font-weight: 600;
  margin-left: 1rem;
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_request__3ma7j {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-top: 1px solid #cfcfd2;
}

.cards_request__3ma7j p {
  margin: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
}

.cards_request__3ma7j:hover {
  background-color: #0f065f;
  color: white;
}

.cards_request__3ma7j:hover p {
  background-color: #0f065f;
  color: white;
}

@media only screen and (max-width: 678px){
  .cards_card__2g2P5{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .cards_value__12g5W {
   padding-left: 2rem;
  }
 .cards_left__3ygZp{
   width: 250px;
   height: 150px;
 }
}
.student_container__OflES {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.student_background_img__G9zMo {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.student_centered_text__3OrbC {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.student_centered_button__2Xnso {
  opacity: 1;
}

.student_sectionStyle__2FZe4 {
  display: grid;
  grid-template-columns: 10% 90%;
}

.student_headerStyle__1LgYe {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.student_heading__23jjh {
  display: grid;
  grid-template-columns: 200px 740px 300px;
}

.student_backarrow__2i6Tl {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 1rem;
  /* background-color: red; */
  /* width: 5rem; */
  margin-left: 10vw;
  margin-top: 1rem;
}

.student_backarrow__2i6Tl img {
  width: 20px;
  cursor: pointer;
}

.student_text__3cM5a {
  /* background-color: saddlebrown; */
  font-weight: bold;
  margin: 30px;
  margin-left: -35px;
  margin-top: 8px;
}

.student_filter__199SI {
  /* background-color: seagreen; */
  margin-left: 75px;
}

.student_filter__199SI img {
  width: 45px;
  margin-top: 14px;
  cursor: pointer;
}

.student_centered__2zBsy {
  /* font-family: 'Poppins-medium';
    width: 70%;
    height: 100%;
    background-color: #FFFFFF;
    position: absolute;
    top: 19%;
    left: 20%; */
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
}

.student_changeDiv__1kDFG {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 45%;
  left: 20%;
  z-index: 150;
  margin: 15% 0% 0 5%;
  /* top: 30%; */
  position: absolute;
}

.student_addBlurr__3iFHp {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.student_header__3mAIM {
  width: 80%;
  height: 10%;
  margin: -3px 0px 0px 135px;
  /* background-color: red; */
}

.student_keyword__3FHOk {
  width: 85%;
  height: 66%;
  border: 1px solid #dbdbdb;
  background-color: white;
  margin: 13px 0px 0px 0px;
  display: -webkit-flex;
  display: flex;
}

.student_keyword__3FHOk img {
  width: 18px;
  padding: 10px;
}

.student_input__IH6QK {
  border: none;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
}

.student_options__3K3Xi img {
  width: 40px;
  float: right;
  margin: -40px 72px 0px 0px;
}

@media only screen and (max-width: 750px) {
  .student_centered__2zBsy {
    width: 100vw;
    left: 0;
  }
  .student_backarrow__2i6Tl {
    margin-left: -57%;
  }
}

@media only screen and (max-width: 1080px) {
  .student_centered__2zBsy {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 86%;
    height: 85%;
    background-color: #ffffff;
    position: absolute;
    top: 10%;
    left: 12%;
  }
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_container__1pvam {
  border: 1px solid #cfcfd2;
  margin: 13px;
  /* padding: 10px 10px 10px 10px; */
  /* height: 140px; */
  cursor: pointer;
}

.cards_container__1pvam:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_card__Jr8_0 {
  /* border: 1px solid #CFCFD2; */
  /* margin: 13px; */
  padding: 10px 10px 10px 10px;
  /* height: 100px; */
  display: grid;
  grid-template-columns: 95px 300px 368px;
  overflow: hidden;
  /* font-family: 'Poppins', sans-serif; */
}

.cards_nodata__ytLSz {
  overflow: hidden;
  font-weight: 500;
  width: 100%;
  height: auto;
  max-height: 75%;
  background-color: #ffffff;
  top: 19%;
  left: 20%;
}

/* .card:hover {
    box-shadow: 10px 10px 12px -4px #00000015;
    transform: scale(1, 1);
} */

.cards_left__3UlcX {
  width: 100px;
  height: 85px;
}

.cards_left__3UlcX img {
  height: 90%;
  width: 95%;
}
.cards_mainDiv__Ldrcd{
  display: -webkit-flex;
  display: flex;
   margin: 5px;
}
.cards_middle__1U9a7 p {
  font-size: 15px;
  margin: -6px 0px 0px 17px;
}

.cards_table1__3-2n4 {
  font-size: 15px;
  min-width: 100%;
  margin: 0px 0px 0px 13px;
}

.cards_right_table1__3bHEx {
  min-width: 100%;
  margin: 15px;
}

.cards_category__1TpK7 {
  opacity: 0.6;
  color: #333333;
  width: 6vw;
  border: 1px solid transparent;
  font-size: 0.8rem;
  border-radius: 10px;
}

.cards_value__2RWcQ {
  opacity: 1;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
  border-radius: 10px;
  width: 70%;
  font-weight: 600;
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_request__1F_jT {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #cfcfd2;
   width: 100%;
   height: 35px;
 /* margin-left: 1.4%; */
 margin-top: -1.5%;
}

.cards_request__1F_jT p {
  /* border: 1px solid #cfcfd2; */
 
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
}

.cards_request__1F_jT:hover {
  background-color: #0f065f;
  color: white;
}

.cards_request__1F_jT:hover p {
  background-color: #0f065f;
  color: white;
}

@media screen and (max-width:767px){
  .cards_mainDiv__Ldrcd{
    display: block;
     /* margin: 5px; */
  }
  .cards_left__3UlcX {
    grid-column: 1 / 4;
  }
  .cards_table1__3-2n4{
    margin: 0px 0px 0px -7px;
  }
  .cards_left__3UlcX img {
    height: 90%;
    width: 100%;
    /* margin-top: 36%; */
   
  }

  .cards_card__Jr8_0 {
    
    /* padding: 10px 10px 10px 10px; */
    display: grid;
  }
  .cards_middle__1U9a7 p{
    margin: -6px 0px 0px 1px;
  }
  .cards_middle__1U9a7 {
    margin: 0;
    width: 100vw;
  }
  .cards_right_table1__3bHEx {
    min-width: 100vw;
    margin-top: 37vh;
    margin-left: -27vw
  }
  .cards_value__2RWcQ {
    opacity: 1;
    color: #333333;
    font-size: 12px;
    font-weight: bold;
    border-radius: 10px;
    width: 80vw;
    font-weight: 600;
  }
}

.student_container__12hU8 {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100vh;
  width: 100%;

  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.student_background_img__2MU2f {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}
.student_headerStyle__Cf_Dk {
  width: 100vw;
}
.student_loader__qF9-9 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.student_nodata__3UEQ_ {
  overflow: hidden;
  font-weight: 500;
  width: 70%;
  height: auto;
  max-height: 75%;
  background-color: #ffffff;
  top: 19%;
  left: 20%;
}
.student_footer__C3awG {
  width: 70.3%;
  height: 14%;
  background-color: transparent;
  position: absolute;
  top: 95%;
  left: 19.1%;
}

.student_centered_text__1OkZk {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.student_centered_button__2WlOX {
  opacity: 1;
}

.student_sectionStyle__3m0V4 {
  display: grid;
  grid-template-columns: 10% 90%;
}

.student_headerStyle__Cf_Dk {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.student_heading__7z1vQ {
  display: grid;
  grid-template-columns: 200px 740px 300px;
}

.student_backarrow__3Dnwy {
  width: 0%;
  margin-left: 135px;
  margin-top: 25px;
}

.student_backarrow__3Dnwy img {
  width: 20px;
}

.student_text__WRhpp {
  font-weight: bold;
  margin: 30px;
  margin-left: -35px;
  margin-top: 8px;
}

.student_filter__1czJp {
  margin-left: 75px;
}

.student_filter__1czJp img {
  width: 45px;
  margin-top: 14px;
  cursor: pointer;
}

.student_centered__3nYWk {
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.student_changeDiv__3Ctgk {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 48%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 30%;
  position: absolute;
}
.student_changeDiv1__20sDN {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;

  left: 38%;
  z-index: 150;

  top: 23%;

  position: absolute;
}
.student_changeDiv3__3k-70 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 40%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 12%;
  position: absolute;
}
.student_addBlurr__wpoGu {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

.student_header__5DoxC {
  width: 83%;
  height: 6%;
  left: 24%;
  margin-top: 1%;
}

.student_keyword__1hb0Q {
  height: 65%;

  border: 1px solid #dbdbdb;
  background-color: white;
  display: -webkit-flex;
  display: flex;
  margin-left: 13.5%;
  margin-top: 1%;
}

.student_keyword__1hb0Q img {
  width: 18px;
  padding: 10px;
}

.student_input__22KI8 {
  border: none;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
  width: 100%;
}
.student_input__22KI8:focus {
  outline: none;
}
.student_options__1SD3R img {
  width: 40px;
  height: 37.5px;
  float: right;

  margin-top: -2.3rem;
  margin-right: -3rem;
}

@media screen and (max-width: 767px) {
  .student_centered__3nYWk {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 93%;
    margin-top: 1rem;
    background-color: #ffffff;
    position: absolute;
    top: 18%;
    left: 5%;
    font-family: "Poppins-medium";
    height: 74%;
  }
  .student_sectionStyle__3m0V4 {
    grid-template-columns: 0px 1fr;
  }
  .student_topHeading__3uXvG {
    padding-top: 1rem;
  }
  .student_header__5DoxC {
    margin-top: -0.8rem;
  }
  .student_keyword__1hb0Q {
    padding: 0.5rem;
    height: 109%;
    margin-left: 4.5%;
    position: -webkit-sticky;
    position: sticky;
  }

  .student_headerStyle__Cf_Dk {
    width: 100vw;
  }
  .student_options__1SD3R img {
    width: 40px;
    height: 37.5px;
    float: right;
    margin-right: -2rem;
  }

  .student_changeDiv__3Ctgk {
    width: 97%;
    left: 1%;
  }
  .student_changeDiv1__20sDN {
    left: 2%;
  }
}
@media screen and (max-width: 767px) {
  /* .leftSection{
    width: 16vw;
  } */
  .student_sidebar___2lm3 {
    width: 80vw;
    height: 57rem;
    background-color: #393939;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 6vh;
  }
  /* .menu {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  } */
  .student_leftSection__X0h0q {
    width: 0vw;
    height: 57rem;
    background-color: #393939;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 6vh;
  }
  .student_searchImg__ZvmDJ {
    margin: 5% 10% 2% 25%;
    width: 35.56vw;
    height: 4.21vh;
  }
  .student_span__1-Ldm {
    margin-left: 25%;
    display: -webkit-flex;
    display: flex;
    font-size: 6.5vw;
  }
  .student_changeDiv3__3k-70 {
    width: 93%;
    left: 3%;
    top: 2%;
  }
  .student_changeDiv2__1EHsg {
    width: 85%;
    left: 5%;
  }
}

@media screen and (max-width: 1080px) {
  .student_centered__3nYWk {
    width: 86%;
    height: 77%;
    background-color: #ffffff;
    position: absolute;
    top: 16%;
    left: 12%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .student_header__5DoxC {
    width: 80%;
    height: 6%;
    position: absolute;
    left: 7%;
    top: 10%;
  }
  .student_changeDiv__3Ctgk,
  .student_changeDiv1__20sDN,
  .student_changeDiv2__1EHsg {
    background-color: #fafbfc;
    border-radius: 30px 30px 30px 30px;
    width: 60%;
    height: 36%;
    left: 24%;
    z-index: 150;
    margin: 15% 0% 0 5;
    top: 30%;
    position: absolute;
  }
  .student_changeDiv3__3k-70 {
    background-color: #fafbfc;
    border-radius: 30px 30px 30px 30px;
    width: 73%;
    height: 43%;
    left: 18%;
    z-index: 150;
    margin: 15% 0% 0 5;
    top: 30%;
    position: absolute;
  }
  .student_headerStyle__Cf_Dk {
    padding: 0;
  }
}

.sendrequest_centered__wgrBF {
  /* background-color: rgb(171, 171, 245); */
  /* background-color: whitesmoke; */
  /* margin-left: 450px; */
  /* margin-right: 450px; */
  /* height: 300px; */
  /* width: 500px; */
  /* margin-top: 164px; */
  /* border-radius: 15px; */
  padding-left: 25px;
}
.sendrequest_footer__12Hax {
  margin-bottom: 3%;
}

.sendrequest_header__r3I3s {
  display: grid;
  grid-template-rows: repeat(2, 30px) 0px;
  padding: 15px;
}
.sendrequest_form__2lG01 {
  overflow: scroll;
  max-height: 100px;
}
.sendrequest_btn__2a2S7 {
  display: -webkit-flex;
  display: flex;
  margin: 4% 4% 4% 20%;
}

.sendrequest_upper__iFiu2 {
  background-color: #fafbfc;
}

.sendrequest_upper__iFiu2 img {
  background-color: #fafbfc;
  float: right;
  margin-top: 4px;
}

.sendrequest_lower__2eW-0 {
  background-color: #fafbfc;
}

.sendrequest_lower__2eW-0 p {
  background-color: #fafbfc;
  font-weight: 700;
  margin-top: 0px;
}

/* .form {
    padding: 15px;
    margin-top: -30px;
}

.form label {
    font-size: 15px;
    
    color: #919191;
} */

.sendrequest_reset__2oy3u {
  background-color: #ffffff;
  border: 1px solid #1e118d;

  border-radius: 10px;
  color: #1e118d;
  padding: 5px;
  float: right;
  margin-left: 370px;
  width: 57px;
}

.sendrequest_reset__2oy3u btn {
  float: right;
}

.sendrequest_apply__hfa2N {
  background-color: #1e118d;
  border: 1px solid #1e118d;

  border-radius: 10px;
  color: #ffffff;
  padding: 5px;
  float: right;
  margin-left: 277px;
  width: 57px;
}

/* .inputleft input {
    width: 75%;
    height: 100%;
    border-radius: 7px;
    border: 1px solid #B9B9B9;
    
    font-size: 11pt;
    margin: 0px;
} */

.sendrequest_requestinput__2v1Qj {
  width: 88%;
  border-radius: 7px;
  border: 1px solid #b9b9b9;

  font-size: 11pt;
  margin: 5px;
  padding: 10px;
}

.sendrequest_radioinput__1xogm {
  margin: 100%;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
}

.sendrequest_radiodiv1__1Ed7W {
  /* width: 88%;
    height: 17px; */
  border-radius: 7px;
  border: 1px solid #b9b9b9;

  font-size: 11pt;
  margin: 5px;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
}

.sendrequest_radiodiv1__1Ed7W p {
  width: 70%;
  margin: 0% 0% 0% 2%;
  font-size: 12px;
}

.sendrequest_radiodiv1__1Ed7W input {
  float: right;
  margin: 0% 0% 0% 24%;
  color: #1e118d;
}

.sendrequest_radiodiv2__2i1dj {
  border-radius: 7px;
  border: 1px solid #b9b9b9;

  font-size: 11pt;
  margin: 12px 0px 0px 7px;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
}

.sendrequest_radiodiv2__2i1dj p {
  width: 70%;
  margin: 0% 0% 0% 2%;
  font-size: 12px;
}

.sendrequest_radiodiv2__2i1dj input {
  float: right;
  margin: 0% 0% 0% 24%;
  color: #1e118d;
}
.sendrequest_blueButton__3cwpV {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #110766;
  font-weight: 500;
  width: 9.8vw;
  height: 5.32vh;
  margin: 6px 0px 0px 14px;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  font-size: 1vw;
  cursor: pointer;
}
.sendrequest_blueButton1__2kJaf {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #11076652;
  font-weight: 500;
  width: 9.8vw;
  height: 5.32vh;
  margin: 6px 0px 0px 14px;
  border-radius: 14px;
  border: 1px solid #11076652;
  opacity: 0.5;
  color: #ffffff;
  text-align: center;
  font-size: 1vw;
  cursor: default;
}
@media only screen and (max-width: 700px) {
  .sendrequest_btn__2a2S7 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 8%;
    margin-left: -10%;
  }
  .sendrequest_blueButton__3cwpV {
    width: 20.8vw;
    font-size: 3vw;
  }
  .sendrequest_blueButton1__2kJaf {
    width: 20.8vw;
    font-size: 3vw;
  }
}

@media only screen and (max-width: 1080px) {
  .sendrequest_blueButton1__2kJaf {
    height: 4.32vh;
  }
  .sendrequest_btn__2a2S7 {
    margin-top: 9%;
  }
}

.AdvanceFilter_container__1Ce-_ {
  /* width: 35%; */
  border-radius: 30px;
  background-color: #ffffff;
}

.AdvanceFilter_closeModal__3le1O {
  float: right;
  width: 25px;
  height: 25px;
  margin: 2% 4% 0 0;
  cursor: pointer;
}
.AdvanceFilter_whiteButton__2YR8k {
  box-shadow: 0 0px 2px 2px #0000001f;
  background-color: #ffffff;
  width: 134px;
  height: 35px;
  margin: 6px 0px 0px 14px;
  font-weight: 500;
  border-radius: 14px;
  border: 1px solid #110766;
  opacity: 1;
  color: #110766;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.AdvanceFilter_para__1FtdN {
  font-size: 18px;
  padding: 2% 0% 0% 7%;
}

.AdvanceFilter_form1__3x6PX {
  display: block;
}

.AdvanceFilter_label__11lk5 {
  display: -webkit-inline-flex;
  display: inline-flex;
  color: #919191;
  font-size: 11pt;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 18px 0 5px 40px;
  font-weight: 500;
}
.AdvanceFilter_label2__37gw6 {
  display: -webkit-inline-flex;
  display: inline-flex;
  color: #919191;
  font-size: 11pt;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 8px 0 5px 40px;
  font-weight: 500;
}

.AdvanceFilter_input__18Hdg {
  display: -webkit-flex;
  display: flex;
  width: 64%;
  height: 23px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0.5% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 6pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 11pt;
  margin-bottom: -1rem;
}

.AdvanceFilter_input__18Hdg:focus,
.AdvanceFilter_input1__bY476.AdvanceFilter_focus__O3xDS {
  border: 1pt solid #b9b9b9;
}

.AdvanceFilter_subheading__3jU2A {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: right;
          justify-content: right;
  margin: 10px 0 5px 40px;
  font-weight: 600;
  margin-top: 4%;
  color: #333333;
  font-size: 12pt;
}

.AdvanceFilter_input1__bY476 {
  display: -webkit-flex;
  display: flex;
  width: 90%;
  height: 23pt;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1% 13% 1% 4%;
  margin-left: 40px;
  border-radius: 6pt;
  border: 1pt solid #b9b9b9;
  opacity: 0.5;
  font-size: 11pt;
  margin-bottom: -1rem;
}

.AdvanceFilter_input1__bY476:focus {
  border: 1pt solid #7e7e7e;
}

.AdvanceFilter_fourth__3IV3g {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.AdvanceFilter_fourth2__1l7FL {
  margin-left: 55px;
}

.AdvanceFilter_btn_css__1dS9j {
  position: relative;
  width: 100%;
  padding: 28px 14px 30px 219px;
}
.AdvanceFilter_date_style__3o_e5 {
  margin-left: 109%;
  margin-top: -3%;
  cursor: pointer;
}
.AdvanceFilter_date_style1__32DrZ {
  margin-left: 109%;
  cursor: pointer;
}
.AdvanceFilter_Calendar__3Ohup {
  margin-top: -7%;
  margin-left: 7%;
  width: 100px;
}
.AdvanceFilter_errorMsgbelow__2DrVd {
  font-size: 0.7rem;
  color: red;

  margin-top: 5%;
  margin-left: 6%;
}
@media screen and (max-width: 775px) {
  .AdvanceFilter_whiteButton__2YR8k {
    width: 20.8vw;
    font-size: 3vw;
  }
  .AdvanceFilter_btn_css__1dS9j {
    padding: 28px 14px 30px 53px;
  }
  .AdvanceFilter_date_style1__32DrZ {
    margin-left: 85%;
    position: absolute;
    top: 185%;
  }
  .AdvanceFilter_date_style__3o_e5 {
    margin-left: 86%;
    position: absolute;
    top: 156%;
  }
  .AdvanceFilter_fourth__3IV3g {
    display: block;
  }
  .AdvanceFilter_input1__bY476 {
    width: 63%;
  }
  .AdvanceFilter_fourth2__1l7FL {
    margin-left: 3px;
    margin-top: 5%;
  }
  .AdvanceFilter_subheading__3jU2A {
    margin: 20px 0 5px 40px;
  }
  .AdvanceFilter_Calendar__3Ohup {
    position: absolute !important;
    left: 5% !important;
  }
}

@media screen and (max-width: 1080px) {
  .AdvanceFilter_btn_css__1dS9j {
    padding: 0;
    margin-top: 45px;
  }
  .AdvanceFilter_headerStyle__I1Dm6 {
    padding: 0;
  }
}

.notification_container__3MlLM {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100%;
}

.notification_background_img__2grtq {
  width: 80%;
  height: 110%;
  margin: -3% 8;
}

.notification_centered_text__30oNy {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.notification_sectionStyle__3iQv1 {
  display: grid;
  grid-template-columns: 6.7% 90%;
}

.notification_loader__QzEQ- {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notification_headerStyle__1epAc {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.notification_centered__3ghL0 {
  font-family: "Poppins-medium";
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.notification_topHeading__KHOvy {
  text-align: center;
  /* position: relative; */
  margin: 1.5% 0 0 -62%;
}
.notification_row__3H4Zo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 8%;
  /* background-color: aquamarine; */
}
.notification_span__Pnnfb {
  font-size: 12pt;
  font-family: "Poppins" "Medium";
  color: #333333;
  max-width: 300px;
  margin: 1.2% 0% 0% 3%;
}
.notification_timespan__1EVGS {
  position: absolute;
  font-size: 7pt;
  color: #333333;
  opacity: 0.6;
  margin: 1rem;
  right: 0;
}
@media screen and (max-width: 700px) {
  .notification_centered__3ghL0 {
    width: 92%;
    left: 5%;
  }
  .notification_topHeading__KHOvy {
    margin: 1.5% 0 0 -78%;
  }
}

@media screen and (max-width: 1080px) {
  .notification_headerStyle__1epAc {
    padding: 0;
  }
  .notification_centered__3ghL0 {
    font-family: "Poppins-medium";
    width: 87%;
    height: 75%;
    background-color: #ffffff;
    position: absolute;
    top: 10%;
    left: 12%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_container__OvwKG {
  border: 1px solid #cfcfd2;
  margin: 13px;
  /* padding: 10px 10px 10px 10px; */
  /* height: 100px; */
  cursor: pointer;
}

.cards_container__OvwKG:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_card__H4Ri7 {
  /* border: 1px solid #CFCFD2; */
  /* margin: 13px; */
  padding: 10px 10px 10px 10px;
  /* height: 100px; */
  display: grid;
  grid-template-columns: 95px 400px 380px;
  overflow: hidden;
  /* font-family: 'Poppins', sans-serif; */
}

/* .card:hover {
    box-shadow: 10px 10px 12px -4px #00000015;
    transform: scale(1, 1);
} */

.cards_right__2hptM {
  width: 100px;
  height: 85px;
  margin-left: 50vw;
}

.cards_left__2pnEX img {
  height: 50px;
  width: 50px;
  margin-right: 2%;
}

.cards_left__2pnEX {
  color: black;
  width: 55vw;
  display: -webkit-flex;
  display: flex;
}
.cards_left__2pnEX p {
  margin: 2px;
}
.cards_time__2SF1f {
  text-align: left;
  font: normal normal normal 12px/30px Poppins;
  letter-spacing: -0.12px;
  color: #333333;
  opacity: 0.6;
}

.cards_orgname__3UzPw {
  text-align: left;
  font: normal normal normal 15px/30px Poppins;
  letter-spacing: -0.15px;
  color: #333333;
  opacity: 1;
}

.cards_oppdes__1Wq18 {
  text-align: left;
  font: normal normal 600 20px/32px Poppins;
  letter-spacing: -0.2px;
  color: #333333;
  opacity: 1;
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

/* .request p {
  margin: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
} */

.cards_request__3U6_2:hover {
  background-color: #0f065f;
  color: white;
}

.cards_request__3U6_2:hover p {
  background-color: #0f065f;
  color: white;
}
@media screen and (max-width: 575px) {
  .cards_right__2hptM {
    width: 75px;
    height: 75px;
    /* margin-left: 29vw; */
    margin-left: 59vw;
    margin-top: -3vh;
  }
  .cards_left__2pnEX {
    width: 80vw;
  }
  .cards_card__H4Ri7 {
    display: block;
  }
}

.cards_maincontainer__AVwqU {
  background-color: white;
  height: 14rem;
}

.cards_maincard__3bWos {
  display: grid;
  grid-template-rows: 0px 150px;
  margin-left: 10px;
}

.cards_card__1erGV {
  border: 1px solid #cfcfd2;
  margin: 15px 0px 0px 0px;
  padding: 16px;
  height: 158px;
  width: 94%;
}

/*.card:hover {
    box-shadow: 20px 20px 18px -8px #00000015;
    transform: scale(1, 1);
}*/

.cards_square__2-4rf {
  margin-left: 1px;
  margin-top: 16px;
  width: 25px;
  height: 25px;
  background-image: -webkit-linear-gradient(-45deg, #36b453 50%, #ffffff 50%);
}

.cards_box1__3rARU {
  width: 80%;
}

.cards_heading__97LP7 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  /* background-color: #1E118D; */
  height: 50px;
}
.cards_constantImg__jHuDb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: -10px;
}
.cards_constantImg1__3sESV {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.cards_lefthead__34-PX {
  font-size: 0.9rem;
  width: 94%;
  font-weight: 700;
}

.cards_lefthead__34-PX p {
  margin: 0px 0px 0px 4px;
}

.cards_lefthead__34-PX p {
  opacity: 0.6;
  color: #333333;
  font-size: 8px;
  margin: 5px;
}

.cards_detail__Kzudx {
  /* background-color: #b63f49; */
  width: 38px;
}

.cards_detail__Kzudx img {
  width: 24px;
  height: 25px;
  left: 665px;
  top: 44px;
  opacity: 1;
}

.cards_more__1otQ3 {
  /* background-color: #3a48c5; */
  width: 38px;
  cursor: pointer;
}

.cards_more__1otQ3 img {
  cursor: pointer;
  z-index: 1;
}
.cards_lefthead1__3KQXE {
  width: 94%;
  font-weight: 700;
}

/* .box1 img {
    width: 24px;
    height: 21px;
    left: 665px;
    top: 44px;
    
} */

.cards_table__x4mhP {
  font-size: 15px;
  min-width: 548px;
  /* margin: 25px 0px; */
}

.cards_table__x4mhP tbody {
  margin: 25px;
}

.cards_shelf__gHd-q {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 93%;
  margin-bottom: 0.8%;
  margin-left: 1%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.cards_shelf__gHd-q:last-child {
  margin-bottom: 0;
}

.cards_labelbox__17gvU {
  -webkit-flex: 0 0 35%;
          flex: 0 0 35%;
  opacity: 0.6;
  color: #333333;
  font-size: 12px;
}

.cards_valuebox__3CJBR {
  -webkit-flex: 0 0 65%;
          flex: 0 0 65%;
  opacity: 1;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}

.cards_box2__2VYvK {
  background-color: white;
}

.cards_card_image__3nDJl img {
  height: 10rem;
  width: 10rem;
  margin-top: -11.7rem;
  margin-right: 10px;
  float: right;
}

.cards_card_info__1khke {
  width: 30px;
}

.cards_appliedImg__1_X_T {
  height: 10%;
}

.cards_left__8WBS4 img {
  width: 45px;
  height: 40px;
  margin-top: -3px;
}

.cards_left__8WBS4 p {
  color: #1e118d;
  margin-left: 62px;
  margin-top: -40px;
  font-size: 15px;
  font-weight: bold;
}

.cards_eye__2pPKz {
  margin-right: 4%;
    margin-top: -14%;
}

.cards_right__jnPTr img {
  width: 32px;
  height: 34px;
  margin-left: 257px;
  margin-top: -3px;
}

.cards_right__jnPTr p {
  color: #747474;
  opacity: 1;
  font-size: 13px;
  margin-left: 300px;
  margin-top: -33px;
}

@media screen and (max-width: 1500px) {
  .cards_lefthead__34-PX {
    width: 84%;
  }
  .cards_lefthead1__3KQXE {
    width: 83%;
  }
}
@media screen and (max-width: 1300px) {
  .cards_lefthead__34-PX {
    width: 80%;
  }
  .cards_lefthead1__3KQXE {
    width: 78%;
  }
}
@media screen and (max-width: 1150px) {
  .cards_lefthead__34-PX {
    width: 76%;
  }
  .cards_lefthead1__3KQXE {
    width: 74%;
  }
}
@media screen and (max-width:575px){
  .cards_card_image__3nDJl img{
     height: 5rem;
      width: 5rem;
      margin-top: -49.7%;
      margin-right: 1px;
  }
  .cards_box1__3rARU{
    width: 100%;
  }
}
.s_opportunities_container__3vwjt {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100%;
}
.s_opportunities_changeDiv1__1WuAT {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 40%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 12%;
  position: absolute;
}

/* .heading {
    height: 60px;
    display: grid;
    grid-template-columns: 200px 740px 300px;
}
.arrow img {
    width: 10%;
    margin: 35px 0px 0px 160px;
}
.text p {
    font-weight: bold;
    margin: 30px;
    margin: 30px 0px 0px 0px;
}*/

.s_opportunities_filter__cImsD {
  height: 50px;
  margin: -10px 0 0px 175px;
  cursor: pointer;
}

.s_opportunities_centered_text__Oq-NH {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.s_opportunities_topHeading__5M_Ip {
  width: 100%;
  height: 5%;
  margin: 2% 0 0 0;
  margin: 2% 0 0 0;
  padding: 0% 11%;
}

.s_opportunities_labelImg__3a35o {
  height: 14px;
  cursor: pointer;
}

.s_opportunities_label__3wtz_ {
  /* float: left; */
  color: #24242e;
  font-weight: 600;
  font-size: 12pt;
  margin: 0px 0 35px 13px;
}

.s_opportunities_centered_button__3nehQ {
  opacity: 1;
}

.s_opportunities_sectionStyle__1a7ca {
  display: grid;
  grid-template-columns: 10% 90%;
}

.s_opportunities_headerStyle__3Tomd {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.s_opportunities_centered__3nFiQ {
  width: 70%;
  height: 75%;
  background-color: #f7f7f7;
  position: absolute;
  top: 19%;
  left: 20%;
  overflow-y: auto;
}

.s_opportunities_header__3yeM1 {
  width: 70.3%;
  height: 14%;
  background-color: #f7f7f7;
  position: absolute;
  top: 10%;
  left: 19.1%;
}

.s_opportunities_footer__3CQNW {
  width: 70.3%;
  height: 14%;
  background-color: #f7f7f7;
  position: absolute;
  top: 94%;
  left: 19.1%;
}

.s_opportunities_keyword__3vpxE {
  width: 94%;
  height: 42%;
  border: 1px solid #dbdbdb;
  background-color: white;
  margin: 10px;
  display: -webkit-flex;
  display: flex;
}

.s_opportunities_keyword__3vpxE img {
  width: 18px;
  padding: 10px;
}

.s_opportunities_input__27Jh8 {
  border: none;
  width: 100%;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
}

.s_opportunities_input__27Jh8:focus {
  outline: none;
}

.s_opportunities_options__og7xi img {
  width: 38px;
  float: right;
  margin: -48px 0px 0px 0px;
}

.s_opportunities_changeDiv__tmxdH {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  /* height: 52%; */
  left: 20%;
  z-index: 150;
  margin: 10% 0% 0 5%;
  /* top: 30%; */
  position: absolute;
  box-shadow: 20pt 23pt 100pt;
}

.s_opportunities_addBlurr__2OI9k {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

@media screen and (max-width: 480px) {
  /* .container {
        background-color: #F7F7F7;
        background-color: red;
        display: grid;
        grid-template-rows: 4rem 37rem;
        height: 100%;
        width: 100%;
    }
    .sidemenu {
        display: none;
    }
    .centered {
        width: 70%;
        height: 75%;
        background-color: #f7f7f7;
        position: absolute;
        top: 19%;
        left: 20%;
        overflow-y: auto;
    } */
}

@media screen and (max-width: 860px) {
  .s_opportunities_headerStyle__3Tomd {
    width: 100vw;
  }
  .s_opportunities_options__og7xi {
    right: -13%;
    top: 55%;
    position: absolute;
  }
  .s_opportunities_header__3yeM1 {
    top: 13%;
    left: 14%;
  }
  .s_opportunities_changeDiv1__1WuAT {
    width: 85%;
    left: 5%;
  }
  .s_opportunities_centered__3nFiQ {
    width: 90%;
    left: 5%;
    top: 22%;
    overflow-x: hidden;
    height: 75vh;
  }
  .s_opportunities_labelImg__3a35o {
    margin-left: -16%;
  }
  .s_opportunities_footer__3CQNW {
    width: 25.3%;
    top: 95%;
    left: 35.1%;
    height: 5%;
  }
}

@media screen and (max-width: 1080px) {
  .s_opportunities_header__3yeM1 {
    top: 9%;
  }
  .s_opportunities_keyword__3vpxE {
    height: 30%;
  }
  .s_opportunities_options__og7xi {
    top: 41%;
  }
  .s_opportunities_centered__3nFiQ {
    width: 90%;
    left: 10%;
    top: 16%;
    overflow-x: hidden;
    height: 75vh;
  }
  .s_opportunities_headerStyle__3Tomd {
    padding: 0;
  }
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_container__2CTNB {
  border: 1px solid #cfcfd2;
  margin: 13px;
  /* padding: 10px 10px 10px 10px; */
  /* height: 100px; */
}

.cards_container__2CTNB:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_card__jgUF1 {
  /* border: 1px solid #CFCFD2; */
  /* margin: 13px; */
  padding: 10px 10px 10px 10px;
  /* height: 100px; */
  display: grid;
  grid-template-columns: 10% 40% 40%;
  overflow: hidden;
  /* font-family: 'Poppins', sans-serif; 29.28vw*/
}

/* .card:hover {
    box-shadow: 10px 10px 12px -4px #00000015;
    transform: scale(1, 1);
} */

.cards_left__1uNDZ {
  width: 100px;
  height: 100px;
  /* height: 85px; */
}

.cards_left__1uNDZ img {
  height: 100%;
  width: 95%;
}

.cards_middle__1FGka p {
  font-size: 15px;
  margin: -6px 0px 0px 17px;
}

.cards_table1__3EO2s {
  font-size: 15px;
  min-width: 230px;
  margin: 0px 0px 0px 13px;
}

.cards_right_table1__1yUNy {
  min-width: 230px;
  margin: 15px;
}

.cards_category__3baeT {
  opacity: 0.6;
  color: #333333;
  /* font-size: 0.86vw; */
  font-size: 0.8rem;
  font-weight: bold;
  width: 6vw;
  border: 1px solid transparent;
  /* margin: 2px; */
  /* padding: 2px; */
  border-radius: 10px;
}

.cards_value__1nBIh {
  /* opacity: 1; */
  /* color: #333333; */
  /* font-size: 0.86vw; */
  /* border: 1px solid transparent; */
  border-radius: 10px;
  /* margin: 2px; */
  /* padding: 2px; */
  border: 1px solid transparent;
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  width: 70%;
  font-weight: 600;
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_request__3RPBs {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-top: 1px solid #cfcfd2;
}

.cards_request__3RPBs p {
  margin: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
}

.cards_request__3RPBs:hover {
  background-color: #0f065f;
  color: white;
}

.cards_request__3RPBs:hover p {
  background-color: #0f065f;
  color: white;
}

@media only screen and (max-width: 650px) {
  .cards_card__jgUF1 {
    display: grid;
    padding: 0;
    grid-template-columns: auto auto auto;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "left . ." "middle right .";
  }
  .cards_middle__1FGka {
    grid-area: middle;
  }
  .cards_right__EOhEH {
    grid-area: right;
  }
  .cards_left__1uNDZ {
    grid-area: left;
    width: 250px;
    height: 150px;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .cards_value__1nBIh {
    margin-left: 3rem;
  }
}

@media only screen and (max-width: 450px) {
  .cards_card__jgUF1 {
    grid-template-areas: "left . ." "middle . ." "right . .";
  }
}

@media only screen and (max-width: 1080px) {
  .cards_card__jgUF1 {
    /* border: 1px solid #CFCFD2; */
    /* margin: 13px; */
    padding: 10px 10px 10px 10px;
    /* height: 100px; */
    display: grid;
    grid-template-columns: 13% 46% 40%;
    overflow: hidden;
  }
}

.student_container__3cLLW {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}
.student_loader__2v9qe {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.student_background_img__2LAiC {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.student_centered_text__2wD-5 {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.student_centered_button__3Qc3Q {
  opacity: 1;
}

.student_sectionStyle__sx-lZ {
  display: grid;
  grid-template-columns: 10% 90%;
}

.student_headerStyle__3d1Ll {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
  width: 100vw;
}

.student_heading__38WUf {
  display: grid;
  grid-template-columns: 200px 740px 300px;
}

.student_backarrow__28BWe {
  /* background-color: red; */
  width: 0%;
  margin-left: 135px;
  margin-top: 25px;
}

.student_backarrow__28BWe img {
  width: 20px;
}

.student_text__1WCCD {
  /* background-color: saddlebrown; */
  font-weight: bold;
  margin: 30px;
  margin-left: -35px;
  margin-top: 8px;
}

.student_filter__O-PbO {
  /* background-color: seagreen; */
  margin-left: 75px;
}

.student_filter__O-PbO img {
  width: 45px;
  margin-top: 14px;
  cursor: pointer;
}

.student_centered__3RdLK {
  /* font-family: 'Poppins-medium';
    width: 70%;
    height: 100%;
    background-color: #FFFFFF;
    position: absolute;
    top: 19%;
    left: 20%; */
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
}

.student_changeDiv__3PWos {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 45%;
  left: 20%;
  z-index: 150;
  margin: 15% 0% 0 5%;
  /* top: 30%; */
  position: absolute;
}

.student_addBlurr__2s5k6 {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.student_header__1d1Ye {
  width: 80%;
  height: 10%;
  margin: -3px 0px 0px 135px;
  /* background-color: red; */
}

.student_keyword__2PTkk {
  width: 85%;
  height: 66%;
  border: 1px solid #dbdbdb;
  background-color: white;
  margin: 13px 0px 0px 0px;
  display: -webkit-flex;
  display: flex;
}

.student_keyword__2PTkk img {
  width: 18px;
  padding: 10px;
}

.student_input__32kVf {
  border: none;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
}

.student_options__j2RFW img {
  width: 40px;
  float: right;
  margin: -40px 72px 0px 0px;
}

@media only screen and (max-width: 650px) {
  .student_centered__3RdLK {
    left: 0;
    width: 100vw;
  }
  .student_backarrow__28BWe {
    margin-left: -117px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1080px) {
  .student_centered__3RdLK {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 87%;
    height: 84%;
    background-color: #ffffff;
    position: absolute;
    top: 11%;
    left: 12%;
  }
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_container__1nopZ {
  border: 1px solid #cfcfd2;
  margin: 13px;
  /* padding: 10px 10px 10px 10px; */
  /* height: 100px; */
  cursor: pointer;
}

.cards_container__1nopZ:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.cards_card__2LH72 {
  padding: 10px 10px 10px 10px;

  display: grid;
  grid-template-columns: 10% 40% 40%;
  overflow: hidden;
}

.cards_left__3L6GT {
  width: 100px;
  height: 85px;
}

.cards_left__3L6GT img {
  height: 100%;
  width: 95%;
  margin-left: 45vw;
}

.cards_middle__2Jq62 p {
  font-size: 15px;
  margin: -6px 0px 0px 17px;
}

.cards_table1__2qQgH {
  font-size: 15px;
  min-width: 230px;
  margin: 0% 0% 0% 40%;
}

.cards_right_table1__13KEu {
  min-width: 230px;
  margin: 15px;
}

.cards_category__1N_HI {
  opacity: 0.6;
  color: #333333;
  /* font-size: 0.86vw; */
  font-size: 0.8rem;
  font-weight: bold;
  width: 6vw;
  border: 1px solid transparent;
  /* margin: 2px; */
  /* padding: 2px; */
  border-radius: 10px;
}

.cards_value__8Pt2r {
  /* opacity: 1; */
  /* color: #333333; */
  /* font-size: 0.86vw; */
  /* border: 1px solid transparent; */
  border-radius: 10px;
  /* margin: 2px; */
  /* padding: 2px; */
  border: 1px solid transparent;
  opacity: 1;
  color: #333333;
  font-size: 1.2rem;
  width: 120%;
  font-weight: 600;
  margin-left: 3%;
}

.cards_value1__2BuS2 {
  margin-left: 3%;
  margin-top: 3%;
  text-align: left;
  font: normal normal normal 15px/30px Poppins;
  letter-spacing: -0.15px;
  color: #333333;
  opacity: 1;
}

.cards_request__3sfCD {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-top: 1px solid #cfcfd2;
}

.cards_request__3sfCD p {
  margin: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
}

.cards_request__3sfCD:hover {
  background-color: #0f065f;
  color: white;
}

.cards_request__3sfCD:hover p {
  background-color: #0f065f;
  color: white;
}

@media screen and (max-width:767px){
  .cards_card__2LH72 {
    grid-template-columns: 0% 0% 0%;
  }
  .cards_left__3L6GT img {
    width: 70%;
    height: 85%;
    margin-left: 50vw;
  }
  .cards_value__8Pt2r {
    font-size: 1rem;
  }
  .cards_value1__2BuS2{
    font-size: 0.8  rem;
  }
}

/* @media screen and (max-width:575px){
  .card {
    grid-template-columns: 0% 0% 0%;
  }
  .left img {
    width: 55%;
    height: 60%;
    margin-left: 35vw;
  }
  .value {
    font-size: 2.5vw;
  }
  .value1{
    font-size: 2vw;
  }
} */
.student_container__3JLiq {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.student_background_img__386n3 {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}
.student_loader__2XzBm {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.student_centered_text__1zGmX {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}
.student_footer__2Saxn {
  width: 70.3%;
  height: 14%;
  background-color: transparent;
  position: absolute;
  top: 95%;
  left: 19.1%;
}
.student_centered_button__3LM0n {
  opacity: 1;
}

.student_sectionStyle__2oBN6 {
  display: grid;
  grid-template-columns: 10% 90%;
}

.student_headerStyle__3RU1n {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.student_heading__yBMzQ {
  display: grid;
  grid-template-columns: 200px 740px 300px;
}

.student_backarrow__21Scw {
  /* background-color: red; */
  width: 0%;
  margin-left: 135px;
  margin-top: 25px;
}

.student_backarrow__21Scw img {
  width: 20px;
}

.student_text__RhRpg {
  /* background-color: saddlebrown; */
  font-weight: bold;
  margin: 30px;
  margin-left: -35px;
  margin-top: 8px;
}

.student_filter__1woFK {
  /* background-color: seagreen; */
  margin-left: 75px;
}

.student_filter__1woFK img {
  width: 45px;
  margin-top: 14px;
  cursor: pointer;
}

.student_centered__1sA9l {
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
}

.student_changeDiv__2K86k {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 45%;
  left: 20%;
  z-index: 150;
  margin: 15% 0% 0 5%;
  /* top: 30%; */
  position: absolute;
}

.student_addBlurr__iHZTE {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.student_header__1zTHP {
  width: 80%;
  height: 10%;
  margin: -3px 0px 0px 135px;
  /* background-color: red; */
}

.student_keyword__2WzOO {
  width: 85%;
  height: 66%;
  border: 1px solid #dbdbdb;
  background-color: white;
  margin: 13px 0px 0px 0px;
  display: -webkit-flex;
  display: flex;
}

.student_keyword__2WzOO img {
  width: 18px;
  padding: 10px;
}

.student_input__2PZ1A {
  border: none;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
}

.student_options__2jDxe img {
  width: 40px;
  float: right;
  margin: -40px 72px 0px 0px;
}

@media only screen and (max-width: 768px) {
  .student_centered__1sA9l {
    font-family: "Poppins-medium";
    width: 100%;
    height: 75%;
    position: absolute;
    top: 20%;
    left: 0%;
    margin-left: 0%;
  }
  .student_headerStyle__3RU1n {
    width: 100vw;
  }
}

@media only screen and (max-width: 1024px) {
  .student_centered__1sA9l {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 86%;
    height: 83%;
    background-color: #ffffff;
    position: absolute;
    top: 11%;
    left: 12%;
  }
  .student_headerStyle__3RU1n {
    width: 100vw;
  }
}



.cards_container__1mfg3 {
  border: 1px solid #cfcfd2;
  margin: 13px;
  
  cursor: pointer;
}

.cards_container__1mfg3:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.cards_card__1kaRK {
  padding: 10px 10px 10px 10px;

  display: grid;
  grid-template-columns: 10% 40% 40%;
  overflow: hidden;
}

.cards_left__dTNEJ {
  width: 100px;
  height: 85px;
}

.cards_left__dTNEJ img {
  height: 100%;
  width: 95%;
  margin-left: 45vw;
}

.cards_middle__3jKEu p {
  font-size: 15px;
  margin: -6px 0px 0px 17px;
}

.cards_table1__2RMR8 {
  font-size: 15px;
  min-width: 230px;
  margin: 0% 0% 0% 40%;
}

.cards_right_table1__2D28y {
  min-width: 230px;
  margin: 15px;
}

.cards_category__1AHup {
  opacity: 0.6;
  color: #333333; 
  font-size: 0.8rem;
  font-weight: bold;
  width: 6vw;
  border: 1px solid transparent;
  
  border-radius: 10px;
}

.cards_value__3S5Es {
 
  border-radius: 10px;
  
  border: 1px solid transparent;
  opacity: 1;
  color: #333333;
  font-size: 1.2rem;
  width: 120%;
  font-weight: 600;
  margin-left: 3%;
}

.cards_value1__1IHdc {
  margin-left: 3%;
  margin-top: 3%;
  text-align: left;
  font: normal normal normal 15px/30px Poppins;
  letter-spacing: -0.15px;
  color: #333333;
  opacity: 1;
}

.cards_request__1uGIC {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-top: 1px solid #cfcfd2;
}

.cards_request__1uGIC p {
  margin: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
}

.cards_request__1uGIC:hover {
  background-color: #0f065f;
  color: white;
}

.cards_request__1uGIC:hover p {
  background-color: #0f065f;
  color: white;
}

@media screen and (max-width:767px){
  .cards_card__1kaRK {
    grid-template-columns: 0% 0% 0%;
  }
  .cards_left__dTNEJ img {
    width: 70%;
    height: 85%;
    margin-left: 50vw;
  }
  .cards_value__3S5Es {
    font-size: 2.5vw;
  }
  .cards_value1__1IHdc{
    font-size: 2vw;
  }
}


@media screen and (max-width:575px){
  .cards_card__1kaRK {
    grid-template-columns: 0% 0% 0%;
  }
  .cards_left__dTNEJ img {
    width: 55%;
    height: 60%;
    margin-left: 35vw;
  }
  .cards_value__3S5Es {
    font-size: 2.5vw;
    max-width: 100px;
  }
  .cards_value1__1IHdc{
    font-size: 2vw;
  }
}
.student_container__26lI5 {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.student_background_img__1YKKB {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}
.student_loader__3OKX7 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.student_centered_text__2Eoe9 {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}
.student_footer__1BT8N {
  width: 70.3%;
  height: 14%;
  background-color: transparent;
  position: absolute;
  top: 95%;
  left: 19.1%;
}
.student_centered_button__j2suS {
  opacity: 1;
}

.student_sectionStyle__56UKx {
  display: grid;
  grid-template-columns: 10% 90%;
}

.student_headerStyle__1Djm4 {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.student_heading__3yy33 {
  display: grid;
  grid-template-columns: 200px 740px 300px;
}

.student_backarrow___zjow {
  width: 0%;
  margin-left: 135px;
  margin-top: 25px;
}

.student_backarrow___zjow img {
  width: 20px;
}

.student_text__2STgX {
  font-weight: bold;
  margin: 30px;
  margin-left: -35px;
  margin-top: 8px;
}

.student_filter__3Oe9S {
  margin-left: 75px;
}

.student_filter__3Oe9S img {
  width: 45px;
  margin-top: 14px;
  cursor: pointer;
}

.student_centered__efeVy {
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
}

.student_changeDiv__9u-ER {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 45%;
  left: 20%;
  z-index: 150;
  margin: 15% 0% 0 5%;
  /* top: 30%; */
  position: absolute;
}

.student_addBlurr__twv77 {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

.student_header__3twKv {
  width: 80%;
  height: 10%;
  margin: -3px 0px 0px 135px;
}

.student_keyword__2lKjO {
  width: 85%;
  height: 66%;
  border: 1px solid #dbdbdb;
  background-color: white;
  margin: 13px 0px 0px 0px;
  display: -webkit-flex;
  display: flex;
}

.student_keyword__2lKjO img {
  width: 18px;
  padding: 10px;
}

.student_input__3WG5R {
  border: none;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
}

.student_options__uiVdu img {
  width: 40px;
  float: right;
  margin: -40px 72px 0px 0px;
}

@media only screen and (max-width: 768px) {
  .student_centered__efeVy {
    font-family: "Poppins-medium";
    width: 100%;
    height: 75%;
    position: absolute;
    top: 20%;
    left: 0%;
    margin-left: 0%;
  }
  .student_headerStyle__1Djm4 {
    width: 100vw;
  }
}

@media screen and (max-width: 1080px) {
  .student_centered__efeVy {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 86%;
    height: 84%;
    background-color: #ffffff;
    position: absolute;
    top: 11%;
    left: 12%;
  }
  .student_headerStyle__1Djm4 {
    padding: 0;
  }
}

.cards_container__2VsT0 {
  border: 1px solid #cfcfd2;
  margin: 13px;
}

.cards_container__2VsT0:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.cards_card__2z2Uu {
  padding: 10px 10px 10px 10px;

  display: grid;
  grid-template-columns: 10% 40% 40%;
  overflow: hidden;
}

.cards_left__bRjnV {
  width: 100px;
}

.cards_left__bRjnV img {
  height: 100%;
  width: 95%;
}

.cards_middle__2sXsJ p {
  font-size: 15px;
  margin: -6px 0px 0px 17px;
}

.cards_table1__2DrQa {
  font-size: 15px;
  min-width: 230px;
  margin: 0px 0px 0px 13px;
}

.cards_right_table1__2bXrs {
  min-width: 230px;
  margin: 15px;
}

.cards_category__184fH {
  opacity: 0.6;
  color: #333333;

  font-size: 0.8rem;
  font-weight: bold;
  width: 6vw;
  border: 1px solid transparent;

  border-radius: 10px;
}

.cards_value__3do6l {
  border-radius: 10px;

  border: 1px solid transparent;
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  width: 70%;
  font-weight: 600;
}

.cards_request__3GD_t {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-top: 1px solid #cfcfd2;
}

.cards_request__3GD_t p {
  margin: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
}

.cards_request__3GD_t:hover {
  background-color: #0f065f;
  color: white;
}

.cards_request__3GD_t:hover p {
  background-color: #0f065f;
  color: white;
}
@media only screen and (max-width: 768px) {
  .cards_card__2z2Uu {
    display: block;
  }
  .cards_left__bRjnV {
    width: 75vw;
    height: 51vw;
  }
  .cards_middle__2sXsJ p {
    margin: 7px 0px 0px 2px;
  }
  .cards_table1__2DrQa {
    margin: 0px 0px 0px -5px;
  }
  .cards_right_table1__2bXrs {
    margin: -5px;
  }
  .cards_category__184fH {
    width: 20vw;
  }
  .cards_value__3do6l {
    width: 53vw;
  }
}

/* 1080 */

@media only screen and (max-width: 1080px) {
  .cards_card__2z2Uu {
    padding: 10px 10px 10px 10px;
    display: grid;
    grid-template-columns: 15% 44% 25%;
    overflow: hidden;
  }
}

.student_container__1ximr {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}
.student_arrowImage__2ggaN {
  cursor: pointer;
  width: 70%;
  margin-bottom: 6px;
}
.student_background_img__UZ-Iw {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}
.student_loader__MH5uN {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.student_nodata__3ul_r {
  overflow: hidden;
  font-weight: 500;
  width: 70%;
  height: auto;
  max-height: 75%;
  background-color: #ffffff;
  top: 19%;
  left: 20%;
}
.student_centered_text__5BxLM {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.student_centered_button__fKLpQ {
  opacity: 1;
}

.student_sectionStyle__1xkRz {
  display: grid;
  grid-template-columns: 10% 90%;
}

.student_headerStyle__2MU3x {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
  width: 100vw;
}

.student_heading__3PpaN {
  display: grid;
  grid-template-columns: 200px 740px 300px;
}

.student_backarrow__16ZrR {
  /* background-color: red; */
  width: 0%;
  margin-left: 135px;
  margin-top: 25px;
}

.student_backarrow__16ZrR img {
  width: 20px;
}

.student_text__1jYOY {
  /* background-color: saddlebrown; */
  font-weight: bold;
  margin: 30px;
  margin-left: -35px;
  margin-top: 8px;
}

.student_filter__3Pjsa {
  /* background-color: seagreen; */
  margin-left: 10px;
}

.student_filter__3Pjsa img {
  width: 24px;
  margin-top: 10px;
  cursor: pointer;
}

.student_centered__29YKd {
  /* font-family: 'Poppins-medium';
    width: 70%;
    height: 100%;
    background-color: #FFFFFF;
    position: absolute;
    top: 19%;
    left: 20%; */
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 19%;
  left: 20%;
}

.student_changeDiv__kl4rS {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 45%;
  left: 20%;
  z-index: 150;
  margin: 15% 0% 0 5%;
  /* top: 30%; */
  position: absolute;
}

.student_addBlurr__3-BGb {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.student_header__21Wki {
  width: 80%;
  height: 10%;
  margin: -3px 0px 0px 135px;
  /* background-color: red; */
}

.student_keyword__3cunH {
  width: 85%;
  height: 66%;
  border: 1px solid #dbdbdb;
  background-color: white;
  margin: 13px 0px 0px 0px;
  display: -webkit-flex;
  display: flex;
}

.student_keyword__3cunH img {
  width: 18px;
  padding: 10px;
}

.student_input__mx-z0 {
  border: none;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
}

.student_options__286OO img {
  width: 40px;
  float: right;
  margin: -40px 72px 0px 0px;
}
@media only screen and (max-width: 768px) {
  .student_centered__29YKd {
    width: 91%;
    left: 5%;
  }
  .student_backarrow__16ZrR {
    margin-left: -106px;
    margin-top: 10px;
  }
  .student_filter__3Pjsa img {
    width: 22px;
  }
}

@media only screen and (max-width: 600px) {
  .student_centered__29YKd {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 86%;
    height: 84%;
    background-color: #ffffff;
    position: absolute;
    top: 10%;
    left: 12%;
  }
}

@media only screen and (max-width: 1080px) {
  .student_centered__29YKd {
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: 500;
    width: 86%;
    height: 75%;
    background-color: #ffffff;
    position: absolute;
    top: 11%;
    left: 12%;
  }
}

.SViewProfile_container__33sR3 {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.SViewProfile_background_img__3bhc7 {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.SViewProfile_centered_text__q7PyP {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.SViewProfile_centered_button__3aEZH {
  opacity: 1;
}

.SViewProfile_topsomepagewrapper__20qHo {
  margin: 1% 11%;
}

.SViewProfile_toprow__3dK4S {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;

}

.SViewProfile_topcolumn__14Prq {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  /* margin-top: 5%; */
  
}
.SViewProfile_para__7J3dt{
  margin-left: 50%;
 margin-top: 10%;
}
.SViewProfile_backarrow__tAZqj {
  /* background-color: red; */
 
  margin-left: -6%;
margin-top: 1%;
}
.SViewProfile_topcolumn1__Q0-RU{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* flex-basis: 100%; */
  /* flex: 1 1; */
 
}

.SViewProfile_button__jM9RS{
  box-shadow: 0 0px 2px 2px #0000001F;
    background-color: #110766;
    font-weight: 500;
    width: 134px;
    height: 35px;
   /* margin-top: 5%; */
  
    border-radius: 14px;
    border: 1px solid #110766;
    opacity: 1;
    color: #FFFFFF;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
   
}
.SViewProfile_accepted__KbTYa{
  box-shadow: 0 0px 2px 2px #0000001F;
    background-color: grey;
    font-weight: 500;
    width: 134px;
    height: 35px;
    /* margin: 6px 0px 0px 10px; */
    margin-top: -5%;
    border-radius: 14px;
    border: 1px solid grey;
    opacity: 1;
    color: #FFFFFF;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    /* margin: ".5% 0px 2% -25px" */
}
.SViewProfile_changeDiv__3snCc {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 45%;
  height: 48%;
  left: 30%;
  z-index: 150;
  margin: 15% 0% 0 5;
  top: 30%;
  position: absolute;
}
.SViewProfile_changeDiv1__3n0d4 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
 left: 38%;
  z-index: 150;
  top: 23%;
position: absolute;
}


.SViewProfile_addBlurr__3ZT_N {
  filter: brightness(10px);
  -webkit-filter: blur(10px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}


.SViewProfile_editbtn__dRUpl {
  text-align: right;
}

.SViewProfile_sectionStyle__3bfri {
  display: grid;
  grid-template-columns: 10% 90%;
}

.SViewProfile_headerStyle__2ntvj {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.SViewProfile_heading__3QoT7 img {
  width: 2%;
  margin-left: 135px;
  margin-top: 25px;
}

.SViewProfile_heading__3QoT7 p {
  font-weight: bold;
  margin-left: 11%;
  margin-top: 3%;
}

.SViewProfile_centered__3eIR- {
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  
}

.SViewProfile_borderbox__1AC8y {
  border: 1px solid #cfcfd2;
  margin: 26px;
  margin-top: 18px;
 
}

.SViewProfile_row1__2ju7O {
  background-color: white;
  border-bottom: 1px solid #dedede;
  padding: 15px;
 
  overflow: hidden;
}

.SViewProfile_row1box__2m0zf {
  display: grid;
  grid-template-columns: 20% 40% 40%;
}

.SViewProfile_left__1S0J7 {
  background-color: white;
}

.SViewProfile_left__1S0J7 img {
  width: 100%;
  margin-top: 5%;
}

.SViewProfile_middle__2z3_v {
  background-color: white;
  margin-left: 10%;
  margin-top: 0%;
}

.SViewProfile_table1__3ox3h {
  font-size: 15px;
  min-width: 280px;
  margin-left: -10px;
}

.SViewProfile_table1__3ox3h td {
  padding: 2% 0 0 0;
}

.SViewProfile_right__2EMs3 {
  background-color: white;
  margin-top: 9%;
  
}



.SViewProfile_category__NVORz {
  opacity: 0.6;
  color: #333333;
  font-size: 0.8rem;
  margin: 2px;
  padding: 3px;
  width: 7vw;
}

.SViewProfile_value__1OkUE {
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  font-weight: 600;
  margin: 3px;
  padding: 3px;
  width: 15vw;
}

.SViewProfile_row2__1f7gO {
  background-color: white;
  border-bottom: 1px solid #dedede;
  height: 12%;
  padding: 15px;
  display: -webkit-flex;
  display: flex;
}

.SViewProfile_row2left__2PoIb {
  width: 24%;
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.SViewProfile_row2right__1bOwR {
  width: 76%;
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
  text-align: justify;
}

.SViewProfile_lastview__3tl-9 {
  text-align: end;
  padding: 1% 2% 1% 0;
}
.SViewProfile_lastview__3tl-9 label {
  cursor: pointer;
}

.SViewProfile_lastview__3tl-9 label:hover{
  color: #110766;
  font-weight: 700;
}

.SViewProfile_row3__ctRTX {
  background-color: white;
  padding: 15px;
}

.SViewProfile_upper__KKyPU {
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
}

.SViewProfile_upperleft__1aHwB {
  background-color: white;
  width: 24%;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}
.SViewProfile_display__TM016{
  display: -webkit-flex;
  display: flex;
}
.SViewProfile_upperright__Dclx0 {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
  width: 76%;
  text-align: justify;
}

.SViewProfile_lower__141zR {
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
}

.SViewProfile_lowerleft__3DZyy {
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
  width: 24%;
}

.SViewProfile_lowerright__21VvG {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  width: 76%;
  color: #333333;
  text-align: justify;
}

.SViewProfile_row4__1sGQS {
  padding: 15px;
  border-bottom: 1px solid #dedede;
  color: black;
  margin-top: 4%;
}

.SViewProfile_row5__2DPNn {
  background-color: white;
 height: 88px;
  padding: 15px;
  display: grid;
 grid-template-columns: 12% 60%;
}

.SViewProfile_row5left__2cZHM {
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.SViewProfile_row5right__2Mjg6 {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
}


@media screen and (max-width: 1368px) {
  .SViewProfile_row4__1sGQS {
    margin-top: 12%;
  }
  .SViewProfile_heading__3QoT7 p {
    margin-top: 2%;
  }
}
@media screen and (max-width: 600px){
  .SViewProfile_value__1OkUE{
    width: 100%;
  }
  .SViewProfile_middle__2z3_v{
    margin-left: 0%;
  }
  .SViewProfile_changeDiv1__3n0d4{
    left: 3%;
  }
  .SViewProfile_right__2EMs3{
    margin-top: -2%;
    margin-left: -4%;
  }
  .SViewProfile_row1box__2m0zf{
    display: block;
  }
  .SViewProfile_row2__1f7gO{
display: block;
  }
  .SViewProfile_sectionStyle__3bfri{
    grid-template-columns: 11% 81%;
  }
  .SViewProfile_changeDiv__3snCc{
    width: 97%;
    left: 1%;
  }
  .SViewProfile_button__jM9RS{
    width: 110px;
    margin-left: 45%;
  }
  .SViewProfile_upperright__Dclx0{
    margin-left: 0%;
    width: 100%;
  }
  .SViewProfile_upper__KKyPU{
    display: block;
  }
.SViewProfile_profile__zrKa2{
  display: block;
}
.SViewProfile_centered__3eIR- {
 left: 0;
 width: 100vw;
}
.SViewProfile_backarrow__tAZqj{
  margin-left: -104%;
    margin-top: 1%;
}
.SViewProfile_para__7J3dt{
  margin-left: -54%;
  margin-top: 4%;
}
.SViewProfile_button__jM9RS{
  width: 110px;
  margin-left: 20%;
}
.SViewProfile_left__1S0J7 img{
  width: 234px;
  height: 150px;
}
.SViewProfile_display__TM016{
  display: block;
}
.SViewProfile_lower__141zR{
  display: block;
}
.SViewProfile_lowerright__21VvG{
  display: block;
}
}
.chatlist_container__30uhg {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100%;
}

.chatlist_background_img__Wt9Yi {
  width: 80%;
  height: 110%;
  margin: -3% 8;
}

.chatlist_centered_text__1jk8A {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}
.chatlist_loader__2lUwM {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.chatlist_sectionStyle__2DbYk {
  display: grid;
  grid-template-columns: 10% 90%;
}

.chatlist_headerStyle__QD2cf {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.chatlist_centered__3BEb3 {
  font-family: "Poppins-medium";
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 25%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.chatlist_topHeading__1U0Qq {
  text-align: center;
  /* position: relative; */
  margin: 1.5% 0 0 -73%;
}
.chatlist_row__1lwyF {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 8%;
  cursor: pointer;
  /* background-color: aquamarine; */
}
.chatlist_span__3Xm2F {
  /* font-size: 12pt;
  font-family: Poppins, SemiBold;
  color: #333333;
  max-width: 300px;
  margin: 1.2% 0% 0% 3%;
  text-align: left; */
  border-radius: 10px;
  /* margin: 2px; */
  /* padding: 2px; */
  border: 1px solid transparent;
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  width: 70%;
  font-weight: 600;
  margin-left: 1rem;
}
.chatlist_timespan__2w9Ha {
  position: absolute;
  font-size: 7pt;
  color: #333333;
  opacity: 0.6;
  margin: 1rem;
  right: 0;
}
.chatlist_keyword__3C0SI {
  position: absolute;
  top: 13%;
  left: 17.5%;
  width: 69vw;
  margin-left: 2rem;
  height: 2rem;
  border: 1px solid #dbdbdb;
  background-color: white;
  display: -webkit-flex;
  display: flex;
  margin-top: 2%;
}

.chatlist_keyword__3C0SI img {
  position: absolute;
  width: 20px;
  top: 20%;
  margin-left: 0.2em;
}

.chatlist_input__2tmxB {
  border: none;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
  width: 100%;
  padding-left: 1.5rem;
}

@media screen and (max-width: 700px) {
  .chatlist_topHeading__1U0Qq {
    margin: 1.5% 0 0 -86%;
  }
  .chatlist_sectionStyle__2DbYk {
    position: relative;
  }
  .chatlist_centered__3BEb3 {
    width: 90%;
    left: 5%;
    top: 15%;
    position: absolute !important;
  }
  .chatlist_keyword__3C0SI {
    width: 90vw;
    margin-left: -3rem;
    top: 6%;
    position: absolute !important;
  }
}

@media screen and (max-width: 1080px) {
  .chatlist_headerStyle__QD2cf {
    padding: 0;
  }
  .chatlist_keyword__3C0SI {
    top: 10%;
  }
  .chatlist_centered__3BEb3 {
    top: 16%;
    height: 79%;
    width: 87%;
    left: 12%;
  }
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_container__1CAXx {
  border: 1px solid #cfcfd2;
  margin: 13px;
  /* padding: 10px 10px 10px 10px; */
  height: 70px;
  cursor: pointer;
}

.cards_container__1CAXx:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_card__2PxoE {
  /* border: 1px solid #CFCFD2; */
  /* margin: 13px; */
  padding: 10px 10px 10px 10px;
  /* height: 100px; */
  display: grid;
  grid-template-columns: 95px 400px 380px;
  overflow: hidden;
  /* font-family: 'Poppins', sans-serif; */
}

/* .card:hover {
    box-shadow: 10px 10px 12px -4px #00000015;
    transform: scale(1, 1);
} */

.cards_right__26vay {
  width: 100px;
  height: 85px;
  margin-left: 50vw;
  
}

.cards_right__26vay img {
  height: 100%;
  width: 95%;
}

.cards_left__23meh{
  color:black;
  width:90vw;
  display: -webkit-flex;
  display: flex;
}
.cards_left__23meh p{
  margin: 2px;
}
.cards_left__23meh img {
  height:50px;
  width:50px;
  margin-right: 2%;
}
.cards_time__Sk8xM{
  text-align: left;
font: normal normal normal 12px/30px Poppins;
letter-spacing: -0.12px;
color: #333333;
opacity: 0.6;
}

.cards_orgname__o_RPE{
  text-align: left;
font: normal normal normal 15px/30px Poppins;
letter-spacing: -0.15px;
color: #333333;
opacity: 1;
}

.cards_oppdes__3ZiNE{
  text-align: left;
font: normal normal 600 20px/32px Poppins;
letter-spacing: -0.2px;
color: #333333;
opacity: 1;
}








/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */



/* .request p {
  margin: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
} */

.cards_request__284eq:hover {
  background-color: #0f065f;
  color: white;
}

.cards_request__284eq:hover p {
  background-color: #0f065f;
  color: white;
}
@media screen and (max-width:575px){
  .cards_right__26vay{
    width: 75px;
    height: 75px;
    margin-left: 29vw;
  }
  .cards_left__23meh {
    width: 70vw;
  }

  .cards_left__23meh p {
    font-size: 3vw;
    overflow-wrap: break-word;
  }
}
.Chat_container__R8SWg {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100%;
}

.Chat_background_img__yKELB {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.Chat_centered_text__9I03e {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.Chat_centered_button__3OQ0P {
  opacity: 100;
}

/* span {
    font-size: 24px;
  } */

.Chat_sectionStyle__1uuGR {
  display: grid;
  grid-template-columns: 10% 90%;
}

.Chat_headerStyle__WrwmE {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.Chat_centered__1UQEF {
  width: 60%;
  height: 72%;
  background-color: #ffffff;
  position: absolute;
  top: 18%;
  left: 20%;
  box-shadow: 0px 4px 15px #7272723c;
  overflow-y: scroll;
}

.Chat_content__35bas {
  display: grid;
  grid-template-rows: 20% 70%;
}

.Chat_title__1lTDP {
  /* background-color: red; */
  font-weight: 600;
  font-size: 20px;
  color: #333333;
  opacity: 100%;
  font-family: "Poppins", sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  margin-top: 30px;
}

.Chat_image__2wQTj {
  background-color: white;
}

.Chat_image__2wQTj img {
  width: 30%;
  margin: 75px 0px 0px 328px;
}

.Chat_heading__17XwY {
  display: grid;
  grid-template-columns: 200px 740px 300px;
}

.Chat_backarrow__3TV3- {
  /* background-color: red; */
  width: 0%;
  margin-left: 135px;
  margin-top: 25px;
}

.Chat_backarrow__3TV3- img {
  width: 20px;
}

.Chat_text__48HcX {
  /* background-color: saddlebrown; */
  font-weight: bold;
  margin: 30px;
  margin-left: -35px;
  margin-top: 8px;
}

.Chat_header__28bBs {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: rgb(151 164 222);
  height: 15%;
  border-bottom: 1px solid #70707030;
  display: grid;
  grid-template-columns: 13% 87%;
}

/* .left {
    background-color: red;
} */

.Chat_left__1pFDo img {
  width: 55px;
  height: 52px;
  margin: 10px 0px 0px 42px;
  border-radius: 50%;
}

/* .mid {
    background-color: yellow;
} */

.Chat_mid__3vSke p {
  margin-top: -20px;
}

/* .right {
    background-color: rgb(155, 155, 155);
} */

.Chat_right__3Ut58 img {
  margin: 30px 0px 0px 50px;
  width: 30px;
  height: 35px;
  cursor: pointer;
}

.Chat_date__qCf8f {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 10px;
  color: #333333;
  opacity: 50%;
}

.Chat_sender__1cy4J {
  width: 242px;
  background-color: #fafafa;
  height: 10%;
  margin: 1px 0px 0px 30px;
  border: 1px solid #eaeaea;
  border-radius: 0px 15px 15px 15px;
}

.Chat_sender__1cy4J p {
  text-align: left;
  letter-spacing: -0.15px;
  color: #333333;
  opacity: 0.7;
  font-size: 11px;
  margin: 5px 0px 0px 18px;
}

.Chat_uReceiver__3wa6D {
  width: 540px;
  background-color: #fafafa;
  min-height: 10%;
  margin: 5px 0px 0px 33%;
  border: 1px solid #eaeaea;
  border-radius: 15px 15px 0px 15px;
  scroll-behavior: smooth;
  top: 0;
  left: 0;
  word-break: break-word;
  display: block;
}
.Chat_rReceiver__swS5D {
  scroll-behavior: smooth;
  top: 0;
  left: 0;
  width: 540px;
  background-color: #fafafa;
  min-height: 10%;
  margin: 5px 20px 0px 2px;
  border: 1px solid #eaeaea;
  border-radius: 0px 15px 15px 15px;
  /* float: left; */
  display: block;
  word-break: break-word;
}

/* .receiver p {
    text-align: left;
    letter-spacing: -0.15px;
    color: #333333;
    opacity: 0.7;
    font-size: 11px;
    margin: 5px 0px 0px 18px;
} */

.Chat_downcontainer__2THQE {
  width: 40%;
  height: 8%;
  border-radius: 30px;
  position: fixed;
  /* top:85%; */
  bottom: 1.5%;
  left: 30%;
  /* margin: 25% 0px 0px 23%; */
  box-shadow: 0px 4px 15px #7272723c;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 0% 90% 10%;
}
.Chat_mid__3vSke {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.Chat_mid__3vSke img {
  width: 20px;
  height: 20px;
}

/* .downleft {
    background-color: yellow;
} */

.Chat_downleft__3iMmN img {
  width: 25px;
  margin: 12px;
}

/* .downmid {
    background-color: blue;
} */

.Chat_downmid__3WSMR p {
  font-size: 15px;
  border-bottom: 1px solid #7272723c;
  color: #676767;
}

/* .downright {
    background-color: red;
} */

.Chat_downright__1wGoM img {
  width: 50px;
  margin: 2px -3px;
}

.Chat_changeDiv__2mRU9 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 25%;
  height: 45%;
  left: 20%;
  z-index: 150;
  margin: 15% 0% 0 15%;
  /* top: 30%; */
  position: absolute;
}
.Chat_more__3gmTk {
  width: 38px;
  cursor: pointer;
}
.Chat_more__3gmTk img {
  cursor: pointer;
  z-index: 1;
}
.Chat_modal_container__1rQgT {
  color: #333333;
  box-shadow: 0 2px 2px 2px #00000029;
  background-color: #ffffff;
  border-radius: 10px 0px 10px 10px;
  margin: -0.5% 0 0 -6%;
  padding: 1% 1% 1% 1%;
  width: 6%;
  opacity: 1;
  position: absolute;
}
.Chat_modal_label__2qtxJ {
  border: none;
  background: none;
  font-size: 0.8rem;
  padding: 0%;
}

.Chat_modal_label__2qtxJ button :hover {
  border: 1px solid #5a1c1c;
  cursor: pointer;
}
.Chat_changeDeleteDiv__UPaKD {
  display: block;
  position: absolute;
  margin: 6% 16%;
}

.Chat_addBlurr__1Euyb {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}
@media screen and (max-width: 700px) {
  .Chat_headerStyle__WrwmE {
    width: 90vw;
  }
  .Chat_sectionStyle__1uuGR {
    width: 90vw;
    overflow: hidden;
  }
  .Chat_centered__1UQEF {
    width: 90%;
    left: 5%;
    top: 18%;
    overflow-x: hidden;
  }
  .Chat_header__28bBs {
    grid-template-columns: 30% 70% 0%;
  }
  .Chat_downcontainer__2THQE {
    width: 80%;
    bottom: 10%;
    left: 10%;
    height: 0%;
  }

  .Chat_uReceiver__3wa6D {
    width: 215px;
    margin: 4px 20px 0px 20%;
    word-wrap: break-all;
  }
  .Chat_uReceiver__3wa6D p {
    /* font-size: 16px; */
  }
  .Chat_rReceiver__swS5D {
    width: 215px;
    word-wrap: break-all;
  }
  .Chat_backarrow__3TV3- {
    margin-left: -10px;
    margin-top: 25px;
  }
  .Chat_text__48HcX {
    margin-left: -180px;
    margin-top: 6px;
  }
}

@media screen and (max-width: 1080px) {
  .Chat_centered__1UQEF {
    top: 11%;
    height: 60%;
    overflow-x: hidden;
    width: 85%;
    left: 12%;
  }
  .Chat_header__28bBs {
    grid-template-columns: 26% 72%;
  }
  .Chat_left__1pFDo {
    margin-top: 32px;
  }
  .Chat_heading__17XwY {
    grid-template-columns: auto auto;
  }
  .Chat_downcontainer__2THQE {
    top: 74%;
    left: 12%;
    width: 84%;
  }
  .Chat_rReceiver__swS5D {
    width: 50%;
    word-wrap: break-all;
  }
  .Chat_uReceiver__3wa6D {
    width: 50%;
    margin: 7px 0 0 45%;
    word-wrap: break-all;
  }
  .Chat_downright__1wGoM img {
    margin: 18px -3px;
  }
  .Chat_headerStyle__WrwmE {
    padding: 0;
  }
}

.ChatDropdown_subContainer1__W1Ywn {
    height: 25px;
    width: 82px;
    box-shadow: 0 0px 2px 2px #00000029;
    background-color: white;
    border-radius: 9px 0px 9px 9px;
    margin: 5px 0px 3px 5px;
    padding-bottom: 5px;
}

.ChatDropdown_label__1V9YG {
    float: left;
    font-weight: 400;
    font-size: 7pt;
    margin: 8px 0 0 15px;
}

.ChatDropdown_changeDiv__1SyNN {
    background-color: #FAFBFC;
    border-radius: 30px 30px 30px 30px;
    width: 45%;
    height: 45%;
    left: 20%;
    z-index: 150;
    margin: 15% 0% 0 5%;
    /* top: 30%; */
    position: absolute;
}

.ChatDropdown_addBlurr__2CbhS {
    filter: brightness(40px);
    -webkit-filter: blur(15px);
    background-color: #F2F2F2;
    overflow-y: hidden;
}
.Delete_centered__2yVhg {
  /* / background-color: rgb(171, 171, 245); / */
  background-color: whitesmoke;
  margin: 6% 15%;
  position: fixed;
  z-index: 100;
  height: 300px;
  width: 33%;
  border-radius: 15px;
  padding-left: 25px;
}

.Delete_header__35bMi {
  display: grid;
  grid-template-rows: repeat(2, 30px) 0px;
  padding: 15px;
}

.Delete_alignImg__3goAz {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}

.Delete_upper__2aVjp img {
  background-color: #fafbfc;
  float: right;
  margin-top: 2px;
}

.Delete_delete__1AV4J img {
  width: 45%;
  margin: -20px 0px 0px 83px;
}

.Delete_delete__1AV4J p {
  font-size: 15px;
  letter-spacing: -0.24px;
  color: #333333;
  opacity: 1;
  margin: 3px 0px 0px 15px;
}

.Delete_reset__280mO {
  background-color: #ffffff;
  border: 1px solid #1e118d;
  opacity: 1;
  border-radius: 10px;
  color: #1e118d;
  padding: 5px;
  float: right;
  margin-left: 370px;
  width: 57px;
}

.Delete_btn__2u-Oz {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 10rem;
  margin-left: 2rem;
}

.Delete_apply__1rqOT {
  background-color: #1e118d;
  border: 1px solid #1e118d;
  opacity: 1;
  border-radius: 10px;
  color: #ffffff;
  padding: 5px;
  float: right;
  margin-left: 277px;
  width: 57px;
}

.Delete_inputage__1fNcL {
  display: grid;
  grid-template-columns: 48% 48%;
  height: 100%;
  /* / background-color: slateblue; / */
}

/* .inputleft input {
  width: 75%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid #B9B9B9;
  ;
  font-size: 11pt;
  margin: 0px;
} */

.Delete_input1__cbcmk {
  width: 95%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  font-size: 11pt;
  margin: 0px;
  padding: 3px;
}

.Delete_input1__cbcmk {
  width: 95%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  font-size: 11pt;
  margin: 0px;
  padding: 3px;
}

.Delete_input1__cbcmk option {
  color: #0b2342;
  opacity: 1;
}

.Delete_location__3cTCX {
  margin-top: 30px;
}

.Delete_form__3lplK input {
  margin: 0px;
  width: 78%;
  border-radius: 7px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  font-size: 11pt;
  margin: 0px;
}

@media screen and (max-width: 700px){
  .Delete_centered__2yVhg{
    width: 85%;
  }
  
}

.chatlist_container__3kSZ4 {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100%;
}

.chatlist_background_img__1BNy9 {
  width: 80%;
  height: 110%;
  margin: -3% 8;
}
.chatlist_loader__3XA0t {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.chatlist_keyword__2Y132 {
  position: absolute;
  top: 13%;
  left: 17.5%;
  width: 69vw;
  margin-left: 2rem;
  height: 2rem;
  border: 1px solid #dbdbdb;
  background-color: white;
  display: -webkit-flex;
  display: flex;
  margin-top: 2%;
}

.chatlist_keyword__2Y132 img {
  position: absolute;
  width: 20px;
  top: 20%;
  margin-left: 0.2rem;
}

.chatlist_input__115xe {
  border: none;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
  width: 100%;
  padding-left: 1.5rem;
}
.chatlist_centered_text__S9sTj {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.chatlist_sectionStyle__2ukMC {
  display: grid;
  grid-template-columns: 10% 90%;
}

.chatlist_headerStyle__1uNZW {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.chatlist_centered__3ksD- {
  font-family: "Poppins-medium";
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 25%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.chatlist_topHeading__ZDaKR {
  text-align: center;
  margin: 1.5% 0 0 -73%;
}
.chatlist_row__2FuPp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 8%;
  cursor: pointer;
  /* background-color: aquamarine; */
}
.chatlist_span__3yKkx {
  /* font-size: 12pt;
  font-family: Poppins, SemiBold;
  color: #333333;
  max-width: 300px;
  margin: 1.2% 0% 0% 3%;
  text-align: left; */
  border-radius: 10px;
  /* margin: 2px; */
  /* padding: 2px; */
  border: 1px solid transparent;
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  width: 70%;
  font-weight: 600;
  margin-left: 1rem;
}
.chatlist_timespan__2DKlU {
  position: absolute;
  font-size: 7pt;
  color: #333333;
  opacity: 0.6;
  margin: 1rem;
  right: 0;
}

@media screen and (max-width: 700px) {
  .chatlist_topHeading__ZDaKR {
    margin: 1.5% 0 0 -86%;
  }

  .chatlist_sectionStyle__2ukMC {
    position: relative;
  }
  .chatlist_centered__3ksD- {
    width: 90%;
    left: 5%;
    top: 15%;
    position: absolute !important;
  }
  .chatlist_keyword__2Y132 {
    width: 90vw;
    margin-left: -3rem;
    top: 6%;
    position: absolute !important;
  }
}

@media screen and (max-width: 1080px) {
  .chatlist_centered__3ksD- {
    font-family: "Poppins-medium";
    width: 88%;
    height: 75%;
    background-color: #ffffff;
    position: absolute;
    top: 19%;
    left: 11%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .chatlist_headerStyle__1uNZW {
    padding: 0;
  }
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_container__3umxW {
  border: 1px solid #cfcfd2;
  margin: 13px;
  /* padding: 10px 10px 10px 10px; */
  height: 70px;
  cursor: pointer;
}

.cards_container__3umxW:hover {
  box-shadow: 10px 10px 12px -4px #00000015;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */

.cards_card__1NjlQ {
  /* border: 1px solid #CFCFD2; */
  /* margin: 13px; */
  padding: 10px 10px 10px 10px;
  /* height: 100px; */
  display: grid;
  grid-template-columns: 95px 400px 380px;
  overflow: hidden;
  /* font-family: 'Poppins', sans-serif; */
}

/* .card:hover {
    box-shadow: 10px 10px 12px -4px #00000015;
    transform: scale(1, 1);
} */

.cards_right__2LX35 {
  width: 100px;
  height: 85px;
  margin-left: 50vw;
  
}

.cards_right__2LX35 img {
  height: 100%;
  width: 95%;
}

.cards_left__33PVI{
  color:black;
  width:90vw;
  display: -webkit-flex;
  display: flex;
}
.cards_left__33PVI p{
  margin: 2px;
}
.cards_left__33PVI img {
  height:50px;
  width:50px;
  margin-right: 2%;
}
.cards_time__2U_Yv{
  text-align: left;
font: normal normal normal 12px/30px Poppins;
letter-spacing: -0.12px;
color: #333333;
opacity: 0.6;
}

.cards_orgname__maT4B{
  text-align: left;
font: normal normal normal 15px/30px Poppins;
letter-spacing: -0.15px;
color: #333333;
opacity: 1;
}

.cards_oppdes__nb6Ix{
  text-align: left;
font: normal normal 600 20px/32px Poppins;
letter-spacing: -0.2px;
color: #333333;
opacity: 1;
}








/* ///////////////////////////////// FOR RECRUITER FLOW ////////////////////////////////////////////////////// */



/* .request p {
  margin: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #0f065f;
  cursor: pointer;
} */

.cards_request__oD1ls:hover {
  background-color: #0f065f;
  color: white;
}

.cards_request__oD1ls:hover p {
  background-color: #0f065f;
  color: white;
}
@media screen and (max-width:575px){
  .cards_right__2LX35{
    width: 75px;
    height: 75px;
    margin-left: 29vw;
  }
  .cards_left__33PVI {
    width: 70vw;
  }

  .cards_left__33PVI p {
    font-size: 3vw;
    overflow-wrap: break-word;
  }
}
.Chat_container__1Dsev {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100%;
}

.Chat_background_img__1z31q {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.Chat_centered_text__2c0th {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.Chat_centered_button__3gTuE {
  opacity: 100;
}

/* span {
    font-size: 24px;
  } */

.Chat_sectionStyle__3oiAq {
  display: grid;
  grid-template-columns: 10% 90%;
}

.Chat_headerStyle__1Jbtj {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.Chat_centered__dLooy {
  width: 60%;
  height: 72%;
  background-color: #ffffff;
  position: absolute;
  top: 18%;
  left: 20%;
  box-shadow: 0px 4px 15px #7272723c;
  overflow-y: scroll;
}

.Chat_content__9cQdg {
  display: grid;
  grid-template-rows: 20% 70%;
}

.Chat_title__1khYh {
  /* background-color: red; */
  font-weight: 600;
  font-size: 20px;
  color: #333333;
  opacity: 100%;
  font-family: "Poppins", sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  margin-top: 30px;
}

.Chat_image__2Ukn_ {
  background-color: white;
}

.Chat_image__2Ukn_ img {
  width: 30%;
  margin: 75px 0px 0px 328px;
}

.Chat_heading__2TraV {
  display: grid;
  grid-template-columns: 200px 740px 300px;
}

.Chat_backarrow__4l37Y {
  /* background-color: red; */
  width: 0%;
  margin-left: 135px;
  margin-top: 25px;
}

.Chat_backarrow__4l37Y img {
  width: 20px;
}

.Chat_text__2BMb7 {
  /* background-color: saddlebrown; */
  font-weight: bold;
  margin: 30px;
  margin-left: -35px;
  margin-top: 8px;
}

.Chat_header__1jN0P {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: rgb(151 164 222);
  height: 15%;
  border-bottom: 1px solid #70707030;
  display: grid;
  grid-template-columns: 13% 87%;
}

/* .left {
    background-color: red;
} */

.Chat_left__1PpXX img {
  width: 55px;
  height: 52px;
  margin: 10px 0px 0px 42px;
  border-radius: 50%;
}

/* .mid {
    background-color: yellow;
} */

.Chat_mid__315n2 p {
  margin-top: -20px;
}

/* .right {
    background-color: rgb(155, 155, 155);
} */

.Chat_right__2UuOY img {
  margin: 30px 0px 0px 50px;
  width: 30px;
  height: 35px;
  cursor: pointer;
}

.Chat_date__Y03TX {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 10px;
  color: #333333;
  opacity: 50%;
}

.Chat_sender__2tC8U {
  width: 242px;
  background-color: #fafafa;
  height: 10%;
  margin: 1px 0px 0px 30px;
  border: 1px solid #eaeaea;
  border-radius: 0px 15px 15px 15px;
}

.Chat_sender__2tC8U p {
  text-align: left;
  letter-spacing: -0.15px;
  color: #333333;
  opacity: 0.7;
  font-size: 11px;
  margin: 5px 0px 0px 18px;
}

.Chat_uReceiver__8la21 {
  width: 540px;
  background-color: #fafafa;
  min-height: 10%;
  margin: 5px 0px 0px 33%;
  border: 1px solid #eaeaea;
  border-radius: 15px 15px 0px 15px;
  scroll-behavior: smooth;
  top: 0;
  left: 0;
  display: block;
  word-break: break-word;
}
.Chat_rReceiver__3tJ6z {
  scroll-behavior: smooth;
  top: 0;
  left: 0;
  width: 540px;
  background-color: #fafafa;
  min-height: 10%;
  margin: 5px 20px 0px 2px;
  border: 1px solid #eaeaea;
  border-radius: 0px 15px 15px 15px;
  /* float: left; */
  display: block;
  word-break: break-word;
}

/* .receiver p {
    text-align: left;
    letter-spacing: -0.15px;
    color: #333333;
    opacity: 0.7;
    font-size: 11px;
    margin: 5px 0px 0px 18px;
} */

.Chat_downcontainer__3_DGn {
  width: 40%;
  height: 8%;
  border-radius: 30px;
  position: fixed;
  /* top:85%; */
  bottom: 1.5%;
  left: 30%;
  /* margin: 25% 0px 0px 23%; */
  box-shadow: 0px 4px 15px #7272723c;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 0% 90% 10%;
}
.Chat_mid__315n2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.Chat_mid__315n2 img {
  width: 20px;
  height: 20px;
}

/* .downleft {
    background-color: yellow;
} */

.Chat_downleft__FKaMQ img {
  width: 25px;
  margin: 12px;
}

/* .downmid {
    background-color: blue;
} */

.Chat_downmid__1mBhe p {
  font-size: 15px;
  border-bottom: 1px solid #7272723c;
  color: #676767;
}

/* .downright {
    background-color: red;
} */

.Chat_downright__PRKDP img {
  width: 50px;
  margin: 2px -3px;
}

.Chat_changeDiv__EJzhK {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  width: 25%;
  height: 45%;
  left: 20%;
  z-index: 150;
  margin: 15% 0% 0 15%;
  /* top: 30%; */
  position: absolute;
}
.Chat_more__1p6tR {
  width: 38px;
  cursor: pointer;
}
.Chat_more__1p6tR img {
  cursor: pointer;
  z-index: 1;
}
.Chat_modal_container__2vlQa {
  color: #333333;
  box-shadow: 0 2px 2px 2px #00000029;
  background-color: #ffffff;
  border-radius: 10px 0px 10px 10px;
  margin: -0.5% 0 0 -6%;
  padding: 1% 1% 1% 1%;
  width: 6%;
  opacity: 1;
  position: absolute;
}
.Chat_modal_label__2Cpq5 {
  border: none;
  background: none;
  font-size: 0.8rem;
  padding: 0%;
}

.Chat_modal_label__2Cpq5 button :hover {
  border: 1px solid #5a1c1c;
  cursor: pointer;
}
.Chat_changeDeleteDiv__3LyQH {
  display: block;
  position: absolute;
  margin: 6% 16%;
}

.Chat_addBlurr__E6yMN {
  filter: brightness(40px);
  -webkit-filter: blur(15px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}
@media screen and (max-width: 700px) {
  .Chat_headerStyle__1Jbtj {
    width: 90vw;
  }
  .Chat_sectionStyle__3oiAq {
    width: 90vw;
    overflow: hidden;
  }
  .Chat_centered__dLooy {
    width: 90%;
    left: 5%;
    top: 16%;
    overflow-x: hidden;
  }
  .Chat_header__1jN0P {
    grid-template-columns: 30% 70% 0%;
  }
  .Chat_downcontainer__3_DGn {
    width: 80%;
    bottom: 10%;
    left: 10%;
    height: 0%;
  }

  .Chat_uReceiver__8la21 {
    width: 215px;
    margin: 4px 20px 0px 20%;
    word-wrap: break-all;
  }
  .Chat_uReceiver__8la21 p {
    /* font-size: 16px; */
  }
  .Chat_rReceiver__3tJ6z {
    width: 215px;
    word-wrap: break-all;
  }
  .Chat_backarrow__4l37Y {
    margin-left: -10px;
    margin-top: 25px;
  }
  .Chat_text__2BMb7 {
    margin-left: -180px;
    margin-top: 6px;
  }
}

@media screen and (max-width: 1080px) {
  .Chat_centered__dLooy {
    top: 11%;
    height: 60%;
    overflow-x: hidden;
    width: 85%;
    left: 12%;
  }
  .Chat_header__1jN0P {
    grid-template-columns: 26% 72%;
  }
  .Chat_left__1PpXX {
    margin-top: 32px;
  }
  .Chat_heading__2TraV {
    grid-template-columns: auto auto;
  }
  .Chat_downcontainer__3_DGn {
    top: 74%;
    left: 12%;
    width: 84%;
  }
  .Chat_rReceiver__3tJ6z {
    width: 50%;
  }
  .Chat_uReceiver__8la21 {
    width: 50%;
    margin: 7px 0 0 45%;
  }
  .Chat_downright__PRKDP img {
    margin: 18px -3px;
  }
  .Chat_headerStyle__1Jbtj {
    padding: 0;
  }
}

.ChatDropdown_subContainer1__2L0-l {
    height: 25px;
    width: 82px;
    box-shadow: 0 0px 2px 2px #00000029;
    background-color: white;
    border-radius: 9px 0px 9px 9px;
    margin: 5px 0px 3px 5px;
    padding-bottom: 5px;
}

.ChatDropdown_label__2Xsui {
    float: left;
    font-weight: 400;
    font-size: 7pt;
    margin: 8px 0 0 15px;
}

.ChatDropdown_changeDiv__3f0Ut {
    background-color: #FAFBFC;
    border-radius: 30px 30px 30px 30px;
    width: 45%;
    height: 45%;
    left: 20%;
    z-index: 150;
    margin: 15% 0% 0 5%;
    /* top: 30%; */
    position: absolute;
}

.ChatDropdown_addBlurr__3xNPj {
    filter: brightness(40px);
    -webkit-filter: blur(15px);
    background-color: #F2F2F2;
    overflow-y: hidden;
}
.Privacypolicy_container__1VrSS {
  display: grid;
  /* / grid-template-rows: 4rem 36rem 5rem; / */
  grid-template-rows: 4rem 57rem;
  height: 100%;
  width: 100vw;
}

.Privacypolicy_background_img__23TdQ {
  width: 92%;
  height: 100%;
  margin: 0% 8%;
  background-repeat: no-repeat;
  background-size: cover;
}

.Privacypolicy_centered_text__2JbpG {
  font-size: 2.92vw;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.Privacypolicy_centered_button__2URDC {
  opacity: 1;
}

.Privacypolicy_spanStyle__3L6Cs {
  font-size: 24px;
}

.Privacypolicy_sectionStyle__GNxec {
  z-index: 90;
  overflow: hidden;
}

.Privacypolicy_loader_style__1S2aB {
  margin: 20% auto;
}

.Privacypolicy_headerStyle__uZPc7 {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.Privacypolicy_footerStyle__30zd6 {
  z-index: 100;
  padding-left: 5%;
  box-shadow: 0 0px 3px 8px #00000029;
}

.Privacypolicy_centered__p7ym0 {
  font-family: "Poppins";
  font-weight: 500;
  z-index: 1;
  position: absolute;
  top: 32%;
  left: 18%;
}

.Privacypolicy_changeDiv__mFN2G {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 11% 29%;
  z-index: 150;
  position: absolute;
}
.Privacypolicy_changeDiv1__1uRXl {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 12% 34%;
  z-index: 150;
  position: absolute;
}
.Privacypolicy_changeDiv2__2pzA2 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 17% 39%;
  z-index: 150;
  position: absolute;
}
.Privacypolicy_addBlurr__zs1MP {
  filter: brightness(40px);
  -webkit-filter: blur(40px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

@media screen and (max-width: 1600px) {
  .Privacypolicy_changeDiv__mFN2G {
    margin: 4% 28%;
  }
  .Privacypolicy_changeDiv1__1uRXl {
    margin: 12% 34%;
  }
  .Privacypolicy_changeDiv2__2pzA2 {
    margin: 15% 35%;
  }
}

@media screen and (max-width: 1400px) {
  .Privacypolicy_changeDiv__mFN2G {
    margin: 2% 27%;
  }
  .Privacypolicy_changeDiv1__1uRXl {
    margin: 12% 34%;
  }
  .Privacypolicy_changeDiv2__2pzA2 {
    margin: 12% 35%;
  }
}
@media screen and (max-width: 1366px) {
  .Privacypolicy_changeDiv__mFN2G {
    margin: 2% 27%;
  }
  .Privacypolicy_changeDiv1__1uRXl {
    margin: 8% 34%;
  }
  .Privacypolicy_changeDiv2__2pzA2 {
    margin: 10% 35%;
  }
}

@media (max-width: 575px) {
  .Privacypolicy_background_img__23TdQ {
    height: 350px;
  }
}

@media screen and (max-width: 766px) {
  .Privacypolicy_centered_text__2JbpG {
    font-size: 2.19vw;
  }
  .Privacypolicy_centered__p7ym0 {
    position: absolute;
    top: 32%;
    left: 8%;
  }
  .Privacypolicy_changeDiv__mFN2G {
    margin: 5% 5%;
    width: 85vw;
  }
  .Privacypolicy_spanStyle__3L6Cs {
    font-size: 1.31vw;
  }
  .Privacypolicy_background_img__23TdQ {
    height: 60.97vh;
  }
  .Privacypolicy_changeDiv1__1uRXl {
    margin: 21% 5%;
  }
  .Privacypolicy_changeDiv2__2pzA2 {
    margin: 10% 2%;
  }
}

.Privacypolicy_hero__2uZvx {
  /* max-height: 400px; */
  border-radius: 30px 30px 0 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  background: #fff;
}
.Privacypolicy_left__mhBgw {
  padding-top: 40px;
  width: 40%;
}
.Privacypolicy_left__mhBgw p:nth-child(1) {
  font-size: 16px;
}
.Privacypolicy_left__mhBgw p:nth-child(2) {
  font-size: 16px;
}
.Privacypolicy_left__mhBgw button {
  background: #9675e4 0% 0% no-repeat padding-box;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 10px;
  color: #fff;
  box-shadow: 0px 3px 6px #00000029;
}
.Privacypolicy_right__3EW2x {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  position: relative;
  width: 50%;
}
.Privacypolicy_right__3EW2x img:nth-child(1) {
  height: 400px;
  width: 100%;
  margin-top: 100px;
}
.Privacypolicy_right__3EW2x img:nth-child(2) {
  position: absolute;
  top: 60%;
  left: 50%;
  height: 50px;
  width: 50px;
}
.Privacypolicy_provide__3lGl9 {
  background: #eff4f8;
  padding: 20px;
}
.Privacypolicy_provideTitle__3pqow {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 80px auto 40px auto;
  width: 90%;
}
.Privacypolicy_provideTitle__3pqow div {
  border: 1px solid #8674ec;
  width: 100px;
}

.Privacypolicy_provideBox__2tIbj {
  display: grid;
  width: 90%;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
  grid-gap: 20px;
}

.Privacypolicy_provideBoxItem__3oVQf {
  background: #fff;
  box-shadow: 5px 30px 40px #00000040;
  border-radius: 30px;
  padding: 20px;
  /* width: 40%; */
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.Privacypolicy_provideBoxItem__3oVQf:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background: linear-gradient(264deg, #6a68d2 0%, #5f5dbd 100%);
}
.Privacypolicy_provideBoxItem__3oVQf img {
  margin-left: 40px;
}
.Privacypolicy_provideBoxItem__3oVQf .Privacypolicy_withHover__33Wpn {
  display: none;
}
.Privacypolicy_provideBoxItem__3oVQf:hover .Privacypolicy_withHover__33Wpn {
  display: block;
}
.Privacypolicy_provideBoxItem__3oVQf:hover .Privacypolicy_withoutHover__110OV {
  display: none;
}
.Privacypolicy_provideBoxItem__3oVQf p {
  margin: 0;
  /* width: 35%; */
  padding: 2px 0;
  margin-left: 40px;
  color: #000;
}
.Privacypolicy_provideBoxItem__3oVQf:hover p {
  color: #fff;
}
.Privacypolicy_provideBoxItem__3oVQf p:nth-child(2) {
  font-weight: bold;
}
.Privacypolicy_provideBoxItem__3oVQf button {
  border: 1px solid #9977e8;
  border-radius: 9px;
  outline: none;
  cursor: pointer;
  color: #9977e8;
  background: transparent;
  padding: 10px;
  margin-left: 40px;
  margin-top: 20px;
}
.Privacypolicy_provideBoxItem__3oVQf:hover button {
  border: 1px solid #fff;
  color: #fff;
}

.Privacypolicy_choose__1LV56 {
  background: #fff;
  padding: 20px;
}
.Privacypolicy_chooseTitle__s0Vfp {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 30px auto 40px auto;
  width: 90%;
}
.Privacypolicy_chooseTitle__s0Vfp div {
  border: 1px solid #8674ec;
  width: 100px;
}

.Privacypolicy_item__Y-gtG {
  width: 90%;
  margin: auto;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
}
.Privacypolicy_item__Y-gtG div {
  border-radius: 30px;
  box-shadow: 2px 10px 20px #0000001a;
  background: #f5f8fb;
  text-align: center;
  padding: 40px;
}
.Privacypolicy_item__Y-gtG div p:nth-child(2) {
  font-weight: bold;
}
.Privacypolicy_trusted__1IHpg {
  padding: 20px;
  background: #eff4f8;
}
.Privacypolicy_trustTitle__2ucMX {
  width: 90%;
  margin: 20px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.Privacypolicy_trustTitle__2ucMX div:nth-child(1),
.Privacypolicy_trustTitle__2ucMX div:nth-child(3) {
  background: #8674ec;
  width: 10%;
  height: 2px;
}
.Privacypolicy_trustTitle__2ucMX div:nth-child(2) {
  font-size: 1.8em;
  font-weight: bold;
}

.Privacypolicy_companies__3CecD {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
}
.Privacypolicy_companies__3CecD div {
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
}
.Privacypolicy_companies__3CecD div img {
  /* width:150px;
    height: 100px; */
}

@media screen and (max-width: 900px) {
  .Privacypolicy_left__mhBgw {
    width: 100%;
    text-align: center;
    height: auto;
  }
  .Privacypolicy_left__mhBgw p:nth-child(1) {
    font-size: 20px;
  }
  .Privacypolicy_left__mhBgw p:nth-child(2) {
    font-size: 16px;
  }
  .Privacypolicy_right__3EW2x {
    display: none;
  }
  .Privacypolicy_chooseTitle__s0Vfp {
    font-size: 20px;
  }
  .Privacypolicy_item__Y-gtG {
    grid-template-columns: 1fr;
  }
  .Privacypolicy_provideTitle__3pqow {
    font-size: 20px;
  }
  .Privacypolicy_provideTitle__3pqow div {
    width: 89px;
  }
  .Privacypolicy_provideBox__2tIbj {
    grid-template-columns: 1fr;
  }
  .Privacypolicy_trustTitle__2ucMX div:nth-child(2) {
    font-size: 12px;
  }
  .Privacypolicy_landingPage__1wk9T {
    /* margin-top: 0; */
  }
  .Privacypolicy_companies__3CecD {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
  .Privacypolicy_companies__3CecD div img {
    /* height: 50px; */
    width: 120px;
  }
}
@media screen and (max-width: 600px) {
  .Privacypolicy_provideTitle__3pqow {
    font-size: 18px;
    margin-top: 10px;
  }
  .Privacypolicy_provideTitle__3pqow div {
    width: 70px;
  }

  .Privacypolicy_chooseTitle__s0Vfp {
    font-size: 18px;
  }
  .Privacypolicy_chooseTitle__s0Vfp div {
    width: 70px;
  }
  .Privacypolicy_companies__3CecD {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
  .Privacypolicy_companies__3CecD div img {
    /* height: 50px; */
    width: 100px;
  }
  .Privacypolicy_trustTitle__2ucMX {
  }
  .Privacypolicy_trustTitle__2ucMX div:nth-child(1),
  .Privacypolicy_trustTitle__2ucMX div:nth-child(3) {
    background: #8674ec;
    width: 5%;
    height: 2px;
  }
  .Privacypolicy_trustTitle__2ucMX div:nth-child(2) {
    font-size: 0.8em;
  }
  /* .trustTitle div:nth-child(2){
        font-size: 12px;
    } */
}
@media screen and (max-width: 400px) {
  .Privacypolicy_trustTitle__2ucMX div:nth-child(2) {
    font-size: 0.6em;
  }
}

/* media query for ipad */
@media screen and (max-width: 1080px) {
  .Privacypolicy_changeDiv__mFN2G {
    margin: 10% 25%;
  }
  .Privacypolicy_headerStyle__uZPc7 {
    padding: 0;
  }
  .Privacypolicy_changeDiv2__2pzA2,
  .Privacypolicy_changeDiv__mFN2G,
  .Privacypolicy_changeDiv1__1uRXl {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 40%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .Privacypolicy_changeDiv1__1uRXl {
    padding: 10px;
  }
}

.termsandcondition_container__30gLd {
  display: grid;
  /* / grid-template-rows: 4rem 36rem 5rem; / */
  grid-template-rows: 4rem 57rem;
  height: 100%;
  width: 100vw;
}

.termsandcondition_background_img__1nNYY {
  width: 92%;
  height: 100%;
  margin: 0% 8%;
  background-repeat: no-repeat;
  background-size: cover;
}

.termsandcondition_centered_text__1unZ9 {
  font-size: 2.92vw;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.termsandcondition_centered_button__1uiDL {
  opacity: 1;
}

.termsandcondition_spanStyle__2MUTa {
  font-size: 24px;
}

.termsandcondition_sectionStyle__3qHwI {
  z-index: 90;
  overflow: hidden;
}

.termsandcondition_loader_style__3qMdR {
  margin: 20% auto;
}

.termsandcondition_headerStyle__2fSpC {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.termsandcondition_footerStyle__1zBnU {
  z-index: 100;
  padding-left: 5%;
  box-shadow: 0 0px 3px 8px #00000029;
}

.termsandcondition_centered__3MdO_ {
  font-family: "Poppins";
  font-weight: 500;
  z-index: 1;
  position: absolute;
  top: 32%;
  left: 18%;
}

.termsandcondition_changeDiv___k1Fu {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 11% 29%;
  z-index: 150;
  position: absolute;
}
.termsandcondition_changeDiv1__3WyXv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 12% 34%;
  z-index: 150;
  position: absolute;
}
.termsandcondition_changeDiv2__Q2-a5 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 17% 39%;
  z-index: 150;
  position: absolute;
}
.termsandcondition_addBlurr__1lNr9 {
  filter: brightness(40px);
  -webkit-filter: blur(40px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

@media screen and (max-width: 1600px) {
  .termsandcondition_changeDiv___k1Fu {
    margin: 4% 28%;
  }
  .termsandcondition_changeDiv1__3WyXv {
    margin: 12% 34%;
  }
  .termsandcondition_changeDiv2__Q2-a5 {
    margin: 15% 35%;
  }
}

@media screen and (max-width: 1400px) {
  .termsandcondition_changeDiv___k1Fu {
    margin: 2% 27%;
  }
  .termsandcondition_changeDiv1__3WyXv {
    margin: 12% 34%;
  }
  .termsandcondition_changeDiv2__Q2-a5 {
    margin: 12% 35%;
  }
}
@media screen and (max-width: 1366px) {
  .termsandcondition_changeDiv___k1Fu {
    margin: 2% 27%;
  }
  .termsandcondition_changeDiv1__3WyXv {
    margin: 8% 34%;
  }
  .termsandcondition_changeDiv2__Q2-a5 {
    margin: 10% 35%;
  }
}

@media (max-width: 575px) {
  .termsandcondition_background_img__1nNYY {
    height: 350px;
  }
}

@media screen and (max-width: 766px) {
  .termsandcondition_centered_text__1unZ9 {
    font-size: 2.19vw;
  }
  .termsandcondition_centered__3MdO_ {
    position: absolute;
    top: 32%;
    left: 8%;
  }
  .termsandcondition_changeDiv___k1Fu {
    margin: 5% 5%;
    width: 85vw;
  }
  .termsandcondition_spanStyle__2MUTa {
    font-size: 1.31vw;
  }
  .termsandcondition_background_img__1nNYY {
    height: 60.97vh;
  }
  .termsandcondition_changeDiv1__3WyXv {
    margin: 21% 5%;
  }
  .termsandcondition_changeDiv2__Q2-a5 {
    margin: 10% 2%;
  }
}

.termsandcondition_hero__1kNrU {
  max-height: 400px;
  border-radius: 30px 30px 0 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  background: #fff;
}
.termsandcondition_left__19JtA {
  padding-top: 40px;
  width: 40%;
}
.termsandcondition_left__19JtA p:nth-child(1) {
  font-size: 16px;
}
.termsandcondition_left__19JtA p:nth-child(2) {
  font-size: 16px;
}
.termsandcondition_left__19JtA button {
  background: #9675e4 0% 0% no-repeat padding-box;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 10px;
  color: #fff;
  box-shadow: 0px 3px 6px #00000029;
}
.termsandcondition_right__3J8D6 {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  position: relative;
  width: 50%;
}
.termsandcondition_right__3J8D6 img:nth-child(1) {
  height: 400px;
  width: 100%;
  margin-top: 100px;
}
.termsandcondition_right__3J8D6 img:nth-child(2) {
  position: absolute;
  top: 60%;
  left: 50%;
  height: 50px;
  width: 50px;
}
.termsandcondition_provide__3d0kW {
  background: #eff4f8;
  padding: 20px;
}
.termsandcondition_provideTitle__2AS27 {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 80px auto 40px auto;
  width: 90%;
}
.termsandcondition_provideTitle__2AS27 div {
  border: 1px solid #8674ec;
  width: 100px;
}

.termsandcondition_provideBox__3tq0I {
  display: grid;
  width: 90%;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
  grid-gap: 20px;
}

.termsandcondition_provideBoxItem__2c-9F {
  background: #fff;
  box-shadow: 5px 30px 40px #00000040;
  border-radius: 30px;
  padding: 20px;
  /* width: 40%; */
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.termsandcondition_provideBoxItem__2c-9F:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background: linear-gradient(264deg, #6a68d2 0%, #5f5dbd 100%);
}
.termsandcondition_provideBoxItem__2c-9F img {
  margin-left: 40px;
}
.termsandcondition_provideBoxItem__2c-9F .termsandcondition_withHover__3iYkn {
  display: none;
}
.termsandcondition_provideBoxItem__2c-9F:hover .termsandcondition_withHover__3iYkn {
  display: block;
}
.termsandcondition_provideBoxItem__2c-9F:hover .termsandcondition_withoutHover__3PXNU {
  display: none;
}
.termsandcondition_provideBoxItem__2c-9F p {
  margin: 0;
  /* width: 35%; */
  padding: 2px 0;
  margin-left: 40px;
  color: #000;
}
.termsandcondition_provideBoxItem__2c-9F:hover p {
  color: #fff;
}
.termsandcondition_provideBoxItem__2c-9F p:nth-child(2) {
  font-weight: bold;
}
.termsandcondition_provideBoxItem__2c-9F button {
  border: 1px solid #9977e8;
  border-radius: 9px;
  outline: none;
  cursor: pointer;
  color: #9977e8;
  background: transparent;
  padding: 10px;
  margin-left: 40px;
  margin-top: 20px;
}
.termsandcondition_provideBoxItem__2c-9F:hover button {
  border: 1px solid #fff;
  color: #fff;
}

.termsandcondition_choose__JkyeA {
  background: #fff;
  padding: 20px;
}
.termsandcondition_chooseTitle__34VOF {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 30px auto 40px auto;
  width: 90%;
}
.termsandcondition_chooseTitle__34VOF div {
  border: 1px solid #8674ec;
  width: 100px;
}

.termsandcondition_item__3C0xQ {
  width: 90%;
  margin: auto;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
}
.termsandcondition_item__3C0xQ div {
  border-radius: 30px;
  box-shadow: 2px 10px 20px #0000001a;
  background: #f5f8fb;
  text-align: center;
  padding: 40px;
}
.termsandcondition_item__3C0xQ div p:nth-child(2) {
  font-weight: bold;
}
.termsandcondition_trusted__3KFX8 {
  padding: 20px;
  background: #eff4f8;
}
.termsandcondition_trustTitle__2_u10 {
  width: 90%;
  margin: 20px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.termsandcondition_trustTitle__2_u10 div:nth-child(1),
.termsandcondition_trustTitle__2_u10 div:nth-child(3) {
  background: #8674ec;
  width: 10%;
  height: 2px;
}
.termsandcondition_trustTitle__2_u10 div:nth-child(2) {
  font-size: 1.8em;
  font-weight: bold;
}

.termsandcondition_companies__2fL7M {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
}
.termsandcondition_companies__2fL7M div {
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
}
.termsandcondition_companies__2fL7M div img {
  /* width:150px;
    height: 100px; */
}

@media screen and (max-width: 900px) {
  .termsandcondition_left__19JtA {
    width: 100%;
    text-align: center;
    height: auto;
  }
  .termsandcondition_left__19JtA p:nth-child(1) {
    font-size: 20px;
  }
  .termsandcondition_left__19JtA p:nth-child(2) {
    font-size: 16px;
  }
  .termsandcondition_right__3J8D6 {
    display: none;
  }
  .termsandcondition_chooseTitle__34VOF {
    font-size: 20px;
  }
  .termsandcondition_item__3C0xQ {
    grid-template-columns: 1fr;
  }
  .termsandcondition_provideTitle__2AS27 {
    font-size: 20px;
  }
  .termsandcondition_provideTitle__2AS27 div {
    width: 89px;
  }
  .termsandcondition_provideBox__3tq0I {
    grid-template-columns: 1fr;
  }
  .termsandcondition_trustTitle__2_u10 div:nth-child(2) {
    font-size: 12px;
  }
  .termsandcondition_landingPage__3bwK6 {
    /* margin-top: 0; */
  }
  .termsandcondition_companies__2fL7M {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
  .termsandcondition_companies__2fL7M div img {
    /* height: 50px; */
    width: 120px;
  }
}
@media screen and (max-width: 600px) {
  .termsandcondition_provideTitle__2AS27 {
    font-size: 18px;
    margin-top: 10px;
  }
  .termsandcondition_provideTitle__2AS27 div {
    width: 70px;
  }

  .termsandcondition_chooseTitle__34VOF {
    font-size: 18px;
  }
  .termsandcondition_chooseTitle__34VOF div {
    width: 70px;
  }
  .termsandcondition_companies__2fL7M {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
  .termsandcondition_companies__2fL7M div img {
    /* height: 50px; */
    width: 100px;
  }
  .termsandcondition_trustTitle__2_u10 {
  }
  .termsandcondition_trustTitle__2_u10 div:nth-child(1),
  .termsandcondition_trustTitle__2_u10 div:nth-child(3) {
    background: #8674ec;
    width: 5%;
    height: 2px;
  }
  .termsandcondition_trustTitle__2_u10 div:nth-child(2) {
    font-size: 0.8em;
  }
  /* .trustTitle div:nth-child(2){
        font-size: 12px;
    } */
}
@media screen and (max-width: 400px) {
  .termsandcondition_trustTitle__2_u10 div:nth-child(2) {
    font-size: 0.6em;
  }
}

/* media query for ipad */
@media screen and (max-width: 1080px) {
  .termsandcondition_changeDiv___k1Fu {
    margin: 10% 25%;
  }
  .termsandcondition_headerStyle__2fSpC {
    padding: 0;
  }
  .termsandcondition_changeDiv2__Q2-a5,
  .termsandcondition_changeDiv___k1Fu,
  .termsandcondition_changeDiv1__3WyXv {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 40%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .termsandcondition_changeDiv1__3WyXv {
    padding: 10px;
  }
}

