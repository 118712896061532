.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.font-face-gm {
  font-family: "SilverAvenueRegular";
}

.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 400px;
  width: 100px;
  font-size: 0.875rem;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  z-index: 200;
  position: absolute;
  bottom: 5%;
  right: 10%;
  min-width: 200px;
}

.toastheader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toastbody {
  padding: 0.75rem;
}
.toasthide {
  opacity: 0;
  display: none;
  position: absolute;
  bottom: 20;
  right: 20;
  min-width: 200px;
}

@media screen and (max-width: 775px) {
  .toast {
    position: absolute;
    top: 46%;
    left: 6%;
    min-width: 268px;
    z-index: 200;
    bottom: 23%;
  }
  .toasthide {
    position: absolute;
    top: 46%;
    left: 6%;
    min-width: 268px;
    z-index: 200;
    bottom: 23%;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
