.modal_container {
  position: absolute;
  background-color: #ffffff;
  border-radius: 10% 0 10% 10%;
  border: 1px solid #e5e5e5;
  margin: 0% 0% 0% 24px;
  font-size: 9pt;
  filter: drop-shadow(0px 3px 6px #00000029);
  padding: 2% 7%;
}

.modal_container p {
  margin: 7% 0% 7% 0%;
  color: #333333;
  opacity: 1;
  font-weight: 400;
  cursor: pointer;
}
.modal_label:hover {
  transform: scale(1.1);
}
.maincard {
  display: grid;
  grid-template-rows: auto auto;
  margin: 3% 4% 3% 0;
  /* grid-template-rows: 10px 150px;
    margin: 16px;
    margin-left: 3px; */
}

.card {
  border: 1px solid #cfcfd2;
  margin: 2% 0% 0% 5%;
  position: relative;
  border-radius: 2%;
  height: 13rem;
  box-shadow: 0px 0px 6pt #675aff3d;
  overflow: hidden;
}

/* .card:hover {
    box-shadow: 20px 20px 18px -8px #00000015;
    transform: scale(1, 1);
} */

.activeSquare {
  width: 5%;
  position: absolute;
  padding: 8% 3% 0 0;
  border-top-left-radius: 4%;
  background-image: -webkit-linear-gradient(-45deg, #36b453 50%, #ffffff 50%);
}

.inactiveSquare {
  width: 5%;
  position: absolute;
  padding: 8% 3% 0 0;
  border-top-left-radius: 4%;
  background-image: -webkit-linear-gradient(-45deg, #ffb031 50%, #ffffff 50%);
}

.upperdiv {
  /* position: absolute; */
  padding: 4% 10% 0% 10%;
  /* height: 65px; */
  /* background-color: red; */
  display: grid;
  grid-template-columns: repeat(2, 1fr) 0px;
}

.headleft {
  background-color: white;
}

.headleft img {
  height: 79px;
  width: 85px;
  border-radius: 54%;
  margin-top: 7px;
}

.headright {
  background-color: white;
  display: grid;
  /* grid-template-rows: 150px 150px; */
  grid-template-columns: 50px 50px;
}

.img1 img {
  margin-left: 124px;
  background-color: white;
  height: 19px;
}

.img2 img {
  margin-left: 48px;
  background-color: white;
  height: 19px;
}

.lowerdiv {
  background-color: white;
  /* width: 266px; */
  padding: 0% 0% 0% 10%;
}

/* .detail {
    display: flex;
    flex-direction: row;
    width: 110%;
    height: 80px;
    background-color: saddlebrown;
} */

.leftdetail {
  display: flex;
  flex-direction: column;
}

.table {
  min-width: 220px;
  /* margin-top: -13px; */
  /* margin: 25px 0px; */
}

.table tbody {
  margin: 25px;
}

/* .detail {
    display: flex;
    flex-direction: column;
    height: 20%;
} */

/* .login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
} */

.category {
  /* display: flex; */
  opacity: 0.6;
  color: #333333;
  font-size: 0.9rem;
  /* line-height: 4%; */
}

.category1 {
  opacity: 0.6;
  color: #333333;
  font-size: 0.8rem;
  /* line-height: 4%; */
}

.value {
  opacity: 1;
  color: #333333;
  font-size: 0.9rem;
  font-weight: bold;
}

@media screen and (max-width: 1550px) {
  .img1 img {
    margin-left: 120px;
  }
  .img2 img {
    margin-left: 37px;
  }
}

@media screen and (max-width: 1392px) {
  .img1 img {
    margin-left: 114px;
  }
  .img2 img {
    margin-left: 35px;
  }
}
@media screen and (max-width:575px){
  .upperdiv{
    padding: 4% 18% 0% 0%;
  }

}
