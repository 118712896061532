.container {
  display: grid;

  grid-template-rows: 4rem 57rem;
  height: 100%;
  width: 100vw;
}

.background_img {
  width: 30%;
  height: 100%;
  float: right;
  margin: 0% 8%;
  background-repeat: no-repeat;
  margin-top: 8rem;
  background-size: cover;
}

.centered_text {
  font-size: 2.92vw;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.centered_button {
  opacity: 1;
}

.spanStyle {
  font-size: 24px;
}

.sectionStyle {
  z-index: 90;
  overflow: hidden;
}

.loader_style {
  margin: 20% auto;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.footerStyle {
  z-index: 100;
  padding-left: 5%;
  box-shadow: 0 0px 3px 8px #00000029;
}

.centered {
  font-family: "Poppins";
  font-weight: 500;
  z-index: 1;
  max-width: 30%;
  position: absolute;
  top: 32%;
  left: 18%;
}

.tagline {
  color: #8f36b3;
  font-size: 1.5rem;
}

.changeDiv {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 11% 29%;
  z-index: 150;
  position: absolute;
}
.changeDiv1 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 12% 34%;
  z-index: 150;
  position: absolute;
}
.changeDiv2 {
  background-color: #fafbfc;
  border-radius: 30px 30px 30px 30px;
  margin: 17% 39%;
  z-index: 150;
  position: absolute;
}
.addBlurr {
  filter: brightness(40px);
  -webkit-filter: blur(40px);
  background-color: #f2f2f2;
  overflow-y: hidden;
}

@media screen and (max-width: 1600px) {
  .changeDiv {
    margin: 4% 28%;
  }
  .changeDiv1 {
    margin: 12% 34%;
  }
  .changeDiv2 {
    margin: 15% 35%;
  }
}

@media screen and (max-width: 1400px) {
  .changeDiv {
    margin: 2% 27%;
  }
  .changeDiv1 {
    margin: 12% 34%;
  }
  .changeDiv2 {
    margin: 12% 35%;
  }
}
@media screen and (max-width: 1366px) {
  .changeDiv {
    margin: 2% 27%;
  }
  .changeDiv1 {
    margin: 8% 34%;
  }
  .changeDiv2 {
    margin: 10% 35%;
  }
}

@media (max-width: 575px) {
  .background_img {
    height: 350px;
  }
}

@media screen and (max-width: 766px) {
  .centered_text {
    font-size: 2.19vw;
  }
  .centered {
    position: absolute;
    top: 32%;
    left: 8%;
  }
  .changeDiv {
    margin: 5% 5%;
    width: 85vw;
  }
  .spanStyle {
    font-size: 1.31vw;
  }
  .background_img {
    height: 60.97vh;
  }
  .changeDiv1 {
    margin: 21% 5%;
  }
  .changeDiv2 {
    margin: 10% 2%;
  }
}

/* media query for ipad */
@media screen and (max-width: 1080px) {
  .changeDiv {
    margin: 10% 25%;
  }
  .headerStyle {
    padding: 0;
  }
  .changeDiv2,
  .changeDiv,
  .changeDiv1 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
  }
  .changeDiv1 {
    padding: 10px;
  }
  .centered {
    font-family: "Poppins";
    font-weight: 500;
    z-index: 1;
    max-width: 41%;
    position: absolute;
    top: 18%;
    left: 12%;
  }
}
