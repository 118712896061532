.container {
  display: grid;
  grid-template-columns: 55% 55%;
}

.leftContainer {
  background-color: #fafbfc;
  width: 100%;
  /* height: 110%; */
  border-radius: 30px 0 0 30px;
}

.leftContainer .leftLogo {
  padding: 6% 0 0 37%;
}

.leftContainer .leftPara {
  font-size: 0.9rem;
  line-height: 1.3;
  font-weight: 500;
  padding: 2% 0 0 6%;
  opacity: 0.7;
}

.leftContainer .leftImg img {
  width: 80%;
  padding: 7% 0 0 10%;
  height: 200px;
}

.rightContainer {
  background-color: #ffffff;
  border-radius: 0 30px 30px 0;
  width: 100%;
  /* height: 110%; */
}
.closeModal {
  float: right;
  width: 25px;
  height: 25px;
  margin: 5%;
  cursor: pointer;
}

.centered {
  background-color: whitesmoke;
  margin-left: 450px;
  margin-right: 450px;
  height: 400px;
  width: 500px;
  margin-top: 130px;
  border-radius: 15px 15px 15px 15px;
  padding-left: 25px;
}

.upper img {
  background-color: whitesmoke;
  float: right;
  margin-top: 4%;
  margin-right: 2%;
}

.logoimg {
  height: 8%;
  margin-left: 40%;
}
.heading {
  margin-top: 10%;
  margin-left: 34%;
  height: 28%;
}
.para {
  margin-left: 25%;
  margin-top: 10%;
  opacity: 0.8;
}
.flex {
  display: flex;
  flex-direction: column;
  height: 80%;

  padding-top: 12%;
}
.text {
  height: 10%;
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  margin-left: 30%;
}
.text div div input {
  width: 1.6em !important;
}

.button1 {
  box-shadow: 0 0px 2px 2px #0000001f;
  border: 1px solid #110766;
  width: 50%;
  height: 40%;
  background-color: #110766;
  margin-top: 10%;
  margin-left: 34%;
  color: white;
  font-size: 17px;
  border-radius: 14px;
  cursor: pointer;
}
.button2 {
  box-shadow: 0 0px 2px 2px #0000001f;
  border: 1px solid #110766;
  width: 50%;
  height: 52%;
  background-color: white;
  margin-top: 4%;
  margin-left: 34%;
  padding: 10px;
  color: 1px solid #110766;
  font-size: 17px;
  border-radius: 14px;
  cursor: pointer;
}
.para {
  width: 50%;
  height: 40%;

  margin-top: 10%;
  margin-left: 38%;
  color: 1px solid #110766;
  font-size: 15px;
}
.errorMsg {
  font-size: 1rem;
  color: red;
  float: right;
  margin-left: 40%;

  margin-top: 2%;
}
@media screen and (max-width: 700px) {
  .leftContainer .leftPara {
    font-size: 3.9vw;
  }
  .heading {
    margin-left: 0%;
  }
  .text div div input {
    width: 0.9em !important;
  }
  .text {
    margin-left: 0%;
    height: 5%;
  }
  .para {
    margin-left: 0%;
    width: 100%;
  }
  .button2 {
    width: 55%;
    margin-left: 20%;
    font-size: 11px;
  }
  .button1 {
    width: 55%;
    margin-left: 25%;
    font-size: 11px;
  }
}

@media screen and (max-width: 1080px) {
  .container {
    grid-template-columns: 50% 90%;
  }
  .headerStyle {
    padding: 0;
  }
}
