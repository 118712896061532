.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 57rem;
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: rgb(238, 238, 245);
  background-position: fixed;
  background-size: cover;
  overflow: hidden;
}

.background_img {
  width: 80%;
  height: 110%;
  margin: -3% 8%;
}

.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}

.centered_button {
  opacity: 1;
}

.topsomepagewrapper {
  margin: 1% 11%;
}

.toprow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.topcolumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.editbtn {
  text-align: right;
}

.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.heading img {
  width: 2%;
  margin-left: 135px;
  margin-top: 25px;
}

.heading p {
  font-weight: bold;
  margin-left: 11%;
  margin-top: 3%;
}

.centered {
  overflow-y: auto;
  overflow-x: hidden;
  font-weight: 500;
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
}

.borderbox {
  border: 1px solid #cfcfd2;
  margin: 26px;
  margin-top: 18px;
  /* height: 155%; */
  /* display: grid;
    grid-template-rows: 109px 137px 400px; */
}

.row1 {
  background-color: white;
  border-bottom: 1px solid #dedede;
  padding: 15px;
  /* display: grid;
    grid-template-columns: 95px 300px 368px; */
  overflow: hidden;
}

.row1box {
  display: grid;
  grid-template-columns: 20% 40% 40%;
}

.left {
  background-color: white;
}

.left img {
  width: 100%;
  margin-top: 5%;
}

.middle {
  background-color: white;
  margin-left: 10%;
  margin-top: 0%;
}

.table1 {
  font-size: 15px;
  min-width: 280px;
  margin-left: -10px;
}

.table1 td {
  padding: 2% 0 0 0;
}

.right {
  background-color: white;
  margin-top: 9%;
  /* padding-top: 20px; */
  margin-left: 10%;
}

.right_table1 {
  min-width: 230px;
}

.category {
  opacity: 0.6;
  color: #333333;
  font-size: 0.8rem;
  margin: 3px;
  padding: 3px;
}

.value {
  margin: 1px;
  padding: 3px;
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  font-weight: 600;
}

.row2 {
  background-color: white;
  border-bottom: 1px solid #dedede;
  height: 12%;
  padding: 15px;
  display: flex;
}

.row2left {
  width: 24%;
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.row2right {
  width: 76%;
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
  text-align: justify;
}

.lastview {
  text-align: end;
  padding: 1% 2% 1% 0;
}
.lastview label {
  cursor: pointer;
}

.lastview label:hover {
  color: #110766;
  font-weight: 700;
}

.row3 {
  background-color: white;
  padding: 15px;
}

.upper {
  display: flex;
  margin-top: 10px;
}

.upperleft {
  background-color: white;
  width: 24%;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.upperright {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
  width: 76%;
  text-align: justify;
}

.lower {
  display: flex;
  margin-top: 10px;
}

.lowerleft {
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
  width: 24%;
}

.lowerright {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  width: 76%;
  color: #333333;
  text-align: justify;
}

.row4 {
  padding: 15px;
  border-bottom: 1px solid #dedede;
  color: black;
  margin-top: 4%;
}

.row5 {
  background-color: white;
  /* border-bottom: 1px solid #DEDEDE; */
  height: 88px;
  padding: 15px;
  display: grid;
  /* grid-template-rows: 150px 150px; */
  /* grid-template-columns: repeat(3, 120px) 0px; */
  grid-template-columns: 12% 60%;
}

.row5left {
  background-color: white;
  opacity: 0.6;
  font-size: 0.8rem;
  color: #333333;
}

.row5right {
  background-color: white;
  font-size: 0.8rem;
  opacity: 1;
  color: #333333;
}

/* @media screen and (max-width: 1368px) {
  .row4 {
    margin-top: 12%;
  }
} */
@media screen and (max-width: 1368px) {
  .row4 {
    margin-top: 12%;
  }
  .heading p {
    margin-top: 2%;
  }
}
@media screen and (max-width: 700px) {
  .sectionStyle {
    display: grid;
    grid-template-columns: 1px auto;
  }
  .centered {
    width: 100vw;
    margin-top: 0.5rem;
  }
  .topsomepagewrapper {
    margin: 0;
  }
  .middle {
    margin-left: 0;
  }
  .right {
    margin-left: -3%;
  }
  .row1box {
    display: block;
  }
  .row2 {
    display: block;
  }
  .upperright {
    margin-left: 25%;
  }
  .profile {
    display: block;
  }
  .lowerright {
    display: block;
  }
}

@media screen and (max-width: 1080px) {
  .centered {
    height: 82%;
    left: 12%;
    top: 13%;
    width: 87%;
  }
  .headerStyle {
    padding: 0;
  }
}
