.container {
  background-color: #f7f7f7;
  display: grid;
  grid-template-rows: 4rem 37rem;
  height: 100%;
}

.background_img {
  width: 80%;
  height: 110%;
  margin: -3% 8;
}

.centered_text {
  font-size: 50px;
  font-weight: bold;
  line-height: 10px;
  letter-spacing: 0.56pt;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sectionStyle {
  display: grid;
  grid-template-columns: 10% 90%;
}

.headerStyle {
  box-shadow: 0 0px 3px 8px #00000029;
  background-color: white;
  padding: 10px;
  text-align: center;
  font-size: 35px;
  color: white;
  z-index: 100;
}

.centered {
  font-family: "Poppins-medium";
  width: 70%;
  height: 75%;
  background-color: #ffffff;
  position: absolute;
  top: 25%;
  left: 20%;
  overflow-x: hidden;
  overflow-y: auto;
}

.topHeading {
  text-align: center;
  /* position: relative; */
  margin: 1.5% 0 0 -73%;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 8%;
  cursor: pointer;
  /* background-color: aquamarine; */
}
.span {
  /* font-size: 12pt;
  font-family: Poppins, SemiBold;
  color: #333333;
  max-width: 300px;
  margin: 1.2% 0% 0% 3%;
  text-align: left; */
  border-radius: 10px;
  /* margin: 2px; */
  /* padding: 2px; */
  border: 1px solid transparent;
  opacity: 1;
  color: #333333;
  font-size: 0.8rem;
  width: 70%;
  font-weight: 600;
  margin-left: 1rem;
}
.timespan {
  position: absolute;
  font-size: 7pt;
  color: #333333;
  opacity: 0.6;
  margin: 1rem;
  right: 0;
}
.keyword {
  position: absolute;
  top: 13%;
  left: 17.5%;
  width: 69vw;
  margin-left: 2rem;
  height: 2rem;
  border: 1px solid #dbdbdb;
  background-color: white;
  display: flex;
  margin-top: 2%;
}

.keyword img {
  position: absolute;
  width: 20px;
  top: 20%;
  margin-left: 0.2em;
}

.input {
  border: none;
  font-size: 15px;
  opacity: 0.5;
  color: #333333;
  width: 100%;
  padding-left: 1.5rem;
}

@media screen and (max-width: 700px) {
  .topHeading {
    margin: 1.5% 0 0 -86%;
  }
  .sectionStyle {
    position: relative;
  }
  .centered {
    width: 90%;
    left: 5%;
    top: 15%;
    position: absolute !important;
  }
  .keyword {
    width: 90vw;
    margin-left: -3rem;
    top: 6%;
    position: absolute !important;
  }
}

@media screen and (max-width: 1080px) {
  .headerStyle {
    padding: 0;
  }
  .keyword {
    top: 10%;
  }
  .centered {
    top: 16%;
    height: 79%;
    width: 87%;
    left: 12%;
  }
}
