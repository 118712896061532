.container {
  background-color: #f7f7f7;
  display: block;
  height: 90vh;
  overflow: auto;
  border-radius: 8px;
}

.mainheading {
  padding: 4% 0% 0% 6%;
  height: 100%;
  text-decoration: underline;
  width: 50%;
  opacity: 0.5;
}
.errorMsgbelow {
  font-size: 0.7rem;
  color: red;

  padding: -0.5% 0 0 0%;
  margin-top: -1.5%;
}
.imgUpload {
  height: 20%;
  margin: -7% auto 0% auto !important;
  cursor: "pointer";
  padding-top: 1rem;
}

.img2 {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 48%;
  bottom: 25%;
}
.closeModal {
  padding: 4% 4% 0% 85%;
  height: 100%;
  width: 8%;
  cursor: pointer;
}

.form_style {
  padding: 2% 3% 3% 3%;
}

.row {
  display: flex;
  flex-direction: row;
}

.rowone {
  display: flex;
  flex-direction: row;
}

.row2 {
  display: flex;
  flex-direction: column;
}

.col {
  display: flex;
  flex-direction: column;
  width: 48%;
  margin: 0 0 0 3%;
}

.colone {
  display: flex;
  flex-direction: column;
  width: 48%;
  margin: 0 0 0 3%;
}

.col2 {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 3%;
}

.category_select {
  width: 95%;
  height: 27px;
  border: 1px solid #b9b9b9;
  opacity: 1;
  margin: 2% 0 4% 0;
  border-radius: 6px;
}

.picdiv {
  width: 103%;
  height: auto;
  margin: 2% 0 4% 0;
  border: 1px solid #b9b9b9;
  border-radius: 6px 6px 6px 6px;
  background-color: white;
}

.mediadiv {
  width: 105%;
  height: auto;
  margin: 2% 0 4% 0;
  border: 1px solid #b9b9b9;
  border-radius: 6px 6px 6px 6px;
  background-color: white;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  min-height: 70px;
  min-width: 570px;
  height: 120px;
  overflow-x: hidden;
  overflow-y: auto;
}

.heading {
  opacity: 0.5;
}

.item {
  position: relative;
}

.errorshow {
  font-size: 0.7rem;
  color: red;
  margin: -3% 0 2% 0;
}

.myCheckbox1css {
  display: none;
}

.imglabel {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

.imglabel:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.images {
  width: 98%;
  height: 98px;
  margin: 1% 1% 1% 1%;
  background-color: rosybrown;
}

.img {
  /* / height: 13vh; / */
  height: 12vh;
  width: 16vh;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}
.img1 {
  height: 12vh;
  width: 16vh;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
  margin-left: 8px;
  margin-top: 25px;
}
.cross {
  position: absolute;
  margin-top: 14px;
  margin-left: -13px;
  cursor: pointer;
}

.warning {
  font-size: 0.7rem;
  color: red;
  margin: -3% 0px 0px 0px;
}
:checked + .imglabel {
  border-color: #ddd;
}

:checked + .imglabel:before {
  content: "✓";
  background-color: #36b453;
  transform: scale(0.6);
}

:checked + .imglabel img {
  transform: scale(0.9);
  /* / box-shadow: 0 0 5px #333; / */
  z-index: -1;
}

.textarea_style {
  height: 70px;
  width: 95.5%;
  border-radius: 6px;
  margin: 2% 0 4% 0;
  border-color: #b9b9b9;
  padding: 1% 0% 0% 2%;
  resize: none;
}
.textarea_style:focus,
.category_select:focus {
  outline: none;
}

.button_row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgDelete {
  height: 20%;
  margin: -6% auto -4% auto;
}

.showvideos {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 700px) {
  .gridContainer {
    min-width: 259px;
  }
  .imgUpload {
    margin-top: -13% !important;
  }
}

@media screen and (max-width: 1080px) {
  .container {
    height: 60vh;
  }
  .headerStyle {
    padding: 0;
  }
}
